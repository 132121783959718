import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useProductOrderDetailsQuery } from "../../hooks/order.hooks";
import { updateOrderStatus } from "../../services/orders.service";
import { formatDate } from "../../utils/constants";
import popMsg from "../../utils/popup";
import Layout from "../ui/Layout";
import DeliveryAddreddTable from "./DeliveryAddreddTable";
import OrderDetailsProductTable from "./OrderDetailsProductTable";
import OrderdUserDetailTable from "./OrderdUserDetailTable";

const OrderDetalPage = () => {
  const { id } = useParams();
  const [statusUpdated, setStatusUpdated] = useState(false);

  const handleStatusChange = (event) => {
    const value = event.target.value;
    updateStatus(value);
  };

  async function updateStatus(val) {
    let payload = { status: val };
    const data = await updateOrderStatus(payload, id);
    if (data.success) {
      popMsg(data.message, true);
      setStatusUpdated(true);
    }
  }

  const { data: OrderDetailsdata } = useProductOrderDetailsQuery(id);

  // const OrderDetailsdata = {
  //   order_id: "SHA1621712495",
  //   order_note: "Here is some note for the product-owner",
  //   discount: 10,
  //   coupon_code: "TY125",
  //   sub_total: 4200,
  //   total_paid: 4230,
  //   created_at: "21-06-2023 07:11 PM",
  //   shipping_charges: 40,
  //   payment_method: 1, // 0 || 1
  //   delivery_status: 0, // 0 | 1 | 2 | 3 | 4 | 5 | 6
  //   transaction_reference: null,
  //   payment_status: 1, // 0 | 1
  //   products: [
  //     {
  //       id: 1,
  //       product_title: "Phone",
  //       image: "2023-06-16-648c41fecb6cc.png",
  //       brand_name: "Taparia",
  //       final_price: 1080,
  //       tax_charges: 110,
  //       quantification: false,
  //       quantity: 1,
  //       // unit: "Piece",
  //     },
  //     {
  //       id: 2,
  //       product_title: "Tomato",
  //       image: "2023-06-16-648c436b353aa.png",
  //       brand_name: "Asian Paints",
  //       final_price: 95,
  //       tax_charges: 8,
  //       quantification: "2 KG",
  //       quantity: 2,
  //       // unit: "KG",
  //     },
  //   ],
  //   delivery_address: {
  //     customer_name: "Rahul",
  //     customer_phone: "9059079002",
  //     country_name: "India",
  //     state_name: "Telangana",
  //     city_name: "Warangal",
  //     zipcode: "506002",
  //     address: "Puppalagutta street",
  //     type: "Home",
  //   },
  //   ordered_by: {
  //     name: "Yash Shukla",
  //     email: "shukla@gmail.com",
  //     phone: "9845698778",
  //     country_name: "India",
  //     state_name: "Gujarat",
  //     city_name: "Ahmedabad",
  //     zipcode: "380015",
  //     address: "Jhodpur X-Road, Satellite.",
  //   },
  // };

  return (
    <Layout title="Order Details">
      {OrderDetailsdata && (
        <div style={{ color: "#333", overflow: "unset" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "2rem",
            }}
          >
            <div style={{ width: "68%" }}>
              {/* left */}
              <div
                style={{ display: "flex", gap: "2rem", marginBottom: "2rem" }}
              >
                {/* Order Detail */}
                <div>
                  <p>
                    <strong>Order ID : </strong>
                    <span>{OrderDetailsdata?.order_id}</span>{" "}
                  </p>
                  <p>
                    <strong>Ordered On : </strong>{" "}
                    <span>
                      {formatDate(OrderDetailsdata?.created_at, true, true)}
                    </span>
                  </p>
                  <div>
                    {/* payment summary */}
                    <p>
                      <strong>Payment Method :</strong>
                      <span>
                        {OrderDetailsdata?.payment_method === 0
                          ? "Cash On Delivery"
                          : "Online"}
                      </span>
                    </p>
                    <p>
                      <strong>Payment Status :</strong>
                      <span>
                        {OrderDetailsdata?.payment_status === 1
                          ? "Paid"
                          : "Pending"}
                      </span>
                    </p>
                    {OrderDetailsdata.transaction_reference && (
                      <p>
                        <strong>Transaction Reference :</strong>
                        <span>{OrderDetailsdata?.transaction_reference}</span>
                      </p>
                    )}
                  </div>
                </div>
                <div>
                  <p>
                    <strong>Order Note :</strong> {OrderDetailsdata?.order_note}
                  </p>
                </div>
              </div>
              <div>
                {/* Products */}
                <OrderDetailsProductTable
                  products={OrderDetailsdata?.products}
                  subTotal={OrderDetailsdata?.sub_total}
                  couponCode={OrderDetailsdata?.coupon_code}
                  discount={OrderDetailsdata?.discount}
                  total={OrderDetailsdata?.total_paid}
                  shippingCharges={OrderDetailsdata?.shipping_charges}
                />
              </div>
            </div>
            <div style={{ width: "30%" }}>
              {/* Right */}
              <div>
                <FormControl style={{ minWidth: "20rem" }} fullWidth>
                  <Select
                    variant="outlined"
                    value={OrderDetailsdata.delivery_status}
                    onChange={(e) => handleStatusChange(e)}
                  >
                    <InputLabel style={{ fontSize: "0.8rem" }}>
                      Order Delivary Status
                    </InputLabel>
                    <MenuItem value=""></MenuItem>
                    <MenuItem value="0">Pending</MenuItem>
                    <MenuItem value="1">Accepted</MenuItem>
                    <MenuItem value="2">Shipped</MenuItem>
                    <MenuItem value="3">Out for Delivery</MenuItem>
                    <MenuItem value="4">Delivered</MenuItem>
                    <MenuItem value="5">Cancelled</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div>
                {/* Ordered By */}
                <OrderdUserDetailTable
                  orderedBy={OrderDetailsdata?.ordered_by}
                />
              </div>
              <div>
                {/* Delivery Address */}
                <DeliveryAddreddTable
                  address={OrderDetailsdata?.delivery_address}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Toaster />
    </Layout>
  );
};

export default OrderDetalPage;

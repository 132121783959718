import { useEffect } from "react";
import Content from "./Content/Content";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Sidebar from "./Sidebar/Sidebar";

const Container = () => {
  return (
    <>
      <div className="container">
        <Sidebar />
        <Header />
        <Content />
        <Footer />
      </div>
    </>
  );
};

export default Container;

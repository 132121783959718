import React, { useEffect, useState } from "react";
import Footer from "../Container/Footer/Footer";
import Header from "../Container/Header/Header";
import Sidebar from "../Container/Sidebar/Sidebar";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";
import VendorSideBar from "../VENDOR/mainPage/vendor_sidebar/Vendor_Sidebar";
import { isAdmin, isAgency, isVendor } from "../../utils/constants";
import AgencySidebar from "../AGENCYLOGIN/mainPage/Agency_sidebar/Agency_Sidebar";

const Layout = (props) => {
  const [userRole, setUserRole] = useState();

  useEffect(() => {
    const token = Cookies.get("content");
    if (token) {
      let tokenData = jwtDecode(token);
      setUserRole(tokenData.role);
    }
  }, []);
  return (
    <>
      <div className="container">
        {isVendor() ? (
          <VendorSideBar />
        ) : isAdmin() ? (
          <Sidebar />
        ) : isAgency() ? (
          <AgencySidebar />
        ) : (
          ""
        )}
        <Header name={props.title} />
        <div className="content_category">{props.children}</div>
        <Footer />
      </div>
    </>
  );
};

export default Layout;

import axios from "../axios";
import { apiRoutes } from "../utils/apiRoutes";
import { emailRegex, phoneRegex } from "../utils/constants";
import popMsg from "../utils/popup";

async function getAllSubadmin() {
  try {
    const response = await axios.get(`${apiRoutes.getSubadmins}`);
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function addSubadmin(payload, setIsLoading) {
  setIsLoading(true);
  let { email, phone } = payload;

  let errorMessages = {
    name: "Name Field is Required",
    email: "Entre Your Email Address",
    phone: "Phone number is Required",
  };

  for (const field in payload) {
    if (!payload[field]) {
      popMsg(errorMessages[payload[field]]);
      return;
    }
  }
  if (!emailRegex.test(email)) {
    popMsg("Invalid Email Address");
    return;
  }
  if (!phoneRegex.test(phone)) {
    popMsg("Invalid Phone Number");
    return;
  }

  try {
    const response = await axios.post(`${apiRoutes.addSubadmin}`, payload);
    const data = response.data;
    return data;
  } catch (err) {
    console.log(err);
  } finally {
    setIsLoading(false);
  }
}

async function updateSubadmin(payload, subadminId, setIsLoading) {
  setIsLoading(true);
  let { email, phone } = payload;

  let errorMessages = {
    name: "Name Field is Required",
    email: "Entre Your Email Address",
    phone: "Phone number is Required",
  };

  for (const field in payload) {
    if (!payload[field]) {
      popMsg(errorMessages[payload[field]]);
      return;
    }
  }
  if (!emailRegex.test(email)) {
    popMsg("Invalid Email Address");
    return;
  }
  if (!phoneRegex.test(phone)) {
    popMsg("Invalid Phone Number");
    return;
  }
  try {
    const response = await axios.post(
      `${apiRoutes.updateSubadmin}${subadminId}`,
      payload
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  } finally {
    setIsLoading(false);
  }
}

export { getAllSubadmin, addSubadmin, updateSubadmin };

import axios from "../axios";
import { AxiosError } from "axios";
import popMsg from "./popup";
import Cookies from "js-cookie";
import jwtDecode from "jwt-decode";

export const initialRows = 10;
export const baseUrl = "https://shaiper.karbh.com";
// export const baseUrl = "http://192.168.1.33:8000";
export const imagepath_services = "/storage/app/public/service/";
export const imagepath_brand = `${baseUrl}/storage/app/public/brand/`;
export const imagepath_subCat = "/storage/app/public/subcategory/";
export const imagepath_subSubCat = "/storage/app/public/subsubcategory/";
export const imagepath_notifications = "/storage/app/public/notification/";
export const imagepath_product = "/storage/app/public/product/";
export const imagepath_categories = `${baseUrl}/storage/app/public/category/`;
export const imagepath_slider = `${baseUrl}/storage/app/public/slider/`;
export const imagepath_sections = `${baseUrl}/storage/app/public/section/`;

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
export const phoneRegex = /^\d{10,11}$/;
export const currency = "₹";

export async function deleteItem(url, setDeleted) {
  try {
    const response = await axios.get(url);
    const data = await response.data;
    if (data.success) {
      setDeleted(true);
    }
    return data;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data && errorResponse.data.message) {
        return err.response?.data;
      }
    }
  }
}

export async function updateStatus(url, payload, statusUpdated) {
  try {
    const response = await axios.post(`${url}`, payload);
    const data = await response.data;
    if (data.success) {
      popMsg(data.message, true);
      statusUpdated(true);
    }
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data && errorResponse.data.message) {
        return err.response?.data;
      }
    }
  }
}

export const formatDate = (date, showWeekday = true, showTime = true) => {
  if (!(date instanceof Date)) {
    date = new Date(date);
    if (isNaN(date.getTime())) {
      return "";
    }
  }
  const options = {
    year: "numeric",
    month: "short",
    day: "2-digit",
    timeZone: "Asia/Kolkata",
  };
  if (showWeekday) options.weekday = "long";
  if (showTime) {
    options.hour = "2-digit";
    options.minute = "2-digit";
    options.second = "2-digit";
  }
  return new Intl.DateTimeFormat("en-IN", options).format(date);
};

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function filterData(searchKey, searchValue, data, setRenderedData) {
  const filtered =
    data &&
    data.filter((cat) => {
      return cat[searchKey]
        .toString()
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });
  setRenderedData(filtered);
}

export function mapSerialNumber(data) {
  const mapped =
    data &&
    data.length &&
    data.map((item, index) => ({ ...item, serialNumber: index + 1 }));
  return mapped;
}

export function isAdmin() {
  let token = Cookies.get("content");
  let tokenInfo = jwtDecode(token);
  let userRole = tokenInfo.role;
  if (userRole === 1) return true;
  return false;
}
export function isVendor() {
  let token = Cookies.get("content");
  let tokenInfo = jwtDecode(token);
  let userRole = tokenInfo.role;
  if (userRole === 3) return true;
  return false;
}

export function isAgency() {
  let token = Cookies.get("content");
  let tokenInfo = jwtDecode(token);
  let userRole = tokenInfo.role;
  if (userRole === 2) return true;
  return false;
}

export function isAlphaNumeric(value) {
  const alphanumericRegex = /^[a-zA-Z0-9]*$/;
  return alphanumericRegex.test(value);
}

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addVendor,
  getAllVendors,
  updateVendor,
} from "../services/vendor.service";

const useVendorQueries = () => {
  return useQuery({
    queryKey: ["vendors"],
    queryFn: getAllVendors,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useAddVendorData = () => {
  const queryClient = useQueryClient();
  return useMutation(addVendor, {
    onSettled: () => {
      queryClient.invalidateQueries("vendors");
    },
    onError: (error) => {
      console.error("error when adding new Vendor", error);
    },
  });
};

const useUpdateVendorData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateVendor, {
    onSettled: () => {
      queryClient.invalidateQueries("vendors");
    },
    onError: (error) => {
      console.error("error when updating Vendor", error);
    },
  });
};

export { useAddVendorData, useVendorQueries, useUpdateVendorData };

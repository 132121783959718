import { CircularProgress } from "@material-ui/core";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useServiceOrdersQuery } from "../../hooks/order.hooks";
import { filterData, formatDate } from "../../utils/constants";
import Layout from "../ui/Layout";
import MyTable from "../ui/MyTable";
import TopBlock from "../ui/TopBlock";

const ServiceOrders = () => {
  const [search, setSearch] = useState("");
  const [renderedData, setRenderedData] = useState([]);

  const navigate = useNavigate();
  const columnHelper = createColumnHelper();
  const { data, isLoading } = useServiceOrdersQuery();

  useEffect(() => {
    filterData("order_id", search, data, setRenderedData);
  }, [search, data]);

  const columns = [
    columnHelper.accessor("serialNumber", {
      header: "S.No",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("order_id", {
      header: "Order ID",
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      cell: (info) => {
        const orderId = info.getValue();
        const handleClick = () => {
          navigate(`/serviceorder/${info.row.original.id}`);
        };
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={handleClick}
          >
            {" "}
            {orderId}
          </span>
        );
      },
    }),
    columnHelper.accessor("user_name", {
      header: "Customer name",
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableColumnFilter: false,
    }),
    columnHelper.accessor("created_at", {
      header: "Order Date",
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      cell: (info) => {
        const date = info.getValue();
        return <span>{formatDate(date, false, true)}</span>;
      },
    }),
    columnHelper.accessor("quoted_price", {
      header: "Quoted Price",
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      cell: (info) => {
        const quotedPrice = info.getValue();
        if (!quotedPrice) return <span>Not-Quoted</span>;
        return <span>{quotedPrice}</span>;
      },
    }),
    columnHelper.accessor("service_status", {
      header: "Service Status",
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      cell: (info) => {
        const status = info.getValue();
        if (status === 0)
          return <span style={{ color: "orange" }}>Quoted</span>;
        if (status === 1)
          return <span style={{ color: "blue" }}>Accepted</span>;
        if (status === 2)
          return <span style={{ color: "green" }}>Completed</span>;
        if (status === 3) return <span style={{ color: "red" }}>Canceled</span>;
      },
    }),
  ];

  return (
    <Layout title="Service Orders">
      <div style={{ overflow: "unset" }}>
        <TopBlock search={search} setSearch={setSearch} dontShowAdd={true} />
        {isLoading && (
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        )}
        {!isLoading && renderedData && renderedData.length && (
          <MyTable columns={columns} data={renderedData} />
        )}
      </div>
      <Toaster />
    </Layout>
  );
};

export default ServiceOrders;

import {
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  makeStyles,
  Dialog,
  FormControl,
  InputLabel,
  DialogActions,
  Button,
  Input,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { imagepath_brand } from "../../../utils/constants";
import popMsg from "../../../utils/popup";
import { useQueryClient } from "@tanstack/react-query";
import { useUpdateBrandData } from "../../../hooks/brand.hooks";

const UpdateBrand = ({ open, setOpen, brand }) => {
  const [brandId, setBrandId] = useState();
  const [brandName, setBrandName] = useState();
  const [oldImage, setOldImage] = useState();
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();

  const queryClient = useQueryClient();
  const { mutateAsync: updateBrand } = useUpdateBrandData();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!brand) return;
    setBrandId(brand.id);
    setBrandName(brand.brand_name);
    setOldImage(brand.image);
  }, [brand]);

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  async function handleSave() {
    const brand = {
      id: brandId,
      brand_name: brandName,
      image,
    };
    updateBrand(brand, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("brands");
      },
    });
  }

  function reset() {
    setBrandName("");
    setImage(null);
    setImagePreview("");
    setOldImage("");
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Update Brand</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Brand Name"
              value={brandName}
              onChange={(e) => setBrandName(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
            />
          </Grid>
          {oldImage && (
            <Grid item xs={12}>
              <img
                src={`${imagepath_brand}${oldImage}`}
                alt="old"
                style={{ height: "10rem", width: "15rem" }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Select Image :
              </InputLabel>
              <Input
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                type="file"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <img
                  style={{
                    height: "10rem",
                    width: "15rem",
                    marginTop: "1rem",
                    marginLeft: "10%",
                  }}
                  src={imagePreview}
                  alt={"service-image"}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button onClick={handleSave} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateBrand;

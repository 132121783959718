import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getAllProducts,
  updateProductAdminDecision,
} from "../services/main-product.service";

const useProductQuery = () => {
  return useQuery({
    queryKey: ["products"],
    queryFn: getAllProducts,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useUpdateAdminProductDecision = () => {
  // const queryClient = useQueryClient();
  return useMutation(updateProductAdminDecision, {
    onError: (err) => {
      console.error("error while updating admin product decision", err);
    },
  });
};

export { useProductQuery, useUpdateAdminProductDecision };

import { Typography } from "@material-ui/core";
const Footer = () => {
  return (
    <>
      <div className="footer" style={{height:"50px",position:"relative"}}>
        <Typography variant="h5" component={"caption"} style={{fontFamily:"initial",color:"white"}}>Contact Us : 9999999990</Typography>
      </div>
    </>
  );
};

export default Footer;

import axios from "../axios";
import { AxiosError } from "axios";
import { apiRoutes } from "../utils/apiRoutes";
import popMsg from "../utils/popup";
import { isValidEmail } from "../utils/constants";

async function getAllAgencies() {
  try {
    const response = await axios.get(`${apiRoutes.getAgencies}`);
    const data = response.data;
    return data.agency;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data) {
        return err.response.data;
      }
    }
  }
}

async function addAgency(payload) {
  let {
    company_name,
    display_name,
    company_phone_number,
    company_email,
    contact_person_name,
    contact_person_phone_number,
    contact_person_email,
    company_adress,
    company_warehouse_adress,
    company_gst,
    company_pan_number,
    country_id,
    state_id,
    city_id,
    zipcode_id,
    reference_id,
    service_id,
  } = payload;
  if (!company_name) {
    popMsg("Company name is required");
    return;
  }
  if (!display_name) {
    popMsg("Display name is required");
    return;
  }
  if (!company_phone_number) {
    popMsg("Company phone number is required");
    return;
  }
  if (company_phone_number.length < 10) {
    popMsg("Entre valid Company Phone number");
    return;
  }
  if (!company_email) {
    popMsg("Company email is required");
    return;
  }
  if (!isValidEmail(company_email)) {
    popMsg("Entre a valid Comapny Email");
    return;
  }
  if (!contact_person_name) {
    popMsg("Contact person name is required");
    return;
  }
  if (!contact_person_phone_number) {
    popMsg("Contact person phone number is required");
    return;
  }
  if (contact_person_phone_number.length < 10) {
    popMsg("Entre a valid Contact person Phone number");
    return;
  }
  if (!contact_person_email) {
    popMsg("Contact person email is required");
    return;
  }
  if (!isValidEmail(contact_person_email)) {
    popMsg("Entre valid Email for Contact person");
  }
  if (!company_adress) {
    popMsg("Company address is required");
    return;
  }
  if (!company_warehouse_adress) {
    popMsg("Company warehouse address is required");
    return;
  }
  if (!company_gst) {
    popMsg("Company GST is required");
    return;
  }
  if (company_gst.length !== 15) {
    popMsg("Invalid GSTN");
    return;
  }
  if (!company_pan_number) {
    popMsg("Company PAN number is required");
    return;
  }
  if (company_pan_number.length !== 10) {
    popMsg("Invalid PAN-Number");
  }
  if (!country_id) {
    popMsg("Country ID is required");
    return;
  }
  if (!state_id) {
    popMsg("State ID is required");
    return;
  }
  if (!city_id) {
    popMsg("City ID is required");
    return;
  }
  if (zipcode_id.length === 0) {
    popMsg("Zip code ID is required");
    return;
  }

  if (!service_id) {
    popMsg("Select Service");
    return;
  }
  return axios.post(`${apiRoutes.addAgency}`, payload);
  // try {
  //   const response = await axios.post(`${apiRoutes.addAgency}`, payload);
  //   const data = response.data;
  //   return data;
  // } catch (err) {
  //   console.error(err);
  // }
}

async function updateAgency(payload) {
  let {
    agencyId,
    company_name,
    display_name,
    company_phone_number,
    company_email,
    contact_person_name,
    contact_person_phone_number,
    contact_person_email,
    company_adress,
    company_warehouse_adress,
    company_gst,
    company_pan_number,
    country_id,
    state_id,
    city_id,
    zipcode_id,
    reference_id,
    service_id,
  } = payload;
  if (!company_name) {
    popMsg("Company name is required");
    return;
  }
  if (!display_name) {
    popMsg("Display name is required");
    return;
  }
  if (!company_phone_number) {
    popMsg("Company phone number is required");
    return;
  }
  if (company_phone_number.length < 10) {
    popMsg("Entre valid Company Phone number");
    return;
  }
  if (!company_email) {
    popMsg("Company email is required");
    return;
  }
  if (!isValidEmail(company_email)) {
    popMsg("Entre a valid Comapny Email");
    return;
  }
  if (!contact_person_name) {
    popMsg("Contact person name is required");
    return;
  }
  if (!contact_person_phone_number) {
    popMsg("Contact person phone number is required");
    return;
  }
  if (contact_person_phone_number.length < 10) {
    popMsg("Entre a valid Contact person Phone number");
    return;
  }
  if (!contact_person_email) {
    popMsg("Contact person email is required");
    return;
  }
  if (!isValidEmail(contact_person_email)) {
    popMsg("Entre valid Email for Contact person");
  }
  if (!company_adress) {
    popMsg("Company address is required");
    return;
  }
  if (!company_warehouse_adress) {
    popMsg("Company warehouse address is required");
    return;
  }
  if (!company_gst) {
    popMsg("Company GST is required");
    return;
  }
  if (company_gst.length !== 15) {
    popMsg("Invalid GSTN");
    return;
  }
  if (!company_pan_number) {
    popMsg("Company PAN number is required");
    return;
  }
  if (company_pan_number.length !== 10) {
    popMsg("Invalid PAN-Number");
  }
  if (!country_id) {
    popMsg("Country ID is required");
    return;
  }
  if (!state_id) {
    popMsg("State ID is required");
    return;
  }
  if (!city_id) {
    popMsg("City ID is required");
    return;
  }
  if (zipcode_id.length === 0) {
    popMsg("Zip code ID is required");
    return;
  }
  if (!service_id) {
    popMsg("Select Service");
    return;
  }
  return axios.post(`${apiRoutes.updateAgency}${agencyId}`, payload);
}

export { getAllAgencies, addAgency, updateAgency };

import {
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Button,
  MenuItem,
  TextField,
  Input,
  makeStyles,
  Dialog,
} from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useState } from "react";
import { useCategoriesQuery } from "../../../hooks/category.hooks";
import { useUpdateSubCategoryData } from "../../../hooks/subcategory.hooks";
import { baseUrl, imagepath_subCat } from "../../../utils/constants";
import popMsg from "../../../utils/popup";

const UpdateSubCategory = ({ open, setOpen, sub }) => {
  const [subId, setSubId] = useState();
  const [category, setCategory] = useState();
  const [subCategoryName, setSubCategoryName] = useState();
  const [oldImage, setOldImage] = useState();
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();

  const queryClient = useQueryClient();
  const { mutateAsync: updateSubCategory } = useUpdateSubCategoryData();
  const { data: categoryData } = useCategoriesQuery();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!sub) return;
    setSubId(sub.id);
    setCategory(sub.category_id);
    setSubCategoryName(sub.sub_category_name);
    setOldImage(sub.image);
  }, [sub]);

  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleClose = () => {
    setOpen(false);
    // reset();
    setImage(null);
    setImagePreview("");
  };

  const handleSave = () => {
    const subcat = {
      id: subId,
      category_id: category,
      sub_category_name: subCategoryName,
      image,
    };
    updateSubCategory(subcat, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("sub-categories");
      },
    });
  };

  // function reset() {
  //   setCategory("");
  //   setSubCategoryName("");
  //   setImage(null);
  //   setOldImage("");
  //   setImagePreview("");
  // }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Update Sub Sub Category</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Select Category
              </InputLabel>
              <Select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                }}
              >
                <MenuItem value=""></MenuItem>
                {categoryData &&
                  categoryData.length &&
                  categoryData.map((cat) => {
                    return (
                      <MenuItem value={cat.id} key={cat.id}>
                        {cat.category_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="sub category name"
              value={subCategoryName}
              onChange={(e) => setSubCategoryName(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          {oldImage && (
            <Grid item xs={12}>
              <img
                style={{ width: "15rem", height: "10rem" }}
                alt="old_sub_cat_img"
                src={`${baseUrl}${imagepath_subCat}${oldImage}`}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Select Image :
              </InputLabel>
              <Input
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                type="file"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <img
                  style={{
                    height: "10rem",
                    width: "15rem",
                    marginTop: "1rem",
                    marginLeft: "10%",
                  }}
                  src={imagePreview}
                  alt={"service-image"}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button variant="outlined" onClick={handleSave} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateSubCategory;

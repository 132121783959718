import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Table,
    TableCell,
    TableContainer,
    TableRow,
    Typography,
  } from "@material-ui/core";
  
  const ServiceDetail = ({
    openProfile,
    setOpenProfile,
    service_name,
    service_type,
    service_description
  }) => {
    const useStyle = makeStyles({
      root: {
        backgroundColor: "white",
        "&:hover": {
          backgroundColor: "#023F9E",
          color: "white",
        },
      },
    });
    const classes = useStyle();
    return (
      <>
        <Box sx={{ width: 1, maxWidth: "lg" }}>
          <Dialog
            fullWidth
            sx={{ width: "75%" }}
            open={openProfile}
            //   onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              <Typography
                style={{ fontWeight: 600, marginLeft: "200px" }}
                variant="h5"
                className="ShowingLableName"
                display="block"
                gutterBottom
              >
                Serivce
              </Typography>
            </DialogTitle>
            <DialogContent>
              <TableContainer>
                <Table>
                  <TableRow>
                    <TableCell>Service name</TableCell>
                    <TableCell>{service_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Service Type</TableCell>
                    <TableCell>{service_type === 1 ? 'By Profession' : "By Requirement" }</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Service Description</TableCell>
                    <TableCell>{service_description}</TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.root}
                style={{
                  borderRadius: 8,
                  padding: "10x",
                  fontSize: "18px",
                  marginTop: "20px",
                  marginRight: "10px",
                  borderColor: "#023F9E",
                }}
                variant="outlined"
                onClick={() => setOpenProfile(false)}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </>
    );
  };
  
  export default ServiceDetail;
  
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

import { addVendor as add } from "../../../services/vendor.service";
import popMsg from "../../../utils/popup";
import {
  getCities,
  getCountries,
  getStates,
  getZipcodes,
} from "../../../services/address.service";

import {
  getCategories,
  getSubCategories,
  getSubSubCategories,
} from "../../../services/product.service";
import { useAddVendorData } from "../../../hooks/vendor.hooks";

const AddVendor = ({ open, setOpen, setAddedVendor }) => {
  const [companyName, setCompanyName] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [mobile, setMobile] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonPhone, setContactPersonPhone] = useState("");
  const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [warehouseAddress, setWarehouseAddress] = useState("");
  const [companyGST, setCompanyGST] = useState("");
  const [companyPAN, setCompanyPAN] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipcode, setZipcode] = useState("");

  const [countryData, setCountryData] = useState();
  const [stateData, setStateData] = useState();
  const [cityData, setCityData] = useState();
  const [zipData, setZipData] = useState();

  const [categoryData, setCategoryData] = useState();
  const [subCategoryData, setSubCategoryData] = useState();
  const [subSubCategoryData, setSubSubCategoryData] = useState();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const { mutateAsync: addVendorData, isLoading } = useAddVendorData();

  //   UseEffects
  useEffect(() => {
    getCountryData();
    getCategoryData();
  }, []);
  useEffect(() => {
    setState("");
    setCity("");
    setZipcode("");
    if (country) getStatesDate(country);
  }, [country]);
  useEffect(() => {
    setCity("");
    setZipcode("");
    if (state) getCityData(state);
  }, [state]);
  useEffect(() => {
    setZipcode("");
    if (city) getZipData(city);
  }, [city]);
  useEffect(() => {
    setSubCategory("");
    setSubSubCategory("");
    if (category) getSubCategoryData(category);
  }, [category]);
  useEffect(() => {
    setSubSubCategory("");
    if (subCategory) getSubSubCategoryData(subCategory);
  }, [subCategory]);

  // Handlers
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  const handleSave = () => {
    // Perform save action here
    addVendor();
  };

  function resetForm() {
    setCompanyName("");
    setDisplayName("");
    setMobile("");
    setCompanyEmail("");
    setContactPersonName("");
    setContactPersonPhone("");
    setContactPersonEmail("");
    setCompanyAddress("");
    setWarehouseAddress("");
    setCompanyGST("");
    setCompanyPAN("");
    setCategory("");
    setSubCategory("");
    setSubSubCategory("");
    setCountry("");
    setState("");
    setCity("");
    setZipcode("");
  }

  //   Functions

  async function addVendor() {
    // setIsLoading(true);
    let payload = {
      company_name: companyName,
      display_name: displayName,
      company_phone_number: mobile,
      company_email: companyEmail,
      contact_person_name: contactPersonName,
      contact_person_phone_number: contactPersonPhone,
      contact_person_email: contactPersonEmail,
      company_adress: companyAddress,
      company_warehouse_adress: warehouseAddress,
      company_gst: companyGST,
      company_pan_number: companyPAN,
      category_id: category,
      sub_category_id: subCategory,
      sub_sub_category_id: subSubCategory,
      country_id: country,
      state_id: state,
      city_id: city,
      zipcode_id: zipcode,
    };
    addVendorData(payload, {
      onSuccess: (res) => {
        setAddedVendor(true);
        popMsg(res.data.message, res.data.success);
        handleClose();
      },
    });
    // console.log(payload);
    // const data = await add(payload);
    // popMsg(data.message, data.success);
    // if (data.success) {
    //   setAddedVendor(true);
    //   handleClose();
    // }
    // // setIsLoading(false);
  }

  async function getCountryData() {
    const data = await getCountries();
    setCountryData(data);
  }
  async function getStatesDate(countryId) {
    const data = await getStates(countryId);
    setStateData(data);
  }
  async function getCityData(stateId) {
    const data = await getCities(stateId);
    setCityData(data);
  }
  async function getZipData(cityId) {
    const data = await getZipcodes(cityId);
    setZipData(data);
  }

  async function getCategoryData() {
    const data = await getCategories();
    let filtered = data.map((cat) => ({
      id: cat.id,
      name: cat.category_name,
    }));
    setCategoryData(filtered);
  }
  async function getSubCategoryData(categoryId) {
    const data = await getSubCategories();
    let filtered = data
      .filter((subCat) => subCat.category_id === categoryId)
      .map((sub) => ({ id: sub.id, name: sub.sub_category_name }));
    setSubCategoryData(filtered);
  }

  async function getSubSubCategoryData(subId) {
    const data = await getSubSubCategories();
    let filtered = data
      .filter((ssc) => ssc.sub_category_id === subId)
      .map((ssc) => ({ id: ssc.id, name: ssc.sub_sub_category_name }));
    setSubSubCategoryData(filtered);
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Add Vendor</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Display Name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Mobile Number"
              value={mobile}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const numbersOnly = enteredValue.replace(/[^0-9]/g, "");
                const truncatedValues = numbersOnly.slice(0, 11);
                setMobile(truncatedValues);
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Company E-mail"
              value={companyEmail}
              onChange={(e) => {
                setCompanyEmail(e.target.value);
              }}
              type="email"
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Contact Person Name"
              value={contactPersonName}
              onChange={(e) => setContactPersonName(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Contact Person Phone"
              value={contactPersonPhone}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const numbersOnly = enteredValue.replace(/[^0-9]/g, "");
                const truncatedValues = numbersOnly.slice(0, 11);
                setContactPersonPhone(truncatedValues);
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Contact Person Email"
              value={contactPersonEmail}
              onChange={(e) => setContactPersonEmail(e.target.value)}
              type="email"
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Company Address"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Warehouse Address"
              value={warehouseAddress}
              onChange={(e) => setWarehouseAddress(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Company GST"
              value={companyGST}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphanumericValue = inputValue
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toUpperCase();
                const trimmedValue = alphanumericValue.slice(0, 15);
                setCompanyGST(trimmedValue);
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Company PAN"
              value={companyPAN}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphanumericValue = inputValue
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toUpperCase();
                const trimmedValue = alphanumericValue.slice(0, 10);
                setCompanyPAN(trimmedValue);
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>Category</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {categoryData &&
                  categoryData.map((cat) => {
                    return (
                      <MenuItem value={cat.id} key={cat.id}>
                        {cat.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Sub Category
              </InputLabel>
              <Select
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {subCategoryData &&
                  subCategoryData.map((subCat) => {
                    return (
                      <MenuItem value={subCat.id} key={subCat.id}>
                        {subCat.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Sub Sub Category
              </InputLabel>
              <Select
                value={subSubCategory}
                onChange={(e) => setSubSubCategory(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {subSubCategoryData &&
                  subSubCategoryData.map((ssc) => {
                    return (
                      <MenuItem value={ssc.id} key={ssc.id}>
                        {ssc.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>Country</InputLabel>
              <Select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {countryData &&
                  countryData.map((country) => {
                    return (
                      <MenuItem value={country.id} key={country.id}>
                        {country.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>State</InputLabel>
              <Select value={state} onChange={(e) => setState(e.target.value)}>
                <MenuItem value=""></MenuItem>
                {stateData &&
                  stateData.map((state) => {
                    return (
                      <MenuItem value={state.id} key={state.id}>
                        {state.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>City</InputLabel>
              <Select value={city} onChange={(e) => setCity(e.target.value)}>
                <MenuItem value=""></MenuItem>
                {cityData &&
                  cityData.map((city) => {
                    return (
                      <MenuItem value={city.id} key={city.id}>
                        {city.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>Zipcode</InputLabel>
              <Select
                value={zipcode}
                onChange={(e) => setZipcode(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {zipData &&
                  zipData.map((zip) => {
                    return (
                      <MenuItem value={zip.id} key={zip.id}>
                        {zip.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button disabled={isLoading} onClick={handleSave} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddVendor;

import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  makeStyles,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TablePagination,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import React, { useEffect } from "react";
import { useState } from "react";
import { initialRows } from "../../../utils/constants";

const QuantificationTable = ({ quanData, setQuanData }) => {
  const [quantity, setQuantity] = useState();
  const [unit, setUnit] = useState();
  const [purchasePrice, setPurchaseprice] = useState();
  const [salePrice, setSalePrice] = useState();
  const [discountType, setDiscountType] = useState();
  const [discount, setDiscount] = useState(0);
  const [finalPrice, setFinalPrice] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
    tableCellHead: {
      fontSize: "1rem",
      fontWeight: "600",
      color: "#36454F",
    },
    tableCellBody: {
      fontSize: "14px",
    },
    icons: {
      width: "25px",
      height: "25px",
      color: "#023F9E",
      padding: "5px",
      cursor: "pointer",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    handleCalculateFinalPrice();
  }, [salePrice, discount, discountType]);

  function reset() {
    setQuantity("");
    setUnit("0");
    setPurchaseprice("");
    setSalePrice("");
    setDiscountType("");
    setDiscount(0);
    setFinalPrice(0);
  }

  // Handlers
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
  };

  const handleCalculateFinalPrice = () => {
    let price;
    if (salePrice && discount) {
      if (discountType === "INR") price = salePrice - discount;
      if (discountType === "%")
        price = salePrice - (salePrice * discount) / 100;
    } else {
      price = 0;
    }
    setFinalPrice(price);
  };

  const handleDeleteQuantification = (index) => {
    let filtered = quanData.filter((quan, idx) => {
      if (idx !== index) return quan;
    });
    setQuanData([...filtered]);
  };

  const handleAddQuantification = () => {
    if (finalPrice === 0) return;
    let obj = {
      quantify: quantity,
      unit: unit,
      purchase_price: purchasePrice,
      sale_price: salePrice,
      final_price_amount: finalPrice,
      discount_type: discountType,
      discount_amount: discount,
      stock: 0,
    };
    setQuanData([...quanData, obj]);
    reset();
  };

  return (
    <div
      style={{
        overflow: "unset",
        margin: "3rem 0rem 3rem 0rem",
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={true}>
          <FormControl fullWidth>
            <TextField
              size="small"
              label="Quantity"
              value={quantity}
              onChange={(e) => {
                const input = e.target.value;
                const regex = /^[0-9]{0,6}$/; // Regex to match only digits and limit to 6 characters
                if (regex.test(input)) {
                  setQuantity(input);
                }
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={true}>
          <FormControl fullWidth>
            <InputLabel style={{ fontSize: "0.8rem" }}>Select Unit</InputLabel>
            <Select
              className="dropdown_product"
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
            >
              <MenuItem value="0"></MenuItem>
              <MenuItem value="1">Dozen</MenuItem>
              <MenuItem value="2">KG</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={true}>
          <FormControl fullWidth>
            <TextField
              size="small"
              label="Sale Price"
              value={salePrice}
              onChange={(e) => {
                const input = e.target.value;
                const regex = /^[0-9]{0,6}$/; // Regex to match only digits and limit to 6 characters
                if (regex.test(input)) {
                  setSalePrice(Number(input));
                }
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </FormControl>
        </Grid>
        <Grid item xs={true}>
          <TextField
            size="small"
            label="Purchase Price"
            value={purchasePrice}
            onChange={(e) => {
              const input = e.target.value;
              const regex = /^[0-9]{0,6}$/; // Regex to match only digits and limit to 6 characters
              if (regex.test(input)) {
                setPurchaseprice(Number(input));
              }
            }}
            InputLabelProps={{
              className: classes.textFieldLabel,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={true}>
          <FormControl fullWidth>
            <InputLabel style={{ fontSize: "0.8rem" }}>
              Discount Type
            </InputLabel>
            <Select
              className="dropdown_product"
              value={discountType}
              onChange={(e) => {
                setDiscount(0);
                setDiscountType(e.target.value);
              }}
            >
              <MenuItem value=""></MenuItem>
              <MenuItem value={"INR"}>INR</MenuItem>
              <MenuItem value={"%"}>Percentage</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={true}>
          <TextField
            size="small"
            label="Discount"
            value={discount}
            onChange={(e) => {
              let input = e.target.value;
              const isValidNumber = /^\d*$/.test(input);
              if (isValidNumber) {
                let val = Number(input);
                if (discountType === "%" && val > 100) return;
                setDiscount(Number(e.target.value));
              }
            }}
            InputLabelProps={{
              className: classes.textFieldLabel,
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={true}>
          <TextField
            label="Final Price"
            size="small"
            value={finalPrice}
            onChange={(e) => setFinalPrice(Number(e.target.value))}
            InputLabelProps={{
              className: classes.textFieldLabel,
            }}
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={true}>
          <Button onClick={handleAddQuantification} variant="outlined">
            Add
          </Button>
        </Grid>
      </Grid>
      {quanData && quanData.length > 0 && (
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCellHead}>
                  Quantity
                </TableCell>
                <TableCell className={classes.tableCellHead}>Unit</TableCell>
                <TableCell className={classes.tableCellHead}>
                  Sale Price
                </TableCell>
                <TableCell className={classes.tableCellHead}>
                  Purchase Price
                </TableCell>
                <TableCell className={classes.tableCellHead}>
                  Discount Type
                </TableCell>
                <TableCell className={classes.tableCellHead}>
                  Discount Amount
                </TableCell>
                <TableCell className={classes.tableCellHead}>
                  Final Price
                </TableCell>
                <TableCell className={classes.tableCellHead}>Delete</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {quanData.map((quan, index) => {
                return (
                  <TableRow>
                    <TableCell className={classes.tableCellBody}>
                      {quan.quantify}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {quan.unit}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {quan.sale_price}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {quan.purchase_price}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {quan.discount_type}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {quan.discount_amount}
                    </TableCell>
                    <TableCell className={classes.tableCellBody}>
                      {quan.final_price_amount}
                    </TableCell>
                    <TableCell>
                      <div>
                        <Delete
                          style={{ cursor: "pointer" }}
                          onClick={() => handleDeleteQuantification(index)}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          <TablePagination
            sx={{ px: 2 }}
            page={page}
            component="div"
            rowsPerPage={rowsPerPage}
            count={quanData && quanData.length ? quanData.length : 0}
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            nextIconButtonProps={{ "aria-label": "Next Page" }}
            backIconButtonProps={{ "aria-label": "Previous Page" }}
            showfirstbutton="true"
            showlastbutton="true"
          />
        </TableContainer>
      )}
    </div>
  );
};

export default QuantificationTable;

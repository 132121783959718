import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Input,
} from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import popMsg from "../../../utils/popup";
import { useEffect } from "react";
import { useUpdateCategory } from "../../../hooks/category.hooks";
import { imagepath_categories } from "../../../utils/constants";
import { useBrandsQuery } from "../../../hooks/brand.hooks";
import { Autocomplete } from "@mui/material";

const EditCategory = ({ open, setOpen, category }) => {
  const [categoryId, setCategoryId] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [brandIds, setBrandIds] = useState([]);
  const [brands, setBrands] = useState([]);

  const queryClient = useQueryClient();
  const { mutate: updateCategory } = useUpdateCategory();
  const { data: brandsData } = useBrandsQuery();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    const category = {
      id: categoryId,
      category_name: categoryName,
      image,
      brands: brandIds,
    };
    updateCategory(category, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("categories");
      },
    });
  };

  useEffect(() => {
    if (!category) return;
    setCategoryId(category.id);
    setCategoryName(category.category_name);
    setOldImage(category.image);
    setBrandIds(category.brands);
  }, [category]);

  useEffect(() => {
    const filtered =
      brandsData &&
      brandsData.filter((brand) => {
        if (brandIds.includes(brand.id)) return brand;
      });
    setBrands(filtered);
  }, [brandsData, brandIds]);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Update Category</DialogTitle>
      {category && (
        <DialogContent>
          <Box style={{ width: "100%", overflow: "unset" }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    size="small"
                    placeholder="Category Name"
                    value={categoryName}
                    onChange={(e) => {
                      let value = e.target.value;

                      setCategoryName(value.slice(0, 30));
                    }}
                    InputLabelProps={{
                      className: classes.textFieldLabel,
                    }}
                    type="text"
                  />
                </FormControl>
              </Grid>
              {oldImage && (
                <Grid item xs={12}>
                  <img
                    alt="cat_old_img"
                    style={{
                      maxHeight: "10rem",
                      maxWidth: "15rem",
                      margin: "1rem",
                    }}
                    src={`${imagepath_categories}${oldImage}`}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel style={{ fontSize: "0.8rem" }}>
                    Select Image :
                  </InputLabel>
                  <Input
                    InputLabelProps={{
                      className: classes.textFieldLabel,
                    }}
                    type="file"
                    onChange={handleImageChange}
                  />
                  {imagePreview && (
                    <img
                      style={{
                        maxHeight: "10rem",
                        maxWidth: "15rem",
                        marginTop: "1rem",
                        marginLeft: "10%",
                      }}
                      src={imagePreview}
                      alt={"category-image"}
                    />
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  {brands && brands.length && (
                    <Autocomplete
                      defaultValue={brands}
                      value={brands}
                      onChange={(_, newValue) => {
                        const val = newValue.map((item) => item.id);
                        setBrandIds(val);
                        setBrands(newValue);
                      }}
                      multiple={true}
                      getOptionLabel={(option) => option.brand_name}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Brands" />
                      )}
                      options={brandsData}
                    />
                  )}
                </FormControl>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditCategory;

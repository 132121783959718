import { CircularProgress } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useDeleteUnitData, useUnitsQuery } from "../../../hooks/unit.hooks";
import { filterData } from "../../../utils/constants";
import popMsg from "../../../utils/popup";
import DelBox from "../../ui/DelBox";
import Layout from "../../ui/Layout";
import MyTable from "../../ui/MyTable";
import RowActions from "../../ui/RowActions";
import TopBlock from "../../ui/TopBlock";
import AddUnit from "./AddUnit";
import UpdateUnit from "./UpdateUnit";

const ManageUnits = () => {
  const [search, setSearch] = useState("");
  const [openAddForm, setOpenAddForm] = useState(false);
  const [renderedData, setRenderedData] = useState([]);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [unitToUpdate, setUnitToUpdate] = useState();
  const [delOpen, setDelOpen] = useState(false);
  const [delId, setDelId] = useState("");

  const columnHelper = createColumnHelper();
  const queryClient = useQueryClient();

  // Quries and mutations
  const { data, isLoading } = useUnitsQuery();
  const { mutateAsync: deleteUnitData } = useDeleteUnitData();

  const handleClickEdit = (row) => {
    setUnitToUpdate(row);
    setOpenUpdateForm(true);
  };
  const handleDelete = () => {
    deleteUnitData(delId, {
      onSuccess: (res) => {
        setDelOpen(false);
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("units");
      },
    });
  };
  const handleClickDelete = (row) => {
    setDelId(row.id);
    setDelOpen(true);
  };

  useEffect(() => {
    filterData("unit_name", search, data, setRenderedData);
  }, [search, data]);

  // Columns
  const columns = [
    columnHelper.accessor("serialNumber", {
      header: "S.No",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("unit_name", {
      header: "Unit Name",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <RowActions
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
          row={info.row}
        />
      ),
    }),
  ];

  return (
    <Layout>
      <div style={{ overflow: "unset" }}>
        <TopBlock
          search={search}
          setSearch={setSearch}
          setOpenAddForm={setOpenAddForm}
        />
        {isLoading && (
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        )}
        {!isLoading && renderedData && renderedData.length && (
          <MyTable columns={columns} data={renderedData} />
        )}
      </div>
      {delOpen && (
        <DelBox
          open={delOpen}
          setOpen={setDelOpen}
          deleteEntry={handleDelete}
        />
      )}
      {openAddForm && <AddUnit open={openAddForm} setOpen={setOpenAddForm} />}
      {openUpdateForm && (
        <UpdateUnit
          open={openUpdateForm}
          setOpen={setOpenUpdateForm}
          unit={unitToUpdate}
          key={"unit-update-form"}
        />
      )}
      <Toaster />
    </Layout>
  );
};

export default ManageUnits;

import axios from "../axios";
import { apiRoutes } from "../utils/apiRoutes";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
let token = Cookies.get("content");
let userRole;
if (token) {
  let tokenData = jwtDecode(token);
  userRole = tokenData.role;
}

async function getAllProducts() {
  try {
    const url = `${apiRoutes.getProducts}`;
    // const url =
    //   userRole === 3
    //     ? `${apiRoutes.getVendorProducts}`
    //     : userRole === 1
    //     ? `${apiRoutes.getProducts}`
    //     : "";
    const response = await axios.get(url);
    const data = response.data.product_list;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function addProduct(payload, setIsLoading) {
  setIsLoading(true);
  try {
    const response = await axios.post(`${apiRoutes.addProduct}`, payload);
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  } finally {
    setIsLoading(false);
  }
}

async function updateProduct(payload, productId) {
  try {
    const response = await axios.post(
      `${apiRoutes.updateProduct}${productId}`,
      payload
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function getProductDetails(productId) {
  try {
    const response = await axios.get(
      `${apiRoutes.getProductDetails}${productId}`
    );
    const data = response.data.product_list;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function changeProductVisibility(payload, productId) {
  try {
    const response = await axios.post(
      `${apiRoutes.updateProductStatus}${productId}`,
      payload
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function updateProductStock(payload, id) {
  try {
    const response = await axios.post(`${apiRoutes.updateStock}${id}`, payload);
    let data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function updateProductAdminDecision(prod) {
  const payload = {
    status: prod.status,
  };
  return axios.post(`${apiRoutes.productAdminDecision}${prod.id}`, payload);
}

export {
  getAllProducts,
  addProduct,
  updateProduct,
  getProductDetails,
  changeProductVisibility,
  updateProductStock,
  updateProductAdminDecision,
};

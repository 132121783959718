import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useEffect, useState, useSelector } from "react";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import Footer from "../../Container/Footer/Footer";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import { url } from "../../../App";
import axios from "axios";
import { isAlphaNumeric } from "../../../utils/constants";

const State = () => {
  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  });
  const classes = useStyle();
  let [country, setCountry] = useState([]);
  let [showCountryID, setshowCountryID] = useState("");
  let [addCountryID, setaddCountryID] = useState("");
  let [countryId, setCountryId] = useState("");
  let [states, setStates] = useState([]);
  let [countryname, setCountryname] = useState("");
  let [search, setSearch] = useState("");
  let [number, setNumber] = useState(10);
  let token = Cookies.get("content");

  // /////////////////////////////////////////////////////////////////////////////////////////////////

  const getCountries = () => {
    fetch(`${url}/api/admin/country`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((res) => {
        setCountry(res.data);
      });
    });
  };
  useEffect(() => {
    getCountries();
  }, []);

  // add state in database
  const addState = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("state_name", countryname.trim());
    formData.append("country_id", addCountryID);
    const config = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        // "Content-Type": "multipart/form-data",
      },
      body: formData,
    };
    console.log("state_name:", countryname, "countryId:", addCountryID);
    if (countryname.trim().length > 1) {
      if (addCountryID > 1) {
        fetch(`${url}/api/admin/state/store`, config).then((response) => {
          response.json().then((res) => {
            if (res.error) {
              return toast(
                <span style={{ fontSize: "20px" }}>
                  ⚠️ {res.error.state_name}
                </span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
            if (res.errors) {
              return toast(
                <span style={{ fontSize: "20px" }}>
                  ⚠️{" "}
                  {res.errors.map((i) => {
                    return i.message;
                  })}
                </span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
            if (res.success) {
              setaddOpen(false);
              setTimeout(() => {
                setaddCountryID("");
                setCountryname("");
              }, 200);
              // setStatename("");
              getCountries();
              getStates();
              return toast(
                <span style={{ fontSize: "20px" }}>State Added ✔️</span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
          });
        });
      } else {
        toast(
          <span style={{ fontSize: "20px" }}>⚠️ Please Provide Country</span>,
          {
            duration: 2000,
            position: "top-center",
            // Styling
            style: {},
            className: "",
            // Custom Icon
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            // Aria
            ariaProps: {
              role: "error",
              "aria-live": "polite",
            },
          }
        );
      }
    } else {
      toast(<span style={{ fontSize: "20px" }}>⚠️ Please Provide State</span>, {
        duration: 2000,
        position: "top-center",
        // Styling
        style: {},
        className: "",
        // Custom Icon
        // Change colors of success/error/loading icon
        iconTheme: {
          primary: "#000",
          secondary: "#fff",
        },
        // Aria
        ariaProps: {
          role: "error",
          "aria-live": "polite",
        },
      });
    }
  };
  const handleEnter = (event) => {
    if (event.key === "Enter") {
      addState(event);
    }
  };

  //pop
  //for edit
  const [open, setOpen] = useState(false);

  // const handleClickOpen = () => {
  //   setOpen(true);
  // };

  // const handleClose = () => {
  //   setOpen(false);
  // };

  //for delete
  const [delopen, setdelOpen] = useState(false);
  const [delID, setdelID] = useState(false);

  // const delhandleClickOpen = () => {
  //   setdelOpen(true);
  // };

  const delhandleClose = () => {
    setOpen(false);
  };
  //for add
  const [addopen, setaddOpen] = useState(false);
  /////////////////////////////////////UPDATE STATE NAME/////////////////////////////////////////////////////
  let [updatestatename, setUpdatestatename] = useState("");
  let [upsID, setUpsID] = useState();

  const updateState = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("country_id", countryId);
    formData.append("state_name", updatestatename);
    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        // "Content-Type": "application/json",
      },
      // body: JSON.stringify({ name: updatestatename }),
      body: formData,
    };
    if (updatestatename.toString().trim().length > 1) {
      fetch(`${url}/api/admin/state/update/${upsID}`, options).then(
        (response) => {
          response.json().then((res) => {
            if (res.error) {
              return toast(
                <span style={{ fontSize: "20px" }}>
                  ⚠️ {res.error.state_name}
                </span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
            if (res.message) {
              setOpen(false);
              getCountries();
              getStates();
              return toast(
                <span style={{ fontSize: "20px" }}>
                  {"Updated Successfully"} ✔️
                </span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
          });
        }
      );
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>⚠️ Please Provide State Name</span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
  };

  //////////////////////////////////DELETE State//////////////////////////////////////////////////////
  const deleteState = () => {
    console.log(delID);
    fetch(`${url}/api/admin/state/destroy/${delID}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((res) => {
        if (res.error) {
          return toast(
            <span style={{ fontSize: "20px" }}>⚠️ {res.error}</span>,
            {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            }
          );
        }
        if (res.success) {
          setdelOpen(false);
          getCountries();
          getStates();
          return toast(
            <span style={{ fontSize: "20px" }}>{res.message} ✔️</span>,
            {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            }
          );
        }
      });
    });
  };

  // /////////////////////////////////Pagination///////////////////////////////////////////////
  const renderData = (arry) => {
    return (
      <TableBody>
        {arry
          ? arry.map((i, index) => {
              return (
                <TableRow
                  className="list_row"
                  id={i.id}
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {indexOfFirstItem + index + 1}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {i.state_name}
                  </TableCell>
                  <TableCell style={{ fontSize: "17px" }} align="right">
                    <ModeEditIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpen(true);
                        setUpsID(i.id);
                        setCountryId(i.country_id);
                        setUpdatestatename(i.state_name);
                      }}
                    />
                    <DeleteIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setdelOpen(true);
                        setdelID(i.id);
                        // setDeleteId(i._id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          : ""}
      </TableBody>
    );
  };
  const [data, setData] = useState([]);
  console.log("Data :", data);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active " : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  // ///////////////////////////////////Get States from DataBase///////////////

  function getStates() {
    try {
      axios
        .get(`${url}/api/admin/state/${showCountryID}`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setStates(res.data.state);
          let searchedStates = res.data.state.filter((i) => {
            return i.state_name
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase());
          });

          setStates(searchedStates);
        });
    } catch (error) {
      toast.error(<span className="text-lg">{error.message}</span>);
    }
  }

  useEffect(() => {
    if (showCountryID) {
      getStates();
    }
  }, [showCountryID, search]);

  // ////////////////////////////////////////////////////////////////////////

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <li className="" onClick={handleNextbtn}>
        {" "}
        &hellip;{" "}
      </li>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleLoadMore = (value) => {
    setitemsPerPage(value);
  };
  return (
    <>
      <div className="container">
        <Sidebar />
        <Header name="Manage State" />
        <div className="content_state">
          {/* ADD State */}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "75%" }}
              open={addopen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography
                  style={{ fontWeight: 500 }}
                  variant="h5"
                  component={"abbr"}
                  display="block"
                  gutterBottom
                >
                  Add State
                </Typography>
              </DialogTitle>
              <DialogContent className="state_add">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel
                    style={{ fontSize: "0.8rem" }}
                    id="demo-simple-select-standard-label"
                  >
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={addCountryID}
                    onChange={(e) => {
                      setaddCountryID(e.target.value);
                    }}
                    label="Select Countru"
                  >
                    {country
                      ? country.map((i) => {
                          return (
                            <MenuItem value={i.id}>{i.country_name}</MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                <TextField
                  value={countryname}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (isAlphaNumeric(value)) setCountryname(value);
                  }}
                  style={{ height: "50px", width: "400px", fontSize: "20px" }}
                  id="standard-basic"
                  label="Enter State Name"
                  variant="standard"
                  onKeyDown={handleEnter}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    // color: "#023F9E",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    addState(e);
                  }}
                >
                  Add
                </Button>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setaddOpen(false);
                    setTimeout(() => {
                      setaddCountryID("");
                      setCountryname("");
                    }, 200);
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Box>

          {/* Edit State ///////////////////////////////////////////////////////////////////////////////////*/}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "75%" }}
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography
                  style={{ fontWeight: 500 }}
                  variant="h5"
                  component={"abbr"}
                  display="block"
                  gutterBottom
                >
                  Update State
                </Typography>
              </DialogTitle>
              <DialogContent>
                <TextField
                  value={updatestatename}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (isAlphaNumeric(value)) setUpdatestatename(value);
                  }}
                  style={{ height: "50px", width: "400px", fontSize: "20px" }}
                  id="standard-basic"
                  label="Update State Name"
                  variant="standard"
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    // color: "#023F9E",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={updateState}
                >
                  Update
                </Button>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          {/* Delete State Permission///////////////////////////////////////////////////////////////////////////////////*/}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "100%" }}
              open={delopen}
              onClose={delhandleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="delete">
                {/* <div className="del_head"></div> */}
                <div className="del_alert">
                  Are you sure, you want to delete this ?
                </div>
                <span
                  style={{
                    color: "red",
                    fontSize: "20px",
                    marginTop: "-15px",
                  }}
                >
                  Caution! This action may affect other data also.
                </span>
                <div className="del_action">
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      padding: "10x",
                      fontSize: "18px",
                      // marginTop: "20px",
                      marginRight: "10px",
                      // color: "#023F9E",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={deleteState}
                  >
                    Yes
                  </Button>
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      padding: "10x",
                      fontSize: "18px",
                      // marginTop: "20px",
                      marginRight: "10px",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={() => setdelOpen(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </Dialog>
          </Box>
          {/* list heading and add btn */}
          <div className="headADD_states">
            <span
              style={{ cursor: "pointer" }}
              className="list_heading"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              All States
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search State"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
              <Button
                size="large"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                className="addbtn"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setaddOpen(true);
                  getCountries();
                }}
              >
                Add
              </Button>
            </div>
          </div>
          {/*  */}
          {/* list of countries */}
          <div className="state_list" style={{ marginTop: "-130px" }}>
            <div className="list_state">
              <FormControl variant="standard" style={{ width: "250px" }}>
                <InputLabel
                  style={{ fontSize: "0.8rem" }}
                  id="demo-simple-select-standard-label"
                >
                  Select Country
                </InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={showCountryID}
                  onChange={(e) => setshowCountryID(e.target.value)}
                >
                  {country
                    ? country.map((i) => {
                        return (
                          <MenuItem value={i.id}>{i.country_name}</MenuItem>
                        );
                      })
                    : ""}
                </Select>
              </FormControl>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderData(states)}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    {/* dropdown for pagination */}
                    <FormControl
                      className="page_space"
                      variant="standard"
                      sx={{ m: 1, minWidth: 40 }}
                    >
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={number}
                        onChange={(e) => {
                          handleLoadMore(e.target.value);
                          setNumber(e.target.value);
                        }}
                        // label="0-5"
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      style={{ marginLeft: "30px" }}
                      variant=""
                      component={""}
                    >
                      {indexOfFirstItem + 1} - {indexOfLastItem}&nbsp;
                      Rows&nbsp; of &nbsp;&nbsp;{states.length}
                    </Typography>
                    <ul className="pageNumbers page_space">
                      {indexOfFirstItem > 0 && (
                        <li className="page_button">
                          <ArrowBackIosIcon
                            onClick={handlePrevbtn}
                            disabled={currentPage == pages[0] ? true : false}
                          />
                        </li>
                      )}
                      {pageDecrementBtn}
                      {renderPageNumbers}
                      {pageIncrementBtn}
                      {data.length > indexOfLastItem && (
                        <li className="page_button">
                          <ArrowForwardIosIcon
                            onClick={handleNextbtn}
                            disabled={
                              currentPage == pages[pages.length - 1]
                                ? true
                                : false
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Toaster />
    </>
  );
};
export default State;

import { Button, TablePagination, TextField } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import Footer from "../../Container/Footer/Footer";
import { getAllOrders } from "../../../services/orders.service";
import { formatDate, initialRows } from "../../../utils/constants";
import { useNavigate } from "react-router-dom";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";
import VendorSidebar from "../../VENDOR/mainPage/vendor_sidebar/Vendor_Sidebar";

const Allorder = () => {
  const [search, setSearch] = useState("");
  const [orderData, setOrderData] = useState([]);
  const [renderedData, setRenderedData] = useState([]);
  const [userRole, setUserRole] = useState(null);

  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    get();
    const token = Cookies.get("content");
    if (token) {
      let tokenData = jwtDecode(token);
      setUserRole(tokenData.role);
    }
  }, []);

  useEffect(() => {
    filterData();
  }, [search]);

  // Handlers
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
  };

  async function get() {
    const data = await getAllOrders();
    setOrderData(data);
    setRenderedData(data);
  }

  function filterData() {
    const filteredData =
      orderData &&
      orderData.filter((order) => {
        return order.order_id
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    setRenderedData(filteredData);
  }
  // /////////////////////////////////Pagination///////////////////////////////////////////////
  const renderData = (data) => {
    return (
      <TableBody>
        {data.map((i, index) => {
          return (
            <TableRow
              key={index}
              className="list_row"
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {(page + 1) * rowsPerPage - rowsPerPage + index + 1}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  variant="text"
                  color="primary"
                  onClick={() => {
                    // navigate("/productorder/" + i.id);
                    navigate(`/order_details/${i.id}`);
                  }}
                >
                  <b>{i.order_id}</b>
                </Button>
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {i.user_name}
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {formatDate(i.created_at, false, false)}
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {parseFloat(i.paid_amount).toFixed(2) + "₹"}
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {i.payment_status === 1 ? (
                  <span style={{ color: "green" }}>Paid</span>
                ) : i.payment_status === 0 ? (
                  <span style={{ color: "red" }}>Unpaid</span>
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {i.delivery_status === 0 ? (
                  <span style={{ color: "orange" }}>Pending</span>
                ) : i.delivery_status === 1 ? (
                  <span style={{ color: "blue" }}>Accepted</span>
                ) : i.delivery_status === 2 ? (
                  <span style={{ color: "orange" }}>Shipped</span>
                ) : i.delivery_status === 3 ? (
                  <span style={{ color: "orange" }}>Out for Delivery</span>
                ) : i.delivery_status === 4 ? (
                  <span style={{ color: "green" }}>Delivered</span>
                ) : i.delivery_status === 5 ? (
                  <span style={{ color: "red" }}>Canceled</span>
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {i.payment_method === 1 ? (
                  <span style={{ color: "skyblue" }}>Online</span>
                ) : i.payment_method === 0 ? (
                  <span style={{ color: "skyblue" }}>Cash on Delivery</span>
                ) : (
                  ""
                )}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };
  return (
    <>
      <div className="container">
        {userRole === 3 ? <VendorSidebar /> : userRole === 1 ? <Sidebar /> : ""}
        <Header name="Manage Product Order" />
        <div className="content_category">
          {/* list heading and add btn */}
          <div className="headADD_states">
            <span
              className="list_heading"
              onClick={() => {
                window.location.reload(false);
              }}
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              All Product Order
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search by Order ID"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
            </div>
          </div>

          {/* list of countries */}
          <div className="sss">
            <div className="ss">
              <TableContainer className="cat_table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Product Order ID
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Customer Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Date
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Total Amount
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Payment Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Order Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          marginLeft: "30px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Payment Method
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderedData &&
                    renderedData.length &&
                    renderData(
                      renderedData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    )}
                </Table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={
                    renderedData && renderedData.length
                      ? renderedData.length
                      : 0
                  }
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  showfirstbutton="true"
                  showlastbutton="true"
                />
              </TableContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Toaster />
    </>
  );
};

export default Allorder;

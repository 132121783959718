import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  makeStyles,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TableHead,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import { Fragment, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import Vendor_Sidebar from "../../../mainPage/vendor_sidebar/Vendor_Sidebar";
import Vendor_Header from "../../../mainPage/Vendor_Header/Vendor_Header";
import productDetails from "../../../../Redux/action/Vendor/Product/productDetails";
import Vendor_Footer from "../../../mainPage/Vendor_Footer/Vendor_Footer";
import CloseIcon from "@mui/icons-material/Close";
import { url } from "../../../../../App";

const Add_productByVendor = () => {
  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
  });

  const classes = useStyle();
  let userID = Cookies.get("userID");
  let [subscribe, setSubscribe] = useState();
  let [quan, setQuan] = useState("");
  let [saleprice, setSaleprice] = useState("");
  let [purchaseprice, setPurchaseprice] = useState("");
  let [discount, setDiscount] = useState("");
  let [discountamount, setDiscountamount] = useState("");
  let [finalprice, setFinalprice] = useState("");
  let [perishable, setPerishable] = useState();
  let [threshold, setThreshold] = useState("");
  let [title, setTitle] = useState("");
  let [subsubId, setSubSubId] = useState("");
  let [discription, setDiscription] = useState("");
  let [subsubcategories, setSubsubcategories] = useState([]);
  let [preview, setPreview] = useState([]);
  let [categories, setCategories] = useState([]);
  let [min_quantity, setMin_quantity] = useState("");
  let [catId, setCatId] = useState("");
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let token = Cookies.get("content");

  // get category
  useEffect(() => {
    fetch(`${url}/api/vendor/category`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((res) => {
        // console.log("response data :", res.category);
        setCategories(res.category);
        // dispatch(addCountries(res.country));
      });
    });
  }, []);

  //get brands
  let [brands, setBrands] = useState("");
  let [brandId, setBrandId] = useState("");
  useEffect(() => {
    fetch(`${url}/api/vendor/brand`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((res) => {
        // console.log("response data :", res.brand);
        setBrands(res.brand);
      });
    });
  }, []);

  //get subcatgory
  let [subcategories, setSubcategories] = useState("");
  //description data get from here .
  let [subId, setSubId] = useState("");
  let [image, setImage] = useState([]);
  useEffect(() => {
    if (catId) {
      fetch(`${url}/api/category/sub-category/${catId}`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then((res) => {
        res.json().then((res) => {
          // console.log("response data :", res.);
          setSubcategories(res.sub_category_list);
        });
      });
    }
  }, [catId]);

  //get subsubcateogry from database
  useEffect(() => {
    if (subId) {
      fetch(`${url}/api/category/sub-sub-category/${subId}`, {
        // fetch(`${url}/api/vendor/sub-sub-category`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      }).then((res) => {
        res.json().then((res) => {
          // console.log("res subsub category data :",res.sub_sub_category)
          setSubsubcategories(res.sub_sub_category);
        });
      });
    }
  }, [subId]);

  ////////////////////////////////////////////////////////////////////////////////////////////////////////
  //image handler
  const imageHandle = (e) => {
    if (e.target.files) {
      setImage(e.target.files);
      let fileArray = Array.from(e.target.files).map((file) => {
        return URL.createObjectURL(file);
      });
      setPreview([]);
      // console.log("Product Images :", fileArray);
      if (fileArray.length < 6) {
        toast(
          <span style={{ fontSize: "20px" }}>Uploaded Successfully ✔️</span>,
          {
            duration: 2000,
            position: "top-center",
            // Styling
            style: {},
            className: "",
            // Custom Icon
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            // Aria
            ariaProps: {
              role: "error",
              "aria-live": "polite",
            },
          }
        );
      } else {
        toast(
          <span style={{ fontSize: "20px" }}>
            Only 5 Image You Can Select 🚫{" "}
          </span>,
          {
            duration: 2000,
            position: "top-center",
            // Styling
            style: {},
            className: "",
            // Custom Icon
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            // Aria
            ariaProps: {
              role: "error",
              "aria-live": "polite",
            },
          }
        );
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      }
      setPreview((pre) => pre.concat(fileArray));
    }
  };
  const removeImage = (index) => {
    let imgs = preview.filter((i, indx) => {
      return indx != index;
    });
    setPreview(imgs);
  };
  if (image) {
    Array.from(image).map((i) => {
      return console.log(i.name);
    });
  }

  const validation = () => {
    if (title.toString().trim().length != 0) {
      if (perishable.toString().trim().length != 0) {
        if (catId.toString().trim().length != 0) {
          if (brandId.toString().trim().length != 0) {
            if (subId.toString().trim().length != 0) {
              if (image.toString().trim().length != 0) {
                if (threshold.toString().trim().length != 0) {
                  if (discription.toString().trim().length != 0) {
                    if (subscribe.toString().trim().length != 0) {
                      dispatch(productDetails(object));
                      navigate("/vendor/products/quan");
                    } else {
                      toast(
                        <span style={{ fontSize: "20px" }}>
                          Please Provide Subscribe 🚫{" "}
                        </span>,
                        {
                          duration: 2000,
                          position: "top-center",
                          // Styling
                          style: {},
                          className: "",
                          // Custom Icon
                          // Change colors of success/error/loading icon
                          iconTheme: {
                            primary: "#000",
                            secondary: "#fff",
                          },
                          // Aria
                          ariaProps: {
                            role: "error",
                            "aria-live": "polite",
                          },
                        }
                      );
                    }
                  } else {
                    toast(
                      <span style={{ fontSize: "20px" }}>
                        Please Provide Discription 🚫{" "}
                      </span>,
                      {
                        duration: 2000,
                        position: "top-center",
                        // Styling
                        style: {},
                        className: "",
                        // Custom Icon
                        // Change colors of success/error/loading icon
                        iconTheme: {
                          primary: "#000",
                          secondary: "#fff",
                        },
                        // Aria
                        ariaProps: {
                          role: "error",
                          "aria-live": "polite",
                        },
                      }
                    );
                  }
                } else {
                  toast(
                    <span style={{ fontSize: "20px" }}>
                      Please Provide Threshold Time 🚫{" "}
                    </span>,
                    {
                      duration: 2000,
                      position: "top-center",
                      // Styling
                      style: {},
                      className: "",
                      // Custom Icon
                      // Change colors of success/error/loading icon
                      iconTheme: {
                        primary: "#000",
                        secondary: "#fff",
                      },
                      // Aria
                      ariaProps: {
                        role: "error",
                        "aria-live": "polite",
                      },
                    }
                  );
                }
              } else {
                toast(
                  <span style={{ fontSize: "20px" }}>
                    Please Provide Image 🚫{" "}
                  </span>,
                  {
                    duration: 2000,
                    position: "top-center",
                    // Styling
                    style: {},
                    className: "",
                    // Custom Icon
                    // Change colors of success/error/loading icon
                    iconTheme: {
                      primary: "#000",
                      secondary: "#fff",
                    },
                    // Aria
                    ariaProps: {
                      role: "error",
                      "aria-live": "polite",
                    },
                  }
                );
              }
            } else {
              toast(
                <span style={{ fontSize: "20px" }}>
                  Please Provide SubCategory 🚫{" "}
                </span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
          } else {
            toast(
              <span style={{ fontSize: "20px" }}>
                Please Provide Brand 🚫{" "}
              </span>,
              {
                duration: 2000,
                position: "top-center",
                // Styling
                style: {},
                className: "",
                // Custom Icon
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },
                // Aria
                ariaProps: {
                  role: "error",
                  "aria-live": "polite",
                },
              }
            );
          }
        } else {
          toast(
            <span style={{ fontSize: "20px" }}>
              Please Provide Category 🚫{" "}
            </span>,
            {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            }
          );
        }
      } else {
        toast(
          <span style={{ fontSize: "20px" }}>
            Please Provide Perishable 🚫{" "}
          </span>,
          {
            duration: 2000,
            position: "top-center",
            // Styling
            style: {},
            className: "",
            // Custom Icon
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            // Aria
            ariaProps: {
              role: "error",
              "aria-live": "polite",
            },
          }
        );
      }
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>Please Provide Title 🚫 </span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
  };

  const calculate = (e) => {
    setDiscountamount(e.target.value);
    if (discount && saleprice) {
      if (discount == "INR") {
        let price = saleprice - e.target.value;
        console.log("Price :", price, saleprice, discountamount);
        setFinalprice(price);
      }
      if (discount == "%") {
        let price = (e.target.value / 100) * saleprice;
        price = saleprice - price;
        // let price=saleprice*(e.target.value)/100;
        setFinalprice(price);
      }
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>
          Please Provide Saleprice & DiscountType 🚫{" "}
        </span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
  };

  let object = {
    title,
    perishable,
    catId,
    brandId,
    subId,
    subsubId,
    discription,
    subscribe,
    threshold,
    image,
    quan,
    min_quantity,
  };

  const Submit = () => {
    // console.log(saleprice,purchaseprice,discount,discountamount,finalprice)
    let data = new FormData();
    data.append("product_title", title);
    data.append("subscribe", subscribe);
    data.append("subcategory_id", subId);
    data.append("sub_sub_category_id", subsubId);
    data.append("is_perishable", perishable);
    data.append("description", discription);
    data.append("category_id", catId);
    data.append("brand_id", brandId);
    data.append("return_time", threshold);
    data.append("userType", "VENDOR");
    data.append("publishType", true);
    data.append("product_status", 0);
    data.append("userID", userID);
    data.append("quantification", 0);
    data.append("min_quantity", min_quantity);
    if (image[0]) {
      data.append("images", image[0]);
    }
    if (image[1]) {
      data.append("image1", image[1]);
    }
    if (image[2]) {
      data.append("image2", image[2]);
    }
    if (image[3]) {
      data.append("image3", image[3]);
    }
    if (image[4]) {
      data.append("image4", image[4]);
    }
    data.append("nqsale_price", saleprice);
    data.append("nqpurchase_price", purchaseprice);
    data.append("nqdiscount_type", discount);
    data.append("nqdiscount_price", discountamount);
    data.append("nqfinal_price", finalprice);
    data.append("nqstock", 0);
    let config = {
      method: "post",
      body: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    if (title.toString().trim().length != 0) {
      if (perishable.toString().trim().length != 0) {
        if (catId.toString().trim().length != 0) {
          if (brandId.toString().trim().length != 0) {
            if (subId.toString().trim().length != 0) {
              if (image.toString().trim().length != 0) {
                if (threshold.toString().trim().length != 0) {
                  if (discription.toString().trim().length != 0) {
                    if (subscribe.toString().trim().length != 0) {
                      if (saleprice.toString().trim().length != 0) {
                        if (purchaseprice.toString().trim().length != 0) {
                          if (discount.toString().trim().length != 0) {
                            if (discountamount.toString().trim().length != 0) {
                              fetch(
                                `${url}/api/vendor/product/store`,
                                config
                              ).then((res) => {
                                res.json().then((res) => {
                                  if (res.success) {
                                    toast(
                                      <span style={{ fontSize: "20px" }}>
                                        {res.success} ✔️ Product Added
                                      </span>,
                                      {
                                        duration: 2000,
                                        position: "top-center",
                                        // Styling
                                        style: {},
                                        className: "",
                                        // Custom Icon
                                        // Change colors of success/error/loading icon
                                        iconTheme: {
                                          primary: "#000",
                                          secondary: "#fff",
                                        },
                                        // Aria
                                        ariaProps: {
                                          role: "error",
                                          "aria-live": "polite",
                                        },
                                      }
                                    );

                                    setTimeout(() => {
                                      navigate("/vendor/products");
                                    }, 800);
                                  } else {
                                    toast(
                                      <span style={{ fontSize: "20px" }}>
                                        {res.error}{" "}
                                      </span>,
                                      {
                                        duration: 2000,
                                        position: "top-center",
                                        // Styling
                                        style: {},
                                        className: "",
                                        // Custom Icon
                                        // Change colors of success/error/loading icon
                                        iconTheme: {
                                          primary: "#000",
                                          secondary: "#fff",
                                        },
                                        // Aria
                                        ariaProps: {
                                          role: "error",
                                          "aria-live": "polite",
                                        },
                                      }
                                    );
                                  }
                                });
                              });
                            } else {
                              toast(
                                <span style={{ fontSize: "20px" }}>
                                  Please Provide DiscountAmount 🚫{" "}
                                </span>,
                                {
                                  duration: 2000,
                                  position: "top-center",
                                  // Styling
                                  style: {},
                                  className: "",
                                  // Custom Icon
                                  // Change colors of success/error/loading icon
                                  iconTheme: {
                                    primary: "#000",
                                    secondary: "#fff",
                                  },
                                  // Aria
                                  ariaProps: {
                                    role: "error",
                                    "aria-live": "polite",
                                  },
                                }
                              );
                            }
                          } else {
                            toast(
                              <span style={{ fontSize: "20px" }}>
                                Please Provide DiscountType 🚫{" "}
                              </span>,
                              {
                                duration: 2000,
                                position: "top-center",
                                // Styling
                                style: {},
                                className: "",
                                // Custom Icon
                                // Change colors of success/error/loading icon
                                iconTheme: {
                                  primary: "#000",
                                  secondary: "#fff",
                                },
                                // Aria
                                ariaProps: {
                                  role: "error",
                                  "aria-live": "polite",
                                },
                              }
                            );
                          }
                        } else {
                          toast(
                            <span style={{ fontSize: "20px" }}>
                              Please Provide Purchase Price 🚫{" "}
                            </span>,
                            {
                              duration: 2000,
                              position: "top-center",
                              // Styling
                              style: {},
                              className: "",
                              // Custom Icon
                              // Change colors of success/error/loading icon
                              iconTheme: {
                                primary: "#000",
                                secondary: "#fff",
                              },
                              // Aria
                              ariaProps: {
                                role: "error",
                                "aria-live": "polite",
                              },
                            }
                          );
                        }
                      } else {
                        toast(
                          <span style={{ fontSize: "20px" }}>
                            Please Provide SalePrice 🚫{" "}
                          </span>,
                          {
                            duration: 2000,
                            position: "top-center",
                            // Styling
                            style: {},
                            className: "",
                            // Custom Icon
                            // Change colors of success/error/loading icon
                            iconTheme: {
                              primary: "#000",
                              secondary: "#fff",
                            },
                            // Aria
                            ariaProps: {
                              role: "error",
                              "aria-live": "polite",
                            },
                          }
                        );
                      }
                    } else {
                      toast(
                        <span style={{ fontSize: "20px" }}>
                          Please Provide Subscribe 🚫{" "}
                        </span>,
                        {
                          duration: 2000,
                          position: "top-center",
                          // Styling
                          style: {},
                          className: "",
                          // Custom Icon
                          // Change colors of success/error/loading icon
                          iconTheme: {
                            primary: "#000",
                            secondary: "#fff",
                          },
                          // Aria
                          ariaProps: {
                            role: "error",
                            "aria-live": "polite",
                          },
                        }
                      );
                    }
                  } else {
                    toast(
                      <span style={{ fontSize: "20px" }}>
                        Please Provide Discription 🚫{" "}
                      </span>,
                      {
                        duration: 2000,
                        position: "top-center",
                        // Styling
                        style: {},
                        className: "",
                        // Custom Icon
                        // Change colors of success/error/loading icon
                        iconTheme: {
                          primary: "#000",
                          secondary: "#fff",
                        },
                        // Aria
                        ariaProps: {
                          role: "error",
                          "aria-live": "polite",
                        },
                      }
                    );
                  }
                } else {
                  toast(
                    <span style={{ fontSize: "20px" }}>
                      Please Provide Threshold Time 🚫{" "}
                    </span>,
                    {
                      duration: 2000,
                      position: "top-center",
                      // Styling
                      style: {},
                      className: "",
                      // Custom Icon
                      // Change colors of success/error/loading icon
                      iconTheme: {
                        primary: "#000",
                        secondary: "#fff",
                      },
                      // Aria
                      ariaProps: {
                        role: "error",
                        "aria-live": "polite",
                      },
                    }
                  );
                }
              } else {
                toast(
                  <span style={{ fontSize: "20px" }}>
                    Please Provide Image 🚫{" "}
                  </span>,
                  {
                    duration: 2000,
                    position: "top-center",
                    // Styling
                    style: {},
                    className: "",
                    // Custom Icon
                    // Change colors of success/error/loading icon
                    iconTheme: {
                      primary: "#000",
                      secondary: "#fff",
                    },
                    // Aria
                    ariaProps: {
                      role: "error",
                      "aria-live": "polite",
                    },
                  }
                );
              }
            } else {
              toast(
                <span style={{ fontSize: "20px" }}>
                  Please Provide SubCategory 🚫{" "}
                </span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
          } else {
            toast(
              <span style={{ fontSize: "20px" }}>
                Please Provide Brand 🚫{" "}
              </span>,
              {
                duration: 2000,
                position: "top-center",
                // Styling
                style: {},
                className: "",
                // Custom Icon
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },
                // Aria
                ariaProps: {
                  role: "error",
                  "aria-live": "polite",
                },
              }
            );
          }
        } else {
          toast(
            <span style={{ fontSize: "20px" }}>
              Please Provide Category 🚫{" "}
            </span>,
            {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            }
          );
        }
      } else {
        toast(
          <span style={{ fontSize: "20px" }}>
            Please Provide Perishable 🚫{" "}
          </span>,
          {
            duration: 2000,
            position: "top-center",
            // Styling
            style: {},
            className: "",
            // Custom Icon
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            // Aria
            ariaProps: {
              role: "error",
              "aria-live": "polite",
            },
          }
        );
      }
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>Please Provide Title 🚫 </span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
  };

  return (
    <>
      <div className="container">
        <Vendor_Sidebar />
        <Vendor_Header />
        <div className="addproduct">
          <div className="add_Header">
            <div className="showexistPage_add">
              {/* <TableHead className="headdd">Product Details</TableHead> */}
              {/* <TableHead className="headdd">Quantification</TableHead> */}
            </div>
            <div style={{ marginLeft: "450px" }}>
              <Button
                className={classes.root}
                startIcon={<ArrowBackIosNewSharpIcon />}
                style={{
                  borderRadius: 8,
                  width: "340px",
                  padding: "10x",
                  fontSize: "18px",
                  marginTop: "20px",
                  marginRight: "-60px",
                  borderColor: "#023F9E",
                }}
                variant="outlined"
                onClick={() => navigate("/vendor/products")}
              >
                Back to product list
              </Button>
            </div>
          </div>
          <div className="add_section">
            <div className="inner_add_section">
              <Typography
                //   className="addHeading"
                style={{ fontSize: "36px", color: "#023F9E" }}
                variant="inherit"
                component={"h1"}
              >
                Product Details
              </Typography>
              <div className="addsectionbyVendor">
                <TextField
                  style={{ height: "50px" }}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                  className="add_text_field"
                  id="standard-basic"
                  label="Product Title"
                  variant="standard"
                />
                {/* Perishable*/}
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Perishable
                  </FormLabel>
                  <RadioGroup
                    onChange={(e) => setPerishable(e.target.value)}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value={1}
                      checked={perishable == 1 ? true : false}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      checked={perishable == 0 ? true : false}
                      value={0}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
                {/* select category */}
                <FormControl
                  style={{ height: "50px" }}
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Category
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={catId}
                    onChange={(e) => setCatId(e.target.value)}
                    label="Category"
                  >
                    {categories
                      ? categories.map((i) => {
                          return (
                            <MenuItem value={i.id}>{i.category_name}</MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                {/* select Brand */}
                <FormControl
                  style={{ height: "50px" }}
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Brand
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={brandId}
                    onChange={(e) => setBrandId(e.target.value)}
                    label="Brand"
                  >
                    {brands
                      ? brands.map((i) => {
                          return (
                            <MenuItem value={i.id}>{i.brand_name}</MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                {/* select Subcategory */}
                <FormControl
                  style={{ height: "50px" }}
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select SubCategory
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={subId}
                    onChange={(e) => {
                      setSubId(e.target.value);
                    }}
                    label="SubCategory"
                  >
                    {subcategories
                      ? subcategories.map((i) => {
                          return (
                            <MenuItem value={i.id}>
                              {i.sub_category_name}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                {/* select SubSubcategory */}
                <FormControl
                  style={{ height: "50px" }}
                  variant="standard"
                  sx={{ m: 1, minWidth: 120 }}
                >
                  <InputLabel id="demo-simple-select-standard-label">
                    Select SubSubCategory
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={subsubId}
                    onChange={(e) => setSubSubId(e.target.value)}
                    label="SubSubCategory"
                  >
                    {subsubcategories
                      ? subsubcategories.map((i) => {
                          return (
                            <MenuItem value={i.id}>
                              {i.sub_sub_category_name}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                {/* Select Image */}
                <div className="image">
                  <Typography variant="inherit" component={"article"}>
                    Select Image (You can select maximum : 5)
                  </Typography>
                  <input
                    style={{ marginTop: "10px" }}
                    className="h-10 text-lg  outline-none"
                    type="file"
                    id="file"
                    enctype="multipart/form-data"
                    multiple
                    max={2}
                    onChange={imageHandle}
                  />
                  <Fragment>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        // justifyContent: "center",
                        flexWrap: "wrap",
                        // alignItems: "center",
                      }}
                      className="image_class"
                    >
                      {preview
                        ? preview.map((i, index) => {
                            return (
                              <div
                                className="flex flex-col"
                                style={{
                                  width: "40%",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  margin: "18px",
                                  position: "relative",
                                  backgroundColor: "blue",
                                }}
                              >
                                <img
                                  name="image"
                                  className=""
                                  alt="default"
                                  src={i}
                                />
                                <span
                                  className={classes.root}
                                  style={{
                                    width: "30px",
                                    height: "30px",
                                    borderRadius: 8,
                                    // fontSize: "18px",
                                    // marginTop: "-20px",
                                    // marginRight: "-60px",
                                    borderColor: "#023F9E",
                                    position: "absolute",
                                    top: 0,
                                    right: 0,
                                    backgroundColor: "white",
                                    borderRadius: "50px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                  variant="outlined"
                                  onClick={() => removeImage(index)}
                                >
                                  <CloseIcon className="hoverCross" />
                                </span>
                                {/* <span
                                  onClick={() => removeImage(index)}
                                  className="px-3 mx-2 bg-red-800 text-white font-semibold w-20 cursor-pointer text-center rounded-md text-sm"
                                >
                                  Remove
                                </span> */}
                              </div>
                            );
                          })
                        : ""}
                    </div>
                  </Fragment>
                </div>
                {/* Select Return Threshold Time  */}
                <TextField
                  style={{ height: "50px" }}
                  value={threshold}
                  onChange={(e) => {
                    setThreshold(e.target.value);
                  }}
                  type="number"
                  className="add_text_field"
                  id="standard-basic"
                  label="Return Threshold Time (IN HOURS)"
                  variant="standard"
                />

                {/* Description */}
                <TextField
                  value={discription}
                  onChange={(e) => setDiscription(e.target.value)}
                  className="add_text_field"
                  id="standard-basic"
                  label="Description"
                  variant="standard"
                />

                {/* Min Quantity */}
                <TextField
                  value={min_quantity}
                  type="number"
                  onChange={(e) => setMin_quantity(e.target.value)}
                  className="add_text_field"
                  id="standard-basic"
                  label="Min Quantity"
                  variant="standard"
                />

                {/* subscribe*/}
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Subscribe
                  </FormLabel>
                  <RadioGroup
                    row
                    onChange={(e) => setSubscribe(e.target.value)}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    {/* <FormControlLabel
                      value={1}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value={0}
                      control={<Radio />}
                      label="No"
                    /> */}
                    <FormControlLabel
                      value={1}
                      checked={subscribe == 1 ? true : false}
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      checked={subscribe == 0 ? true : false}
                      value={0}
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>

                {/* Quantification*/}
                <FormControl>
                  <FormLabel id="demo-row-radio-buttons-group-label">
                    Quantification
                  </FormLabel>
                  <RadioGroup
                    onChange={(e) => setQuan(e.target.value)}
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>

                {/* Quantification */}
                {quan == "No" ? (
                  <div>
                    <TextField
                      style={{ marginTop: "10px" }}
                      className="add_text_field"
                      id="standard-basic"
                      label="Sale Price"
                      variant="standard"
                      value={saleprice}
                      onChange={(e) => {
                        setSaleprice(e.target.value);
                      }}
                    />
                    <TextField
                      style={{ marginTop: "10px" }}
                      value={purchaseprice}
                      className="add_text_field"
                      id="standard-basic"
                      label="Purchase Price"
                      variant="standard"
                      type={"number"}
                      onChange={(e) => {
                        setPurchaseprice(e.target.value);
                      }}
                    />
                    <FormControl
                      style={{ width: "100%", marginTop: "10px" }}
                      variant="standard"
                    >
                      <InputLabel id="demo-simple-select-standard-label">
                        Discount Type
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={discount}
                        onChange={(e) => setDiscount(e.target.value)}
                        label="Discount Type"
                      >
                        <MenuItem value={"INR"}>INR</MenuItem>
                        <MenuItem value={"%"}>%</MenuItem>
                      </Select>
                    </FormControl>
                    <TextField
                      style={{ marginTop: "10px" }}
                      className="add_text_field"
                      id="standard-basic"
                      label="Discount Price"
                      variant="standard"
                      type={"number"}
                      value={discountamount}
                      onChange={calculate}
                    />
                    <TextField
                      style={{ marginTop: "10px" }}
                      className="add_text_field"
                      id="standard-basic"
                      label="Final Price"
                      variant="standard"
                      type={"number"}
                      disabled
                      value={finalprice}
                    />
                  </div>
                ) : (
                  ""
                )}
                {quan == "Yes" ? (
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      // padding: "10x",
                      fontSize: "18px",
                      margin: "20px",
                      // marginRight: "10px",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={validation}
                  >
                    Next
                  </Button>
                ) : quan == "No" ? (
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      // padding: "10x",
                      fontSize: "18px",
                      margin: "20px",
                      // marginRight: "10px",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={Submit}
                  >
                    Submit
                  </Button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <Vendor_Footer />
      </div>
      <Toaster />
    </>
  );
};

export default Add_productByVendor;

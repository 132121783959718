import React, { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import { useNavigate } from "react-router-dom";
import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  makeStyles,
  TableBody,
  Button,
  Typography,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
  TablePagination,
} from "@material-ui/core";
import { FaLightbulb } from "react-icons/fa";
import { imagePathProduct as imagePath } from "../../../App";
import "./index.css";
import MyButton from "./MyButton";
import Reason from "./Reason";
import {
  filterData,
  formatDate,
  imagepath_product,
  initialRows,
} from "../../../utils/constants";
import {
  useReturnOrderDecision,
  useReturnOrderQuery,
} from "../../../hooks/order.hooks";
import popMsg from "../../../utils/popup";
import { useQueryClient } from "@tanstack/react-query";
const ReturnOrder = () => {
  const [search, setSearch] = useState("");
  const [renderedData, setRenderedData] = useState([]);
  const [showReason, setShowReason] = useState(false);
  const [showReasonOf, setShowReasonOf] = useState();

  const [reasonForReturn, setReasonForReturn] = useState("");

  const [orderId, setOrderId] = useState();
  const [showProductImage, setShowProductImage] = useState(false);
  const [productImage, setProductImage] = useState();
  const navigate = useNavigate();

  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  const queryClient = useQueryClient();
  const { data } = useReturnOrderQuery();
  const { mutateAsync: updateDecision } = useReturnOrderDecision();

  // /////////////////
  const useStyles = makeStyles({
    head: {
      fontSize: "18px",
      fontWeight: "550",
      color: "#36454F",
    },
  });
  const classes = useStyles();
  // /////////////////

  ////////////////                                      Return Order Details
  // Handlers
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
  };

  const approveHandler = (id) => {
    const decision = {
      orderId: id,
      status: 1,
    };
    console.log(decision);
    updateDecision(decision, {
      onSuccess: (res) => {
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("return_orders");
      },
    });
  };
  const rejectHandler = (id) => {
    const decision = {
      orderId: id,
      status: 2,
    };
    updateDecision(decision, {
      onSuccess: (res) => {
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("return_orders");
      },
    });
  };
  useEffect(() => {
    filterData("order_id", search, data, setRenderedData);
  }, [search, data]);
  // ///////////////
  const renderData = (data) => {
    return (
      <TableBody>
        {data &&
          data.length &&
          data?.map((order, index) => {
            return (
              <TableRow
                key={order.id}
                className="list_row"
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "14px" }}
                >
                  {index + 1} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <Button
                    varient="text"
                    color="primary"
                    onClick={() => {
                      navigate(`/productorder/${order.order_id}`);
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>
                      {order.order_number}
                    </span>
                  </Button>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "14px" }}
                >
                  {<p>{order.created_at}</p>}
                  {/* {<span>{formatDate(order.created_at, true, true)}</span>} */}
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "14px" }}
                >
                  <span style={{ fontWeight: 600, color: "#85BB65" }}>
                    {"₹ " + order.paid_amount.toFixed(2)}
                  </span>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "14px" }}
                >
                  <span style={{ fontWeight: 600, color: "#8a2be2" }}>
                    {order.user_name}
                  </span>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "14px" }}
                >
                  <div>
                    <div
                      style={{ position: "relative" }}
                      onClick={() => {
                        setShowProductImage(true);
                        setProductImage(order.product_image);
                      }}
                    >
                      <img
                        style={{
                          height: "40px",
                          width: "40px",
                          position: "relative",
                          borderRadius: "50%",
                          cursor: "pointer",
                        }}
                        src={`${imagePath}${order.product_image}`}
                      />
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "14px" }}
                >
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      flexDirection: "column",
                      gap: "5px",
                    }}
                  >
                    <div
                      onClick={() => {
                        setShowReason(true);
                        setShowReasonOf(order);
                        setReasonForReturn(order.reason_for_return);
                      }}
                      style={{
                        display: "flex",
                        alineItems: "center",
                        cursor: "pointer",
                      }}
                    >
                      <FaLightbulb
                        style={{ fontSize: "18px", color: "crimson" }}
                        className="icon_return"
                      />
                      <span>Reason</span>
                    </div>
                  </div>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontSize: "14px" }}
                >
                  <div
                    className={
                      order.payment_method === 0
                        ? "payment_method__cod"
                        : order.payment_method === 1
                        ? "payment_method__online"
                        : ""
                    }
                  >
                    {order.payment_method === 0
                      ? "COD"
                      : order.payment_method === 1
                      ? "Online"
                      : ""}
                  </div>
                </TableCell>
                <TableCell>
                  {order.is_status === 0 ? (
                    <div style={{ display: "flex" }}>
                      <MyButton
                        onClick={() => {
                          approveHandler(order.id);
                        }}
                        style={{
                          backgroundColor: "#32CD32",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        lable={"Approve"}
                      />
                      <span>&nbsp;&nbsp;|&nbsp;&nbsp; </span>
                      <MyButton
                        onClick={() => {
                          rejectHandler(order.id);
                        }}
                        style={{
                          backgroundColor: "#FF3131",
                          color: "#fff",
                          cursor: "pointer",
                        }}
                        lable={"Reject"}
                      />
                    </div>
                  ) : order.is_status === 1 ? (
                    <span style={{ fontWeight: 600, color: "green" }}>
                      Approved
                    </span>
                  ) : order.is_status === 2 ? (
                    <span style={{ fontWeight: 600, color: "red" }}>
                      Rejected
                    </span>
                  ) : (
                    ""
                  )}
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    );
  };

  return (
    <div className="container">
      <Sidebar />
      <Header name="Return orders" />
      <div className="content_category">
        <div className="headADD_states">
          <span
            className="list_heading"
            style={{
              marginLeft: "6px",
              marginTop: "14px",
              cursor: "pointer",
            }}
          >
            Return orders
          </span>
          <div>
            <TextField
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              id="outlined-basic"
              placeholder="Search by Order ID"
              variant="outlined"
              style={{ marginRight: "15px" }}
            />
          </div>
        </div>
        <div className="sss">
          <div className="ss">
            <TableContainer className="cat_table" component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="return table">
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.head}>
                      No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Order ID
                    </TableCell>
                    <TableCell className={classes.head}>Date</TableCell>
                    <TableCell className={classes.head}>Amount</TableCell>
                    <TableCell className={classes.head}>User_name</TableCell>
                    <TableCell className={classes.head}>Product</TableCell>
                    <TableCell className={classes.head}>Reason</TableCell>
                    <TableCell className={classes.head}>
                      Payment Method
                    </TableCell>
                    <TableCell className={classes.head}>Approval</TableCell>
                  </TableRow>
                </TableHead>
                {renderedData &&
                  renderedData.length &&
                  renderData(renderedData)}
              </Table>
              <TablePagination
                sx={{ px: 2 }}
                page={page}
                component="div"
                rowsPerPage={rowsPerPage}
                // count={productData && productData.length ? productData.length : 0}
                count={(renderedData && renderedData.length) || 0}
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{ "aria-label": "Next Page" }}
                backIconButtonProps={{ "aria-label": "Previous Page" }}
                showfirstbutton="true"
                showlastbutton="true"
              />
            </TableContainer>
          </div>
        </div>
      </div>
      {/* Window to Show Return Reason, if any image and description */}
      {showReason && (
        <Reason
          open={showReason}
          setOpen={setShowReason}
          reason={reasonForReturn}
          description={showReasonOf && showReasonOf?.description}
          returnImage={
            showReasonOf && showReasonOf?.image ? showReasonOf.image : ""
          }
        />
      )}
      {showProductImage && (
        <Dialog
          open={showProductImage}
          onClose={() => setShowProductImage(false)}
          fullWidth
        >
          <DialogTitle>Product</DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <img
                alt={productImage}
                style={{ maxWidth: "25rem", maxHeight: "25rem" }}
                src={`https://shaiper.karbh.com${imagepath_product}${productImage}`}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setShowProductImage(false)}
              color="secondary"
              variant="outlined"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
};

export default ReturnOrder;

let initialState={
    userProfileName:null,
    token:null,
    product:null,
    addProductsDataForUpdate:Array,
    search:null,
    UpdatedProductData:null,
    addCountries:null,
    stock:[],
    astheticMenu:false,
    frontEndMenu:false,
    physicalMenu:false,
    sectionMenu:false,
};

const reducer=(state=initialState,action)=>{
    switch(action.type){
        case "userProfileName":{
            return {
                ...state,userProfileName:action.playload
            };
        }
        case "auth":{
            return {
                ...state,token:action.playload
            };
        }
        case "logout":{
            return {
                ...state,token:null
            };
        }
        case "product":{
            return {
                ...state,product:action.playload
            };
        }
        case "addProductsDataForUpdate":{
            return {
                ...state,addProductsDataForUpdate:action.playload
            };
        }
        case "UpdatedProductData":{
            return {
                ...state,UpdatedProductData:action.playload
            };
        }
        case "addCountry":{
            return {
                ...state,addCountries:action.playload
            };
        }
        case "search":{
            return {
                ...state,search:action.playload
            };
        }
        case "stock":{
            return {
                ...state,stock:action.playload
            };
        }
        case "astheticMenu":{
            return {
                ...state,astheticMenu:action.playload
            };
        }
        case "frontEndMenu":{
            return {
                ...state,frontEndMenu:action.playload
            };
        }
        case "physicalMenu":{
            return {
                ...state,physicalMenu:action.playload
            };
        }
        case "sectionMenu":{
            return {
                ...state,sectionMenu:action.playload
            };
        }
        default:return state;
    }
}

export default reducer;
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addSliderData,
  deleteSliderData,
  getMobileSliderData,
  getWebSliderData,
  updateSliderData,
  updateSliderStatus,
} from "../services/slider.service";

const useWebSliderQuery = () => {
  return useQuery({
    queryKey: ["web-sliders"],
    queryFn: getWebSliderData,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useMobileSliderQuery = () => {
  return useQuery({
    queryKey: ["mobile-sliders"],
    queryFn: getMobileSliderData,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useAddSliderData = () => {
  const queryClient = useQueryClient();
  return useMutation(addSliderData, {
    onSettled: () => {
      queryClient.invalidateQueries("web-sliders");
      queryClient.invalidateQueries("mobile-sliders");
    },
    onError: (error) => {
      console.error("error when adding new sub-sub-category", error);
    },
  });
};

const useUpdateSliderData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSliderData, {
    onSettled: () => {
      queryClient.invalidateQueries("web-sliders");
      queryClient.invalidateQueries("mobile-sliders");
    },
    onError: (error) => {
      console.error("error when adding new sub-sub-category", error);
    },
  });
};

const useDeleteSliderData = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSliderData, {
    onSettled: () => {
      queryClient.invalidateQueries("web-sliders");
      queryClient.invalidateQueries("mobile-sliders");
    },
    onError: (error) => {
      console.error("error when deleting sub-sub-category", error);
    },
  });
};

const useUpdateSliderStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSliderStatus, {
    onSettled: () => {
      queryClient.invalidateQueries("web-sliders");
      queryClient.invalidateQueries("mobile-sliders");
    },
    onError: (error) => {
      console.error("error when changing status sub-sub-category", error);
    },
  });
};

export {
  useWebSliderQuery,
  useMobileSliderQuery,
  useAddSliderData,
  useDeleteSliderData,
  useUpdateSliderStatus,
  useUpdateSliderData,
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete, InputLabel } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useCategoriesQuery } from "../../../../hooks/category.hooks";
import { useProductQuery } from "../../../../hooks/product.hooks";
import { useAddSliderData } from "../../../../hooks/slider.hooks";
import { useSubCategoryQuery } from "../../../../hooks/subcategory.hooks";
import popMsg from "../../../../utils/popup";
import DropDown from "../../../ui/DropDown";

const AddWebSlider = ({ open, setOpen }) => {
  const [bannerTitle, setBannerTitle] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [product, setProduct] = useState("");
  const [subscribedProd, setSubscribedProd] = useState("");
  const [url, setUrl] = useState("");
  const [bannerFor, setBannerFor] = useState("");
  // const [slider,setSlider] = useState("")

  const { data: categoryData } = useCategoriesQuery();
  const { data: subCategoryData } = useSubCategoryQuery();
  const { data: productData } = useProductQuery();
  const { mutateAsync: addSlider } = useAddSliderData();
  const queryClient = useQueryClient();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };
  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  function resetForm() {
    setBannerTitle("");
    setImage(null);
    setImagePreview("");
    setBannerFor("");
    setCategory("");
    setSubCategory("");
    setProduct("");
    setSubscribedProd("");
    setUrl("");
  }

  const handleSave = () => {
    const slider = {
      type: 1,
      title: bannerTitle,
      image,
      selectedBannerFor: bannerFor,
      category,
      subCategory,
      product,
      subscribedProduct: subscribedProd,
      url,
    };
    addSlider(slider, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("web-sliders");
        queryClient.invalidateQueries("mobile-sliders");
      },
    });
  };

  // Dropdown options
  const categoryOptions = categoryData?.map((cat) => ({
    id: cat.id,
    value: cat.id,
    label: cat.category_name,
  }));
  const subCategoryOptions = subCategoryData?.map((sub) => ({
    id: sub.id,
    value: sub.id,
    label: sub.sub_category_name,
  }));
  const productOptions = productData?.map((prod) => ({
    label: prod.product_title,
    value: prod.id,
  }));
  const subscribedProdOptions = productData
    ?.filter((prod) => prod.is_subscribed === 1)
    .map((prod) => ({
      label: prod.product_title,
      value: prod.id,
    }));

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Web Slider</DialogTitle>
      <DialogContent>
        <Box style={{ width: "100%", overflow: "unset" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  placeholder="Banner Title"
                  value={bannerTitle}
                  onChange={(e) => {
                    setBannerTitle(e.target.value);
                  }}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel style={{ fontSize: "0.8rem" }}>
                  Select Image :
                </InputLabel>
                <Input
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  type="file"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    style={{
                      maxHeight: "10rem",
                      maxWidth: "15rem",
                      marginTop: "1rem",
                      marginLeft: "10%",
                    }}
                    src={imagePreview}
                    alt={"category-image"}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel style={{ fontSize: "0.8rem" }}>
                  Banner For:
                </InputLabel>
                <Select
                  value={bannerFor}
                  onChange={(e) => {
                    const file = e.target.value;
                    setBannerFor(file);
                  }}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value="0">All Products</MenuItem>
                  <MenuItem value="1">Category</MenuItem>
                  <MenuItem value="2">SubCategory</MenuItem>
                  <MenuItem value="3">Single Product</MenuItem>
                  <MenuItem value="4">Subscribed Product</MenuItem>
                  <MenuItem value="5">Other URL</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {bannerFor === "5" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <TextField
                    InputLabelProps={{
                      className: classes.textFieldLabel,
                    }}
                    placeholder="Entre URL"
                    size="small"
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  />
                </FormControl>
              </Grid>
            )}
            {bannerFor === "1" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <DropDown
                    label={"Select Category"}
                    options={categoryOptions}
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    key="dropdown-category"
                  />
                </FormControl>
              </Grid>
            )}
            {bannerFor === "2" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <DropDown
                    label="Select Sub-Category"
                    options={subCategoryOptions}
                    value={subCategory}
                    onChange={(e) => setSubCategory(e.target.value)}
                    key="dropdown-sub_cateogory"
                  />
                </FormControl>
              </Grid>
            )}
            {bannerFor === "3" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={product}
                    onChange={(e, newValue) => setProduct(newValue)}
                    options={productOptions}
                    renderInput={(params) => (
                      <TextField {...params} label={"Select Product"} />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
            {bannerFor === "4" && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Autocomplete
                    value={subscribedProd}
                    onChange={(e, newValue) => setSubscribedProd(newValue)}
                    options={subscribedProdOptions}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={"Select Subscribed-Product"}
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddWebSlider;

import {
  Button,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@mui/icons-material/Search";
import { Input } from "@mui/material";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import React from "react";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
const Agency_Header = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  let navigate = useNavigate();
  let userName = Cookies.get("userName");
  // console.log("cookies by vendor :", cookies);
  // console.log("userName :", userName);
  return (
    <>
     <div className="header">
        <Typography
        onClick={()=>{window.location.reload(false)}}
          className="heading"
          style={{ fontFamily: "revert", fontSize: "23px", marginLeft: "35px" ,cursor:"pointer"}}
          component="h2"
        >
          {props.name ? props.name : "Shapier"}
        </Typography>
        <div className="search">
          {/* <Input />
          <SearchIcon /> */}
        </div>
        <div className="profile">
          <PopupState variant="popover" popupId="demo-popup-menu">
            {(popupState) => (
              <React.Fragment>
                <Button
                  className="SidebarLable"
                  style={{
                    padding: 8,
                    width: 200,
                    height: 50,
                    marginRight: "27px",
                    overflow: "hidden",
                  }}
                  variant="contained"
                  {...bindTrigger(popupState)}
                >
                  <ListItemIcon>
                    <AccountCircleIcon
                      style={{
                        marginLeft: "9px",
                        width: "35px",
                        height: "35px",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    style={{ marginRight: "43px", fontFamily: "sans-serif" }}
                    primary={userName ? userName : "Anshu"}
                  />
                  {/* <DashboardIcon />
                    <Typography variant="inherit">Address Esthetics</Typography> */}
                </Button>
                <Menu style={{ marginTop: 51 }} {...bindMenu(popupState)}>
                  <MenuItem
                    style={{ width: 224, padding: 10 }}
                    onClick={() => {
                      // navigate("/profile");
                    }}
                  >
                    Profile
                  </MenuItem>
                  <MenuItem
                    style={{ width: 224, padding: 10 }}
                    onClick={()=>navigate("/agency/main")}
                  >
                    Dashboard
                  </MenuItem>
                  <MenuItem
                    style={{ width: 224, padding: 10 }}
                    onClick={() => {
                      // removeCookie("content");
                      // removeCookie("userID");
                      // removeCookie("Role");
                      navigate("/")
                      window.location.reload(false);
                    }}
                  >
                    Logout
                  </MenuItem>
                </Menu>
              </React.Fragment>
            )}
          </PopupState>
        </div>
      </div>
    </>
  );
};

export default Agency_Header;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addUnits,
  deleteUnits,
  getAllUnits,
  updateUnits,
} from "../services/units.service";

const useUnitsQuery = () => {
  return useQuery({
    queryKey: ["units"],
    queryFn: getAllUnits,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useAddUnitsData = () => {
  const queryClient = useQueryClient();
  return useMutation(addUnits, {
    onSettled: () => {
      queryClient.invalidateQueries("units");
    },
    onError: (error) => {
      console.error("error when adding new Unit", error);
    },
  });
};

const useUpdateUnitData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateUnits, {
    onSettled: () => {
      queryClient.invalidateQueries("units");
    },
    onError: (error) => {
      console.error("error when updating  Unit", error);
    },
  });
};

const useDeleteUnitData = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteUnits, {
    onSettled: () => {
      queryClient.invalidateQueries("units");
    },
    onError: (error) => {
      console.error("error when deleting  Unit", error);
    },
  });
};

export { useUnitsQuery, useAddUnitsData, useUpdateUnitData, useDeleteUnitData };

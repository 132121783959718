import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import Footer from "../../Container/Footer/Footer";

import AddSubadmin from "./AddSubadmin";
import UpdateSubadmin from "./UpdateSubadmin";
import { getAllSubadmin } from "../../../services/subadmin.service";
import { initialRows } from "../../../utils/constants";
// import DeleteConfirmation from "../../ui/DeleteConfirmation";
import { apiRoutes } from "../../../utils/apiRoutes";
import axios from "../../../axios";
import popMsg from "../../../utils/popup";
const SubAdmin = () => {
  const [openAddSubadmin, setOpenAddSubadmin] = useState(false);
  const [subadminAdded, setSubadminAdded] = useState(false);
  const [openUpdateSubadmin, setOpenUpdateSubadmin] = useState(false);
  const [subAdminUpdated, setSubadminUpdated] = useState(false);
  const [subadminToUpdate, setSubadminToUpdate] = useState();
  let [search, setSearch] = useState("");
  const [subadminData, setSubadminData] = useState();
  const [renderedData, setRenderedData] = useState();
  const [delSubadminId, setDelSubadminId] = useState("");
  const [delOpen, setDelOpen] = useState(false);
  const [deletedSubadmin, setDeletedSubadmin] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  // Handlers
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    // setPage(0);
  };
  const handleDelete = () => {
    deleteSubadmin();
  };
  // Handlers - END

  // Functions
  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    filterData();
  }, [search]);

  useEffect(() => {
    if (subadminAdded) get();
    setSubadminAdded(false);
  }, [subadminAdded]);

  useEffect(() => {
    if (subAdminUpdated) get();
    setSubadminUpdated(false);
  }, [subAdminUpdated]);

  useEffect(() => {
    if (deletedSubadmin) get();
    setDeletedSubadmin(false);
  }, [deletedSubadmin]);

  async function deleteSubadmin() {
    let url = `${apiRoutes.deleteSubadmin}${delSubadminId}`;
    const response = await axios.get(url);
    const data = response.data;
    if (data.success) {
      setDeletedSubadmin(true);
      popMsg(data.message, true);
      setDelOpen(false);
    }
  }

  function filterData() {
    const filtered =
      subadminData &&
      subadminData.filter((sub) => {
        return sub.name.toString().toLowerCase().includes(search.toLowerCase());
      });
    setRenderedData(filtered);
  }

  async function get() {
    const data = await getAllSubadmin();
    setSubadminData(data.admin_list);
    setRenderedData(data.admin_list);
  }

  // ////////////////////////////////Table Body///////////////////////////////////////////////
  const renderData = (data) => {
    return (
      <TableBody>
        {data
          ? data.map((i, index) => {
              return (
                <TableRow
                  className="list_row"
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {(page + 1) * rowsPerPage - rowsPerPage + index + 1}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {i.name}
                  </TableCell>
                  <TableCell style={{ fontSize: "17px" }} align="right">
                    <ModeEditIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenUpdateSubadmin(true);
                        setSubadminToUpdate(i);
                      }}
                    />
                    <DeleteIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDelOpen(true);
                        setDelSubadminId(i.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          : ""}
      </TableBody>
    );
  };

  return (
    <>
      <div className="container">
        <Sidebar />
        <Header />
        <div className="content_state">
          {/* list heading and add btn */}
          <div className="headADD_states">
            <span
              className="list_heading"
              onClick={() => {
                get();
              }}
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              All SubAdmins
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search SubAdmin"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
              <Button
                size="large"
                className="addbtn"
                variant="contained"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                startIcon={<AddIcon />}
                onClick={() => setOpenAddSubadmin(true)}
              >
                Add
              </Button>
            </div>
          </div>
          {/* list of countries */}
          <div className="sss">
            <div className="ss">
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Subadmin Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderedData &&
                    renderedData.length &&
                    renderData(
                      renderedData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    )}
                </Table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={
                    renderedData && renderedData.length
                      ? renderedData.length
                      : 0
                  }
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  showFirstButton
                  showLastButton
                />
              </TableContainer>
            </div>
          </div>
          <Toaster />
        </div>
        <AddSubadmin
          open={openAddSubadmin}
          setOpen={setOpenAddSubadmin}
          setSubadminAdded={setSubadminAdded}
        />
        <UpdateSubadmin
          open={openUpdateSubadmin}
          setOpen={setOpenUpdateSubadmin}
          setSubadminUpdated={setSubadminUpdated}
          subadmin={subadminToUpdate}
        />
        <Footer />
      </div>
      <Dialog
        open={delOpen}
        onClose={() => setDelOpen(false)}
        aria-labelledby="delete-dialog-title"
        maxWidth="xs"
      >
        <DialogTitle id="delete-dialog-title">Delete Confirmation</DialogTitle>
        <DialogContent>
          <DialogTitle>Are you sure you want to delete this item?</DialogTitle>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDelOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SubAdmin;

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCategoriesQuery } from "../../../../hooks/category.hooks";
import { useProductQuery } from "../../../../hooks/product.hooks";
import { useUpdateSliderData } from "../../../../hooks/slider.hooks";
import { useSubCategoryQuery } from "../../../../hooks/subcategory.hooks";
import { imagepath_slider } from "../../../../utils/constants";
import popMsg from "../../../../utils/popup";
import DropDown from "../../../ui/DropDown";

const UpdateMobileSlider = ({ open, setOpen, slide }) => {
  const [slideId, setSlideId] = useState("");
  const [bannerTitle, setBannerTitle] = useState("");
  const [oldImage, setOldImage] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [product, setProduct] = useState("");
  const [subscribedProd, setSubscribedProd] = useState("");
  const [url, setUrl] = useState("");
  const [bannerFor, setBannerFor] = useState("");

  const queryClient = useQueryClient();
  const { data: categoryData } = useCategoriesQuery();
  const { data: subCategoryData } = useSubCategoryQuery();
  const { data: productData } = useProductQuery();
  const { mutateAsync: updateSlider } = useUpdateSliderData();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!slide) return;
    setSlideId(slide.id);
    setBannerFor(slide.slider);
    setBannerTitle(slide.banner_title);
    setOldImage(slide.image);
    setBannerFor(slide.slider);
    if (slide.slider === "1") setCategory(slide.category_id);
    if (slide.slider === "2") setSubCategory(slide.sub_category_id);
    if (slide.slider === "5") setUrl(slide.other_url);
  }, [slide]);

  useEffect(() => {
    if (productData && slide.product_id) {
      const op = productData.map((prod) => {
        return {
          value: prod.id.toString(),
          label: prod.product_title,
        };
      });
      const filteredOption = op.find(
        (option) => option.value === slide.product_id
      );
      setProduct(filteredOption);
    }
  }, [productData]);
  useEffect(() => {
    if (productData && slide.subscribe_id) {
      const op = productData.map((prod) => {
        return {
          value: prod.id.toString(),
          label: prod.product_title,
        };
      });
      const filteredOption = op.find(
        (option) => option.value === slide.subscribe_id
      );
      setSubscribedProd(filteredOption);
    }
  }, [productData]);

  const handleClose = () => {
    setOpen(false);
    setImage(null);
    setImagePreview("");
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleSave = () => {
    const slide = {
      id: slideId,
      type: 0,
      title: bannerTitle,
      image,
      selectedBannerFor: bannerFor,
      category,
      subCategory,
      product,
      subscribedProduct: subscribedProd,
      url,
    };
    updateSlider(slide, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("web-sliders");
        queryClient.invalidateQueries("mobile-sliders");
      },
    });
  };

  // Dropdown options
  const categoryOptions = categoryData?.map((cat) => ({
    id: cat.id,
    value: cat.id,
    label: cat.category_name,
  }));
  const subCategoryOptions =
    subCategoryData &&
    subCategoryData?.map((sub) => ({
      id: sub.id,
      value: sub.id,
      label: sub.sub_category_name,
    }));
  const productOptions =
    productData &&
    productData?.map((prod) => ({
      label: prod.product_title,
      value: prod.id,
    }));
  const subscribedProdOptions =
    productData &&
    productData
      ?.filter((prod) => prod.is_subscribed === 1)
      .map((prod) => ({
        label: prod.product_title,
        value: prod.id,
      }));

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Update Mobile Slider</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                size="small"
                placeholder="Banner Title"
                value={bannerTitle}
                onChange={(e) => {
                  setBannerTitle(e.target.value);
                }}
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
            </FormControl>
          </Grid>
          {oldImage && (
            <Grid item xs={12}>
              <img
                src={`${imagepath_slider}${oldImage}`}
                alt="old"
                style={{ height: "10rem", width: "15rem" }}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Select Image :
              </InputLabel>
              <Input
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                type="file"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <img
                  style={{
                    height: "10rem",
                    width: "15rem",
                    marginTop: "1rem",
                    marginLeft: "10%",
                  }}
                  src={imagePreview}
                  alt={"service-image"}
                />
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Banner For:
              </InputLabel>
              <Select
                value={bannerFor}
                onChange={(e) => {
                  const file = e.target.value;
                  setBannerFor(file);
                }}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="0">All Products</MenuItem>
                <MenuItem value="1">Category</MenuItem>
                <MenuItem value="2">SubCategory</MenuItem>
                <MenuItem value="3">Single Product</MenuItem>
                <MenuItem value="4">Subscribed Product</MenuItem>
                <MenuItem value="5">Other URL</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {bannerFor === "5" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  placeholder="Entre URL"
                  size="small"
                  value={url}
                  onChange={(e) => setUrl(e.target.value)}
                />
              </FormControl>
            </Grid>
          )}
          {bannerFor === "1" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <DropDown
                  label={"Select Category"}
                  options={categoryOptions}
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                  key="dropdown-category"
                />
              </FormControl>
            </Grid>
          )}
          {bannerFor === "2" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <DropDown
                  label="Select Sub-Category"
                  options={subCategoryOptions}
                  value={subCategory}
                  onChange={(e) => setSubCategory(e.target.value)}
                  key="dropdown-sub_cateogory"
                />
              </FormControl>
            </Grid>
          )}
          {bannerFor === "3" && productOptions && productOptions.length && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  value={product}
                  onChange={(e, newValue) => setProduct(newValue)}
                  options={productOptions}
                  renderInput={(params) => (
                    <TextField {...params} label={"Select Product"} />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          {bannerFor === "4" && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  value={subscribedProd}
                  onChange={(e, newValue) => setSubscribedProd(newValue)}
                  options={subscribedProdOptions}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={"Select Subscribed-Product"}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateMobileSlider;

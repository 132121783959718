import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useUpdateUnitData } from "../../../hooks/unit.hooks";
import popMsg from "../../../utils/popup";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";

const UpdateUnit = ({ open, setOpen, unit }) => {
  const [unitId, setUnitId] = useState("");
  const [unitName, setUnitName] = useState("");

  const queryClient = useQueryClient();
  const { mutateAsync: updateUnitData } = useUpdateUnitData();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!unit) return;
    setUnitId(unit.id);
    setUnitName(unit.unit_name);
  }, [unit]);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    const unit = {
      id: unitId,
      unitName,
    };
    updateUnitData(unit, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("units");
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Unit</DialogTitle>
      <DialogContent>
        <Box style={{ width: "100%", overflow: "unset" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  placeholder="Unit Name"
                  value={unitName}
                  onChange={(e) => {
                    let value = e.target.value;
                    setUnitName(value);
                  }}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateUnit;

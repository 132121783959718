import { Button, Grid, TextField, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { updateProductStock } from "../../../services/main-product.service";
import popMsg from "../../../utils/popup";
import Window from "../../ui/Window";

const UpdateStock = ({ open, setOpen, product, updated }) => {
  const [stock, setStock] = useState();
  const [quantification, setQuantification] = useState(0);

  const [openAddQuanStock, setOpenAddQuanStock] = useState(false);
  const [selectedQuantification, setSelectedQuantification] = useState();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    setStock(0);
  };

  const handleQuantUpdateClose = () => {
    setOpenAddQuanStock(false);
    setStock(0);
  };

  async function updateStock(payload, id) {
    const data = await updateProductStock(payload, id);
    if (data.success) {
      popMsg(data.message, true);
      handleClose();
      setOpenAddQuanStock(false);
      updated(true);
    }
  }

  const handleStockUpdateProd = (id, stock) => {
    let payload = {
      stock,
      quantification,
    };
    updateStock(payload, id);
  };

  useEffect(() => {
    if (
      product &&
      product.quantification &&
      product.quantification.length > 0
    ) {
      setQuantification(1);
    }
  }, [product]);
  return (
    <Window open={open} setOpen={handleClose} title={"Update Stock"}>
      <h2>{product && product.product_title && product.product_title}</h2>
      {product &&
        (!product.quantification || product.quantification.length === 0) && (
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                size="small"
                label="Current Stock"
                value={product && product.stock && product.stock}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                size="small"
                label="Add Stock"
                value={stock}
                onChange={(e) => {
                  setStock(Number(e.target.value));
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                size="small"
                variant="outlined"
                value={Number(product.stock) + stock}
                fullWidth
                disabled
              />
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                onClick={() =>
                  handleStockUpdateProd(
                    product.id,
                    Number(product.stock) + stock
                  )
                }
                variant="contained"
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        )}
      {product &&
        product.quantification &&
        product.quantification.map((quan) => {
          return (
            <Grid key={quan.id} container spacing={2}>
              <Grid item xs={true}>
                <TextField
                  label={"Quantity"}
                  size="small"
                  value={`${quan.quantify} - ${quan.unitname}`}
                  disabled
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </Grid>
              <Grid item xs={true}>
                <TextField
                  label="Current Stock"
                  size="small"
                  value={quan.stock}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item xs={true}>
                <Button
                  onClick={() => {
                    setOpenAddQuanStock(true);
                    setSelectedQuantification(quan);
                  }}
                >
                  Add Stock
                </Button>
                <Window
                  open={openAddQuanStock}
                  setOpen={handleQuantUpdateClose}
                  title="Add Stock"
                >
                  {selectedQuantification && (
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField
                          size="small"
                          label="Qunatity"
                          value={`${selectedQuantification.quantify} - ${selectedQuantification.unitname}`}
                          disabled
                          InputLabelProps={{
                            className: classes.textFieldLabel,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          size="small"
                          label="Current Stock"
                          value={selectedQuantification.stock}
                          disabled
                          InputLabelProps={{
                            className: classes.textFieldLabel,
                          }}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          InputLabelProps={{
                            className: classes.textFieldLabel,
                          }}
                          size="small"
                          label="Add Stock"
                          value={stock}
                          onChange={(e) => {
                            setStock(Number(e.target.value));
                          }}
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          InputLabelProps={{
                            className: classes.textFieldLabel,
                          }}
                          size="small"
                          variant="outlined"
                          value={Number(selectedQuantification.stock) + stock}
                          fullWidth
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <Button
                          fullWidth
                          onClick={() =>
                            handleStockUpdateProd(
                              selectedQuantification.id,
                              Number(selectedQuantification.stock) + stock
                            )
                          }
                          variant="contained"
                        >
                          Submit
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </Window>
              </Grid>
            </Grid>
          );
        })}
    </Window>
  );
};

export default UpdateStock;

import {
  Grid,
  TextField,
  makeStyles,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  Select,
  MenuItem,
  Input,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useZipcodesQuery } from "../../../hooks/zipcodes.hooks";
import { addProduct } from "../../../services/main-product.service";
import {
  getAllBrands,
  getCategories,
  getCategoriesByBrand,
  getSubCategories,
  getSubSubCategories,
} from "../../../services/product.service";
import popMsg from "../../../utils/popup";
import Layout from "../../ui/Layout";
import QuantificationTable from "./QuantificationTable";

const AddProduct = () => {
  const [productTitle, setProductTitle] = useState("");
  const [perishable, setPerishable] = useState(0);
  const [threshold, setThreshold] = useState(0);
  const [category, setCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [subSubCategory, setSubSubCategory] = useState("");
  const [brand, setBrand] = useState("");
  const [superAdminCommType, setSuperAdminCommType] = useState("%");
  const [superAdminComm, setSuperAdminComm] = useState(0);
  const [agencyCommType, setAgencyCommType] = useState("%");
  const [agencyComm, setAgencyComm] = useState(0);
  const [description, setDescription] = useState("");
  const [minQuantity, setMinQuantity] = useState(0);
  const [taxCharges, setTaxCharges] = useState(0);
  const [subscribe, setSubscribe] = useState("0");
  const [quan, setQuan] = useState("No");
  const [image, setImage] = useState([]);
  const [imagePreview, setImagePreview] = useState([]);
  const [finalPrice, setFinalPrice] = useState(0);
  const [zipcodes, setZipcodes] = useState([]);
  const [zipcodeIds, setZipcodeIds] = useState([]);

  const [categoryData, setCategoryData] = useState([]);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [subSubCategoryData, setSubSubCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  // const [zipData,setZipData] = useState([])

  const [markedPrice, setMarkedPrice] = useState(0);
  const [purchasePrice, setPurchasePrice] = useState(0);
  const [discountType, setDiscountType] = useState("%");
  const [discount, setDiscount] = useState(0);

  //   States for If Quantification is Yes

  const [_quantity, _setQuantity] = useState(0);
  const [_unit, _setUnit] = useState();
  const [_purchasePrice, _setPurchaseprice] = useState();
  const [_salePrice, _setSalePrice] = useState();
  const [_discountType, _setDiscountType] = useState();
  const [_discount, _setDiscount] = useState();
  const [_finalPrice, _setFinalPrice] = useState();

  const [quanData, setQuanData] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const { data: zipcodesData } = useZipcodesQuery();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    // getCategoryData();
    getBrands();
  }, []);
  useEffect(() => {
    if (brand) getCategoryData(brand);
  }, [brand]);

  useEffect(() => {
    setSubCategory("");
    setSubSubCategory("");
    if (category) getSubCategoryData(category);
  }, [category]);

  useEffect(() => {
    setSubSubCategory("");
    if (subCategory) getSubSubCategoryData(subCategory);
  }, [subCategory]);

  useEffect(() => {
    handleCalculateFinalPrice();
  }, [markedPrice, discount, discountType]);

  //   Handle
  const handleImageChange = (e) => {
    const files = e.target.files;
    const selectedImages = [];

    for (let i = 0; i < Math.min(files.length, 5); i++) {
      selectedImages.push(files[i]);
    }

    setImage(selectedImages);
    const imagePreviews = Array.from(files)
      .slice(0, 5)
      .map((file) => URL.createObjectURL(file));
    setImagePreview(imagePreviews);
  };

  const handleCalculateFinalPrice = () => {
    let price;
    if (markedPrice && discount) {
      if (discountType === "INR") price = markedPrice - discount;
      if (discountType === "%")
        price = markedPrice - (markedPrice * discount) / 100;
    }
    setFinalPrice(price);
  };

  //   Functions
  async function getCategoryData(brandId) {
    const data = await getCategoriesByBrand(brandId);
    let filtered = data.map((cat) => ({
      id: cat.id,
      name: cat.category_name,
    }));
    console.log(filtered);
    setCategoryData(filtered);
  }
  async function getSubCategoryData(categoryId) {
    const data = await getSubCategories();
    let filtered = data
      .filter((subCat) => subCat.category_id === categoryId)
      .map((sub) => ({ id: sub.id, name: sub.sub_category_name }));
    setSubCategoryData(filtered);
  }

  async function getSubSubCategoryData(subId) {
    const data = await getSubSubCategories();
    let filtered = data
      .filter((ssc) => ssc.sub_category_id === subId)
      .map((ssc) => ({ id: ssc.id, name: ssc.sub_sub_category_name }));
    setSubSubCategoryData(filtered);
  }

  async function getBrands() {
    const data = await getAllBrands();
    let filtered = data.map((brand) => {
      return {
        id: brand.id,
        name: brand.brand_name,
      };
    });
    setBrandData(filtered);
  }

  const handleClose = () => {
    resetForm();
    navigate("/manage_product");
  };

  const handleSubmit = () => {
    add();
  };

  async function add() {
    let payload = new FormData();
    payload.append("product_title", productTitle);
    payload.append("is_perishable", perishable);
    payload.append("return_time", threshold);
    payload.append("category_id", category);
    payload.append("subcategory_id", subCategory);
    payload.append("sub_sub_category_id", subSubCategory);
    payload.append("brand_id", brand);
    if (image.length > 1) {
      for (let i = 1; i < image.length; i++) {
        payload.append(`image${i}`, image[1]);
      }
    }
    payload.append("images", image[0]);
    payload.append("agency_commission_type", agencyCommType);
    payload.append("agency_commission", agencyComm);
    payload.append("admin_commission_type", superAdminCommType);
    payload.append("admin_commission", superAdminComm);
    payload.append("description", description);
    payload.append("min_quantity", minQuantity);
    payload.append("tex_charges", taxCharges);
    payload.append("subscribe", subscribe);
    payload.append("deliver_ability", JSON.stringify(zipcodeIds));
    if (quan === "No") {
      payload.append("quantification", 0);
      payload.append("nqstock", 0);
      payload.append("nqsale_price", markedPrice);
      payload.append("nqpurchase_price", purchasePrice);
      payload.append("nqdiscount_type", discountType);
      payload.append("nqdiscount_price", discount);
      payload.append("nqfinal_price", finalPrice);
    } else if (quan === "Yes") {
      payload.append("quantification", 1);
      payload.append("quantifications", JSON.stringify(quanData));
    }
    const data = await addProduct(payload, setIsLoading);
    if (data.success) {
      popMsg(data.message, true);
      handleClose();
    }
  }

  function resetForm() {
    setProductTitle("");
    setPerishable(0);
    setThreshold(0);
    setCategory("");
    setSubCategory("");
    setSubSubCategory("");
    setBrand("");
    setSuperAdminComm(0);
    setSuperAdminCommType(0);
    setDescription("");
    setMinQuantity(0);
    setTaxCharges(0);
    setSubscribe("0");
    setQuan("No");
    setImage([]);
    setImagePreview([]);
    setFinalPrice(0);
    setZipcodeIds([]);
    setZipcodes([]);
  }

  return (
    <Layout title="Add Product">
      <div
        style={{
          minHeight: "80vh",
          height: "fit-content",
          marginBottom: "2rem",
          overflow: "unset",
          background: "#fff",
          padding: "2rem",
          borderRadius: "1rem",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Product Title"
              value={productTitle}
              onChange={(e) => setProductTitle(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <FormControl>
              <FormLabel style={{ fontSize: "0.8rem" }}>Perishable</FormLabel>
              <RadioGroup
                onChange={(e) => setPerishable(e.target.value)}
                row
                aria-label="add-product-perishable-radio-group"
              >
                <FormControlLabel
                  value={1}
                  checked={perishable == 1 ? true : false}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  checked={perishable == 0 ? true : false}
                  value={0}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <TextField
              size="small"
              label="Threshold Time"
              value={threshold}
              onChange={(e) => setThreshold(Number(e.target.value))}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>Brand</InputLabel>
              <Select value={brand} onChange={(e) => setBrand(e.target.value)}>
                <MenuItem value=""></MenuItem>
                {brandData &&
                  brandData.length > 0 &&
                  brandData.map((brand) => {
                    return (
                      <MenuItem value={brand.id} key={brand.id}>
                        {brand.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>Category</InputLabel>
              <Select
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {categoryData &&
                  categoryData.length > 0 &&
                  categoryData.map((cat) => {
                    return (
                      <MenuItem value={cat.id} key={cat.id}>
                        {cat.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Sub Category
              </InputLabel>
              <Select
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {subCategoryData &&
                  subCategoryData.length > 0 &&
                  subCategoryData.map((subCat) => {
                    return (
                      <MenuItem value={subCat.id} key={subCat.id}>
                        {subCat.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Sub Sub Category
              </InputLabel>
              <Select
                value={subSubCategory}
                onChange={(e) => setSubSubCategory(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {subSubCategoryData &&
                  subSubCategoryData.length > 0 &&
                  subSubCategoryData.map((ssc) => {
                    return (
                      <MenuItem value={ssc.id} key={ssc.id}>
                        {ssc.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                Agency Commission Type
              </InputLabel>
              <Select
                value={agencyCommType}
                onChange={(e) => setAgencyCommType(Number(e.target.value))}
                label="Agency Commission Type"
              >
                <MenuItem value={"%"}>Percentage</MenuItem>
                <MenuItem value={"INR"}>INR</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              label="Agency Commission"
              value={agencyComm}
              onChange={(e) => setAgencyComm(Number(e.target.value))}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-standard-label">
                Super Admin Commission Type
              </InputLabel>
              <Select
                value={superAdminCommType}
                onChange={(e) => setSuperAdminCommType(Number(e.target.value))}
                label="Agency Commission Type"
              >
                <MenuItem value={"%"}>Percentage</MenuItem>
                <MenuItem value={"INR"}>INR</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              label="Super Admin Commission"
              value={superAdminComm}
              onChange={(e) => setSuperAdminComm(Number(e.target.value))}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              maxRows={4}
              size="small"
              label="Product Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={9}>
            <FormControl style={{ padding: "1.5rem" }} fullWidth>
              <InputLabel
                htmlFor="image-upload"
                style={{
                  width: "40%",
                  background: "aliceblue",
                  fontSize: "0.8rem",
                  display: "block",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  padding: "12px 18px",
                  cursor: "pointer",
                }}
              >
                Select Images (up to 5)
              </InputLabel>
              <Input
                id="image-upload"
                type="file"
                onChange={handleImageChange}
                inputProps={{
                  multiple: true,
                  accept: "image/*",
                  max: 5,
                  style: { display: "none" },
                }}
              />
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                {imagePreview &&
                  imagePreview.length > 0 &&
                  imagePreview.map((preview, index) => (
                    <img
                      key={index}
                      style={{
                        height: "10rem",
                        width: "15rem",
                        marginTop: "1rem",
                        marginLeft: "10%",
                      }}
                      src={preview}
                      alt={`service-image-${index}`}
                    />
                  ))}
              </div>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            {/* {
                  <Autocomplete/>
                } */}
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              label="Min Quantity"
              value={minQuantity}
              onChange={(e) => setMinQuantity(Number(e.target.value))}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              size="small"
              label="Tax Charges"
              value={taxCharges}
              onChange={(e) => setTaxCharges(Number(e.target.value))}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <FormLabel style={{ fontSize: "0.8rem" }}>Subscribe</FormLabel>
              <RadioGroup
                onChange={(e) => setSubscribe(e.target.value)}
                row
                aria-label="add-product-perishable-radio-group"
              >
                <FormControlLabel
                  value={1}
                  checked={subscribe == 1 ? true : false}
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  checked={subscribe == 0 ? true : false}
                  value={0}
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl>
              <FormLabel style={{ fontSize: "0.8rem" }}>
                Quantification
              </FormLabel>
              <RadioGroup onChange={(e) => setQuan(e.target.value)} row>
                <FormControlLabel
                  checked={quan === "Yes" ? true : false}
                  value="Yes"
                  control={<Radio />}
                  label="Yes"
                />
                <FormControlLabel
                  checked={quan == "No" ? true : false}
                  value="No"
                  control={<Radio />}
                  label="No"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {zipcodesData && (
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  value={zipcodes}
                  onChange={(_, newValue) => {
                    const ids = newValue.map((item) => item.id);
                    setZipcodeIds(ids);
                    setZipcodes(newValue);
                  }}
                  getOptionLabel={(option) => option.zipcode}
                  options={zipcodesData}
                  multiple={true}
                  renderInput={(params) => (
                    <TextField {...params} label="Select Zipcode" />
                  )}
                />
              </FormControl>
            </Grid>
          )}
        </Grid>
        {quan === "No" ? (
          <div
            style={{ minHeight: "10rem", marginTop: "3rem", overflow: "unset" }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  label="Market Price"
                  value={markedPrice}
                  onChange={(e) => setMarkedPrice(Number(e.target.value))}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  label="Purchase Price"
                  value={purchasePrice}
                  onChange={(e) => setPurchasePrice(Number(e.target.value))}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={3}>
                <FormControl fullWidth>
                  <InputLabel style={{ fontSize: "0.8rem" }}>
                    Discount Type
                  </InputLabel>
                  <Select
                    value={discountType}
                    onChange={(e) => {
                      setDiscount(0);
                      setDiscountType(e.target.value);
                    }}
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={"INR"}>INR</MenuItem>
                    <MenuItem value={"%"}>Percentage</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  size="small"
                  label="Discount"
                  value={discount}
                  onChange={(e) => {
                    let input = e.target.value;
                    const isValidNumber = /^\d*$/.test(input);
                    if (isValidNumber) {
                      let val = Number(input);
                      if (discountType === "%" && val > 100) return;
                      setDiscount(Number(e.target.value));
                    }
                  }}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  disabled
                  size="small"
                  // label="FinalPrice"
                  value={finalPrice}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Button
                    onClick={handleSubmit}
                    variant="outlined"
                    color="primary"
                    disabled={isLoading}
                  >
                    Submit
                  </Button>
                </FormControl>
              </Grid>
            </Grid>
          </div>
        ) : (
          ""
        )}
        {quan === "Yes" ? (
          <>
            <QuantificationTable
              quanData={quanData}
              setQuanData={setQuanData}
            />
            <Button
              disabled={isLoading}
              onClick={handleSubmit}
              variant="outlined"
              color="primary"
            >
              Submit
            </Button>
          </>
        ) : (
          ""
        )}
      </div>
      <Toaster />
    </Layout>
  );
};

export default AddProduct;

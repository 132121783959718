import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "../axios";
import {
  getAllReturnOrders,
  getAllServiceOrders,
  getServiceOrderDetails,
  updateReturnOrderDecision,
  updateServiceOrderStatus,
} from "../services/orders.service";
import { apiRoutes } from "../utils/apiRoutes";

const fetchOrderDetails = async (orderId) => {
  try {
    const response = await axios.get(`${apiRoutes.orderDetails}${orderId}`);
    return response.data;
  } catch (error) {
    throw new Error("Faild to Fetch Order Details", error);
  }
};

const useProductOrderDetailsQuery = (orderId) => {
  return useQuery(
    ["product-order-details", orderId],
    () => fetchOrderDetails(orderId),
    { enabled: !!orderId }
  );
};

const useServiceOrdersQuery = () => {
  return useQuery({
    queryKey: ["service_orders"],
    queryFn: getAllServiceOrders,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useReturnOrderQuery = () => {
  return useQuery({
    queryKey: ["return_orders"],
    queryFn: getAllReturnOrders,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useServiceOrderDetailsQuery = (id) => {
  return useQuery({
    queryKey: ["service_order_details", id],
    queryFn: () => getServiceOrderDetails(id),
    staleTime: 300000,
    cacheTime: 3600000,
    // enabled: !!id,
  });
};

const useReturnOrderDecision = () => {
  const queryClient = useQueryClient();
  return useMutation(updateReturnOrderDecision, {
    onSettled: () => {
      queryClient.invalidateQueries("return_orders");
    },
    onError: (error) => {
      console.error("error when updating return order status", error);
    },
  });
};

const useServieOrderStatusUpdate = () => {
  const queryClient = useQueryClient();
  return useMutation(updateServiceOrderStatus, {
    onSettled: () => {
      queryClient.invalidateQueries("service_order_details");
    },
    onError: (error) => {
      console.error("error when updating service order status", error);
    },
  });
};

export {
  useProductOrderDetailsQuery,
  useServiceOrderDetailsQuery,
  useServieOrderStatusUpdate,
  useServiceOrdersQuery,
  useReturnOrderQuery,
  useReturnOrderDecision,
};

import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableBody,
} from "@material-ui/core";
import React from "react";
import { baseUrl, imagepath_product } from "../../utils/constants";

const OrderDetailsProductTable = ({
  products,
  subTotal,
  discount,
  couponCode,
  total,
  shippingCharges,
}) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Product</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Quantity</TableCell>
            <TableCell>Sub-Total</TableCell>
            <TableCell>Tax</TableCell>
            <TableCell>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products &&
            products.length &&
            products.map((product) => {
              return (
                <TableRow key={product.id}>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <img
                        style={{
                          maxWidth: "6rem",
                          maxHeight: "6rem",
                          borderRadius: "1rem",
                        }}
                        src={`${baseUrl}${imagepath_product}${product?.image}`}
                      />
                      <p>
                        {product?.product_title}{" "}
                        {product?.quantification
                          ? " - " + product?.quantification
                          : ""}
                      </p>
                    </div>
                  </TableCell>
                  <TableCell>{product?.final_price}</TableCell>
                  <TableCell>
                    {Number(product?.quantity)} {product?.unit && product?.unit}
                  </TableCell>
                  <TableCell>
                    {Number(product?.final_price) * Number(product?.quantity)}
                  </TableCell>
                  <TableCell>{product.tax_charges}</TableCell>
                  <TableCell>
                    {Number(product?.final_price) * Number(product?.quantity) +
                      Number(product?.tax_charges)}
                  </TableCell>
                </TableRow>
              );
            })}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>Sub-Total</TableCell>
            <TableCell>{subTotal}</TableCell>
          </TableRow>
          {discount && couponCode && (
            <TableRow>
              <TableCell></TableCell>
              <TableCell></TableCell>
              <TableCell>Coupon-Code</TableCell>
              <TableCell>{couponCode}</TableCell>
              <TableCell>Discount</TableCell>
              <TableCell>- {discount}</TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>Shipping Charges</TableCell>
            <TableCell>+ {shippingCharges}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>Total</TableCell>
            <TableCell>{total}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default OrderDetailsProductTable;

import React from 'react'
import './index.css'

const MyButton = ({lable,style,onClick}) => {
  return (
    <div>
        <button
        onClick={onClick}
        className='myBtn'
        style={{
          ...style
        }} >
            {lable}
        </button>
    </div>
  )
}

export default MyButton
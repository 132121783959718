import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Fragment, useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import VisibilityIcon from "@mui/icons-material/Visibility";
import addProductsDataForUpdate from "../../../../Redux/action/addProductsDataForUpdate";
import BuildCircleIcon from "@mui/icons-material/BuildCircle";
import stock from "../../../../Redux/action/stock";
// import Stock from "../../../../product/Products/Stock/Stock";
import Stock from "../Stock/Stock";
import Cookies from "js-cookie";
import Vendor_Sidebar from "../../../mainPage/vendor_sidebar/Vendor_Sidebar";
import Vendor_Header from "../../../mainPage/Vendor_Header/Vendor_Header";
import Vendor_Footer from "../../../mainPage/Vendor_Footer/Vendor_Footer";
import { FormControl, MenuItem, Select } from "@mui/material";
import { url } from "../../../../../App";
import { imagePathProduct as imagePath } from "../../../../../App";

const Manage_Vendor_Product = () => {
  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
  });
  const classes = useStyle();
  const [data, setData] = useState([]);
  let [stockopen, setstockOpen] = useState(false);
  let [dataForStock, setDataForStock] = useState({});
  let [openImage, setOpenImage] = useState(false);
  let [i, setI] = useState("");
  let [number, setNumber] = useState(10);
  let [search, setSearch] = useState("");
  let [filterProduct, setFilterProduct] = useState([]);
  const location = useLocation();
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let token = Cookies.get("content");
  ////for delete
  useEffect(() => {
    if (location.state) {
      setFilterProduct(location.state.showProduct);
    }
  }, [location.state]);

  // console.log("location :", filterProduct);
  const [delopen, setdelOpen] = useState(false);
  const [delID, setdelID] = useState(false);
  const [ppen, setOpen] = useState(false);
  const delhandleClose = () => {
    setOpen(false);
  };

  // get Products from database
  useEffect(() => {
    let config = {
      method: "get",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(`${url}/api/vendor/product`, config).then((res) => {
      res.json().then((res) => {
        let pro = res.product_list.filter((i) => {
          return i.product_title
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        });
        setData(pro);
      });
    });
  }, [search]);
  //////////////////////////////////DELETE COUNTRY//////////////////////////////////////////////////////
  const deleteProduct = () => {
    fetch(`${url}/api/vendor/product/destroy/${delID}`, {
      method: "get",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((res) => {
        if (res.error) {
          return toast.error(<span className="text-lg">{res.error}</span>);
        }
        if (res.message) {
          setTimeout(() => {
            setdelOpen(false);
            window.location.reload(false);
          }, 300);
          return toast.success(<span className="text-lg">{res.message}</span>);
        }
      });
    });
  };

  // status update
  const updateStatus = (status, id) => {
    const contryConfig = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ status }),
    };
    fetch(`${url}/api/vendor/product/is_status/${id}`, contryConfig).then(
      (response) => {
        response.json().then((res) => {
          if (res.error) {
            return toast.error(<span className="text-lg">{res.error}</span>);
          }
          if (res.success) {
            return window.location.reload(false);
          }
        });
      }
    );
  };

  // /////////////////////////////////Pagination///////////////////////////////////////////////
  const renderData = (data) => {
    return (
      <TableBody>
        {/* {console.log("iiiiiiiiii", data)} */}
        {data
          ? data.map((i, index) => {
              return (
                <TableRow
                  className="list_row"
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {indexOfFirstItem + index + 1}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {i.product_title}
                  </TableCell>
                  <TableCell>
                    <img
                      onClick={() => {
                        setI(i.images[0]);
                        setOpenImage(true);
                      }}
                      src={`${imagePath}${i.images[0]}`}
                      style={{
                        width: "50px",
                        height: "50px",
                        cursor: "pointer",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                    align="center"
                  >
                    {i.product_status === 1 ? (
                      <span style={{ color: "green" }}>Approve</span>
                    ) : i.product_status === 0 ? (
                      <span style={{ color: "Orange" }}>Panding</span>
                    ) : i.product_status === 2 ? (
                      <span style={{ color: "red" }}>Rejected</span>
                    ) : (
                      ""
                    )}
                  </TableCell>
                  <TableCell>
                    <Switch
                      color="primary"
                      style={{ cursor: "pointer" }}
                      checked={i.is_status}
                      onChange={(e, val) => {
                        updateStatus(val, i.id);
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{
                      fontSize: "17px",
                      justifySelf: "end",
                      cursor: "pointer",
                    }}
                  >
                    <ModeEditIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        padding: "5px",
                      }}
                      onClick={() => {
                        dispatch(
                          addProductsDataForUpdate({
                            id: i.id,
                            product_Title: i.product_title,
                            Isperishable: i.is_perishable,
                            categoryId: i.category_id,
                            categoryName: i.category_name,
                            brandId: i.brand_id,
                            brandName: i.brand_name,
                            subCategoryId: i.sub_category_id,
                            subSubCategoryId: i.sub_sub_category_id,
                            // // subName: i.subName[0].name,
                            subsubName: i.sub_sub_category_name,
                            images: i.images,
                            threshold: i.return_time,
                            description: i.description,
                            subscribe: i.is_subscribed,
                            isQuantification: i.is_quantification,
                            saleprice: i.sale_price,
                            purchaseprice: i.purchase_price,
                            discountType: i.discount_type,
                            discountAmount: i.discount_price,
                            finalPrice: i.final_price,
                            quantifications: i.quantification,
                            // productStatus: i.product_status,
                            agencyComission: i.agency_commission,
                            agencyComissionType: i.agency_commission_type,
                            superAdminComissionType: i.admin_commission_type,
                            superAdminComission: i.admin_commission,
                            minQuantity: i.min_quantity,
                            product_status: i.product_status,
                            stock: i.stock,
                          })
                        );
                        navigate("/vendor/products/update/addProduct");
                      }}
                    />
                    <DeleteIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        padding: "5px",
                      }}
                      onClick={() => {
                        setdelOpen(true);
                        setdelID(i.id);
                        // setDeleteId(i._id);
                      }}
                    />
                    <VisibilityIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        padding: "5px",
                      }}
                      onClick={() => {
                        dispatch(
                          addProductsDataForUpdate({
                            id: i.id,
                            product_Title: i.product_title,
                            category: i.category_id,
                            categoryName: i.category_name,
                            brand: i.brand_id,
                            brandName: i.brand_name,
                            subCategory: i.sub_category_id,
                            subName: i.sub_category_name,
                            subsubCategory: i.subsubCategory,
                            subsubName: i.subsubCategory,
                            banner: i.images,
                            description: i.description,
                            threshold: i.return_time,
                            Isperishable: i.is_perishable,
                            subscribe: i.is_subscribed,
                            Quantification: i.quantification,
                            status: i.is_status,
                            agencyComission: i.agency_commission,
                            agencyComissionType: i.agency_commission_type,
                            superAdminComissionType: i.admin_commission,
                            superAdminComission: i.admin_commission_type,
                            saleprice: i.sale_price,
                            purchaseprice: i.purchase_price,
                            discountType: i.discount_type,
                            dicountAmount: i.discount_price,
                            finalPrice: i.final_price,
                          })
                        );
                        navigate("/vendor/products/productDetails");
                      }}
                    />
                    <BuildCircleIcon
                      style={{
                        width: "31px",
                        height: "31px",
                        color: "#023F9E",
                        padding: "5px",
                      }}
                      onClick={() => {
                        {
                          i.is_quantification
                            ? dispatch(
                                stock({
                                  id: i._id,
                                  product_Title: i.product_title,
                                  category: i.category,
                                  // categoryName: i.catName[0].name,
                                  brand: i.brand,
                                  // brandName: i.BrandName[0].name,
                                  subCategory: i.subCategory,
                                  // subName: i.subName[0].name,
                                  banner: i.image,
                                  description: i.description,
                                  threshold: i.Return_Threshold_Time,
                                  Isperishable: i.Isperishable,
                                  subscribe: i.subscribe,
                                  Quantification: i.quantification,
                                  is_quantification: i.is_quantification,
                                  productStatus: i.productStatus,
                                })
                              )
                            : dispatch(stock(i));
                        }
                        setDataForStock({
                          id: i.id,
                          product_Title: i.product_title,
                          categoryId: i.category_id,
                          // categoryName: i.catName[0].name,
                          brandId: i.brand_id,
                          // brandName: i.BrandName[0].name,
                          subCategoryId: i.sub_category_id,
                          // subName: i.subName[0].name,
                          images: i.images,
                          description: i.description,
                          threshold: i.return_time,
                          Isperishable: i.is_perishable,
                          subscribe: i.is_subscribed,
                          quantification: i.is_quantification,
                          productStatus: i.is_status,
                          quantifications: i.quantification,
                        });
                        setstockOpen(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          : ""}
      </TableBody>
    );
  };

  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  // const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // console.log("indexOfFirstItem :", indexOfFirstItem);
  // console.log("indexOfLastItem :", indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active " : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <li className="" onClick={handleNextbtn}>
        {" "}
        &hellip;{" "}
      </li>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleLoadMore = (value) => {
    setitemsPerPage(value);
  };

  return (
    <>
      <div className="container">
        <Vendor_Sidebar />
        <Vendor_Header name="Manage Product" />
        <div className="content_category">
          {/* Image Pop-up */}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "75%" }}
              open={openImage}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogContent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // marginTop: "-5px",
                  margin: "-10px",
                }}
              >
                <img
                  src={`${imagePath}${i}`}
                  alt="default-img"
                  style={{ width: "400px", height: "fit-content" }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "10px",
                    marginRight: "10px",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setOpenImage(false);
                  }}
                >
                  Close
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          {/* Stock Pop up show */}
          <Stock
            show={stockopen}
            setstockOpen={setstockOpen}
            title={dataForStock.product_Title}
            Quantification={dataForStock.Quantification}
            id={dataForStock.id}
          />
          {/* Delete country Permission///////////////////////////////////////////////////////////////////////////////////*/}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "100%" }}
              open={delopen}
              onClose={delhandleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="delete">
                {/* <div className="del_head"></div> */}
                <div className="del_alert">
                  Are you sure, you want to delete this ?
                </div>
                <div className="del_action">
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      padding: "10x",
                      fontSize: "18px",
                      // marginTop: "20px",
                      marginRight: "10px",
                      // color: "#023F9E",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={deleteProduct}
                  >
                    Yes
                  </Button>
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      padding: "10x",
                      fontSize: "18px",
                      // marginTop: "20px",
                      marginRight: "10px",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={() => setdelOpen(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </Dialog>
          </Box>

          {/* list heading and add btn */}
          <div className="headADD_states">
            <span
              onClick={() => {
                window.location.reload(false);
              }}
              className="list_heading"
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              All Products
            </span>
            <div>
              <TextField
                id="outlined-basic"
                placeholder="Search Product"
                variant="outlined"
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                style={{ marginRight: "15px" }}
              />
              <Button
                size="large"
                className="addbtn"
                variant="contained"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                startIcon={<AddIcon />}
                onClick={() => navigate("/vendor/addProductByVendor")}
              >
                Add
              </Button>
            </div>
          </div>

          {/* list of countries */}
          <div className="sss">
            <div className="ss">
              <TableContainer className="cat_table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontSize: "17px" }}>
                        No&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name
                      </TableCell>
                      <TableCell style={{ fontSize: "17px" }}>Image</TableCell>
                      <TableCell style={{ fontSize: "17px" }} align="center">
                        Product Status
                      </TableCell>
                      <TableCell style={{ fontSize: "17px" }}>
                        Visible
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "17px", paddingLeft: "65px" }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderData(data)}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    {/* dropdown for pagination */}
                    <FormControl
                      className="page_space"
                      variant="standard"
                      sx={{ m: 1, minWidth: 40 }}
                    >
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={number}
                        onChange={(e) => {
                          handleLoadMore(e.target.value);
                          setNumber(e.target.value);
                        }}
                        // label="0-5"
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      style={{ marginLeft: "30px" }}
                      variant=""
                      component={""}
                    >
                      {indexOfFirstItem + 1} - {indexOfLastItem}&nbsp;
                      Rows&nbsp; of &nbsp;&nbsp;{data.length}
                    </Typography>
                    <ul className="pageNumbers page_space">
                      {indexOfFirstItem > 0 && (
                        <li className="page_button">
                          <ArrowBackIosIcon
                            onClick={handlePrevbtn}
                            disabled={currentPage == pages[0] ? true : false}
                          />
                        </li>
                      )}
                      {pageDecrementBtn}
                      {renderPageNumbers}
                      {pageIncrementBtn}
                      {data.length > indexOfLastItem && (
                        <li className="page_button">
                          <ArrowForwardIosIcon
                            onClick={handleNextbtn}
                            disabled={
                              currentPage == pages[pages.length - 1]
                                ? true
                                : false
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Vendor_Footer />
      </div>
      <Toaster />
    </>
  );
};

export default Manage_Vendor_Product;

import axios from "../axios";
import { apiRoutes } from "../utils/apiRoutes";
// import popMsg from "../utils/popup";

async function getServices() {
  try {
    const response = await axios.get(`${apiRoutes.getServices}`);
    const data = response.data.service;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function addService(payload, setIsLoading) {
  setIsLoading(true);
  try {
    const response = await axios.post(`${apiRoutes.addService}`, payload);
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
    return;
  } finally {
    setIsLoading(false);
  }
}

async function updateService(payload, serviceId, setIsLoading) {
  setIsLoading(true);
  try {
    const response = await axios.post(
      `${apiRoutes.updateService}${serviceId}`,
      payload
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
    return;
  } finally {
    setIsLoading(false);
  }
}

export { getServices, addService, updateService };

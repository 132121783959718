import {
    Box,
    Button,
    Typography,
  } from "@material-ui/core";
  import "./OrderDetails.css";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import Paper from "@mui/material/Paper";
  import { useEffect, useState } from "react";
  import toast, { Toaster } from "react-hot-toast";
  import Grid from "@mui/material/Grid";
  import { useNavigate, useParams } from "react-router-dom";
  import MenuItem from "@mui/material/MenuItem";
  import FormControl from "@mui/material/FormControl";
  import Select from "@mui/material/Select";
  import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
  import TableContainer from "@mui/material/TableContainer";
  import EmailIcon from "@mui/icons-material/Email";
  import DateRangeIcon from "@mui/icons-material/DateRange";
  import PersonIcon from "@mui/icons-material/Person";
  import Cookies from "js-cookie";
  import Sidebar from "../../Container/Sidebar/Sidebar";
  import Header from "../../Container/Header/Header";
  import Footer from "../../Container/Footer/Footer";
  import { imagePathServices as imagePath } from "../../../App";
  import { url } from "../../../App";
import Agency_Sidebar from "../mainPage/Agency_sidebar/Agency_Sidebar";
import Agency_Header from "../mainPage/Agency_Header/Agency_Header";
  
  const OrderDetailAgency = () => {
    const [status, setStatus] = useState("");
    const [userdata, setUserData] = useState([]);
    const [orderdata, setOrderData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [orderdate, setorderdate] = useState("");
    const params = useParams();
    const token = Cookies.get("content");
    const navigate = useNavigate()
  
    function getProductsOnScreen(id) {
      let config = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      fetch(`${url}/api/agency/service/order/detail/${id}`, config)
        .then((res) => res.json())
        .then((result) => {
          // console.log("Service customer list user", result);
          if (result.order === "No Available") return null;
          setUserData(result.user);
          setOrderData(result.order);
          setServiceData(result.service);
        });
    }
  
    useEffect(() => {
      getProductsOnScreen(params.id);
    }, []);
  
    useEffect(() => {
      if (orderdata && orderdata.created_at) {
        var isoDateTime = new Date(orderdata.created_at);
        var localDateTime =
          isoDateTime.toLocaleDateString() +
          " " +
          isoDateTime.toLocaleTimeString();
        // console.log("orderdata.created_at", localDateTime);
        setorderdate(localDateTime);
      }
    }, [orderdata && orderdata.created_at]);
  
    // /////////////////////////////////status update///////////////////////////////////////////////
    const updateStatus = (status, id) => {
      const contryConfig = {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      };
      fetch(`${url}/api/agency/service/order/status/${id}`, contryConfig).then(
        (response) => {
          response.json().then((res) => {
            if (res.error) {
              return toast.error(<span className="text-lg">{res.error}</span>);
            }
            if (res.success) {
              return (
                window.location.reload(false), getProductsOnScreen(params.id)
              );
            }
          });
        }
      );
    };
  
    const renderData = (data) => {
      return (
        <div>
          {data ? (
            <img
              src={`${imagePath}${data}`}
              alt=""
              style={{ width: "300px", height: "250px" }}
            />
          ) : (
            ""
          )}
        </div>
      );
    };
  
    return (
      <>
        <div className="container">
          <Agency_Sidebar/>
          <Agency_Header name='Orders'/>
          <div className="content_category2">
            {/* Details */}
            {orderdata && serviceData && userdata ? (
              <div className="orderDetailsDiv1">
                <div className="orderDetailsDiv2">
                  {/* order Details */}
                  <Box
                    sx={{
                      "& > :not(style)": {
                        m: 1,
                        // minWidth: "50rem",
                        padding: "2rem",
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <Paper elevation={3}>
                      <Typography>
                        <h3>Service Order Details</h3>
                      </Typography>
                      <hr />
                      <div className="serviceOrder">
                        <div className="serviceOrder1">
                          <img
                            src={
                              serviceData.images
                                ? `${imagePath}${serviceData.images}`
                                : ""
                            }
                            alt=""
                            style={{ width: "150px", height: "100px" }}
                          />
                        </div>
                        <div className="serviceOrder2">
                          <Typography>
                            <b>{serviceData.service_title}</b>
                          </Typography>
                        </div>
                        <div className="serviceOrder3">
                          <Typography>{serviceData.description}</Typography>
                        </div>
                      </div>
                      <hr />
                      <div className="orderdetaildiv1">
                        <div className="orderdetaildiv2">
                          <Typography>
                            <h4>Service Id : {orderdata.order_id}</h4>
                          </Typography>
                          <br />
                          <Typography>
                            <h4 style={{ color: "orange" }}>
                              Quoted Price :{" "}
                              {parseFloat(orderdata.quoted_price).toFixed(2) +
                                "₹"}
                            </h4>
                          </Typography>
                          <br />
                          <Typography>
                            <h4>Description : </h4>
                            {orderdata.description}
                          </Typography>
                        </div>
                        <div className="orderdetaildiv3">
                          {renderData(orderdata.image)}
                          {/* {renderData(serviceData.images)} */}
                          {/* {orderdata.image ? (
                          <img
                            src={
                              serviceData.images
                                ? // ? `${imagePathservice}${orderdata.image}`
                                  `${imagePath}${serviceData.images}`
                                : ""
                            }
                            alt=""
                            style={{ width: "300px", height: "250px" }}
                          />
                        ) : (
                          ""
                        )} */}
                        </div>
                      </div>
                    </Paper>
                  </Box>
                </div>
                <div className="orderDetailsDiv3">
                  {/* Customer details */}
                  <Box
                    sx={{
                      "& > :not(style)": {
                        m: 1,
                        // minWidth: "25rem",
                        padding: "2rem",
                        borderRadius: "5px",
                      },
                    }}
                  >
                    <Paper elevation={3}>
                      <Grid>
                        <Typography>
                          <h3>Customer</h3>
                        </Typography>
                      </Grid>
                      <hr />
                      <Grid>
                        <Typography
                          style={{
                            display: "flex",
                            alignItem: "center",
                            margin: "1rem 0 1rem 0",
                          }}
                        >
                          <PersonIcon />{" "}
                          <h4>
                            {userdata.customer_name ? userdata.customer_name : ""}
                          </h4>
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography style={{ margin: "1rem 0 1rem 0" }}>
                          <h4>Contact Info</h4>
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            display: "flex",
                            alignItem: "center",
                            margin: "0rem 0 1rem 0",
                          }}
                        >
                          <EmailIcon />
                          {userdata.customer_email ? userdata.customer_email : ""}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            display: "flex",
                            alignItem: "center",
                            margin: "0rem 0 1rem 0",
                          }}
                        >
                          <LocalPhoneIcon />
                          {userdata.customer_phone ? userdata.customer_phone : ""}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            display: "flex",
                            alignItem: "center",
                            margin: "1rem 0 1rem 0",
                          }}
                        >
                          <h4>Delivery Address</h4>
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            display: "flex",
                            alignItem: "center",
                            margin: "0rem 0 1rem 0",
                          }}
                        >
                          {userdata.customer_name ? userdata.customer_name : ""}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            display: "flex",
                            alignItem: "center",
                            margin: "0rem 0 1rem 0",
                          }}
                        >
                          {userdata.customer_phone ? userdata.customer_phone : ""}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            display: "flex",
                            alignItem: "center",
                            margin: "0rem 0 1rem 0",
                          }}
                        >
                          {userdata.type ? userdata.type : ""}
                        </Typography>
                      </Grid>
                      <Grid>
                        <Typography
                          style={{
                            display: "flex",
                            alignItem: "center",
                            margin: "0rem 0 1rem 0",
                          }}
                        >
                          {userdata.address_line1
                            ? userdata.address_line1 + ", " + userdata.city_name
                              ? userdata.city_name
                              : "" +
                                ", " +
                                // userdata.city_name +
                                // ", " +
                                userdata.pincode
                              ? userdata.pincode
                              : ""
                            : ""}
                        </Typography>
                      </Grid>
                    </Paper>
                  </Box>
                </div>
              </div>
            ) : (
              ""
            )}
  
            {/* list heading and add btn */}
            <div className="headADD_states">
              {/* <span
                className="list_heading"
                onClick={() => {
                  window.location.reload(false);
                }}
                style={{
                  marginLeft: "6px",
                  marginTop: "14px",
                  cursor: "pointer",
                }}
              >
                Service Order Details
              </span> */}
              <div className="listListPo">
                <span
                  className="list_heading"
                  onClick={() => {
                    window.location.reload(false);
                  }}
                  style={{
                    marginLeft: "6px",
                    marginTop: "14px",
                    cursor: "pointer",
                  }}
                >
                  Service Order Details
                </span>
                <div className="listListPo2">
                  <span
                    className="list_headingPO"
                    style={{
                      // marginLeft: "2px",
                      marginTop: "14px",
                      cursor: "pointer",
                      color: "grey",
                    }}
                  >
                    Order Date: <DateRangeIcon />
                    {orderdate}
                  </span>
                  <span
                    className="list_headingPO"
                    style={{
                      // marginLeft: "2px",
                      marginTop: "14px",
                      cursor: "pointer",
                      // backgroundColor: "rgba(0, 201, 219, .1)",
                    }}
                  ></span>
                  <span
                    className="list_headingPO"
                    style={{
                      // marginLeft: "2px",
                      marginTop: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {orderdata && orderdata.service_status === 0 ? (
                      <span style={{ color: "orange" }}>Quoted</span>
                    ) : orderdata && orderdata.service_status === 1 ? (
                      <span style={{ color: "blue" }}>Accepted</span>
                    ) : orderdata && orderdata.service_status === 2 ? (
                      <span style={{ color: "green" }}>Completed</span>
                    ) : orderdata && orderdata.service_status === 3 ? (
                      <span style={{ color: "red" }}>Canceled</span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div>
                {/* <TextField
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                      id="outlined-basic"
                      placeholder="Search by Order Number"
                      variant="outlined"
                      style={{ marginRight: "15px" }}
                    /> */}
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  style={{ marginTop: "0rem" }}
                >
                  <Select
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      updateStatus(e.target.value, orderdata.id);
                    }}
                    displayEmpty
                    //   label= "Update Status"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      Update Service Order Status
                    </MenuItem>
                    <MenuItem value={0}>Quoted</MenuItem>
                    <MenuItem value={1}>Accepted</MenuItem>
                    <MenuItem value={2}>Completed</MenuItem>
                    <MenuItem value={3}>Canceled</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  size="large"
                  className="addbtn"
                  variant="contained"
                  style={{
                    height: "55px",
                    backgroundColor: "#023F9E",
                    color: "white",
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate("/agency/orders")}
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
          <Footer />
        </div>
  
        <Toaster />
      </>
    );
  };
  
  export default OrderDetailAgency;
  
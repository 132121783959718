import { Button, Switch, TablePagination, TextField } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import Footer from "../../Container/Footer/Footer";
import AddCoupon from "./AddCoupon";
import UpdateCoupon from "./UpdateCoupon";
import DeleteConfirmation from "../../ui/DeleteConfirmation";
import { apiRoutes } from "../../../utils/apiRoutes";
import { getAllCoupons } from "../../../services/coupons.service";
import {
  currency,
  formatDate,
  initialRows,
  updateStatus,
} from "../../../utils/constants";

const DiscountCoupon = () => {
  const [openAddCoupon, setOpenAddCoupon] = useState(false);
  const [couponAdded, setCouponAdded] = useState(false);
  const [openUpdateCoupon, setOpenUpdateCoupon] = useState(false);
  const [couponUpdated, setCouponUpdated] = useState(false);
  const [couponToUpdate, setCouponToUpdate] = useState();
  const [search, setSearch] = useState("");
  const [delCouponId, setDelCouponId] = useState();
  const [couponDeleted, setCouponDeleted] = useState(false);
  const [delOpen, setDelOpen] = useState(false);
  const [couponData, setCouponData] = useState();
  const [renderedData, setRenderedData] = useState();
  const [statusUpdated, setStatusUpdated] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (couponAdded) get();
    setCouponAdded(false);
  }, [couponAdded]);

  useEffect(() => {
    if (couponUpdated) get();
    setCouponUpdated(false);
  }, [couponUpdated]);

  useEffect(() => {
    if (couponDeleted) get();
    setCouponDeleted(false);
  }, [couponDeleted]);

  useEffect(() => {
    if (statusUpdated) get();
    setStatusUpdated(false);
  }, [statusUpdated]);

  useEffect(() => {
    filterData();
  }, [search]);

  // Handlers
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    // setPage(0);
  };

  // /////////////////////////////////get data///////////////////////////////////////////////

  async function get() {
    const data = await getAllCoupons();
    setCouponData(data);
    setRenderedData(data);
  }
  function filterData() {
    const filtered =
      couponData &&
      couponData.filter((coupon) => {
        return coupon.coupon_code
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    setRenderedData(filtered);
  }
  async function updateCouponStatus(couponId, status) {
    const url = `${apiRoutes.updateCouponStatus}${couponId}`;
    const payload = { status };
    updateStatus(url, payload, setStatusUpdated);
  }
  // /////////////////////////////////rendrer Data///////////////////////////////////////////////
  const renderData = (data) => {
    return (
      <TableBody>
        {data &&
          data.length > 0 &&
          data.map((i, index) => {
            return (
              <TableRow
                key={index}
                className="list_row"
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  {(page + 1) * rowsPerPage - rowsPerPage + index + 1}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {i.title}
                  {/* </link> */}
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  {i.coupon_code}
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  {i.coupon_type === 0
                    ? "Default"
                    : i.coupon_type === 1
                    ? "First Order"
                    : ""}
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  {i.access_limit}
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  {formatDate(i.start_date, false, false)}
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  {formatDate(i.expire_date, false, false)}
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  {i.min_purchase}
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  {i.max_discount}
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  {i.discount_type === 1
                    ? `${i.discount} %`
                    : i.discount_type === 0
                    ? `${currency} ${i.discount}`
                    : ""}
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  <Switch
                    style={{ cursor: "pointer" }}
                    color="primary"
                    checked={i.is_status}
                    onChange={(e, val) => {
                      updateCouponStatus(i.id, val);
                    }}
                  />
                </TableCell>
                <TableCell
                  style={{ fontSize: "13px" }}
                  component="th"
                  scope="row"
                >
                  <div style={{ display: "flex" }}>
                    <ModeEditIcon
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#023F9E",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpenUpdateCoupon(true);
                        setCouponToUpdate(i);
                      }}
                    />
                    <DeleteIcon
                      style={{
                        width: "25px",
                        height: "25px",
                        color: "#023F9E",
                        padding: "5px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setDelCouponId(i.id);
                        setDelOpen(true);
                      }}
                    />
                  </div>
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    );
  };
  return (
    <>
      <div className="container">
        <Sidebar />
        <Header name="Manage Discount Coupon" />
        <div className="content_category">
          {/* list heading and add btn */}
          <div className="headADD_states">
            <span
              className="list_heading"
              onClick={() => {
                window.location.reload(false);
              }}
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              All Discount Coupon
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search Coupon Code"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
              <Button
                size="large"
                className="addbtn"
                variant="contained"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                startIcon={<AddIcon />}
                onClick={() => setOpenAddCoupon(true)}
              >
                Add
              </Button>
            </div>
          </div>

          {/* list of Coupon */}
          <div className="sss">
            <div className="ss">
              <TableContainer className="cat_table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                        width={"12%"}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Title
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Coupon Code
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Coupon Type
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Access Limit
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Start Date
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Expire Date
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          marginLeft: "30px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Min Purchase
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          marginLeft: "30px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Max Discount
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          marginLeft: "30px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Discount
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          marginLeft: "30px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Status
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "14px",
                          marginLeft: "30px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderedData &&
                    renderedData.length &&
                    renderData(
                      renderedData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    )}
                </Table>
              </TableContainer>
              <TablePagination
                sx={{ px: 2 }}
                page={page}
                component="div"
                rowsPerPage={rowsPerPage}
                count={
                  renderedData && renderedData.length ? renderedData.length : 0
                }
                onPageChange={handleChangePage}
                rowsPerPageOptions={[5, 10, 25]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                nextIconButtonProps={{ "aria-label": "Next Page" }}
                backIconButtonProps={{ "aria-label": "Previous Page" }}
                showFirstButton
                showLastButton
              />
            </div>
          </div>
        </div>
        <AddCoupon
          open={openAddCoupon}
          setOpen={setOpenAddCoupon}
          setCouponAdded={setCouponAdded}
        />
        <UpdateCoupon
          open={openUpdateCoupon}
          setOpen={setOpenUpdateCoupon}
          coupon={couponToUpdate}
          setCouponUpdated={setCouponUpdated}
        />
        <DeleteConfirmation
          open={delOpen}
          setOpen={setDelOpen}
          setDeleted={setCouponDeleted}
          url={`${apiRoutes.deleteCoupon}${delCouponId}`}
        />
        <Footer />
      </div>
      <Toaster />
    </>
  );
};

export default DiscountCoupon;

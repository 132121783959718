import axios from "../axios";
import { apiRoutes } from "../utils/apiRoutes";
import { mapSerialNumber } from "../utils/constants";

async function getAllUnits() {
  try {
    const response = await axios.get(`${apiRoutes.getUnits}`);
    const data = response.data.unit;
    const mapped = mapSerialNumber(data);
    console.log("response", response);
    console.log("data", data);
    console.log("mapped", mapped);
    return mapped;
  } catch (err) {
    console.error(err);
  }
}
async function addUnits(unit) {
  const payload = { unit_name: unit };
  return axios.post(`${apiRoutes.addUnits}`, payload);
}
async function updateUnits(unit) {
  const payload = {
    unit_name: unit.unitName,
  };
  return axios.post(`${apiRoutes.updateUnits}${unit.id}`, payload);
}
async function deleteUnits(unitId) {
  return axios.get(`${apiRoutes.deleteUnit}${unitId}`);
}

export { getAllUnits, addUnits, updateUnits, deleteUnits };

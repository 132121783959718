import {
  Button,
  makeStyles,
  TablePagination,
  TextField,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import AgencyProfile from "./AgencyProfile";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import Footer from "../../Container/Footer/Footer";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddAgency from "./AddAgency";
import UpdateAgency from "./UpdateAgency";
import { getAllAgencies } from "../../../services/agency.service";
import { initialRows } from "../../../utils/constants";
import DeleteConfirmation from "../../ui/DeleteConfirmation";
import { apiRoutes } from "../../../utils/apiRoutes";
const Agency = () => {
  const [openAddAgency, setOpenAgency] = useState(false);
  const [agencyAdded, setAgencyAdded] = useState(false);
  const [updateSelectedAgency, setUpdateSelectedAgency] = useState();
  const [openUpdateAgency, setOpenUpdateAgency] = useState(false);
  const [updatedAgency, setUpdatedAgency] = useState();
  const [agencies, setAgencies] = useState();
  const [renderedData, setRenderedData] = useState();
  let [search, setSearch] = useState("");
  const [deleteId, setDeleteId] = useState();
  const [delOpen, setDelOpen] = useState(false);
  const [deletedAgency, setDeletedAgency] = useState(false);
  const [viewAgency, setViewAgency] = useState();
  const [openAgencyProfile, setOpenAgencyProfile] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  // UseEffect
  useEffect(() => {
    if (agencyAdded) {
      get();
    }
    setAgencyAdded(false);
  }, [agencyAdded]);
  useEffect(() => {
    if (updatedAgency) get();
    setUpdatedAgency(false);
  }, [updatedAgency]);

  useEffect(() => {
    if (deletedAgency) get();
    setDeletedAgency();
  }, [deletedAgency]);

  useEffect(() => {
    filterData();
  }, [search]);

  useEffect(() => {
    get();
  }, []);

  // Handlers
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
  };

  // Functions
  async function get() {
    const data = await getAllAgencies();
    setAgencies(data);
    setRenderedData(data);
  }

  async function filterData() {
    let filteredData =
      agencies &&
      agencies.filter((agy) => {
        return agy.company_name
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    setRenderedData(filteredData);
  }

  // /////////////////////////////////Pagination///////////////////////////////////////////////
  const renderData = (data) => {
    // if (!data.length) return null;
    return (
      <TableBody>
        {data && data.length > 0 ? (
          data.map((i, index) => {
            return (
              <TableRow
                className="list_row"
                key={i.id}
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell
                  style={{ fontSize: "17px" }}
                  component="th"
                  scope="row"
                >
                  {data.length
                    ? (page + 1) * rowsPerPage - rowsPerPage + index + 1
                    : ""}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {i.company_name}
                </TableCell>
                <TableCell
                  style={{ fontSize: "17px", cursor: "pointer" }}
                  align="right"
                >
                  <ModeEditIcon
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#023F9E",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setOpenUpdateAgency(true);
                      setUpdateSelectedAgency(i);
                    }}
                  />
                  <DeleteIcon
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#023F9E",
                      cursor: "pointer",
                      padding: "5px",
                    }}
                    onClick={() => {
                      setDelOpen(true);
                      setDeleteId(i.id);
                    }}
                  />
                  <VisibilityIcon
                    onClick={() => {
                      setViewAgency(i);
                      setOpenAgencyProfile(true);
                    }}
                    style={{
                      width: "30px",
                      height: "30px",
                      color: "#023F9E",
                      padding: "5px",
                      cursor: "pointer",
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })
        ) : (
          <TableRow>
            <TableCell>No Data </TableCell>
          </TableRow>
        )}
      </TableBody>
    );
  };

  return (
    <>
      <div className="container">
        <Sidebar />
        <Header name="Manage Agency" />
        <div className="content_category">
          <div className="headADD_states">
            <span
              className="list_heading"
              onClick={() => {
                window.location.reload(false);
              }}
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              All Agencies
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search Agency"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
              <Button
                size="large"
                className="addbtn"
                variant="contained"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                startIcon={<AddIcon />}
                onClick={() => {
                  setOpenAgency(true);
                }}
              >
                Add
              </Button>
            </div>
          </div>
          {/* Table */}
          <div className="sss">
            <div className="ss">
              <TableContainer className="cat_table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Agency Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderedData &&
                    renderedData.length &&
                    renderData(
                      renderedData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    )}
                </Table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={
                    renderedData && renderedData.length
                      ? renderedData.length
                      : 0
                  }
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  showfirstbutton="true"
                  showlastbutton="true"
                />
              </TableContainer>
            </div>
          </div>
        </div>
        {openAddAgency && (
          <AddAgency
            open={openAddAgency}
            setOpen={setOpenAgency}
            setAddedAgency={setAgencyAdded}
          />
        )}
        {openUpdateAgency && (
          <UpdateAgency
            open={openUpdateAgency}
            setOpen={setOpenUpdateAgency}
            updatedAgency={setUpdatedAgency}
            selectedAgency={updateSelectedAgency}
          />
        )}
        {delOpen && (
          <DeleteConfirmation
            open={delOpen}
            setOpen={setDelOpen}
            url={`${apiRoutes.deleteAgency}${deleteId}`}
            setDeleted={setDeletedAgency}
          />
        )}
        {openAgencyProfile && (
          <AgencyProfile
            open={openAgencyProfile}
            setOpen={setOpenAgencyProfile}
            agency={viewAgency}
          />
        )}
        <Footer />
      </div>
      <Toaster />
    </>
  );
};

export default Agency;

import React, { useEffect, useState } from "react";
import {
  TableContainer,
  TextField,
  Paper,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Button,
  Table,
} from "@material-ui/core";
import Vendor_Sidebar from "../mainPage/vendor_sidebar/Vendor_Sidebar";
import Vendor_Header from "../mainPage/Vendor_Header/Vendor_Header";
import { makeStyles } from "@material-ui/styles";
import { imagePathProduct, url } from "../../../App";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useNavigate } from "react-router-dom";
const Orders = () => {
  // ///////////       useState variable declaration.!!
  const [orderData, setOrderData] = useState();
  const [search, setSearch] = useState();
  const [itemsPerPage, setitemsPerPage] = useState(10);
  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);
  const [number, setNumber] = useState(10);

  const token = Cookies.get("content");
  const navigate = useNavigate();
  const useStyles = makeStyles({
    head: {
      fontSize: "18px",
      fontWeight: "550",
      color: "#36454F",
    },
  });
  const classes = useStyles();

  useEffect(() => {
    console.log("useEffect working");
    getOrders();
  }, [search]);
  // //////////////////////////////////////////
  const getOptions = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  };
  async function getOrders() {
    const response = await fetch(`${url}/api/vendor/order`, getOptions);
    const data = await response.json();
    if (data.success) {
      setOrderData(data.order);
      const searchedOrders = data.order.filter((order) => {
        return order.order_id
          .toString()
          .toLowerCase()
          .includes(search.toString().toLowerCase());
      });
      setOrderData(searchedOrders);
    } else toast.error(data.message);
  }

  //   ////////////////////////// Table Body
  const renderData = (data) => {
    return (
      <TableBody>
        {data.map((order, index) => {
          return (
            <TableRow
              key={order.id}
              className="list_row"
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell
                component="th"
                scope="row"
                style={{ fontSize: "14px" }}
              >
                {index + 1} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  varient="text"
                  color="primary"
                  onClick={() => {
                    navigate(`/vendor/order-detail/${order.id}`);
                  }}
                >
                  <span style={{ fontWeight: 600 }}>{order.order_id}</span>
                </Button>
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ fontSize: "14px" }}
              >
                <span style={{ fontWeight: 600, color: "#8a2be2" }} > {order.user_name ? order.user_name : ""} </span>
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ fontSize: "14px" }}
              >
                {order.created_at.split("T")[0]}
              </TableCell>
              {/* <TableCell>
                <div>
                  <div>
                    {order.product &&
                      order.product.map((product) => {
                        return (
                          <div
                            onClick={(product) => {
                              setViewProduct(product.id);
                            }}
                          >
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={`${imagePathProduct}${product.image}`}
                            />
                          </div>
                        );
                      })}
                  </div>
                  <div>
                    {order.product &&
                      order.product.map((product) => {
                        return (
                          <div
                            className={
                              order.order_id === viewProduct
                                ? "activePop"
                                : "inactivePop"
                            }
                            onClick={() => {
                              setViewProduct(null);
                            }}
                          >
                            <img
                              style={{ width: "50px", height: "50px" }}
                              src={`${imagePathProduct}${product.image}`}
                            />
                            <p> {product.product_title} </p>
                          </div>
                        );
                      })}
                  </div>
                </div>
              </TableCell> */}
              <TableCell
                component="th"
                scope="row"
                style={{ fontSize: "14px" }}
              >
                <span style={{ fontWeight: 600, color: "#85BB65" }}>
                  {"₹ " + order.paid_amount.toFixed(2)}
                </span>
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ fontSize: "14px" }}
              >
                <div
                  className={
                    order.payment_method === 0
                      ? "payment_method__cod"
                      : order.payment_method === 1
                      ? "payment_method__online"
                      : ""
                  }
                >
                  {order.payment_method === 0
                    ? "COD"
                    : order.payment_method === 1
                    ? "Online"
                    : ""}
                </div>
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ fontSize: "14px" }}
              >
                {order.payment_status === 0
                  ? <span style={{color:'#FF7A00'}}> Pending </span>
                  : order.payment_status === 1
                  ? <span style={{color:'green'}}>Paid</span>
                  : ""}
              </TableCell>
              <TableCell
                component="th"
                scope="row"
                style={{ fontSize: "14px" }}
              >
                {console.log("delivery_status", order.delivery_status)}
                {order.delivery_status === 0 ? <span style={{color: '#FF7A00'}} > Order Placed </span> : ""}
                {order.delivery_status === 1 ? <span style={{color: '#0085FF'}} > Accepted </span> : ""}
                {order.delivery_status === 2 ? <span style={{color: '#0085FF'}} > Shipped </span> : ""}
                {order.delivery_status === 3 ? <span style={{color: '#0085FF'}} > Out for Delivery </span> : ""}
                {order.delivery_status === 4 ? <span style={{color: '#4CAF50'}} > Delivered </span> : ""}
                {order.delivery_status === 5 ? <span style={{color: 'red'}} > Cancelled </span> : ""}
                {order.delivery_status === 6 ? <span> {'Return (pending)'} </span> : ""}
                {order.delivery_status === 7 ? <span> {'Return (Approved)'} </span> : ""}
                {order.delivery_status === 8 ? <span>{"Return (Rejected)"}</span> : ""}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };
  const [currentPage, setCurrentPage] = useState(1);

  const handleClick = (e) => {
    setCurrentPage(Number(e.target.id));
  };
  const pages = [];
  for (let i = 1; i <= Math.ceil(orderData?.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    orderData && orderData.slice(indexOfFirstItem, indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active " : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });
  const handleNextbtn = () => {
    setCurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };
  const handlePrevbtn = () => {
    setCurrentPage(currentPage - 1);
    if ((currentPage - 1) % pageNumberLimit === 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <li className="" onClick={handleNextbtn}>
        {" "}
        &hellip;{" "}
      </li>
    );
  }
  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleLoadMore = (value) => {
    setitemsPerPage(value);
  };

  return (
    <>
      <div className="container">
        <Vendor_Sidebar />
        <Vendor_Header name="Orders" />
        <div className="content_category">
          <div className="headADD_states">
            <span
              className="list_heading"
              onClick={() => {
                getOrders();
              }}
            >
              Orders
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search by Order ID"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
            </div>
          </div>
          <div className="sss">
            <div className="ss">
              <TableContainer className="cat_table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="Order-table">
                  <TableHead>
                    <TableRow>
                      <TableCell className={classes.head}>
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Order ID
                      </TableCell>
                      <TableCell className={classes.head}>User</TableCell>
                      <TableCell className={classes.head}>Date</TableCell>
                      {/* <TableCell className={classes.head}>Products</TableCell> */}
                      <TableCell className={classes.head}>Amount</TableCell>
                      <TableCell className={classes.head}>
                        Payment Method
                      </TableCell>
                      <TableCell className={classes.head}>
                        Payment Status
                      </TableCell>
                      <TableCell className={classes.head}>
                        Delivery Status
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {/* {console.log("Currentitems", currentItems, indexOfFirstItem)} */}
                  {currentItems ? renderData(currentItems) : ""}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    <FormControl
                      className="page_space"
                      variant="standard"
                      sx={{ m: 1, minWidth: 40 }}
                    >
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={number}
                        onChange={(e) => {
                          handleLoadMore(e.target.value);
                          setNumber(e.target.value);
                        }}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      style={{ marginLeft: "30px" }}
                      variant=""
                      component={""}
                    >
                      {indexOfFirstItem + 1} - {indexOfLastItem}&nbsp;
                      Rows&nbsp; of &nbsp;&nbsp;{renderData.length}
                    </Typography>
                    <ul className="pageNumbers page_space">
                      {indexOfFirstItem > 0 && (
                        <li className="page_button">
                          <ArrowBackIosIcon
                            onClick={handlePrevbtn}
                            disabled={currentPage == pages[0] ? true : false}
                          />
                        </li>
                      )}
                      {pageDecrementBtn}
                      {renderPageNumbers}
                      {pageIncrementBtn}
                      {renderData.length > indexOfLastItem && (
                        <li className="page_button">
                          <ArrowForwardIosIcon
                            onClick={handleNextbtn}
                            disabled={
                              currentPage == pages[pages.length - 1]
                                ? true
                                : false
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Toaster />
      </div>
    </>
  );
};

export default Orders;

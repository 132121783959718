import {
  Box,
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  Typography,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  makeStyles,
} from "@material-ui/core";
import { Reorder } from "@material-ui/icons";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { url } from "../../../../../App";
//   import { toast } from "react-toastify";
const Stock = (props) => {
  // console.log("propsprops",props)

  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
  });

  const classes = useStyle();
  let stocks = useSelector((state) => state.reducer.stock);
  // console.log("stocksstocksstocks", stocks);
  const [stock, setStock] = useState();
  const [preStock, setPreStock] = useState();
  const [ind, setIndex] = useState("");
  const [totalStock, setTotalStock] = useState();
  const [open, setOpen] = useState(false);
  let token = Cookies.get("content");
  useEffect(() => {
    setOpen(props.show);
  }, [props.show]);
  useEffect(() => {
    let total = Number(preStock) + Number(stock);
    // console.log("total :", total);
    setTotalStock(total);
  }, [stock]);
  let [reload, setReload] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      // setReload(false)
    }, 500);
  }, [reload]);

  //add stock in database
  const addStock = (index, id) => {
    // console.log("indexindexindex", index, id, totalStock);
    // let stock = new FormData();
    // stock.append("stock", totalStock);
    // stock.append("quantification", index);
    // console.log("indexindexindexfdsjikfbskjdsdgdf", stock);

    let config = {
      method: "POST",
      body: JSON.stringify({ stock: totalStock, quantification: index }),
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    fetch(`${url}/api/vendor/product/update-stock/${id}`, config).then(
      (res) => {
        res.json().then((res) => {
          if (res.success) {
            toast(<span style={{ fontSize: "20px" }}>Added Stock ✔️</span>, {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            });
            setStock("");
            // setReload(true);
            // setOpen(false)
            window.location.reload(false);
          }
        });
      }
    );
  };

  return (
    <>
      <Box sx={{ width: 1 }}>
        <Dialog
          fullWidth
          maxWidth="md"
          // sx={{ width: "100%" }}
          open={open}
          onClose={open}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Typography
              style={{ fontWeight: 600, color: "#023F9E" }}
              variant="h5"
              display="block"
              gutterBottom
            >
              Add Stock
            </Typography>
          </DialogTitle>
          <DialogContent>
            <TableContainer style={{ width: 900 }} component={Paper}>
              {stocks.Quantification ? (
                <Table aria-label="simple table">
                  <TableHead style={{ width: 1200 }}>
                    <TableRow style={{ height: 10 }}>
                      <TableCell>Sr. no.</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell align="right">Quantify (unit)</TableCell>
                      <TableCell align="right">Current Stock</TableCell>
                      <TableCell align="right">Add Stock</TableCell>
                      {/* <TableCell align="right">Total Stock</TableCell> */}
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {console.log("sjhjshgfisgf", stocks)} */}
                    {stocks.Quantification
                      ? stocks.Quantification.map((row, i) => (
                          // console.log("dsfdsfsdfdfiii",row),
                          <TableRow
                            style={{ height: 10 }}
                            sx={{
                              "&:last-child td, &:last-child th": {
                                border: 0,
                              },
                            }}
                          >
                            <TableCell component="th" scope="row">
                              {i + 1}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {props.title}
                            </TableCell>
                            <TableCell align="right">
                              {row.quantify}
                              {/* {row.unitId} */}
                              {row.unit}
                            </TableCell>
                            <TableCell align="right">{row.stock}</TableCell>
                            <TableCell align="right">
                              <TextField
                                //   value={stock}
                                variant="outlined"
                                style={{ width: "90px" }}
                                type={"number"}
                                onChange={(e) => {
                                  setIndex(i);
                                  setPreStock(row.stock);
                                  setStock(e.target.value);
                                }}
                              />
                            </TableCell>
                            <TableCell align="right">
                              <Button
                                className={classes.root}
                                style={{
                                  borderRadius: 8,
                                  // padding: "10x",
                                  fontSize: "18px",
                                  // marginTop: "20px",
                                  marginRight: "10px",
                                  borderColor: "#023F9E",
                                }}
                                variant="outlined"
                                onClick={() => {
                                  addStock(stocks.is_quantification, row.id);
                                }}
                              >
                                Save
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))
                      : ""}
                  </TableBody>
                </Table>
              ) : (
                <Table aria-label="simple table">
                  <TableHead style={{ width: 1200 }}>
                    <TableRow style={{ height: 10 }}>
                      <TableCell>Sr. no.</TableCell>
                      <TableCell>Title</TableCell>
                      <TableCell align="center">Current Stock</TableCell>
                      <TableCell align="center">Add Stock</TableCell>
                      {/* <TableCell align="right">Total Stock</TableCell> */}
                      <TableCell align="right">Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* {console.log("dskjghduj",stocks)} */}
                    {
                      <TableRow
                        style={{ height: 10 }}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {1}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {stocks.product_title}
                        </TableCell>
                        <TableCell align="center">{stocks.stock}</TableCell>
                        <TableCell align="center">
                          <TextField
                            //   value={stock}
                            variant="outlined"
                            style={{ width: "90px" }}
                            type={"number"}
                            onChange={(e) => {
                              // setIndex(i);
                              setPreStock(stocks.stock);
                              setStock(e.target.value);
                            }}
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            className={classes.root}
                            style={{
                              borderRadius: 8,
                              // padding: "10x",
                              fontSize: "18px",
                              // marginTop: "20px",
                              marginRight: "10px",
                              borderColor: "#023F9E",
                            }}
                            variant="outlined"
                            onClick={() => {
                              addStock(stocks.is_quantification, stocks.id);
                            }}
                          >
                            Save
                          </Button>
                        </TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </DialogContent>

          <DialogActions>
            <Button
              className={classes.root}
              style={{
                borderRadius: 8,
                padding: "10x",
                fontSize: "18px",
                marginTop: "20px",
                marginRight: "25px",
                borderColor: "#023F9E",
              }}
              variant="outlined"
              onClick={() => props.setstockOpen(false)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default Stock;

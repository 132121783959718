import { TableCell, TableContainer, TableRow } from "@material-ui/core";
import React from "react";

const OrderdUserDetailTable = ({ orderedBy }) => {
  return (
    <div
      style={{
        padding: "1rem 0rem",
        border: "0.5px solid #333",
        borderRadius: "0.6rem",
        margin: "1rem 0rem",
      }}
    >
      <h4>Ordered By :</h4>
      <TableContainer>
        <TableRow>
          <TableCell>Name</TableCell>
          <TableCell>{orderedBy?.name}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Email</TableCell>
          <TableCell>{orderedBy?.email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Phone</TableCell>
          <TableCell>{orderedBy?.phone}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Address</TableCell>
          <TableCell>{`${orderedBy?.address},${orderedBy?.city_name},${orderedBy?.state_name},${orderedBy?.country_name}-${orderedBy?.zipcode}`}</TableCell>
        </TableRow>
      </TableContainer>
    </div>
  );
};

export default OrderdUserDetailTable;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addNewSubSubCategory,
  deleteSubSubCategoryData,
  getSubSubCategories,
  updateSubSubCategoryData,
  updateSubSubCategoryStatus,
} from "../services/product.service";

const useSubSubCatQuery = () => {
  return useQuery({
    queryKey: ["sub-sub-categories"],
    queryFn: getSubSubCategories,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useAddSubSubCategoryData = () => {
  const queryClient = useQueryClient();
  return useMutation(addNewSubSubCategory, {
    onSettled: () => {
      queryClient.invalidateQueries("sub-sub-categories");
    },
    onError: (error) => {
      console.error("error when adding new sub-sub-category", error);
    },
  });
};

const useUpdateSubSubCatData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSubSubCategoryData, {
    onSettled: () => {
      queryClient.invalidateQueries("sub-sub-categories");
    },
    onError: (error) => {
      console.error("error when updating sub-sub-category", error);
    },
  });
};

const useDeleteSubSubCatData = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSubSubCategoryData, {
    onSettled: () => {
      queryClient.invalidateQueries("sub-sub-categories");
    },
    onError: (error) => {
      console.error("error when deleting sub-sub-category", error);
    },
  });
};

const useUpdateSubSubCatStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSubSubCategoryStatus, {
    onSettled: () => {
      queryClient.invalidateQueries("sub-sub-categories");
    },
    onError: (error) => {
      console.error("error when changing status sub-sub-category", error);
    },
  });
};

export {
  useSubSubCatQuery,
  useAddSubSubCategoryData,
  useUpdateSubSubCatData,
  useDeleteSubSubCatData,
  useUpdateSubSubCatStatus,
};

import { useQuery } from "@tanstack/react-query";
import { getAllZipcodes } from "../services/address.service";

const useZipcodesQuery = () => {
  return useQuery({
    queryFn: getAllZipcodes,
    queryKey: ["zipcodes_all"],
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

export { useZipcodesQuery };

import { Typography } from "@material-ui/core";
const Content = () => {
  return (
    <>
      <div className="content">
        <div className="headADD">
          <Typography
            variant="inherit"
            style={{ color: "#023F9E" }}
            component={"h2"}
          >
            DASHBOARD
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Content;

import axios from "../axios";
// import { AxiosError } from "axios";
import { apiRoutes } from "../utils/apiRoutes";
import popMsg from "../utils/popup";

async function getAllCoupons() {
  try {
    const response = await axios.get(`${apiRoutes.getCoupons}`);
    const data = response.data.coupon;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function addCoupon(payload, setIsLoading) {
  setIsLoading(true);
  const requiredFields = [
    { field: "title", message: "Title is Required" },
    { field: "description", message: "Description is Required" },
    { field: "coupon_type", message: "Coupon Type is Required" },
    { field: "access_limit", message: "Access Limit is Required" },
    { field: "start_date", message: "Start Date is Required" },
    { field: "expire_date", message: "Expire Date is Required" },
    { field: "min_purchase", message: "Minimum Purchase is Required" },
    { field: "max_discount", message: "Maximum Discount is Required" },
    { field: "discount", message: "Discount is Required" },
    { field: "discount_type", message: "Discount Type is Required" },
    { field: "coupon_code", message: "Coupon Code is Required" },
  ];

  for (const { field, message } of requiredFields) {
    if (!payload.hasOwnProperty(field)) {
      popMsg(message);
      return;
    }
  }

  try {
    const response = await axios.post(`${apiRoutes.addCoupon}`, payload);
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  } finally {
    setIsLoading(false);
  }
}

async function updateCoupon(payload, couponId, setIsLoading) {
  setIsLoading(true);
  const requiredFields = [
    { field: "title", message: "Title is Required" },
    { field: "description", message: "Description is Required" },
    { field: "coupon_type", message: "Coupon Type is Required" },
    { field: "access_limit", message: "Access Limit is Required" },
    { field: "start_date", message: "Start Date is Required" },
    { field: "expire_date", message: "Expire Date is Required" },
    { field: "min_purchase", message: "Minimum Purchase is Required" },
    { field: "max_discount", message: "Maximum Discount is Required" },
    { field: "discount", message: "Discount is Required" },
    { field: "discount_type", message: "Discount Type is Required" },
    { field: "coupon_code", message: "Coupon Code is Required" },
  ];

  for (const { field, message } of requiredFields) {
    if (!payload.hasOwnProperty(field)) {
      popMsg(message);
      return;
    }
  }
  try {
    const response = await axios.post(
      `${apiRoutes.updateCoupon}${couponId}`,
      payload
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  } finally {
    setIsLoading(false);
  }
}

export { getAllCoupons, addCoupon, updateCoupon };

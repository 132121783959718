import {
  Button,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import toast, { Toaster } from "react-hot-toast";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
import Vendor_Sidebar from "../../../mainPage/vendor_sidebar/Vendor_Sidebar";
import Vendor_Header from "../../../mainPage/Vendor_Header/Vendor_Header";
import Vendor_Footer from "../../../mainPage/Vendor_Footer/Vendor_Footer";
import { imagePathProduct as imagePath } from "../../../../../App";

const ProductDetailsByVendor = () => {
  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
  });

  const classes = useStyle();
  let [q, setQ] = useState([]);
  let [idd, setId] = useState();
  let [purchase, setPurchase] = useState();
  let [discountType, setDiscountType] = useState();
  let [final, setFinal] = useState();
  let [discountAmount, setDiscountAmount] = useState();
  let navigate = useNavigate();
  let [productStatus, setProductStatus] = useState();
  let [approve, setApprove] = useState(false);
  let [saleprice, setSaleprice] = useState("");
  let [agencyComission, setAgencyComission] = useState("");
  let data = useSelector((state) => state.reducer.addProductsDataForUpdate);
  // console.log("data :", data);
  let [file, setFile] = useState([]);
  let token = Cookies.get("content");

  useEffect(() => {
    setFile(data.banner);
    setQ(data.Quantification);
    setApprove(data.status);
    setId(data.id);
    setAgencyComission(data.agencyComission);
    setSaleprice(data.saleprice);
    setPurchase(data.purchaseprice);
    setDiscountType(data.discountType);
    setDiscountAmount(data.dicountAmount);
    setFinal(data.finalPrice);
  }, [data]);
  console.log("setSaleprice :", saleprice);
  console.log("Product status :", approve, data.productStatus, data);
  //Approve || Reject USER

  const Approve_Reject = (status) => {
    const config = {
      method: "PUT",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        productStatus: status,
      }),
    };
    fetch(`/api/web/product/approve/${idd}`, config).then((response) => {
      response.json().then((res) => {
        if (res.productStatus == "APPROVED") {
          setApprove(true);
          setProductStatus("APPROVED");
          toast.success(<div className="text-lg">Approved !</div>);
        }
        if (res.productStatus == "REJECTED") {
          setApprove(true);
          setProductStatus("REJECTED");
          toast.success(<div className="text-lg">Rejected !</div>);
        }
        if (res.productStatus == "AUTO-APPROVED") {
          setApprove(true);
          setProductStatus("AUTO-APPROVED");
        }
        if (res.productStatus == "PENDING") {
          setProductStatus("PENDING");
          setApprove(false);
        }
        setTimeout(() => {
          navigate("/products");
        }, 1000);
      });
    });
  };

  function commissionCalc(price, percentage) {
    let agencyComm = (percentage / 100) * price;
    return agencyComm;
  }

  return (
    <>
      <div className="container">
        <Vendor_Sidebar />
        <Vendor_Header />
        <div className="addproduct">
          <div className="add_Header">
            <div className="showexistPage_add">
              {/* <TableHead className="headdd">Product Details</TableHead> */}
              {/* <TableHead className="headdd">Quantification</TableHead> */}
            </div>
            {data.hideButton ? (
              ""
            ) : (
              <div style={{ marginLeft: "450px" }}>
                <Button
                  className={classes.root}
                  startIcon={<ArrowBackIosNewSharpIcon />}
                  style={{
                    borderRadius: 8,
                    width: "340px",
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "-60px",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={() => navigate("/vendor/products")}
                >
                  Back to product list
                </Button>
              </div>
            )}
          </div>
          <div className="add_section">
            <div className="Quan_inner_add_section_proDetails">
              <div className="productDetailsHead">
                <Typography
                  className="addHeading"
                  style={{ fontSize: "36px", color: "#023F9E" }}
                  variant="inherit"
                  component={"h2"}
                >
                  Product Details
                </Typography>
              </div>
              {/* ///////show details here///////////// */}
              <div className="product_details">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    padding: "30px",
                  }}
                >
                  <div className="product_details_images">
                    {file
                      ? file.map((i) => {
                          return (
                            <img
                              style={{
                                width: "200px",
                                height: "200px",
                                margin: "3px",
                                border: "2px solid black",
                              }}
                              src={`${imagePath}${i}`}
                            />
                          );
                        })
                      : ""}
                  </div>
                </div>
                <div>
                  <DialogContent>
                    <TableContainer>
                      <Table>
                        <TableRow>
                          <TableCell>Product Title</TableCell>
                          <TableCell>{data.product_Title}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Category</TableCell>
                          <TableCell>{data.categoryName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Sub-Category</TableCell>
                          <TableCell>{data.subName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Sub-Sub-Category</TableCell>
                          <TableCell>{data.subsubName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Brand</TableCell>
                          <TableCell>{data.brandName}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Return Threshold Time</TableCell>
                          <TableCell>{data.threshold}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Status</TableCell>
                          <TableCell>
                            {data.status === 1
                              ? "Visible"
                              : data.status === 0
                              ? "Invisible"
                              : ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Subscribe</TableCell>
                          <TableCell>
                            {data.subscribe === 1
                              ? "Yes"
                              : data.subscribe === 0
                              ? "No"
                              : ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Isperishable</TableCell>
                          <TableCell>
                            {data.Isperishable === 1
                              ? "Yes"
                              : data.Isperishable === 0
                              ? "No"
                              : ""}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Description</TableCell>
                          <TableCell>{data.description}</TableCell>
                        </TableRow>
                      </Table>
                    </TableContainer>
                  </DialogContent>
                </div>
              </div>
              {/* list of quntification */}
              <div style={{ padding: "14px", marginTop: "-95px" }}>
                {!data.Quantification ? (
                  <Typography
                    className="addHeading"
                    style={{
                      fontSize: "30px",
                      color: "#023F9E",
                      margin: "1rem 0 1rem 0",
                    }}
                    variant="inherit"
                    component={"h2"}
                  >
                    Prizing Details
                  </Typography>
                ) : (
                  <Typography
                    className="addHeading"
                    style={{
                      fontSize: "30px",
                      color: "#023F9E",
                      margin: "1rem 0 1rem 0",
                    }}
                    variant="inherit"
                    component={"h2"}
                  >
                    Quantification Details
                  </Typography>
                )}

                <TableContainer className="cat_table" component={Paper}>
                  {!data.Quantification ? (
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        {/* ///////////////////////// */}
                        <TableRow>
                          <TableCell style={{ fontSize: "17px" }}>
                            Sale Price
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px" }}
                            align="center"
                          >
                            Purchase Price
                          </TableCell>
                          {/* <TableCell style={{ fontSize: "17px" }}>
                            Discount Type
                          </TableCell> */}
                          <TableCell
                            style={{ fontSize: "17px" }}
                            align="center"
                          >
                            Discount Amount
                          </TableCell>
                          <TableCell style={{ fontSize: "17px" }} align="right">
                            Final Price
                          </TableCell>
                          {/* <TableCell style={{ fontSize: "17px" }} align="right">
                            Cost Price
                          </TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell style={{ fontSize: "17px" }}>
                            {saleprice}
                          </TableCell>
                          <TableCell
                            style={{ fontSize: "17px" }}
                            align="center"
                          >
                            {purchase}
                          </TableCell>
                          {/* <TableCell style={{ fontSize: "17px" }}>
                            {discountType}
                          </TableCell> */}
                          <TableCell
                            style={{ fontSize: "17px" }}
                            align="center"
                          >
                            {discountType === "%"
                              ? commissionCalc(saleprice, discountAmount)
                              : discountAmount}
                          </TableCell>
                          <TableCell style={{ fontSize: "17px" }} align="right">
                            {final}
                          </TableCell>
                          {/* <TableCell style={{ fontSize: "17px" }} align="right">
                            {value}
                          </TableCell> */}
                        </TableRow>
                      </TableBody>
                    </Table>
                  ) : (
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                      <TableHead>
                        {/* ///////////////////////// */}
                        <TableRow>
                          <TableCell style={{ fontSize: "17px" }}>
                            Quantify
                          </TableCell>
                          <TableCell style={{ fontSize: "17px" }}>
                            Unit
                          </TableCell>
                          <TableCell style={{ fontSize: "17px" }}>
                            Sale Price
                          </TableCell>
                          <TableCell style={{ fontSize: "17px" }} align="right">
                            Purchase Price
                          </TableCell>
                          {/* <TableCell style={{ fontSize: "17px" }} align="right">
                            Discount type
                          </TableCell> */}
                          <TableCell style={{ fontSize: "17px" }} align="right">
                            Discount Amount
                          </TableCell>
                          <TableCell style={{ fontSize: "17px" }} align="right">
                            Customer Seen Price
                          </TableCell>
                          {/* <TableCell style={{ fontSize: "17px" }} align="right">
                            Cost Price
                          </TableCell> */}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {q
                          ? q.map((i) => {
                              return (
                                <TableRow>
                                  <TableCell style={{ fontSize: "17px" }}>
                                    {i.quantify}
                                  </TableCell>
                                  <TableCell style={{ fontSize: "17px" }}>
                                    {i.unit}
                                  </TableCell>
                                  <TableCell style={{ fontSize: "17px" }}>
                                    {i.sale_price}
                                  </TableCell>
                                  <TableCell
                                    style={{ fontSize: "17px" }}
                                    align="right"
                                  >
                                    {i.purchase_price}
                                  </TableCell>
                                  {/* <TableCell
                                    style={{ fontSize: "17px" }}
                                    align="right"
                                  >
                                    {i.discount_type}
                                  </TableCell> */}
                                  <TableCell
                                    style={{ fontSize: "17px" }}
                                    align="right"
                                  >
                                    {i.discount_type === "%"
                                      ? commissionCalc(
                                          i.sale_price,
                                          i.discount_amount
                                        )
                                      : i.discount_amount}
                                  </TableCell>
                                  <TableCell
                                    style={{ fontSize: "17px" }}
                                    align="right"
                                  >
                                    {i.final_price_amount}
                                  </TableCell>
                                  {/* <TableCell
                                    style={{ fontSize: "17px" }}
                                    align="right"
                                  >
                                    {(data.agencyComissionType == "%"
                                      ? (agencyComission / 100) * i.finalprice
                                      : data.agencyComissionType == "INR"
                                      ? i.finalprice - agencyComission
                                      : "") +
                                      (data.superAdminComissionType == "%"
                                        ? (data.superAdminComission / 100) *
                                          i.finalprice
                                        : data.superAdminComission == "INR"
                                        ? i.finalprice -
                                          data.superAdminComission
                                        : "") -
                                      i.finalprice}
                                  </TableCell> */}
                                </TableRow>
                              );
                            })
                          : ""}
                      </TableBody>
                    </Table>
                  )}
                </TableContainer>
              </div>
            </div>
          </div>
        </div>
        <Vendor_Footer />
      </div>

      <Toaster />
    </>
  );
};

export default ProductDetailsByVendor;

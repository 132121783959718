import Vendor_Content from "./Vendor_Content/Vendor_Content";
import Vendor_Footer from "./Vendor_Footer/Vendor_Footer";
import Vendor_Sidebar from "./vendor_sidebar/Vendor_Sidebar";
// import Header from '../../Container/Header/Header'
import Vendor_Header from "./Vendor_Header/Vendor_Header";
const Vendor_Dashboard = () => {
  return (
    <>
      <div className="container">
        <Vendor_Sidebar/>
        <Vendor_Header/>
        <Vendor_Content/>
        <Vendor_Footer/> 
      </div>
    </>
  );
};

export default Vendor_Dashboard;

import { CircularProgress, Switch } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  useBrandsQuery,
  useDeleteBrandData,
  useUpdateBrandStatus,
} from "../../../hooks/brand.hooks";
import { filterData, imagepath_brand } from "../../../utils/constants";
import popMsg from "../../../utils/popup";
import DelBox from "../../ui/DelBox";
import Layout from "../../ui/Layout";
import MyTable from "../../ui/MyTable";
import TopBlock from "../../ui/TopBlock";
import AddBrand from "./AddBrand";
import RowActions from "../../ui/RowActions";
import UpdateBrand from "./UpdateBrand";

const Brands = () => {
  const [search, setSearch] = useState("");
  const [openAddForm, setOpenAddForm] = useState(false);
  const [renderedData, setRenderedData] = useState([]);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [brandToUpdate, setBrandToUpdate] = useState();
  const [delOpen, setDelOpen] = useState(false);
  const [delId, setDelId] = useState("");

  const columnHelper = createColumnHelper();
  const queryClient = useQueryClient();

  //   Queries and Mutations
  const { data, isLoading } = useBrandsQuery();
  const { mutateAsync: deleteBrand } = useDeleteBrandData();
  const { mutateAsync: updateStatus } = useUpdateBrandStatus();

  // Handlers
  const handleClickEdit = (row) => {
    setBrandToUpdate(row);
    setOpenUpdateForm(true);
  };
  const handleDelete = () => {
    deleteBrand(delId, {
      onSuccess: (res) => {
        setDelOpen(false);
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("categories");
      },
    });
  };
  const handleClickDelete = (row) => {
    setDelId(row.id);
    setDelOpen(true);
  };
  const handleChangeStatus = (_, val, row) => {
    const brand = {
      id: row.id,
      status: val,
    };
    // Optimistically Updating UI
    row.is_status = val;
    updateStatus(brand, {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["brands"]);
        popMsg(res.data.message, res.data.success);
      },
      onError: (err) => {
        console.error("Error updating category status:", err);
        // Revert the UI state if needed
        row.is_status = !val;
      },
    });
  };

  //   USE-EFFECTS
  useEffect(() => {
    filterData("brand_name", search, data, setRenderedData);
  }, [search, data]);
  // Columns
  const columns = [
    columnHelper.accessor("serialNumber", {
      header: "S.No",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("brand_name", {
      header: "Brand Name",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("image", {
      header: "Image",
      cell: (info) => (
        <img
          style={{ maxWidth: "6rem", maxHeight: "6rem", borderRadius: "10px" }}
          src={`${imagepath_brand}${info.getValue()}`}
          alt={info.getValue()}
        />
      ),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor("is_status", {
      header: "Status",
      cell: (info) => (
        <Switch
          onChange={(e, val) => handleChangeStatus(e, val, info.row.original)}
          defaultChecked={info.getValue()}
        />
      ),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <RowActions
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
          row={info.row}
        />
      ),
    }),
  ];
  return (
    <Layout title="Brand">
      <div style={{ overflow: "unset" }}>
        <TopBlock
          search={search}
          setOpenAddForm={setOpenAddForm}
          setSearch={setSearch}
        />
        {isLoading && (
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        )}
        {!isLoading && renderedData && renderedData.length && (
          <MyTable columns={columns} data={renderedData} />
        )}
      </div>
      <AddBrand open={openAddForm} setOpen={setOpenAddForm} />
      <UpdateBrand
        open={openUpdateForm}
        setOpen={setOpenUpdateForm}
        brand={brandToUpdate}
        key={"update_brand_form"}
      />
      {delOpen && (
        <DelBox
          open={delOpen}
          setOpen={setDelOpen}
          deleteEntry={handleDelete}
        />
      )}
      <Toaster />
    </Layout>
  );
};

export default Brands;

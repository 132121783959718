import {
  makeStyles,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Input,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { useBrandsQuery } from "../../../hooks/brand.hooks";
import { useAddCategoryData } from "../../../hooks/category.hooks";
import popMsg from "../../../utils/popup";

const AddCategory = ({ open, setOpen }) => {
  const [categoryName, setCategoryName] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");
  const [brands, setBrands] = useState([]);
  const [brandIds, setBrandIds] = useState([]);

  const { data: brandData } = useBrandsQuery();
  const { mutateAsync: addCategory } = useAddCategoryData();
  const queryClient = useQueryClient();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = async () => {
    const category = {
      category_name: categoryName,
      image,
      brands: brandIds,
    };
    addCategory(category, {
      onSuccess: () => {
        handleClose();
        popMsg("Category Added Successfully", true);
        queryClient.invalidateQueries("categories");
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Category</DialogTitle>
      <DialogContent>
        <Box style={{ width: "100%", overflow: "unset" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  placeholder="Category Name"
                  value={categoryName}
                  onChange={(e) => {
                    let value = e.target.value;

                    setCategoryName(value.slice(0, 30));
                  }}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  type="text"
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel style={{ fontSize: "0.8rem" }}>
                  Select Image :
                </InputLabel>
                <Input
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  type="file"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    style={{
                      maxHeight: "10rem",
                      maxWidth: "15rem",
                      marginTop: "1rem",
                      marginLeft: "10%",
                    }}
                    src={imagePreview}
                    alt={"category-image"}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                {brandData && (
                  <Autocomplete
                    value={brands}
                    onChange={(_, newValue) => {
                      const val = newValue.map((item) => item.id);
                      setBrandIds(val);
                      setBrands(newValue);
                    }}
                    multiple={true}
                    getOptionLabel={(option) => option.brand_name}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Brands" />
                    )}
                    options={brandData}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCategory;

import axios from "../axios";
import { apiRoutes } from "../utils/apiRoutes";
import { mapSerialNumber } from "../utils/constants";

async function getWebSliderData() {
  try {
    const response = await axios.get(`${apiRoutes.getWebSliderData}`);
    const data = response.data.slider_list;
    const mapped = mapSerialNumber(data);
    return mapped;
  } catch (err) {
    console.error(err);
  }
}

async function getMobileSliderData() {
  try {
    const response = await axios.get(`${apiRoutes.getMobileSliderData}`);
    const data = response.data.slider_list;
    const mapped = mapSerialNumber(data);
    return mapped;
  } catch (err) {
    console.error(err);
  }
}

async function addSliderData(slider) {
  const payload = new FormData();
  payload.append("slider_type", slider.type);
  payload.append("banner_title", slider.title);
  payload.append("images", slider.image);
  payload.append("bannerFor", slider.selectedBannerFor);
  const bannerFor = slider.selectedBannerFor;
  if (bannerFor === "0") {
    payload.append("slider", "0");
    payload.append("AllProducts", "All Products");
  }
  if (bannerFor === "1") {
    payload.append("slider", "1");
    payload.append("category_id", slider.category);
  }
  if (bannerFor === "2") {
    payload.append("slider", "2");
    payload.append("sub_category_id", slider.subCategory);
  }
  if (bannerFor === "3") {
    payload.append("slider", "3");
    payload.append("product_id", slider.product.value);
  }
  if (bannerFor === "4") {
    payload.append("slider", "4");
    payload.append("subscribe_id", slider.subscribeProduct.value);
  }
  if (bannerFor === "5") {
    payload.append("slider", "5");
    payload.append("other_url", slider.url);
  }
  return axios.post(`${apiRoutes.addSlider}`, payload);
}

async function updateSliderData(slider) {
  const payload = new FormData();
  payload.append("slider_type", slider.type);
  payload.append("banner_title", slider.title);
  if (slider.image) payload.append("images", slider.image);
  payload.append("bannerFor", slider.selectedBannerFor);
  const bannerFor = slider.selectedBannerFor;
  if (bannerFor === "0") {
    payload.append("slider", "0");
    payload.append("AllProducts", "All Products");
  }
  if (bannerFor === "1") {
    payload.append("slider", "1");
    payload.append("category_id", slider.category);
  }
  if (bannerFor === "2") {
    payload.append("slider", "2");
    payload.append("sub_category_id", slider.subCategory);
  }
  if (bannerFor === "3") {
    payload.append("slider", "3");
    payload.append("product_id", slider.product.value);
  }
  if (bannerFor === "4") {
    payload.append("slider", "4");
    payload.append("subscribe_id", slider.subscribeProduct.value);
  }
  if (bannerFor === "5") {
    payload.append("slider", "5");
    payload.append("other_url", slider.url);
  }
  return axios.post(`${apiRoutes.updateSlider}${slider.id}`, payload);
}

async function deleteSliderData(sliderId) {
  return axios.get(`${apiRoutes.deleteSlider}${sliderId}`);
}

async function updateSliderStatus(slider) {
  const payload = { status: slider.status };
  return axios.post(`${apiRoutes.updateSliderStatus}${slider.id}`, payload);
}

export {
  getWebSliderData,
  getMobileSliderData,
  addSliderData,
  deleteSliderData,
  updateSliderStatus,
  updateSliderData,
};

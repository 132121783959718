import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
// import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Cookies from "js-cookie";
import Footer from "../../Container/Footer/Footer";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
// import { height } from "@mui/system";
import { FormControl, MenuItem, Select } from "@mui/material";
// import { useNavigate } from "react-router-dom";
import { url } from "../../../App";
import { isAlphaNumeric } from "../../../utils/constants";

const Country = () => {
  let [search, setSearch] = useState("");

  const getCountries = () => {
    fetch(`${url}/api/admin/country`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((res) => {
        // setCountries(res.data);
        let searchedCountries = res.data.filter((i) => {
          return i.country_name
            .toString()
            .toLowerCase()
            .includes(search.toLowerCase());
        });
        setData(searchedCountries);
      });
    });
  };
  useEffect(() => {
    getCountries();
  }, []);
  useEffect(() => {
    getCountries();
  }, [search]);

  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  });
  const classes = useStyle();
  // let [countries, setCountries] = useState([]);
  let [countryname, setCountryname] = useState("");

  const [addopen, setaddOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [number, setNumber] = useState(10);
  let token = Cookies.get("content");
  // let navigate = useNavigate();
  // get country from apis

  // /////////////////////////////////////////////Add country in database///////////////////////////////////////
  const addCountry = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("country_name", countryname.trim());
    const options = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        // "Content-Type": "application/json",
      },
      // body: JSON.stringify({ name: countryname.trim() }),
      body: formData,
    };
    if (countryname.toString().trim().length > 1) {
      fetch(`${url}/api/admin/country/store`, options).then((response) => {
        response.json().then((res) => {
          if (res.errors) {
            toast(
              <span style={{ fontSize: "20px" }}>
                ⚠️ {res.errors.map((i) => i.message)}
              </span>,
              {
                duration: 2000,
                position: "top-center",
                // Styling
                style: {},
                className: "",
                // Custom Icon
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },
                // Aria
                ariaProps: {
                  role: "error",
                  "aria-live": "polite",
                },
              }
            );
          } else {
            toast(<span style={{ fontSize: "20px" }}>Country Added ✔️</span>, {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            });
            setaddOpen(false);
            getCountries();
            setCountryname("");
          }
        });
      });
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>
          ⚠️ Please Provide Country Name{" "}
        </span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
  };

  /////////////////////////////////////UPDATE COUNTRY NAME/////////////////////////////////////////////////////
  let [updateCountryname, setUpdateCountryname] = useState("");
  let [upcID, setUpcID] = useState();
  const updateCountry = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("country_name", updateCountryname);
    const contryConfig = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        // "Content-Type": "application/json",
      },
      // body: JSON.stringify({ country_name: updateCountryname }),
      body: formData,
    };
    if (updateCountryname.toString().trim().length != 0) {
      fetch(`${url}/api/admin/country/update/${upcID}`, contryConfig).then(
        (response) => {
          response.json().then((res) => {
            if (res.errors) {
              return toast(
                <span style={{ fontSize: "20px" }}>
                  ⚠️ {res.errors.map((i) => i.message)}
                </span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
            if (res.success) {
              setOpen(false);
              getCountries();
              return toast(
                <span style={{ fontSize: "20px" }}>
                  {`Updated Successfully`} ✔️
                </span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
          });
        }
      );
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>
          ⚠️ Please Provide Country Name
        </span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
  };

  ////////////////////////////DELETE COUNTRY/////////////////////////////////////////
  //for delete
  const [delopen, setdelOpen] = useState(false);
  const [delID, setdelID] = useState(false);

  const delhandleClickOpen = () => {
    setdelOpen(true);
  };

  const delhandleClose = () => {
    setOpen(false);
  };
  const deleteCountry = () => {
    fetch(`${url}/api/admin/country/destroy/${delID}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((res) => {
        if (res.error) {
          return toast(
            <span style={{ fontSize: "20px" }}>🛑 {res.error}</span>,
            {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            }
          );
        }
        if (res.success) {
          setdelOpen(false);
          getCountries();
          toast(<span style={{ fontSize: "20px" }}>Deleted ✔️</span>, {
            duration: 2000,
            position: "top-center",
            // Styling
            style: {},
            className: "",
            // Custom Icon
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            // Aria
            ariaProps: {
              role: "error",
              "aria-live": "polite",
            },
          });
        }
      });
    });
  };

  const render = (data) => {
    return (
      <TableBody>
        {data
          ? data.map((i, index) => {
              return (
                <TableRow
                  // style={{backgroundColor:"red"}}
                  className="list_row"
                  id={i.id}
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {indexOfFirstItem + index + 1}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {i.country_name}
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "17px", cursor: "pointer" }}
                    align="right"
                  >
                    <ModeEditIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        setUpcID(i.id);
                        setUpdateCountryname(i.country_name);
                        setOpen(true);
                      }}
                    />
                    <DeleteIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        setdelOpen(true);
                        setdelID(i.id);
                        // setDeleteId(i._id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          : ""}
      </TableBody>
    );
  };

  const [data, setData] = useState([]);
  // console.log("Data :", data);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // console.log("indexOfFirstItem :", indexOfFirstItem);
  // console.log("indexOfLastItem :", indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active " : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };
  const handleEnter = (event) => {
    if (event.key === "Enter") {
      addCountry(event);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <li className="" onClick={handleNextbtn}>
        {" "}
        &hellip;{" "}
      </li>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleLoadMore = (value) => {
    setitemsPerPage(value);
  };

  return (
    <>
      <div className="container">
        <Sidebar />
        <Header name="Manage Country" />
        <div className="content_states">
          {/* ADD COUNTRY */}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "75%" }}
              open={addopen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography
                  style={{ fontWeight: 500 }}
                  variant="h5"
                  component={"abbr"}
                  display="block"
                  gutterBottom
                >
                  Add Country
                </Typography>
              </DialogTitle>
              <DialogContent>
                <TextField
                  value={countryname}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (isAlphaNumeric(value)) setCountryname(value);
                  }}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  onKeyDown={handleEnter}
                  style={{ height: "50px", width: "400px", fontSize: "20px" }}
                  id="standard-basic"
                  label="Enter Country Name"
                  variant="standard"
                />
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    // color: "#023F9E",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    addCountry(e);
                  }}
                >
                  Add
                </Button>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setaddOpen(false);
                    setCountryname("");
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
          {/* Edit country ///////////////////////////////////////////////////////////////////////////////////*/}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "75%" }}
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography
                  style={{ fontWeight: 500 }}
                  variant="h5"
                  component={"abbr"}
                  display="block"
                  gutterBottom
                >
                  Update Country
                </Typography>
              </DialogTitle>
              <DialogContent>
                <TextField
                  value={updateCountryname}
                  onChange={(e) => {
                    let value = e.target.value;
                    if (isAlphaNumeric(value)) setUpdateCountryname(value);
                  }}
                  style={{ height: "50px", width: "400px", fontSize: "20px" }}
                  id="standard-basic"
                  label="Update Country Name"
                  variant="standard"
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    // color: "#023F9E",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={updateCountry}
                >
                  Update
                </Button>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Box>

          {/* Delete country Permission///////////////////////////////////////////////////////////////////////////////////*/}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "100%" }}
              open={delopen}
              onClose={delhandleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="delete">
                {/* <div className="del_head"></div> */}
                <div className="del_alert">
                  Are you sure, you want to delete this ?
                </div>
                <span
                  style={{
                    color: "red",
                    fontSize: "20px",
                    marginTop: "-15px",
                  }}
                >
                  Caution! This action may affect other data also.
                </span>
                <div className="del_action">
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      padding: "10x",
                      fontSize: "18px",
                      // marginTop: "20px",
                      marginRight: "10px",
                      // color: "#023F9E",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={deleteCountry}
                  >
                    Yes
                  </Button>
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      padding: "10x",
                      fontSize: "18px",
                      // marginTop: "20px",
                      marginRight: "10px",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={() => setdelOpen(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </Dialog>
          </Box>
          {/* search bar for  country */}
          {/* list heading and add btn */}
          <div className="headADD_states">
            <span
              onClick={() => {
                window.location.reload(false);
              }}
              className="list_heading"
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              All Countries
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search Country"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
              <Button
                size="large"
                className="addbtn"
                variant="contained"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                startIcon={<AddIcon />}
                onClick={() => setaddOpen(true)}
              >
                Add
              </Button>
            </div>
          </div>
          {/* list of countries */}
          <div className="sss">
            <div className="ss">
              <TableContainer component={Paper}>
                <Table
                  // sx={{ maxWidth: 1450 }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Country Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {render(currentItems)}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    {/* dropdown for pagination */}
                    <FormControl
                      className="page_space"
                      variant="standard"
                      sx={{ m: 1, minWidth: 40 }}
                    >
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={number}
                        onChange={(e) => {
                          handleLoadMore(e.target.value);
                          setNumber(e.target.value);
                        }}
                        // label="0-5"
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      style={{ marginLeft: "30px" }}
                      variant=""
                      component={""}
                    >
                      {indexOfFirstItem + 1} - {indexOfLastItem}&nbsp;
                      Rows&nbsp; of &nbsp;&nbsp;{data.length}
                    </Typography>
                    <ul className="pageNumbers page_space">
                      {indexOfFirstItem > 0 && (
                        <li className="page_button">
                          <ArrowBackIosIcon
                            onClick={handlePrevbtn}
                            disabled={currentPage == pages[0] ? true : false}
                          />
                        </li>
                      )}
                      {pageDecrementBtn}
                      {renderPageNumbers}
                      {pageIncrementBtn}
                      {data.length > indexOfLastItem && (
                        <li className="page_button">
                          <ArrowForwardIosIcon
                            onClick={handleNextbtn}
                            disabled={
                              currentPage == pages[pages.length - 1]
                                ? true
                                : false
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                  {/* <Button onClick={handleLoadMore} variant="contained">
                    Load More
                  </Button> */}
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <Toaster />
    </>
  );
};

export default Country;

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Button,
  Grid,
  makeStyles,
} from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { addService } from "../../../services/serviceProduct.service";
import popMsg from "../../../utils/popup";

const AddService = ({ open, setOpen, setAddedService }) => {
  const [serviceName, setServiceName] = useState();
  const [serviceType, setServiceType] = useState();
  const [image, setImage] = useState();
  const [serviceDescription, setServiceDescription] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };
  const handleSave = () => {
    add();
  };

  function resetForm() {
    setServiceName("");
    setServiceType("");
    setImage("");
    setServiceDescription("");
    setImagePreview("");
  }

  async function add() {
    if (!serviceType) {
      popMsg("Service Type is Required", false);
      return;
    }
    if (!serviceName) {
      popMsg("Service Name is Required", false);
      return;
    }
    if (!serviceDescription) {
      popMsg("Service Description is Required", false);
      return;
    }
    if (!image) {
      popMsg("Image is Required");
      return;
    }
    const payload = new FormData();
    payload.append("service_type", serviceType);
    payload.append("service_name", serviceName);
    payload.append("service_detail", serviceDescription);
    payload.append("image", image);
    const data = await addService(payload, setIsLoading);
    if (data.success) {
      setAddedService(true);
      popMsg(data.service, true);
      handleClose();
    }
  }

  return (
    <Box sx={{ width: 1 }}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Service</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Service Name"
                value={serviceName}
                onChange={(e) => setServiceName(e.target.value)}
                fullWidth
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel style={{ fontSize: "0.8rem" }}>
                  Service Type
                </InputLabel>
                <Select
                  onChange={(e) => setServiceType(e.target.value)}
                  value={serviceType}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value={1}> By Profession</MenuItem>
                  <MenuItem value={2}> By Requirement</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel style={{ fontSize: "0.8rem" }}>
                  Select Image :
                </InputLabel>
                <Input
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  type="file"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    style={{
                      height: "10rem",
                      width: "15rem",
                      marginTop: "1rem",
                      marginLeft: "10%",
                    }}
                    src={imagePreview}
                    alt={"service-image"}
                  />
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                label="Service Description"
                value={serviceDescription}
                onChange={(e) => setServiceDescription(e.target.value)}
                fullWidth
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={handleSave}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddService;

import { Button, Typography } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
const Vendor_Content = () => {
  let [countries, setCountries] = useState([]);
  // get country from apis
  useEffect(() => {
    fetch("/api/web/country").then((res) => {
      console.log(res);
      res.json().then((res) => {
        setCountries(res.country);
      });
    });
  }, []);
  console.log("counties :", countries);
  return (
    <>
      <div className="content">
        <div className="headADD">
          <Typography
            variant="inherit"
            style={{ color: "#023F9E" }}
            component={"h2"}
          >
            DASHBOARD
          </Typography>
        </div>
      </div>
    </>
  );
};

export default Vendor_Content;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { error } from "jquery";
import { addAgency, updateAgency } from "../services/agency.service";

const useAddAgencyData = () => {
  const qureyClient = useQueryClient();
  return useMutation(addAgency, {
    onSettled: () => {},
    onError: () => {
      console.error("error when adding new agency", error);
    },
  });
};

const useUpdateAgencyData = () => {
  return useMutation(updateAgency, {
    onSettled: () => {},
    onError: (err) => {
      console.error("error while updating agency", err);
    },
  });
};

export { useAddAgencyData, useUpdateAgencyData };

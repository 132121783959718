import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";
import { deleteItem } from "../../utils/constants";
import popMsg from "../../utils/popup";

const DeleteConfirmation = ({ open, setOpen, url, setDeleted }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async () => {
    const data = await deleteItem(url, setDeleted);
    if (data.success) popMsg(data.message, data.success);
    setOpen(false);
  };
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      maxWidth="xs"
    >
      <DialogTitle id="delete-dialog-title">Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmation;

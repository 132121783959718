import { Typography } from "@material-ui/core";
const Vendor_Footer = () => {
  return (
    <>
      <div className="footer">
        <Typography variant="h5" component={"caption"} style={{fontFamily:"initial",color:"white"}}>Contact-Us : 9999999990</Typography>
      </div>
    </>
  );
};

export default Vendor_Footer;

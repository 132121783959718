import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { useCategoriesQuery } from "../../../hooks/category.hooks";
import { useAddSubCategoryData } from "../../../hooks/subcategory.hooks";
import popMsg from "../../../utils/popup";

const AddSubCategory = ({ open, setOpen }) => {
  const [category, setCategory] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const { mutateAsync: addSubCategory } = useAddSubCategoryData();
  const { data: categoryData, isLoading: loadingCategories } =
    useCategoriesQuery();
  const queryClient = useQueryClient();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleClose = () => {
    setOpen(false);
    reset();
  };
  function reset() {
    setCategory("");
    setSubCategoryName("");
    setImage(null);
    setImagePreview("");
  }

  const handleSave = () => {
    const subcat = {
      category_id: category,
      image,
      sub_category_name: subCategoryName,
    };
    addSubCategory(subcat, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("sub-categories");
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Sub-Category</DialogTitle>
      <DialogContent>
        <Box style={{ width: "100%", overflow: "unset" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel className="select_label">
                  Select Category
                </InputLabel>
                <Select
                  value={category}
                  onChange={(e) => setCategory(e.target.value)}
                >
                  {loadingCategories && <CircularProgress />}
                  <MenuItem value=""></MenuItem>
                  {categoryData &&
                    categoryData.length &&
                    categoryData.map((cat) => {
                      return (
                        <MenuItem value={cat.id}>{cat.category_name}</MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="smail"
                  placeholder="Sub-Category Name"
                  value={subCategoryName}
                  onChange={(e) => {
                    let value = e.target.value;
                    setSubCategoryName(value);
                  }}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel style={{ fontSize: "0.8rem" }}>
                  Select Image :
                </InputLabel>
                <Input
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  type="file"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    style={{
                      maxHeight: "10rem",
                      maxWidth: "15rem",
                      marginTop: "1rem",
                      marginLeft: "10%",
                    }}
                    src={imagePreview}
                    alt={"category-image"}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddSubCategory;

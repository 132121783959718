import axios from "../axios";
import { AxiosError } from "axios";
import popMsg from "../utils/popup";
import { apiRoutes } from "../utils/apiRoutes";

async function getCountries() {
  try {
    const response = await axios.get(`${apiRoutes.getCountries}`);
    const data = response.data.data;
    const filtered = data.map((country) => ({
      id: country.id,
      name: country.country_name,
    }));
    return filtered;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data && errorResponse.data.message) {
        return err.response?.data;
      }
    }
    return popMsg(err.message, false);
  }
}

async function getStates(countryId) {
  if (!countryId) return;
  try {
    const response = await axios.get(`${apiRoutes.getStates}${countryId}`);
    const data = response.data.state;
    const filtered = data.map((state) => ({
      id: state.id,
      name: state.state_name,
    }));
    return filtered;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data && errorResponse.data.message) {
        return err.response?.data;
      }
    }
    return popMsg(err.message, false);
  }
}

async function getCities(stateId) {
  if (!stateId) return;
  try {
    const response = await axios.get(`${apiRoutes.getCities}${stateId}`);
    const data = response.data.city;
    const filtered = data.map((city) => ({
      id: city.id,
      name: city.city_name,
    }));
    return filtered;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data && errorResponse.data.message) {
        return err.response?.data;
      }
    }
    return popMsg(err.message, false);
  }
}

async function getZipcodes(cityId) {
  try {
    const response = await axios.get(`${apiRoutes.getZipcodes}${cityId}`);
    const data = response.data.zipcode;
    const filtered = data.map((zip) => ({
      id: zip.id,
      name: zip.zipcode.toString(),
    }));
    return filtered;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data && errorResponse.data.message) {
        return err.response?.data;
      }
    }
    return popMsg(err.message, false);
  }
}

async function getAllZipcodes() {
  try {
    const response = await axios.get(`${apiRoutes.getAllZipcodes}`);
    const data = response.data.zipcode;
    const mapped = data.map((zip) => ({
      id: zip.id,
      zipcode: zip.zipcode,
    }));
    return mapped;
  } catch (err) {
    console.error(err);
  }
}

export { getCountries, getStates, getCities, getZipcodes, getAllZipcodes };

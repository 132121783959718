import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { Toaster } from "react-hot-toast";
import { useParams } from "react-router-dom";
import {
  useServiceOrderDetailsQuery,
  useServieOrderStatusUpdate,
} from "../../hooks/order.hooks";
import {
  baseUrl,
  currency,
  formatDate,
  imagepath_services,
  isAgency,
} from "../../utils/constants";
import popMsg from "../../utils/popup";
import Layout from "../ui/Layout";
import DeliveryAddreddTable from "./DeliveryAddreddTable";
import OrderdUserDetailTable from "./OrderdUserDetailTable";

const ServiceOrderDetailsPage = () => {
  const { id } = useParams();

  const { data: serviceOrderDetail } = useServiceOrderDetailsQuery(id);
  const { mutateAsync: updateOrderStatus } = useServieOrderStatusUpdate();
  const queryClient = useQueryClient();
  console.log("ID", id);
  console.log("Data", serviceOrderDetail);
  const handleStatusChange = (e) => {
    const order = {
      id,
      status: e.target.value,
    };
    updateOrderStatus(order, {
      onSuccess: (res) => {
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("service_order_details");
      },
    });
  };

  //   const serviceOrderDetail = {
  //     id: 43,
  //     order_id: "SHA0451743",
  //     quoted_price: 500,
  //     service_status: 0,
  //     is_status: 1,
  //     is_deleted: 1,
  //     service_id: 7,
  //     service: {
  //       id: 7,
  //       service_title: "Cleaning and Brooming",
  //       image: "2022-08-19-62ff2f5817515.jpg",
  //       description: "some description given by the user",
  //     },
  //     address_id: 65,
  //     delivery_address: {
  //       customer_name: "Rahul",
  //       customer_phone: "9059079002",
  //       country_name: "India",
  //       alternate_phone: "9875468756",
  //       state_name: "Telangana",
  //       city_name: "Warangal",
  //       zipcode: "506002",
  //       address: "Puppalagutta street",
  //       address_line2: "",
  //       type: "Home",
  //     },
  //     ordered_by: {
  //       name: "Yash Shukla",
  //       email: "shukla@gmail.com",
  //       phone: "9845698778",
  //       country_name: "India",
  //       state_name: "Gujarat",
  //       city_name: "Ahmedabad",
  //       zipcode: "380015",
  //       address: "Jhodpur X-Road, Satellite.",
  //     },
  //     created_at: "2022-10-04T17:20:34.000000Z",
  //     updated_at: "2023-07-04T10:34:02.000000Z",
  //   };

  return (
    <Layout title="Service Order Details">
      {serviceOrderDetail && (
        <div style={{ color: "#333", overflow: "unset" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "2rem",
            }}
          >
            <div style={{ width: "68%" }}>
              {/* left */}
              <div
                style={{ display: "flex", gap: "2rem", marginBottom: "2rem" }}
              >
                {/* Order Details */}
                <div>
                  <p>
                    <strong>Order ID :</strong>
                    <span>{serviceOrderDetail?.order_id}</span>
                  </p>
                  <p>
                    <strong>Ordered On :</strong>
                    <span>{formatDate(serviceOrderDetail?.created_at)}</span>
                  </p>
                  <p>
                    <strong>Last Updated :</strong>
                    <span>{formatDate(serviceOrderDetail?.updated_at)}</span>
                  </p>
                  <p>
                    <strong>Quoted Price :</strong>
                    <span>
                      {currency} {serviceOrderDetail?.quoted_price}
                    </span>
                  </p>
                </div>
              </div>
              <div
                style={{
                  border: "0.5px solid #ddd",
                  padding: "3rem 1rem",
                  borderRadius: "1rem",
                  display: "flex",
                  gap: "2rem",
                }}
              >
                <div style={{ maxWidth: "35%" }}>
                  <img
                    alt={`service_img_${serviceOrderDetail.service?.id}`}
                    src={`${baseUrl}${imagepath_services}${serviceOrderDetail?.service?.images}`}
                    style={{
                      maxWidth: "10rem",
                      maxHeight: "10rem",
                      borderRadius: "1rem",
                    }}
                  />
                  <h2>{serviceOrderDetail?.service?.service_title}</h2>
                </div>
                <div>
                  <p>{serviceOrderDetail?.service?.description}</p>
                </div>
              </div>
            </div>
            <div style={{ width: "30%" }}>
              {/* right */}
              {isAgency() && (
                <div>
                  <FormControl style={{ minWidth: "20rem" }} fullWidth>
                    <Select
                      variant="outlined"
                      value={serviceOrderDetail?.service_status}
                      onChange={(e) => handleStatusChange(e)}
                    >
                      <InputLabel>Service Order Status</InputLabel>
                      <MenuItem value=""></MenuItem>
                      {/* <MenuItem value={0}>Quoted</MenuItem>
                      <MenuItem value={1}>Accepted</MenuItem> */}
                      <MenuItem value={2}>Completed</MenuItem>
                      <MenuItem value={3}>Canceled</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              )}
              <div>
                {/* Ordered By */}
                <OrderdUserDetailTable orderedBy={serviceOrderDetail?.user} />
              </div>
              <div>
                <DeliveryAddreddTable
                  address={serviceOrderDetail?.delivery_address}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <Toaster />
    </Layout>
  );
};

export default ServiceOrderDetailsPage;

import React, { useState } from "react";
import Sidebar from "../Container/Sidebar/Sidebar";
import Header from "../Container/Header/Header";
import Footer from "../Container/Footer/Footer";
import toast, { Toaster } from "react-hot-toast";
import { FaShippingFast } from "react-icons/fa";
import { FaRupeeSign } from "react-icons/fa";
import { GiGears } from "react-icons/gi";
import { GiCancel } from "react-icons/gi";

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Switch,
} from "@material-ui/core";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import { url } from "../../App";
import Cookies from "js-cookie";
import { useEffect } from "react";
import "./index.css";
import popMsg from "../../utils/popup";

const Settings = () => {
  const [maintanceStatus, setMaintanceStatus] = useState();
  const [maintananceMessage, setMaintanceMessage] = useState();
  const [shippingCharge, setShippingCharge] = useState();
  const [shippingInput, setShippingInput] = useState(false);
  const [codStatus, setCodStatus] = useState();
  const [payOnlineStatus, setPayOnlineStatus] = useState();
  const token = Cookies.get("content");
  // //////////////////////////////////
  // Getting under-maintanence (API)
  const headerGet = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
  };
  async function getMaintanenceStatus() {
    const response = await fetch(
      `${url}/api/admin/get-under-maintenance`,
      headerGet
    );
    const data = await response.json();
    if (data.success) {
      setMaintanceStatus(data.under_maintenance_info.status);
      data.under_maintenance_info.status === 1
        ? setMaintanceMessage("Under-maintanence")
        : setMaintanceMessage("Site is Online");
    }
  }
  useEffect(() => {
    getMaintanenceStatus();
  }, []);
  async function postMaintanenceStatus(e) {
    const response = await fetch(`${url}/api/admin/under-maintenance`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify({
        flag: e.target.checked ? 1 : 0,
      }),
    });
    const data = await response.json();
    if (data.success) {
      popMsg(data.message, data.success);
      getMaintanenceStatus();
    }
  }
  // /////////////////////////////////////////
  const postMaintenanceHandler = (e) => {
    setMaintanceStatus(e.target.checked ? 1 : 0);
    postMaintanenceStatus(e);
  };
  //   //////////////////////////////////////////////
  // Shipping Charges -GET
  const shippingGetHeader = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  };
  async function getShippingCharges() {
    const response = await fetch(
      `${url}/api/shiping-charge`,
      shippingGetHeader
    );
    const data = await response.json();
    setShippingCharge(data.shiping_charge.shiping_charge);
    // console.log(data.shiping_charge.shiping_charge)
  }
  useEffect(() => {
    getShippingCharges();
  }, []);
  //shipping Charges - POST
  const shippingBody = {
    shipping_charges: shippingCharge,
  };
  const shippingPostHeader = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `bearer ${token}`,
    },
    body: JSON.stringify(shippingBody),
  };
  async function postShippingCharges() {
    const response = await fetch(
      `${url}/api/admin/product/update-shipping-charges`,
      shippingPostHeader
    );
    const data = await response.json();
    if (data.success) {
      setShippingInput(false);
      getShippingCharges();
      popMsg(data.message, data.success);
    }
    // console.log(data);
  }
  const postShippingChargeHandler = () => {
    postShippingCharges();
  };
  //   //////////////////////////////////////////////////

  async function getDeliveryOptions() {
    const response = await fetch(`${url}/api/admin/get-payment-method`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
    });
    const data = await response.json();
    data.payment_method.map((item) => {
      if (item.id === 5) {
        setCodStatus(item.status);
      }
      if (item.id === 6) {
        setPayOnlineStatus(item.status);
      }
    });
  }
  useEffect(() => {
    getDeliveryOptions();
  }, []);
  //   ///////            POST Delivery-Options
  async function postDeliveryOptions(method, status) {
    const response = await fetch(`${url}/api/admin/payment-method/${method}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `bearer ${token}`,
      },
      body: JSON.stringify({ status: status }),
    });
    const data = await response.json();
    if (data.success) {
      getDeliveryOptions();
      popMsg(data.message, data.success);
    }
  }
  const postDeliveryOptionsHandler = () => {
    postDeliveryOptions(5, codStatus);
    postDeliveryOptions(6, payOnlineStatus);
  };
  //   /////////////////////////////////////////////////////
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 35,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  return (
    <div className="container">
      <Sidebar />
      <Header name="Settings" />
      {/* <div className="content_category"> */}
      <div
        style={{
          padding: "80px",
          background: "#fff",
        }}
      >
        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "0.5px solid #999",
              minHeight: "300px",
              minWidth: "180px",
              padding: "20px",
              alignItems: "center",
              gap: "15px",
              borderRadius: "15px",
              justifyContent: "center",

              //   cursor: "pointer",
            }}
          >
            <div>
              <FaShippingFast style={{ fontSize: "50px", color: "#3BBAD5" }} />
            </div>
            <div>
              <h4>Shipping Charges</h4>
            </div>
            <div>
              {shippingInput ? (
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: "25px",
                  }}
                >
                  <GiCancel
                    onClick={() => {
                      setShippingInput(false);
                    }}
                    style={{ marginLeft: "120px", cursor: "pointer" }}
                  />
                  <input
                    value={shippingCharge}
                    onChange={(e) => {
                      setShippingCharge(e.target.value);
                    }}
                    style={{
                      width: "80px",
                      border: "0.5px solid #333",
                      borderRadius: "5px",
                      padding: "5px 8px",
                      fontWeight: 500,
                      color: "blue",
                      fontSize: "16px",
                    }}
                  />
                  <button
                    style={{ marginTop: "15px" }}
                    onClick={postShippingChargeHandler}
                    className="btn__update"
                  >
                    Update
                  </button>
                </div>
              ) : (
                <p
                  className="shipping__para"
                  onClick={() => {
                    setShippingInput(true);
                  }}
                >
                  {shippingCharge}
                </p>
              )}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "0.5px solid #999",
              minHeight: "300px",
              minWidth: "180px",
              padding: "20px",
              alignItems: "center",
              gap: "15px",
              borderRadius: "15px",
              justifyContent: "center",
              //   cursor: "pointer",
            }}
          >
            <div>
              <GiGears style={{ fontSize: "50px", color: "#C0C0C0" }} />
            </div>
            <div>
              <h4>Maintenance</h4>
              <FormGroup style={{ marginTop: "50px" }}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography>Off</Typography>
                  <AntSwitch
                    checked={maintanceStatus}
                    onChange={(e) => {
                      postMaintenanceHandler(e);
                    }}
                    inputProps={{ "aria-label": "ant design" }}
                  />
                  <Typography>On</Typography>
                </Stack>
              </FormGroup>
              <p className={maintanceStatus ? "offlineUM" : "onlineUM"}>
                {maintananceMessage}
              </p>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "0.5px solid #999",
              minHeight: "300px",
              minWidth: "180px",
              padding: "20px",
              alignItems: "center",
              gap: "15px",
              borderRadius: "15px",
              height: "230px",
              justifyContent: "centre",
              overflow: "unset",
              //   cursor: "pointer",
            }}
          >
            <div>
              <FaRupeeSign style={{ fontSize: "50px", color: "#85BB65" }} />
            </div>
            <div>
              <h4>Delivery Options</h4>
            </div>
            <div>
              <FormGroup>
                <FormControlLabel
                  checked={codStatus ? true : false}
                  onChange={(e) => {
                    e.target.checked ? setCodStatus(1) : setCodStatus(0);
                  }}
                  control={<Checkbox />}
                  label={<span style={{ fontSize: "14px" }}>{"COD"}</span>}
                />
                <FormControlLabel
                  checked={payOnlineStatus ? true : false}
                  onChange={(e) => {
                    e.target.checked
                      ? setPayOnlineStatus(1)
                      : setPayOnlineStatus(0);
                  }}
                  control={<Checkbox />}
                  label={
                    <span style={{ fontSize: "14px" }}>{"Online payment"}</span>
                  }
                />
                <button
                  style={{ marginTop: "20px" }}
                  onClick={postDeliveryOptionsHandler}
                  className="btn__update"
                >
                  Update
                </button>
              </FormGroup>
            </div>
          </div>
        </div>
      </div>
      {/* </div> */}
      <Footer />
      <Toaster />
    </div>
  );
};

export default Settings;

import { Button } from "@mui/material";
import React from "react";
import { BiLeftArrow } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const BackButton = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(-1);
  };
  return (
    <div>
      <Button
        style={{ padding: "0.5rem 2rem" }}
        variant="contained"
        onClick={handleClick}
      >
        <span
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            gap: "1rem",
          }}
        >
          <BiLeftArrow /> <span>Back</span>
        </span>
      </Button>
    </div>
  );
};

export default BackButton;

import React, { useState, useEffect } from "react";
import Sidebar from "../Container/Sidebar/Sidebar";
import Header from "../Container/Header/Header";
import {
  Button,
  TableContainer,
  TextField,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import { Toaster } from "react-hot-toast";
import { getNotifications } from "../../services/notifications.service";
import AddNotificationForm from "./AddNotificationForm";
import {
  baseUrl,
  imagepath_notifications,
  initialRows,
} from "../../utils/constants";

const NotificationPanel = () => {
  const [notificationData, setNotificationData] = useState();
  const [renderedData, setRenderedData] = useState();
  const [openAddNotifications, setOpenAddNotifications] = useState(false);
  const [notificationAdded, setNotificationAdded] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  const [search, setSearch] = useState("");

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    filterData();
  }, [search]);

  useEffect(() => {
    if (notificationAdded) get();
    setNotificationAdded(false);
  }, [notificationAdded]);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    // setPage(0);
  };

  async function get() {
    const data = await getNotifications();
    setNotificationData(data);
    setRenderedData(data);
  }

  function filterData() {
    const filtered =
      notificationData &&
      notificationData.filter((notification) => {
        return notification.notification_title
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    setRenderedData(filtered);
  }

  const renderData = (data) => {
    return (
      <TableBody>
        {data &&
          data.length > 0 &&
          data.map((i, index) => {
            return (
              <TableRow
                key={index}
                className="list_row"
                sx={{
                  "&:last-child td, &:last-child th": {
                    border: 0,
                  },
                }}
              >
                <TableCell
                  style={{ fontSize: "17px" }}
                  component="th"
                  scope="row"
                >
                  {(page + 1) * rowsPerPage - rowsPerPage + index + 1}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {i.notification_title}
                </TableCell>
                <TableCell
                  style={{ fontSize: "17px" }}
                  component="th"
                  scope="row"
                >
                  {i.notification_description}
                </TableCell>
                <TableCell
                  style={{ fontSize: "17px" }}
                  component="th"
                  scope="row"
                >
                  <img
                    style={{ height: "60px", width: "60px" }}
                    src={`${baseUrl}${imagepath_notifications}${i.image}`}
                  />
                </TableCell>
              </TableRow>
            );
          })}
      </TableBody>
    );
  };

  return (
    <div>
      <div className="container">
        <Sidebar />
        <Header name="Manage Discount Coupon" />
        <div className="content_category">
          <div className="headADD_states">
            <span
              className="list_heading"
              onClick={() => {
                get();
              }}
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              Notifications
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
              <Button
                size="large"
                className="addbtn"
                variant="contained"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                startIcon={<AddIcon />}
                onClick={() => setOpenAddNotifications(true)}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="sss">
            <div className="ss">
              <TableContainer className="cat_table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Title
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Image
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Description
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderedData &&
                    renderedData.length &&
                    renderData(
                      renderedData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    )}
                </Table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={
                    renderedData && renderedData.length
                      ? renderedData.length
                      : 0
                  }
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  showFirstButton
                  showLastButton
                />
              </TableContainer>
            </div>
          </div>
        </div>
      </div>
      <AddNotificationForm
        open={openAddNotifications}
        setOpen={setOpenAddNotifications}
        setAddedNotification={setNotificationAdded}
      />
      <Toaster />
    </div>
  );
};

export default NotificationPanel;

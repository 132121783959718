import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  makeStyles,
} from "@material-ui/core";
import popMsg from "../../../utils/popup";

import { useState } from "react";
import { updateSubadmin } from "../../../services/subadmin.service";
import { useEffect } from "react";

const UpdateSubadmin = ({ open, setOpen, setSubadminUpdated, subadmin }) => {
  const [subadminId, setSubadminId] = useState();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();
  useEffect(() => {
    if (!subadmin) return;
    setSubadminId(subadmin.id);
    setName(subadmin.name);
    setEmail(subadmin.email);
    setPhone(subadmin.phone);
  }, [subadmin]);
  function resetForm() {
    setName("");
    setEmail("");
    setPhone("");
    setSubadminId("");
  }

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };
  const handleSave = () => {
    update();
  };

  async function update() {
    let payload = {
      name,
      email,
      phone,
    };
    const data = await updateSubadmin(payload, subadminId, setIsLoading);
    if (data.success) {
      popMsg(data.message, true);
      handleClose();
      setSubadminUpdated(true);
    }
  }

  return (
    <Box sx={{ width: 1 }}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Update Sub-Admin</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                fullWidth
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                InputProps={{
                  type: "email",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="phone"
                value={phone}
                onChange={(e) => {
                  const enteredValue = e.target.value;
                  const numbersOnly = enteredValue.replace(/[^0-9]/g, "");
                  const truncatedValues = numbersOnly.slice(0, 11);
                  setPhone(truncatedValues);
                }}
                fullWidth
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={handleSave}
            color="primary"
          >
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default UpdateSubadmin;

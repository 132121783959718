import Agency_Content from "./Agency_Content/Agency_Content";
import Agency_Footer from "./Agency_Footer/Agency_Footer";
import Agency_Sidebar from "./Agency_sidebar/Agency_Sidebar";
// import Header from '../../Container/Header/Header'
import Agency_Header from "./Agency_Header/Agency_Header";
const Agency_Dashboard = () => {
  return (
    <>
      <div className="container">
        <Agency_Sidebar/>
        <Agency_Header/>
        <Agency_Content/>
        <Agency_Footer/> 
      </div>
    </>
  );
};

export default Agency_Dashboard;

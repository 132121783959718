import {
  Button,
  FormControl,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import toast, { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ArrowBackIosNewSharpIcon from "@mui/icons-material/ArrowBackIosNewSharp";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import Vendor_Sidebar from "../../../mainPage/vendor_sidebar/Vendor_Sidebar";
import Vendor_Header from "../../../mainPage/Vendor_Header/Vendor_Header";
import { url } from "../../../../../App";

const QuanBy_vendor = () => {
  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
  });

  const classes = useStyle();
  let userID = Cookies.get("userID");
  let productData = useSelector((state) => state.vendorReducer.productDetails);
  // console.log("quali pro : ", productData); //get product data by redux
  let [quantify, setQuantify] = useState("");
  let navigate = useNavigate();
  let token = Cookies.get("content");
  let [saleprice, setSaleprice] = useState("");
  let [purchaseprice, setPurchaseprice] = useState("");
  let [discount, setDiscount] = useState("");
  let [discountamount, setDiscountamount] = useState("");
  let [finalprice, setFinalprice] = useState();
  let [items, setItem] = useState([]);
  let [units, setUnits] = useState([]);
  let [unitId, setUnitId] = useState("");

  useEffect(() => {
    fetch(`${url}/api/vendor/unit`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((res) => {
        setUnits(res.unit);
        // console.log(res.unit)
      });
    });
  }, []);

  const calculate = (e) => {
    setDiscountamount(e.target.value);
    if (discount && saleprice) {
      if (discount == "INR") {
        let price = saleprice - e.target.value;
        console.log("Price :", price, saleprice, discountamount);
        setFinalprice(price);
      }
      if (discount == "%") {
        let price = (e.target.value / 100) * saleprice;
        price = saleprice - price;
        // let price=saleprice*(e.target.value)/100;
        setFinalprice(price);
      }
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>
          Please Provide Saleprice & DiscountType 🚫{" "}
        </span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
  };

  ///////////////////////////////////////////////////////////////////////////////////////////////////
  const add_Multiple = () => {
    let obj = {
      quantify: quantify,
      unit: unitId,
      sale_price: saleprice,
      purchase_price: purchaseprice,
      discount_type: discount,
      discount_amount: discountamount,
      final_price_amount: finalprice,
      stock: 0,
    };
    if (quantify.toString().trim().length != 0) {
      if (unitId.toString().trim().length != 0) {
        if (saleprice.toString().trim().length != 0) {
          if (purchaseprice.toString().trim().length != 0) {
            if (discount.toString().trim().length != 0) {
              if (discountamount.toString().trim().length != 0) {
                setItem((pre) => {
                  return [...pre, obj];
                });
              } else {
                toast(
                  <span style={{ fontSize: "20px" }}>
                    Please Provide DiscountAmount 🚫{" "}
                  </span>,
                  {
                    duration: 2000,
                    position: "top-center",
                    // Styling
                    style: {},
                    className: "",
                    // Custom Icon
                    // Change colors of success/error/loading icon
                    iconTheme: {
                      primary: "#000",
                      secondary: "#fff",
                    },
                    // Aria
                    ariaProps: {
                      role: "error",
                      "aria-live": "polite",
                    },
                  }
                );
              }
            } else {
              toast(
                <span style={{ fontSize: "20px" }}>
                  Please Provide DiscountType 🚫{" "}
                </span>,
                {
                  duration: 2000,
                  position: "top-center",
                  // Styling
                  style: {},
                  className: "",
                  // Custom Icon
                  // Change colors of success/error/loading icon
                  iconTheme: {
                    primary: "#000",
                    secondary: "#fff",
                  },
                  // Aria
                  ariaProps: {
                    role: "error",
                    "aria-live": "polite",
                  },
                }
              );
            }
          } else {
            toast(
              <span style={{ fontSize: "20px" }}>
                Please Provide PurchasePrice 🚫{" "}
              </span>,
              {
                duration: 2000,
                position: "top-center",
                // Styling
                style: {},
                className: "",
                // Custom Icon
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },
                // Aria
                ariaProps: {
                  role: "error",
                  "aria-live": "polite",
                },
              }
            );
          }
        } else {
          toast(
            <span style={{ fontSize: "20px" }}>
              Please Provide Saleprice 🚫{" "}
            </span>,
            {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            }
          );
        }
      } else {
        toast(
          <span style={{ fontSize: "20px" }}>Please Provide Unit 🚫 </span>,
          {
            duration: 2000,
            position: "top-center",
            // Styling
            style: {},
            className: "",
            // Custom Icon
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            // Aria
            ariaProps: {
              role: "error",
              "aria-live": "polite",
            },
          }
        );
      }
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>Please Provide Quantify 🚫 </span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
    setQuantify("");
    setUnitId("");
    setSaleprice("");
    setPurchaseprice("");
    setDiscount("");
    setDiscountamount("");
    setFinalprice("");
  };

  const deleteItem = (index) => {
    let data = items.filter((i, inx) => {
      return inx != index;
    });
    setItem(data);
  };

  /////////////////////////////////////////////////////////////////////////////////////////////////////////
  const SubmitData = async () => {
    let data = new FormData();
    data.append("product_title", productData.title);
    data.append("subscribe", productData.subscribe);
    data.append("subcategory_id", productData.subId);
    data.append("sub_sub_category_id", productData.subsubId);
    data.append("is_perishable", productData.perishable);
    data.append("description", productData.discription);
    data.append("category_id", productData.catId);
    data.append("brand_id", productData.brandId);
    data.append("return_time", productData.threshold);
    data.append("min_quantity", productData.min_quantity);
    data.append("userType", "VENDOR");
    data.append("publishType", true);
    data.append("productStatus", 0);
    data.append("userID", userID);
    data.append("quantification", 1);

    // if (productData.image) {
    //   Array.from(productData.image).map((i) => {
    //     return data.append("images", i);
    //   });
    // }
    // data.append("image", productData.image);
    if (productData.image[0]) {
      data.append("images", productData.image[0]);
    }
    if (productData.image[1]) {
      data.append("image1", productData.image[1]);
    }
    if (productData.image[2]) {
      data.append("image2", productData.image[2]);
    }
    if (productData.image[3]) {
      data.append("image3", productData.image[3]);
    }
    if (productData.image[4]) {
      data.append("image4", productData.image[4]);
    }
    data.append("quantifications", JSON.stringify(items));

    let config = {
      method: "post",
      body: data,
      headers: {
        authorization: `Bearer ${token}`,
      },
    };
    
    if (items.length > 0) {
      fetch(`${url}/api/vendor/product/store`, config).then((res) => {
        res.json().then((res) => {
          if (res.success) {
            toast(
              <span style={{ fontSize: "20px" }}>
                {res.success} ✔️ Successfully Added
              </span>,
              {
                duration: 2000,
                position: "top-center",
                // Styling
                style: {},
                className: "",
                // Custom Icon
                // Change colors of success/error/loading icon
                iconTheme: {
                  primary: "#000",
                  secondary: "#fff",
                },
                // Aria
                ariaProps: {
                  role: "error",
                  "aria-live": "polite",
                },
              }
            );
            setTimeout(() => {
              navigate("/vendor/products");
            }, 1000);
          } else {
            toast(<span style={{ fontSize: "20px" }}>{res.error} 🚫 </span>, {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            });
          }
        });
      });
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>
          Please Provide Quantification 🚫{" "}
        </span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
  };

  return (
    <>
      <div className="container">
        <Vendor_Sidebar />
        <Vendor_Header />
        <div className="addproduct">
          <div className="add_Header">
            <div className="showexistPage_add">
              {/* <TableHead className="headdd">Product Details</TableHead> */}
              {/* <TableHead className="headdd">Quantification</TableHead> */}
            </div>
            <div style={{ marginLeft: "450px" }}>
              <Button
                className={classes.root}
                startIcon={<ArrowBackIosNewSharpIcon />}
                style={{
                  borderRadius: 8,
                  width: "340px",
                  padding: "10x",
                  fontSize: "18px",
                  marginTop: "20px",
                  marginRight: "-60px",
                  borderColor: "#023F9E",
                }}
                variant="outlined"
                onClick={() => navigate("/vendor/products")}
              >
                Back to product list
              </Button>
            </div>
          </div>
          <div className="add_section">
            <div className="Quan_inner_add_section">
              <Typography
                className="addHeading"
                style={{ fontSize: "36px", color: "#023F9E" }}
                variant="inherit"
                component={"h2"}
              >
                Quantification
              </Typography>
              <div className="inner_quan_product">
                <TextField
                  value={quantify}
                  onChange={(e) => {
                    setQuantify(e.target.value);
                  }}
                  type="number"
                  id="standard-basic"
                  label="Quantify"
                  variant="standard"
                />

                {/* Select Unit */}
                <FormControl variant="standard" style={{ width: "200px" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Unit
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={unitId}
                    onChange={(e) => setUnitId(e.target.value)}
                    label="Select Unit"
                  >
                    {units
                      ? units.map((i) => {
                          return (
                            <MenuItem value={i.id}>{i.unit_name}</MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                <TextField
                  value={saleprice}
                  onChange={(e) => {
                    setSaleprice(e.target.value);
                  }}
                  type="number"
                  id="standard-basic"
                  label="Sale Price"
                  variant="standard"
                />
                <TextField
                  value={purchaseprice}
                  onChange={(e) => {
                    setPurchaseprice(e.target.value);
                  }}
                  type="number"
                  id="standard-basic"
                  label="Purchase Price"
                  variant="standard"
                />
                {/* Discount Type */}
                <FormControl variant="standard" style={{ width: "200px" }}>
                  <InputLabel id="demo-simple-select-standard-label">
                    Discount Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={discount}
                    onChange={(e) => setDiscount(e.target.value)}
                    label="Discount Type"
                  >
                    <MenuItem value={"INR"}>INR</MenuItem>
                    <MenuItem value={"%"}>%</MenuItem>
                  </Select>
                </FormControl>
                <TextField
                  value={discountamount}
                  onChange={calculate}
                  type="number"
                  id="standard-basic"
                  label={`Discount ${discount ? discount : "Type"}`}
                  variant="standard"
                />
                <TextField
                  value={finalprice}
                  disabled
                  type="number"
                  id="standard-basic"
                  label={finalprice ? finalprice : "Final Price"}
                  variant="standard"
                />
                <Button
                  className={classes.root}
                  style={{
                    width: "120px",
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    // marginTop: "20px",
                    marginRight: "-10px",
                    // color: "#023F9E",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={add_Multiple}
                >
                  Add
                </Button>
                {/* <Button onClick={add_Multiple} variant="contained">
                  +
                </Button> */}
              </div>
              {/* list of quntification */}
              <Typography
                className="addHeading"
                style={{ fontSize: "30px", color: "#023F9E" }}
                variant="inherit"
                component={"h2"}
              >
                Lists of Quantification
              </Typography>
              <TableContainer className="cat_table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    {/* ///////////////////////// */}
                    <TableRow>
                      <TableCell style={{ fontSize: "17px", width: "14%" }}>
                        Quantify
                      </TableCell>
                      <TableCell style={{ fontSize: "17px", width: "14%" }}>
                        Unit
                      </TableCell>
                      <TableCell style={{ fontSize: "17px", width: "14%" }}>
                        Sale Price
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "17px", width: "14%" }}
                        align="right"
                      >
                        Purchase Price
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "17px", width: "14%" }}
                        align="right"
                      >
                        Discount Type
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "17px", width: "14%" }}
                        align="right"
                      >
                        Discount
                      </TableCell>
                      <TableCell
                        style={{ fontSize: "17px", width: "14%" }}
                        align="right"
                      >
                        Final Price
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {/* ///////////////////////// */}
                    {items
                      ? items.map((i, index) => {
                          return (
                            <TableRow>
                              <TableCell
                                style={{ fontSize: "17px", width: "14.5%" }}
                              >
                                {i.quantify}
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "17px", width: "14.5%" }}
                              >
                                {i.unit}
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "17px", width: "14.5%" }}
                              >
                                {i.sale_price}
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "17px", width: "14.5%" }}
                                align="right"
                              >
                                {i.purchase_price}
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "17px", width: "14.5%" }}
                                align="right"
                              >
                                {i.discount_type}
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "17px", width: "14.5%" }}
                                align="right"
                              >
                                {i.discount_amount}
                              </TableCell>
                              <TableCell
                                style={{ fontSize: "17px", width: "14.5%" }}
                                align="right"
                              >
                                {i.final_price_amount}
                              </TableCell>
                            </TableRow>
                          );
                        })
                      : ""}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    width: "300px",
                    fontSize: "18px",
                    marginTop: "20px",
                    // marginRight: "10px",
                    // color: "#023F9E",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={SubmitData}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};
export default QuanBy_vendor;

import { Routes, Route } from "react-router-dom";
import "./App.css";
import City from "./Components/address/city/City";
import Country from "./Components/address/country/Country";
import State from "./Components/address/state/State";
import Zipcode from "./Components/address/zipcode/Zipcode";
import Container from "./Components/Container/Container";
import Garbage from "./Components/Garbage";
import Brand from "./Components/product/brand/Brands";
import Category from "./Components/product/category/Categories";
import Vendor from "./Components/product/vendor/Vendor";
import Login from "./Components/User/Login";
import Agency from "./Components/product/agency/Agency";
import Manage_Services from "./Components/product/services/ManageServices";
import SubAdmin from "./Components/Admin/subadmin/SubAdmin";
import Add_productByVendor from "./Components/VENDOR/Container/Products/Add Product/Add_productByVendor";
import Auth from "./Components/Authentication/Auth";
import QuanBy_vendor from "./Components/VENDOR/Container/Products/Add Product/QuanBy_vendor";
import AuthForVendor from "./Components/Authentication/AuthForVendor";
import Vendor_Dashboard from "./Components/VENDOR/mainPage/Vendor_Dashboard";
import Manage_Vendor_Product from "./Components/VENDOR/Container/Products/Manage Product/Manage_Vendor_Product";
import Update_addProduct_ByVendor from "./Components/VENDOR/Container/Products/Update Product/Update_addProduct_ByVendor";
import Update_quan_ByVendor from "./Components/VENDOR/Container/Products/Update Product/Update_quan_ByVendor";
import ProductDetailsByVendor from "./Components/VENDOR/Container/Products/Product Details/ProductDetailsByVendor";
import CustomerList from "./Components/Admin/Customer/CustomerList";

import Agency_Dashboard from "./Components/AGENCYLOGIN/mainPage/Agency_Dashboard";
import Allorder from "./Components/order/All/Allorder";
import DiscountCoupon from "./Components/order/DiscountCoupon/DiscountCoupon";
import Settings from "./Components/settings";
import ReturnOrder from "./Components/order/ReturnOrder";
import NotificationPanel from "./Components/notificationPanel";
import Orders from "./Components/VENDOR/orders/Orders";
import OrderDetailsVendor from "./Components/VENDOR/orders/OrderDetailVendor";
import ServiceOrders from "./Components/AGENCYLOGIN/serviceOrder";
import OrderDetailAgency from "./Components/AGENCYLOGIN/serviceOrder/OrderDetailAgency";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

/* 
  Routes 2k23
*/

// ////////////////////////////////

/*
  Importing Routes for Product Management
*/
import ManageProducts from "./Components/product/main_product/ManageProducts";
import AddProducts from "./Components/product/main_product/AddProduct";
import UpdateProducts from "./Components/product/main_product/UpdateProduct";
import ProductDetails from "./Components/product/main_product/ProductDetails";
import OrderDetailsPage from "./Components/order/OrderDetalPage";
import SubCategories from "./Components/product/subCategory/SubCategories";
import SubSubCategories from "./Components/product/subsubcategory/SubSubCategories";
import ManageWebSlider from "./Components/frontend_settings/sliders/web/ManageWebSlider";
import ManageUnits from "./Components/product/unit/ManageUnits";
import ServiceOrderDetailsPage from "./Components/order/ServiceOrderDetailsPage";
import ManageMobileSlider from "./Components/frontend_settings/sliders/mobile/ManageMobileSlider";
import ManageWebHome from "./Components/frontend_settings/homepage/ManageWebHome";
import ManageServiceOrders from "./Components/order/ServiceOrders";
// import MultiselectDrop from "./Components/address/MultiselectDrop";

// ........................................Client Side Routes...................................../////
// import Layout from "./Client/components/Layout";
// import ProductDescriptionCard from "./Client/utilities/ProductDescriptionCard";
// import Home from "./Client/Home";

export const url = "https://shaiper.karbh.com";
// export const url = "http://192.168.1.33:8000";
export const imagePathSubCat =
  "https://shaiper.karbh.com/storage/app/public/subcategory/";
export const imagePathSubSubCat =
  "https://shaiper.karbh.com/storage/app/public/subsubcategory/";
export const imagePathServices =
  "https://shaiper.karbh.com/storage/app/public/service/";
export const imagePathProduct =
  "https://shaiper.karbh.com/storage/app/public/product/";
export const imagePathSlider =
  "https://shaiper.karbh.com/storage/app/public/slider/";
export const imagePathNotification =
  "http://shaiper.karbh.com/storage/app/public/notification/";

const App = () => {
  const queryClient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route
            path="/home"
            element={
              // <Auth>
              <Container />
              // </Auth>
            }
          />
          {/* /////////ASTHETIC ADDRESS///////// */}
          <Route
            path="/country"
            element={
              // <Auth>
              <Country />
              // </Auth>
            }
          ></Route>
          <Route
            path="/state"
            element={
              // <Auth>
              <State />
              // </Auth>
            }
          ></Route>
          <Route
            path="/city"
            element={
              // <Auth>
              <City />
              // </Auth>
            }
          ></Route>
          <Route
            path="/zipcode"
            element={
              // <Auth>
              <Zipcode />
              // </Auth>
            }
          ></Route>

          {/* /////////PHYSICAL PRODUCT///////// */}
          <Route
            path="/category"
            element={
              // <Auth>
              <Category />
              // </Auth>
            }
          ></Route>
          <Route
            path="/subcategory"
            element={
              // <Auth>
              <SubCategories />
              // </Auth>
            }
          ></Route>
          <Route
            path="/subsubcategory"
            element={
              // <Auth>
              <SubSubCategories />
              // </Auth>
            }
          ></Route>
          <Route
            path="/brand"
            element={
              // <Auth>
              <Brand />
              // </Auth>
            }
          ></Route>
          <Route
            path="/vendor"
            element={
              // <Auth>
              <Vendor />
              // </Auth>
            }
          ></Route>
          <Route
            path="/agency"
            element={
              // <Auth>
              <Agency />
              // </Auth>
            }
          ></Route>
          <Route
            path="/unit"
            element={
              // <Auth>
              <ManageUnits />
              // </Auth>
            }
          ></Route>
          <Route
            path="/service"
            element={
              // <Auth>
              <Manage_Services />
              // </Auth>
            }
          ></Route>
          <Route
            path="/subadmin"
            element={
              // <Auth>
              <SubAdmin />
              // </Auth>
            }
          ></Route>
          <Route
            path="/customer"
            element={
              // <Auth>
              <CustomerList />
              // </Auth>
            }
          ></Route>
          <Route
            path="/mobileslider"
            element={
              // <Auth>
              <ManageMobileSlider />
              // </Auth>
            }
          ></Route>
          <Route
            path="/webslider"
            element={
              // <Auth>
              <ManageWebSlider />
              // </Auth>
            }
          ></Route>
          <Route
            path="/websection"
            element={
              // <Auth>
              <ManageWebHome />
              // </Auth>
            }
          ></Route>
          {/* <Route
            path="/mobilesection"
            element={
              // <Auth>
              <MobileSection />
              // </Auth>
            }
          ></Route> */}
          <Route
            path="/garbage"
            element={
              // <Auth>
              <Garbage />
              // </Auth>
            }
          ></Route>

          <Route
            path="/allorder"
            element={
              // <Auth>
              <Allorder />
              // </Auth>
            }
          />
          <Route
            path="/returnOrder"
            element={
              // <Auth>
              <ReturnOrder />
              // </Auth>
            }
          />
          <Route
            path="/productorder/:id"
            element={
              // <Auth>
              <OrderDetailsPage />
              // </Auth>
            }
          />

          <Route
            path="/discount/coupon"
            element={
              // <Auth>
              <DiscountCoupon />
              // </Auth>
            }
          />
          <Route
            path="/notifications"
            element={
              // <Auth>
              <NotificationPanel />
              // </Auth>
            }
          />
          <Route
            path="/settings"
            element={
              // <Auth>
              <Settings />
              // </Auth>
            }
          />

          <Route
            path="/discount/coupon"
            element={
              // <Auth>
              <DiscountCoupon />
              // </Auth>
            }
          />
          <Route
            path="/allservice"
            element={
              // <Auth>
              <ManageServiceOrders />
              // </Auth>
            }
          />
          <Route
            path="/serviceorder/:id"
            element={
              // <Auth>
              <ServiceOrderDetailsPage />
              // </Auth>
            }
          />

          {/* /////////////////////Vendor user Routes///////////////////////*/}
          <Route
            path="/vendor/main"
            element={
              // <AuthForVendor>
              <Vendor_Dashboard />
              // </AuthForVendor>
            }
          ></Route>
          <Route
            path="/vendor/Products"
            element={
              // <AuthForVendor>
              <Manage_Vendor_Product />
              // <Vendor />
              // </AuthForVendor>
            }
          ></Route>
          <Route
            path="/vendor/orders"
            element={
              // <AuthForVendor>
              <Orders />
              // <Vendor />
              // </AuthForVendor>
            }
          ></Route>
          <Route
            path="/vendor/order-detail/:id"
            element={
              // <AuthForVendor>
              <OrderDetailsVendor />
              // </AuthForVendor>
            }
          ></Route>
          <Route
            path="/vendor/addProductByVendor"
            element={
              // <AuthForVendor>
              <Add_productByVendor />
              // </AuthForVendor>
            }
          ></Route>
          <Route
            path="/vendor/products/quan"
            element={
              // <AuthForVendor>
              <QuanBy_vendor />
              // </AuthForVendor>
            }
          ></Route>
          <Route
            path="/vendor/products/update/addProduct"
            element={
              // <AuthForVendor>
              <Update_addProduct_ByVendor />
              // </AuthForVendor>
            }
          ></Route>
          <Route
            path="/vendor/products/update/quan"
            element={
              // <AuthForVendor>
              <Update_quan_ByVendor />
              // </AuthForVendor>
            }
          ></Route>
          <Route
            path="/vendor/products/productDetails"
            element={
              // <AuthForVendor>
              <ProductDetailsByVendor />
              // </AuthForVendor>
            }
          ></Route>
          <Route path="/manage_product" element={<ManageProducts />} />
          <Route path="/manage_product/add" element={<AddProducts />} />
          <Route
            path="/manage_product/update/:id"
            element={<UpdateProducts />}
          />
          <Route
            path="/manage_product/details/:id"
            element={<ProductDetails />}
          />
          <Route path="/order_details/:id" element={<OrderDetailsPage />} />
          <Route path="/vendor/manage_product" element={<ManageProducts />} />
          <Route path="/vendor/manage_orders" element={<Allorder />} />
          {/* /////////////////////Agency user Routes///////////////////////*/}
          <Route
            path="/agency/main"
            element={
              // <AuthForAgency>
              <Agency_Dashboard />
              // </AuthForAgency>
            }
          />
          <Route
            path="/agency/orders"
            element={
              // <AuthForAgency>
              <ServiceOrders />
              // </AuthForAgency>
            }
          />
          <Route
            path="/agency/order-detail/:id"
            element={
              // <AuthForAgency>
              <OrderDetailAgency />
              // </AuthForAgency>
            }
          />
          {/* <Route
        path="/product_details/:id"
        element={
          <ProductDetail/>
        }
        /> */}
          {/* <Route
            path="/agency/Products"
            element={
              // <AuthForAgency>
              <Manage_Vendor_Product />
              // </AuthForAgency>
            }
          /> */}

          {/* //////////////////////////////Client Side Route ////////////////////////////////
          <Route
            path="/"
            element={
              <Layout>
                <Home />
              </Layout>
            }
          ></Route>
          <Route
            path="/productid"
            element={
              <Layout>
                <ProductDescriptionCard />
              </Layout>
            }
          ></Route> */}
        </Routes>
        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </>
  );
};

export default App;

import React from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl, imagepath_product } from "../../../utils/constants";
import Window from "../../ui/Window";

const UserWishlist = ({ open, setOpen, data }) => {
  const navigate = useNavigate();
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Window open={open} setOpen={handleClose} title="Wishlist">
      <div style={{ display: "flex", flexWrap: "wrap", gap: "1rem" }}>
        {data.length > 1 &&
          data.map((product) => {
            return (
              <div
                style={{
                  border: "0.5px solid #888",
                  borderRadius: "0.6rem",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "1rem",
                  gap: "1rem",
                }}
                onClick={() => {
                  navigate(`/manage_product/details/${product.product_id}`);
                }}
              >
                <div
                  style={{
                    minHeight: "70%",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    style={{ maxWidth: "15rem", maxHeight: "12rem" }}
                    src={`${baseUrl}${imagepath_product}${product.product.images}`}
                  />
                </div>
                <h4>{product.product.product_title}</h4>
              </div>
            );
          })}
      </div>
    </Window>
  );
};

export default UserWishlist;

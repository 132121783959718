import { CircularProgress, Switch } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  useDeleteSliderData,
  useUpdateSliderStatus,
  useWebSliderQuery,
} from "../../../../hooks/slider.hooks";
import { filterData, imagepath_slider } from "../../../../utils/constants";
import popMsg from "../../../../utils/popup";
import DelBox from "../../../ui/DelBox";
import Layout from "../../../ui/Layout";
import MyTable from "../../../ui/MyTable";
import RowActions from "../../../ui/RowActions";
import TopBlock from "../../../ui/TopBlock";
import AddWebSlider from "./AddWebSlider";
import UpdateWebSlider from "./UpdateWebSlider";

const ManageWebSlider = () => {
  const [search, setSearch] = useState("");
  const [openAddForm, setOpenAddForm] = useState(false);
  const [renderedData, setRenderedData] = useState([]);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [sliderToUpdate, setSliderToUpdate] = useState();
  const [delOpen, setDelOpen] = useState(false);
  const [delId, setDelId] = useState("");

  const columnHelper = createColumnHelper();
  const queryClient = useQueryClient();

  // Queries and Mutations
  const { data, isLoading } = useWebSliderQuery();
  const { mutateAsync: deleteSliderData } = useDeleteSliderData();
  const { mutateAsync: updateSliderStatus } = useUpdateSliderStatus();

  //   Handlers
  const handleClickEdit = (row) => {
    setSliderToUpdate(row);
    setOpenUpdateForm(true);
  };

  const handleDelete = () => {
    deleteSliderData(delId, {
      onSuccess: (res) => {
        setDelOpen(false);
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("web-sliders");
        queryClient.invalidateQueries("mobile-sliders");
      },
    });
  };

  const handleClickDelete = (row) => {
    setDelId(row.id);
    setDelOpen(true);
  };

  const handleChangeStatus = (_, val, row) => {
    const slider = {
      id: row.id,
      status: val,
    };
    row.is_status = val;
    updateSliderStatus(slider, {
      onSuccess: (res) => {
        queryClient.invalidateQueries("web-sliders");
        queryClient.invalidateQueries("mobile-sliders");
        popMsg(res.data.message, res.data.success);
      },
      onError: (err) => {
        console.error("Error updating category status:", err);
        // Revert the UI state if needed
        row.is_status = !val;
      },
    });
  };

  useEffect(() => {
    filterData("banner_title", search, data, setRenderedData);
  }, [search, data]);

  const columns = [
    columnHelper.accessor("serialNumber", {
      header: "S.No",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("banner_title", {
      header: "Title",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("image", {
      header: "Image",
      cell: (info) => (
        <img
          style={{ maxWidth: "6rem", maxHeight: "6rem", borderRadius: "10px" }}
          src={`${imagepath_slider}${info.getValue()}`}
          alt={info.getValue()}
        />
      ),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor("is_status", {
      header: "Status",
      cell: (info) => (
        <Switch
          onChange={(e, val) => handleChangeStatus(e, val, info.row.original)}
          defaultChecked={info.getValue()}
        />
      ),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <RowActions
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
          row={info.row}
        />
      ),
    }),
  ];

  return (
    <Layout title="Web Slider">
      <div style={{ overflow: "unset" }}>
        <TopBlock
          search={search}
          setSearch={setSearch}
          setOpenAddForm={setOpenAddForm}
        />
        {isLoading && (
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        )}
        {!isLoading && renderedData && renderedData.length && (
          <MyTable columns={columns} data={renderedData} />
        )}
      </div>
      {openAddForm && (
        <AddWebSlider open={openAddForm} setOpen={setOpenAddForm} />
      )}
      {delOpen && (
        <DelBox
          open={delOpen}
          setOpen={setDelOpen}
          deleteEntry={handleDelete}
        />
      )}
      {openUpdateForm && (
        <UpdateWebSlider
          open={openUpdateForm}
          setOpen={setOpenUpdateForm}
          slide={sliderToUpdate}
        />
      )}
      <Toaster />
    </Layout>
  );
};

export default ManageWebSlider;

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { FiPackage } from "react-icons/fi";
import "../../../../App.css";
import { useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
const Vendor_Sidebar = () => {
  let navigate = useNavigate();

  return (
    <>
      <div className="sidebar">
        <img
          alt={"APP-LOGO"}
          src={require("./sp.png")}
          style={{ width: "100%", justifySelf: "center" }}
        />
        {/* Address asthetic */}
        <div>
          <List>
            <hr />
            <div
              className="sideName_color"
              disablePadding
              onClick={() => navigate("/vendor/main")}
            >
              <ListItemButton>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItemButton>
            </div>

            {/* //////// Manage Product ///////////// */}
            <div
              className="sideName_color"
              onClick={() => {
                navigate("/vendor/manage_product");
              }}
              key={"2"}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  <AccessTimeFilledIcon />
                </ListItemIcon>
                <ListItemText primary={"Manage Product"} />
              </ListItemButton>
            </div>
            <div
              className="sideName_color"
              onClick={() => {
                // navigate("/vendor/orders");
                navigate("/vendor/manage_orders");
              }}
              key={"3"}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  <FiPackage style={{ fontSize: "26px" }} />
                </ListItemIcon>
                <ListItemText primary={"Orders"} />
              </ListItemButton>
            </div>
          </List>
        </div>
      </div>
    </>
  );
};

export default Vendor_Sidebar;

import { Button, Typography } from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
const Agency_Content = () => {
  let [countries, setCountries] = useState([]);
  // get country from apis
  // useEffect(() => {
  //   fetch("/api/web/country").then((res) => {
  //     console.log(res);
  //     res.json().then((res) => {
  //       setCountries(res.country);
  //     });
  //   });
  // }, []);
  // console.log("counties :", countries);
  return (
    <>
      <div className="content">
        <div className="headADD">
          <Typography
            variant="inherit"
            style={{ color: "#023F9E" }}
            component={"h2"}
          >
            DASHBOARD
          </Typography>
          {/* <Button
            size="large"
            className="addbtn"
            variant="contained"
            startIcon={<AddIcon />}
          >
            Add
          </Button> */}
        </div>
        {/* list of countries */}
        {/* <TableContainer component={Paper} style={{ marginTop: "17px" }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ fontSize: "17px" }}>
                  No&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name
                </TableCell>
                <TableCell style={{ fontSize: "17px" }} align="right">
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {countries
                ? countries.map((i, index) => {
                    return (
                      <TableRow
                        key={index}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell
                          style={{ fontSize: "17px" }}
                          component="th"
                          scope="row"
                        >
                          {index + 1}
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                          {i.name}
                        </TableCell>
                        <TableCell style={{ fontSize: "17px" }} align="right">
                          <ModeEditIcon
                            style={{
                              width: "30px",
                              height: "30px",
                              color: "blue",
                              padding: "5px",
                            }}
                          />
                          <DeleteIcon
                            style={{
                              width: "30px",
                              height: "30px",
                              color: "blue",
                              padding: "5px",
                            }}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  })
                : ""}
            </TableBody>
          </Table>
        </TableContainer> */}
      </div>
    </>
  );
};

export default Agency_Content;

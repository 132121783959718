import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  TablePagination,
  TextField,
} from "@material-ui/core";
import axios from "../../../axios";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import Footer from "../../Container/Footer/Footer";
import ServiceDetail from "./ServiceDetail";
import { imagePathServices as imagePath } from "../../../App";
import AddService from "./AddService";
import UpdateService from "./UpdateService";
import { getServices } from "../../../services/serviceProduct.service";
import { initialRows, isAdmin } from "../../../utils/constants";
import { apiRoutes } from "../../../utils/apiRoutes";
import popMsg from "../../../utils/popup";
import Layout from "../../ui/Layout";
const Manage_Services = () => {
  const [openAddService, setOpenAddService] = useState(false);
  const [serviceAdded, setServiceAdded] = useState(false);
  const [openUpdateService, setOpenUpdateService] = useState(false);
  const [serviceUpdated, setServiceUpdated] = useState(false);
  const [selectedServiceUpdate, setSelectedServiceUpdate] = useState();
  const [renderedData, setRenderedData] = useState();
  const [serviceData, setServiceData] = useState();
  const [delOpen, setDelOpen] = useState(false);
  const [delId, setDelId] = useState("");
  const [serviceDeleted, setServiceDeleted] = useState();
  const [search, setSearch] = useState("");

  const [countryname, setCountryname] = useState("");
  const [serviceTypeID, setServiceTypeID] = useState("");
  const [serviceDescription, setServiceDescription] = useState("");
  const [openProfile, setOpenProfile] = useState(false);
  const [i, setI] = useState("");
  const [openImage, setOpenImage] = useState(false);

  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
  });
  const classes = useStyle();

  // UseEffects
  useEffect(() => {
    get();
  }, []);
  useEffect(() => {
    filterData();
  }, [search]);
  useEffect(() => {
    if (serviceAdded) get();
    setServiceAdded(false);
  }, [serviceAdded]);

  useEffect(() => {
    if (serviceUpdated) get();
    setServiceUpdated(false);
  }, [serviceUpdated]);

  useEffect(() => {
    if (serviceDeleted) {
      get();
    }
    setServiceDeleted(false);
  }, [serviceDeleted]);

  //

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    // setPage(0);
  };
  const handleDelete = () => {
    deleteService();
  };
  async function deleteService() {
    const response = await axios.get(`${apiRoutes.deleteService}${delId}`);
    const data = response.data;
    if (data.success) {
      setServiceDeleted(true);
      popMsg("Service Deleted Successfully", true);
    }
    setDelOpen(false);
  }

  //

  async function get() {
    const data = await getServices();
    setRenderedData(data);
    setServiceData(data);
  }

  async function filterData() {
    let filteredData =
      serviceData &&
      serviceData.filter((service) => {
        return service.service_name
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    setRenderedData(filteredData);
  }

  // /////////////////////////////////Pagination///////////////////////////////////////////////
  const renderData = (data) => {
    return (
      <TableBody>
        {data && data.length
          ? data.map((i, index) => {
              return (
                <TableRow
                  // style={{backgroundColor:"red"}}
                  className="list_row"
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {(page + 1) * rowsPerPage - rowsPerPage + index + 1}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {i.service_name}
                  </TableCell>
                  <TableCell>
                    <img
                      onClick={() => {
                        setI(i.image);
                        setOpenImage(true);
                      }}
                      src={`${imagePath}${i.image}`}
                      style={{
                        width: "50px",
                        height: "50px",
                        cursor: "pointer",
                      }}
                    />
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {i.service_type === 1 ? "Profession" : "Requirement"}
                  </TableCell>
                  <TableCell style={{ fontSize: "17px" }} align="right">
                    {isAdmin() && (
                      <ModeEditIcon
                        style={{
                          width: "30px",
                          height: "30px",
                          cursor: "pointer",
                          color: "#023F9E",
                          padding: "5px",
                        }}
                        onClick={() => {
                          setSelectedServiceUpdate(i);
                          setOpenUpdateService(true);
                        }}
                      />
                    )}
                    {isAdmin() && (
                      <DeleteIcon
                        style={{
                          width: "30px",
                          height: "30px",
                          color: "#023F9E",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                        onClick={() => {
                          setDelId(i.id);
                          setDelOpen(true);
                        }}
                      />
                    )}
                    <VisibilityIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        color: "#023F9E",
                        padding: "5px",
                      }}
                      onClick={() => {
                        setCountryname(i.service_name);
                        setServiceTypeID(i.service_type);
                        setServiceDescription(i.service_detail);
                        setOpenProfile(true);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          : " "}
      </TableBody>
    );
  };

  return (
    <Layout>
      <div style={{ overflow: "unset" }}>
        <ServiceDetail
          service_name={countryname}
          service_type={serviceTypeID}
          service_description={serviceDescription}
          openProfile={openProfile}
          setOpenProfile={setOpenProfile}
        />
        <Box sx={{ width: 1 }}>
          <Dialog
            sx={{ width: "75%" }}
            open={openImage}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent
              style={{
                display: "flex",
                flexDirection: "column",
                // marginTop: "-5px",
                margin: "-10px",
              }}
            >
              <img
                src={`${imagePath}${i}`}
                alt="default-img"
                style={{ width: "400px", height: "fit-content" }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                className={classes.root}
                style={{
                  borderRadius: 8,
                  padding: "10x",
                  fontSize: "18px",
                  marginTop: "10px",
                  marginRight: "10px",
                  borderColor: "#023F9E",
                }}
                variant="outlined"
                onClick={() => {
                  setOpenImage(false);
                }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        {/* list heading and add btn */}
        <div className="headADD_states">
          <span
            className="list_heading"
            onClick={() => {
              window.location.reload(false);
            }}
            style={{
              marginLeft: "6px",
              marginTop: "14px",
              cursor: "pointer",
            }}
          >
            All Services
          </span>
          <div>
            <TextField
              onChange={(e) => {
                setSearch(e.target.value);
              }}
              id="outlined-basic"
              placeholder="Search Service"
              variant="outlined"
              style={{ marginRight: "15px" }}
            />
            {isAdmin() && (
              <Button
                size="large"
                className="addbtn"
                variant="contained"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                startIcon={<AddIcon />}
                onClick={() => setOpenAddService(true)}
              >
                Add
              </Button>
            )}
          </div>
        </div>
        {/* list of countries */}

        <TableContainer style={{ marginTop: "2rem" }} component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{
                    fontSize: "18px",
                    fontWeight: "550",
                    color: "#36454F",
                  }}
                >
                  No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "18px",
                    fontWeight: "550",
                    color: "#36454F",
                  }}
                >
                  Image
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "18px",
                    fontWeight: "550",
                    color: "#36454F",
                  }}
                  align="left"
                >
                  Type
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "18px",
                    fontWeight: "550",
                    color: "#36454F",
                  }}
                  align="right"
                >
                  Action
                </TableCell>
              </TableRow>
            </TableHead>
            {renderedData &&
              renderedData.length &&
              renderData(
                renderedData.slice(
                  page * rowsPerPage,
                  page * rowsPerPage + rowsPerPage
                )
              )}
          </Table>
          <TablePagination
            sx={{ px: 2 }}
            page={page}
            component="div"
            rowsPerPage={rowsPerPage}
            count={
              renderedData && renderedData.length ? renderedData.length : 0
            }
            onPageChange={handleChangePage}
            rowsPerPageOptions={[5, 10, 25]}
            onRowsPerPageChange={handleChangeRowsPerPage}
            nextIconButtonProps={{ "aria-label": "Next Page" }}
            backIconButtonProps={{ "aria-label": "Previous Page" }}
            showFirstButton
            showLastButton
          />
        </TableContainer>
      </div>
      {openAddService && (
        <AddService
          open={openAddService}
          setOpen={setOpenAddService}
          setAddedService={setServiceAdded}
        />
      )}
      {openUpdateService && (
        <UpdateService
          open={openUpdateService}
          setOpen={setOpenUpdateService}
          setUpdatedService={setServiceUpdated}
          service={selectedServiceUpdate}
        />
      )}
      {delOpen && (
        <Dialog
          open={delOpen}
          onClose={() => setDelOpen(false)}
          aria-labelledby="delete-dialog-title"
          maxWidth="xs"
        >
          <DialogTitle id="delete-dialog-title">
            Delete Confirmation
          </DialogTitle>
          <DialogContent>
            <DialogTitle>
              Are you sure you want to delete this item?
            </DialogTitle>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDelOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={handleDelete} color="secondary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      )}
      <Toaster />
    </Layout>
  );
};

export default Manage_Services;

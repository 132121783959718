import React, { useState } from "react";
import {
  makeStyles,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Button,
  Input,
} from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import { useAddBrandData } from "../../../hooks/brand.hooks";
import popMsg from "../../../utils/popup";

const AddBrand = ({ open, setOpen }) => {
  const [brandName, setBrandName] = useState("");
  const [image, setImage] = useState(null);
  const [imagePreview, setImagePreview] = useState("");

  const { mutateAsync: addBrand } = useAddBrandData();
  const queryClient = useQueryClient();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };

  function resetForm() {
    setBrandName("");
    setImage(null);
    setImagePreview("");
  }
  const handleSave = () => {
    const brand = {
      brand_name: brandName,
      image,
    };
    addBrand(brand, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("brands");
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Brand</DialogTitle>
      <DialogContent>
        <Box style={{ width: "100%", overflow: "unset" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  placeholder="Brand Name"
                  value={brandName}
                  onChange={(e) => {
                    let value = e.target.value;
                    setBrandName(value);
                  }}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel style={{ fontSize: "0.8rem" }}>
                  Select Image :
                </InputLabel>
                <Input
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  type="file"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    style={{
                      maxHeight: "10rem",
                      maxWidth: "15rem",
                      marginTop: "1rem",
                      marginLeft: "10%",
                    }}
                    src={imagePreview}
                    alt={"category-image"}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddBrand;

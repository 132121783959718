export const apiRoutes = {
  getCountries: "/api/admin/country",
  getStates: "/api/admin/state/",
  getCities: "/api/admin/city/",
  getZipcodes: "/api/admin/zipcode/",
  // Brand
  updateBrand: "/api/admin/brand/update/",
  getBrand: "/api/admin/brand",
  addBrand: "/api/admin/brand/store",
  deleteBrand: "/api/admin/brand/destroy/",
  updateBrandStatus: "/api/admin/brand/status/",
  // Coupons
  getCoupons: "/api/admin/coupen",
  addCoupon: "/api/admin/coupen/store",
  updateCoupon: "/api/admin/coupen/update/",
  deleteCoupon: "/api/admin/coupen/destroy/",
  updateCouponStatus: "/api/admin/coupen/status/",
  // Customers
  getCustomers: "/api/admin/customer-list",
  updateCustomerStatus: "/api/admin/customer-status/",
  //
  getCategories: "/api/admin/category",
  addCategory: "/api/admin/category/store",
  updateCategories: "/api/admin/category/update/",
  deleteCategory: "/api/admin/category/destroy/",
  updateCategoryStatus: "/api/admin/category/status/",
  getCategoryByBrand: "/api/admin/category/brand/",
  //
  getSubCategories: "/api/admin/sub-category",
  addSubCategory: "/api/admin/sub-category/store",
  updateSubCategory: "/api/admin/sub-category/update/",
  deleteSubCategory: "/api//admin/sub-category/destroy/",
  updateSubCategoryStatus: "/api/admin/sub-category/status/",
  //
  getSubSubCategories: "/api/admin/sub-sub-category",
  addSubSubCategories: "/api/admin/sub-sub-category/store",
  updateSubSubCategory: "/api/admin/sub-sub-category/update/",
  deleteSubSubCategory: "/api/admin/sub-sub-category/destroy/",
  updateSubSubCategoryStatus: "/api/admin/sub-sub-category/status/",
  // Login
  login: "/api/login",
  // Units
  getUnits: "/api/admin/unit",
  addUnits: "/api/admin/unit/store",
  updateUnits: "/api/admin/unit/update/",
  deleteUnit: "/api/admin/unit/destroy/",
  //VENDOR
  addVendor: "/api/admin/vender_add",
  getAllVendors: "/api/admin/vendor",
  updateVendor: "/api/admin/vendor/update/",
  deleteVendor: "/api/admin/vendor/destroy/",
  // Manage - SERVICE
  getServices: "/api/admin/service",
  addService: "/api/admin/service/store",
  updateService: "/api/admin/service/update/",
  deleteService: "/api/admin/service/destroy/",
  // Manage - Agency
  getAgencies: "/api/admin/agency",
  addAgency: "/api/admin/agency/store",
  updateAgency: "/api/admin/agency/update/",
  deleteAgency: "/api/admin/agency/destroy/",
  // Notifications
  getNotifications: "/api/admin/notification",
  addNotifications: "/api/admin/notification/store",
  // Orders
  getAllOrders: "/api/admin/order",
  getAllServiceOrders: "/api/admin/service/order",
  getAllReturnOrders: "/api/admin/order/return",
  orderDetails: "/api/admin/order/detail/",
  orderStatusUpdate: "/api/admin/order/status/",
  getServiceOrderDetails: "/api/admin/service/order/detail/",
  updateServiceOrderStatus: "/api/admin/service/order/status/",
  returnOrderAdminAction: "/api/admin/order/return/status/",
  // Product
  getProducts: "/api/admin/product",
  addProduct: "/api/admin/product/store",
  updateProduct: "/api/admin/product/update/",
  deleteProduct: "/api/admin/product/destroy/",
  updateStock: "/api/admin/product/update-stock/",
  getProductDetails: "/api/admin/product/show/",
  updateProductStatus: "/api/admin/product/status/",
  productAdminDecision: "/api/admin/product/status/",
  //Slider
  getWebSliderData: "/api/admin/slider/1",
  getMobileSliderData: "/api/admin/slider/0",
  addSlider: "/api/admin/slider/store",
  updateSlider: "/api/admin/slider/update/",
  deleteSlider: "/api/admin/slider/destroy/",
  updateSliderStatus: "/api/admin/slider/status/",
  // SubAdmin
  getSubadmins: "/api/admin/sub_admin_get",
  addSubadmin: "/api/admin/sub_admin_new",
  updateSubadmin: "/api/admin/sub-admin/update/",
  deleteSubadmin: "/api/admin/sub-admin/destroy/",
  // Vendor
  getVendorProducts: "/api/vendor/product",
  // Web - Home
  getWebSection: "/api/admin/section",
  addWebSection: "/api/admin/section/store",
  updateWebSection: "/api/admin/section/update/",
  deleteWebSection: "/api/admin/section/destroy/",
  // Zipcode
  deleteZipcode: "/api/admin/zipcode/destroy/",
  getAllZipcodes: "/api/admin/zipcode",
};

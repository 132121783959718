import { CircularProgress, Switch } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import {
  useDeleteSubSubCatData,
  useSubSubCatQuery,
  useUpdateSubSubCatStatus,
} from "../../../hooks/ssc.hooks";
import {
  baseUrl,
  filterData,
  imagepath_subSubCat,
} from "../../../utils/constants";
import popMsg from "../../../utils/popup";
import DelBox from "../../ui/DelBox";
import Layout from "../../ui/Layout";
import MyTable from "../../ui/MyTable";
import RowActions from "../../ui/RowActions";
import TopBlock from "../../ui/TopBlock";
import AddSubSubCategory from "./AddSubSubCategory";
import UpdateSubSubCategory from "./UpdateSubSubCategory";

const SubSubCategories = () => {
  const [search, setSearch] = useState("");
  const [openAddForm, setOpenAddForm] = useState(false);
  const [renderedData, setRenderedData] = useState([]);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [sscToUpdate, setSscToUpdate] = useState();
  const [delOpen, setDelOpen] = useState(false);
  const [delId, setDelId] = useState("");

  const columnHelper = createColumnHelper();
  const queryClient = useQueryClient();

  // Queries and Mutations
  const { data, isLoading } = useSubSubCatQuery();
  const { mutateAsync: deleteSsc } = useDeleteSubSubCatData();
  const { mutateAsync: updateSscStatus } = useUpdateSubSubCatStatus();

  const handleClickEdit = (row) => {
    setSscToUpdate(row);
    setOpenUpdateForm(true);
  };
  const handleDelete = () => {
    deleteSsc(delId, {
      onSuccess: (res) => {
        setDelOpen(false);
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("sub-sub-categories");
      },
    });
  };
  const handleClickDelete = (row) => {
    setDelId(row.id);
    setDelOpen(true);
  };

  const handleChangeStatus = (_, val, row) => {
    const ssc = {
      id: row.id,
      status: val,
    };
    row.is_status = val;
    updateSscStatus(ssc, {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["sub-sub-categories"]);
        popMsg(res.data.message, res.data.success);
      },
      onError: (err) => {
        console.error("Error updating sub-sub-categories status:", err);
        // Revert the UI state if needed
        row.is_status = !val;
      },
    });
  };

  useEffect(() => {
    filterData("sub_sub_category_name", search, data, setRenderedData);
  }, [search, data]);
  // Columns
  const columns = [
    columnHelper.accessor("serialNumber", {
      header: "S.No",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("sub_sub_category_name", {
      header: "Sub-Sub-Category-Name",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("image", {
      header: "Image",
      cell: (info) => (
        <img
          style={{ maxWidth: "6rem", maxHeight: "6rem", borderRadius: "10px" }}
          src={`${baseUrl}${imagepath_subSubCat}${info.getValue()}`}
          alt={info.getValue()}
        />
      ),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor("category_name", {
      header: "Category",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("sub_category_name", {
      header: "Sub Category",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("is_status", {
      header: "Status",
      cell: (info) => (
        <Switch
          onChange={(e, val) => handleChangeStatus(e, val, info.row.original)}
          defaultChecked={info.getValue()}
        />
      ),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <RowActions
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
          row={info.row}
        />
      ),
    }),
  ];

  return (
    <Layout title="Sub Sub Categories">
      <div style={{ overflow: "unset" }}>
        <TopBlock
          search={search}
          setSearch={setSearch}
          setOpenAddForm={setOpenAddForm}
        />
        {isLoading && (
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        )}
        {!isLoading && renderedData && renderedData.length && (
          <MyTable columns={columns} data={renderedData} />
        )}
      </div>
      <AddSubSubCategory open={openAddForm} setOpen={setOpenAddForm} />
      <UpdateSubSubCategory
        open={openUpdateForm}
        setOpen={setOpenUpdateForm}
        subSubCat={sscToUpdate}
      />
      <DelBox open={delOpen} setOpen={setDelOpen} deleteEntry={handleDelete} />
      <Toaster />
    </Layout>
  );
};

export default SubSubCategories;

import { InputLabel, MenuItem, Select } from "@material-ui/core";
import React from "react";

/*
    options = [
        {
            id: uniqueId,
            value,
            label
        }
    ]
*/

const DropDown = ({ options, value, onChange, label }) => {
  return (
    <Select value={value} onChange={onChange}>
      <InputLabel style={{ fontSize: "0.8rem" }}>{label}</InputLabel>
      <MenuItem value=""></MenuItem>
      {options?.map((item, index) => {
        return (
          <MenuItem key={item?.id || index} value={item.value}>
            {item.label}
          </MenuItem>
        );
      })}
    </Select>
  );
};

export default DropDown;

import {
  Button,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState, useSelector } from "react";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import Footer from "../../Container/Footer/Footer";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { url } from "../../../App";
import axios from "axios";

const City = () => {
  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  });
  const classes = useStyle();
  let [showCountryID, setshowCountryID] = useState();
  let [showStateID, setshowStateID] = useState();
  let [search, setSearch] = useState("");
  let [addCountryID, setaddCountryID] = useState(Number);
  let [addstateID, setaddstateID] = useState("");
  let [countries, setCountry] = useState([]);
  let [cityname, setCityname] = useState("");
  let [number, setNumber] = useState(10);
  let [cities, setCities] = useState([]);
  let [stateId, setStateId] = useState(0); //Used for updating city_name
  let [countryId, setCountryId] = useState(0); //Used for updating city_name
  const [data, setData] = useState([]);

  let token = Cookies.get("content");

  const getCountries = () => {
    fetch(`${url}/api/admin/country`, {
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((res) => {
        setCountry(res.data);
      });
    });
  };
  useEffect(() => {
    getCountries();
    setaddCountryID("");
  }, []);

  ///////////////////////////////////////GET STATES FOR ADD CITY////////////////////////////////////////////////
  let [addStates, setaddStates] = useState("");

  // console.log("addCountryID:", addCountryID);
  function getStates() {
    try {
      axios
        .get(`${url}/api/admin/state/${addCountryID}`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          // console.log("addCountryID inside", addCountryID);
          setaddStates(res.data.state);
          // console.log(addStates);
        });
    } catch (error) {
      toast.error(<span className="text-lg">{error.message}</span>);
    }
  }
  useEffect(() => {
    if (addCountryID) {
      getStates();
    }
  }, [addCountryID]);

  const addCity = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("state_id", addstateID);
    formData.append("country_id", addCountryID);
    formData.append("city_name", cityname.trim());
    const config = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
      },
      body: formData,
    };
    if (addCountryID > 1) {
      if (addstateID > 1) {
        if (cityname.toString().trim().length > 1) {
          fetch(`${url}/api/admin/city/store`, config).then((response) => {
            response.json().then((res) => {
              // console.log(res);
              if (res.errors) {
                return toast(
                  <span style={{ fontSize: "20px" }}>
                    ⚠️ {res.errors.map((i) => i.message)}{" "}
                  </span>,
                  {
                    duration: 2000,
                    position: "top-center",
                    // Styling
                    style: {},
                    className: "",
                    // Custom Icon
                    // Change colors of success/error/loading icon
                    iconTheme: {
                      primary: "#000",
                      secondary: "#fff",
                    },
                    // Aria
                    ariaProps: {
                      role: "error",
                      "aria-live": "polite",
                    },
                  }
                );
              }
              if (res.success) {
                setaddOpen(false);
                getCities();
                setTimeout(() => {
                  setCityname("");
                  setaddCountryID("");
                  setaddstateID("");
                }, 200);
                // window.location.reload(false);
                return toast(
                  <span style={{ fontSize: "20px" }}>City Added ✔️</span>,
                  {
                    duration: 2000,
                    position: "top-center",
                    // Styling
                    style: {},
                    className: "",
                    // Custom Icon
                    // Change colors of success/error/loading icon
                    iconTheme: {
                      primary: "#000",
                      secondary: "#fff",
                    },
                    // Aria
                    ariaProps: {
                      role: "error",
                      "aria-live": "polite",
                    },
                  }
                );
              }
            });
          });
        } else {
          toast(
            <span style={{ fontSize: "20px" }}>⚠️ Please Provide City</span>,
            {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            }
          );
        }
      } else {
        toast(
          <span style={{ fontSize: "20px" }}>
            ⚠️ Please Provide State Name{" "}
          </span>,
          {
            duration: 2000,
            position: "top-center",
            // Styling
            style: {},
            className: "",
            // Custom Icon
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            // Aria
            ariaProps: {
              role: "error",
              "aria-live": "polite",
            },
          }
        );
      }
    } else {
      toast(
        <span style={{ fontSize: "20px" }}>⚠️ Please Provide Country </span>,
        {
          duration: 2000,
          position: "top-center",
          // Styling
          style: {},
          className: "",
          // Custom Icon
          // Change colors of success/error/loading icon
          iconTheme: {
            primary: "#000",
            secondary: "#fff",
          },
          // Aria
          ariaProps: {
            role: "error",
            "aria-live": "polite",
          },
        }
      );
    }
  };

  const handleEnter = (event) => {
    if (event.key === "Enter") {
      addCity(event);
    }
  };
  //pop
  //for edit
  const [open, setOpen] = useState(false);

  //for delete
  const [delopen, setdelOpen] = useState(false);
  const [delID, setdelID] = useState(null);

  const delhandleClickOpen = () => {
    setdelOpen(true);
  };

  const delhandleClose = () => {
    setOpen(false);
  };
  //for add
  const [addopen, setaddOpen] = useState(false);

  /////////////////////////////////////UPDATE CITY NAME/////////////////////////////////////////////////////
  let [updatecityname, setUpdatecityname] = useState("");
  let [upctID, setUpctID] = useState();

  const updateCity = (e) => {
    e.preventDefault();
    const cityUpdateData = {
      city_name: updatecityname,
      state_id: stateId,
      country_id: countryId,
    };
    const contryConfig = {
      method: "POST",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(cityUpdateData),
      // body: formData,
    };
    if (upctID) {
      if (updatecityname.toString().trim().length != 0) {
        fetch(`${url}/api/admin/city/update/${upctID}`, contryConfig).then(
          (response) => {
            // console.log("ResPONSE", response)
            response.json().then((res) => {
              // console.log("RES",res)
              if (res.error) {
                return toast(
                  <span style={{ fontSize: "20px" }}>
                    ⚠️ {res.error.city_name}{" "}
                  </span>,
                  {
                    duration: 2000,
                    position: "top-center",
                    // Styling
                    style: {},
                    className: "",
                    // Custom Icon
                    // Change colors of success/error/loading icon
                    iconTheme: {
                      primary: "#000",
                      secondary: "#fff",
                    },
                    // Aria
                    ariaProps: {
                      role: "error",
                      "aria-live": "polite",
                    },
                  }
                );
              }

              if (res.success) {
                setOpen(false);
                getCities();
                return toast(
                  <span style={{ fontSize: "20px" }}>
                    {" "}
                    Updated Successfully ✔️
                  </span>,
                  {
                    duration: 2000,
                    position: "top-center",
                    // Styling
                    style: {},
                    className: "",
                    // Custom Icon
                    // Change colors of success/error/loading icon
                    iconTheme: {
                      primary: "#000",
                      secondary: "#fff",
                    },
                    // Aria
                    ariaProps: {
                      role: "error",
                      "aria-live": "polite",
                    },
                  }
                );
              }
            });
          }
        );
      } else {
        toast(
          <span style={{ fontSize: "20px" }}>
            ⚠️ Please Provide City Name{" "}
          </span>,
          {
            duration: 2000,
            position: "top-center",
            // Styling
            style: {},
            className: "",
            // Custom Icon
            // Change colors of success/error/loading icon
            iconTheme: {
              primary: "#000",
              secondary: "#fff",
            },
            // Aria
            ariaProps: {
              role: "error",
              "aria-live": "polite",
            },
          }
        );
      }
    }
  };

  //////////////////////////////////DELETE State//////////////////////////////////////////////////////
  const deleteCity = () => {
    fetch(`${url}/api/admin/city/destroy/${delID}`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    }).then((response) => {
      response.json().then((res) => {
        if (res.error) {
          return toast(
            <span style={{ fontSize: "20px" }}>⚠️ {res.error}</span>,
            {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            }
          );
        }
        if (res.success) {
          setdelOpen(false);
          getCities();
          return toast(
            <span style={{ fontSize: "20px" }}>{res.message} ✔️</span>,
            {
              duration: 2000,
              position: "top-center",
              // Styling
              style: {},
              className: "",
              // Custom Icon
              // Change colors of success/error/loading icon
              iconTheme: {
                primary: "#000",
                secondary: "#fff",
              },
              // Aria
              ariaProps: {
                role: "error",
                "aria-live": "polite",
              },
            }
          );
        }
      });
    });
  };

  //////////////////////////////////gets State from database///////////////////////////////////////////////////
  let [states, setStates] = useState([]);

  function getStatesOnScreen() {
    try {
      axios
        .get(`${url}/api/admin/state/${showCountryID}`, {
          headers: {
            authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          setStates(res.data.state);
        });
    } catch (error) {
      toast.error(<span className="text-lg">{error.message}</span>);
    }
  }
  useEffect(() => {
    if (showCountryID) {
      getStatesOnScreen();
      setData([]);
    }
  }, [showCountryID]);

  // /////////////////////////////////Pagination///////////////////////////////////////////////
  const renderData = (data) => {
    // console.log("on line 569:",data)
    return (
      <TableBody>
        {data.length !== 0
          ? data.map((i, index) => {
              return (
                <TableRow
                  className="list_row"
                  key={i.id}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {index + 1}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {i.city_name}
                  </TableCell>
                  <TableCell style={{ fontSize: "17px" }} align="right">
                    <ModeEditIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        color: "#023F9E",
                        //   padding: "2px",
                      }}
                      onClick={() => {
                        setOpen(true);
                        setUpctID(i.id);
                        setUpdatecityname(i.city_name);
                        setStateId(i.state_id);
                        setCountryId(i.country_id);
                      }}
                    />
                    <DeleteIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        marginLeft: "7px",
                        cursor: "pointer",
                        //   padding: "2px",
                      }}
                      onClick={() => {
                        setdelOpen(true);
                        setdelID(i.id);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          : ""}
      </TableBody>
    );
  };
  // console.log("Data :", data);
  const [currentPage, setcurrentPage] = useState(1);
  const [itemsPerPage, setitemsPerPage] = useState(10);

  const [pageNumberLimit, setpageNumberLimit] = useState(5);
  const [maxPageNumberLimit, setmaxPageNumberLimit] = useState(5);
  const [minPageNumberLimit, setminPageNumberLimit] = useState(0);

  const handleClick = (event) => {
    setcurrentPage(Number(event.target.id));
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(cities.length / itemsPerPage); i++) {
    pages.push(i);
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);
  // console.log("indexOfFirstItem :", indexOfFirstItem);
  // console.log("indexOfLastItem :", indexOfLastItem);
  const renderPageNumbers = pages.map((number) => {
    if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
      return (
        <li
          key={number}
          id={number}
          onClick={handleClick}
          className={currentPage == number ? "active " : null}
        >
          {number}
        </li>
      );
    } else {
      return null;
    }
  });

  //////////////////////////////////gets Cities from database/////////////////////////////////////////////////
  function getCities() {
    return axios
      .get(`${url}/api/admin/city/${showStateID}`, {
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        if (res.data.error) {
          return toast.error(<span className="text-lg">{res.error}</span>);
        }
        if (res.data.length !== 0) {
          let searchedCities = res.data.city.filter((i) => {
            return i.city_name
              .toString()
              .toLowerCase()
              .includes(search.toLowerCase());
          });
          setData(searchedCities);
        }
        setCities(res.data.city);
      });
  }
  useEffect(() => {
    if (showStateID) {
      getCities();
    }
  }, [showStateID, search]);

  const handleNextbtn = () => {
    setcurrentPage(currentPage + 1);

    if (currentPage + 1 > maxPageNumberLimit) {
      setmaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit + pageNumberLimit);
    }
  };

  const handlePrevbtn = () => {
    setcurrentPage(currentPage - 1);

    if ((currentPage - 1) % pageNumberLimit == 0) {
      setmaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
      setminPageNumberLimit(minPageNumberLimit - pageNumberLimit);
    }
  };

  let pageIncrementBtn = null;
  if (pages.length > maxPageNumberLimit) {
    pageIncrementBtn = (
      <li className="" onClick={handleNextbtn}>
        {" "}
        &hellip;{" "}
      </li>
    );
  }

  let pageDecrementBtn = null;
  if (minPageNumberLimit >= 1) {
    pageDecrementBtn = <li onClick={handlePrevbtn}> &hellip; </li>;
  }

  const handleLoadMore = (value) => {
    setitemsPerPage(value);
  };
  return (
    <>
      <div className="container">
        <Sidebar />
        <Header name="Manage City" />
        <div className="content_states">
          {/* ADD City */}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "75%" }}
              open={addopen}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography
                  style={{ fontWeight: 500 }}
                  variant="h5"
                  component={"abbr"}
                  display="block"
                  gutterBottom
                >
                  Add City
                </Typography>
              </DialogTitle>
              <DialogContent className="state_add">
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel
                    style={{ fontSize: "0.8rem" }}
                    id="demo-simple-select-standard-label"
                  >
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={addCountryID}
                    onChange={(e) => {
                      setaddCountryID(e.target.value);
                    }}
                    label="Select Countru"
                  >
                    {countries
                      ? countries.map((i) => {
                          return (
                            <MenuItem key={i.id} value={i.id}>
                              {i.country_name}
                            </MenuItem>
                          );
                        })
                      : " "}
                  </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel
                    style={{ fontSize: "0.8rem" }}
                    id="demo-simple-select-standard-label"
                  >
                    Select State
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={addstateID}
                    onChange={(e) => {
                      setaddstateID(e.target.value);
                    }}
                    label="Select Country"
                  >
                    {addStates
                      ? addStates.map((i) => {
                          return (
                            <MenuItem key={i.id} value={i.id}>
                              {i.state_name}
                            </MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                <TextField
                  value={cityname}
                  onChange={(e) => {
                    setCityname(e.target.value);
                  }}
                  style={{ height: "50px", width: "400px", fontSize: "20px" }}
                  id="standard-basic"
                  label="Enter City Name"
                  variant="standard"
                  onKeyDown={handleEnter}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    // color: "#023F9E",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={(e) => {
                    addCity(e);
                  }}
                >
                  Add
                </Button>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setaddOpen(false);
                    setTimeout(() => {
                      setCityname("");
                      setaddCountryID("");
                      setaddstateID("");
                    }, 200);
                  }}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Box>

          {/* Edit State ///////////////////////////////////////////////////////////////////////////////////*/}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "75%" }}
              open={open}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                <Typography
                  style={{ fontWeight: 500 }}
                  variant="h5"
                  component={"abbr"}
                  display="block"
                  gutterBottom
                >
                  Update City
                </Typography>
              </DialogTitle>
              <DialogContent>
                <TextField
                  value={updatecityname}
                  onChange={(e) => {
                    setUpdatecityname(e.target.value);
                  }}
                  style={{ height: "50px", width: "400px", fontSize: "20px" }}
                  id="standard-basic"
                  label="Update City Name"
                  variant="standard"
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </DialogContent>
              <DialogActions>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    // color: "#023F9E",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={updateCity}
                >
                  Update
                </Button>
                <Button
                  className={classes.root}
                  style={{
                    borderRadius: 8,
                    padding: "10x",
                    fontSize: "18px",
                    marginTop: "20px",
                    marginRight: "10px",
                    borderColor: "#023F9E",
                  }}
                  variant="outlined"
                  onClick={() => setOpen(false)}
                >
                  Cancel
                </Button>
              </DialogActions>
            </Dialog>
          </Box>

          {/* Delete State Permission///////////////////////////////////////////////////////////////////////////////////*/}
          <Box sx={{ width: 1 }}>
            <Dialog
              sx={{ width: "100%" }}
              open={delopen}
              onClose={delhandleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <div className="delete">
                {/* <div className="del_head"></div> */}
                <div className="del_alert">
                  Are you sure, you want to delete this ?
                </div>
                <span
                  style={{
                    color: "red",
                    fontSize: "20px",
                    marginTop: "-15px",
                  }}
                >
                  Caution! This action may affect other data also.
                </span>
                <div className="del_action">
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      padding: "10x",
                      fontSize: "18px",
                      // marginTop: "20px",
                      marginRight: "10px",
                      // color: "#023F9E",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={deleteCity}
                  >
                    Yes
                  </Button>
                  <Button
                    className={classes.root}
                    style={{
                      borderRadius: 8,
                      padding: "10x",
                      fontSize: "18px",
                      // marginTop: "20px",
                      marginRight: "10px",
                      borderColor: "#023F9E",
                    }}
                    variant="outlined"
                    onClick={() => setdelOpen(false)}
                  >
                    No
                  </Button>
                </div>
              </div>
            </Dialog>
          </Box>
          {/* list heading and add btn */}
          <div className="headADD_states">
            <span
              style={{ cursor: "pointer" }}
              className="list_heading"
              onClick={() => {
                window.location.reload(false);
              }}
            >
              All Cities
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search City"
                variant="outlined"
                style={{ marginRight: "15px" }}
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
              <Button
                size="large"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                className="addbtn"
                variant="contained"
                startIcon={<AddIcon />}
                onClick={() => {
                  setaddOpen(true);
                }}
              >
                Add
              </Button>
            </div>
          </div>
          {/*  */}

          {/* list of countries */}
          <div className="state_list" style={{ marginTop: "-50px" }}>
            <div className="list_state">
              <div style={{ display: "flex", columnGap: "20px" }}>
                <FormControl variant="standard" style={{ width: "250px" }}>
                  <InputLabel
                    style={{ fontSize: "0.8rem" }}
                    id="demo-simple-select-standard-label"
                  >
                    Select Country
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={showCountryID}
                    onChange={(e) => setshowCountryID(e.target.value)}
                  >
                    {countries
                      ? countries.map((i) => {
                          return (
                            <MenuItem value={i.id}>{i.country_name}</MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
                <FormControl variant="standard" style={{ width: "250px" }}>
                  <InputLabel
                    style={{ fontSize: "0.8rem" }}
                    id="demo-simple-select-standard-label"
                  >
                    Select State
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={showStateID}
                    onChange={(e) => setshowStateID(e.target.value)}
                  >
                    {states
                      ? states.map((i) => {
                          return (
                            <MenuItem value={i.id}>{i.state_name}</MenuItem>
                          );
                        })
                      : ""}
                  </Select>
                </FormControl>
              </div>
              <TableContainer style={{ marginTop: "13px" }} component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderData(currentItems)}
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "center",
                      marginLeft: "20px",
                    }}
                  >
                    {/* dropdown for pagination */}
                    <FormControl
                      className="page_space"
                      variant="standard"
                      sx={{ m: 1, minWidth: 40 }}
                    >
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        value={number}
                        onChange={(e) => {
                          handleLoadMore(e.target.value);
                          setNumber(e.target.value);
                        }}
                        // label="0-5"
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={20}>20</MenuItem>
                        <MenuItem value={30}>30</MenuItem>
                      </Select>
                    </FormControl>
                    <Typography
                      style={{ marginLeft: "30px" }}
                      variant=""
                      component={""}
                    >
                      {indexOfFirstItem + 1} - {indexOfLastItem}&nbsp;
                      Rows&nbsp; of &nbsp;&nbsp;{data.length}
                    </Typography>
                    <ul className="pageNumbers page_space">
                      {indexOfFirstItem > 0 && (
                        <li className="page_button">
                          <ArrowBackIosIcon
                            onClick={handlePrevbtn}
                            disabled={currentPage == pages[0] ? true : false}
                          />
                        </li>
                      )}
                      {pageDecrementBtn}
                      {renderPageNumbers}
                      {pageIncrementBtn}
                      {data.length > indexOfLastItem && (
                        <li className="page_button">
                          <ArrowForwardIosIcon
                            onClick={handleNextbtn}
                            disabled={
                              currentPage == pages[pages.length - 1]
                                ? true
                                : false
                            }
                          />
                        </li>
                      )}
                    </ul>
                  </div>
                </Table>
              </TableContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>

      <Toaster />
    </>
  );
};
export default City;

import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  Box,
  TextField,
  DialogActions,
  Button,
} from "@material-ui/core";
import { Autocomplete } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useProductQuery } from "../../../hooks/product.hooks";
import { useUpdateWebSection } from "../../../hooks/websection.hooks";
import { imagepath_sections } from "../../../utils/constants";
import popMsg from "../../../utils/popup";

const UpdateWebSection = ({ open, setOpen, section }) => {
  const [sectionId, setSectionId] = useState("");
  const [sectionType, setSectionType] = useState();
  const [sectionName, setSectionName] = useState("");
  const [sectionOrder, setSectionOrder] = useState();
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [oldImage, setOldImage] = useState("");
  const [productIds, setProductIds] = useState([]);
  const [products, setProducts] = useState([]);

  const { data: productData } = useProductQuery();
  const { mutateAsync: update } = useUpdateWebSection();
  const queryClient = useQueryClient();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!section) return;
    setSectionId(section.id);
    setSectionName(section.section_name);
    setSectionType(section.section_type);
    setSectionOrder(section.section_order);
    if (section.image) setOldImage(section.image);
    if (section.product_id && section.product_id.length)
      setProductIds(section.product_id);
  }, [section]);

  useEffect(() => {
    const filtered =
      productData &&
      productData.filter((product) => {
        if (productIds.includes(product.id.toString())) return product;
      });
    // console.log("Product data", productData);
    // console.log("Filtered", filtered);
    // console.log("ProductIds", productIds);
    setProducts(filtered);
  }, [productData, productIds]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
    setImagePreview(URL.createObjectURL(file));
  };

  const handleClose = () => {
    setOpen(false);
    setImage(null);
    setImagePreview("");
  };
  const handleSave = () => {
    const section = {
      sectionId,
      sectionName,
      sectionType,
      sectionOrder,
      image,
      productIds,
    };
    update(section, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("web-sliders");
        queryClient.invalidateQueries("mobile-sliders");
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add WebSection</DialogTitle>
      <DialogContent>
        <Box style={{ width: "100%", overflow: "unset" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  placeholder="Section Name"
                  value={sectionName}
                  onChange={(e) => setSectionName(e.target.value)}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  placeholder="Order"
                  value={sectionOrder}
                  oonChange={(e) => {
                    const input = e.target.value;
                    const regex = /^[0-9]{0,6}$/; // Regex to match only digits and limit to 6 characters
                    if (regex.test(input)) {
                      setSectionOrder(input);
                    }
                  }}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel>Section type</InputLabel>
                <Select
                  value={sectionType}
                  onChange={(e) => setSectionType(e.target.value)}
                >
                  <MenuItem value=""></MenuItem>
                  <MenuItem value={0}>Product Slider</MenuItem>
                  <MenuItem value={1}>Product Grid</MenuItem>
                  <MenuItem value={2}>Banner Image</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {oldImage && (
              <Grid item xs={12}>
                <img
                  src={`${imagepath_sections}${oldImage}`}
                  alt="old"
                  style={{ height: "10rem", width: "15rem" }}
                />
              </Grid>
            )}
            {sectionType === 2 && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel style={{ fontSize: "0.8rem" }}>
                    Select Image :
                  </InputLabel>
                  <Input
                    InputLabelProps={{
                      className: classes.textFieldLabel,
                    }}
                    type="file"
                    onChange={handleImageChange}
                  />
                  {imagePreview && (
                    <img
                      style={{
                        maxHeight: "10rem",
                        maxWidth: "15rem",
                        marginTop: "1rem",
                        marginLeft: "10%",
                      }}
                      src={imagePreview}
                      alt={"category-image"}
                    />
                  )}
                </FormControl>
              </Grid>
            )}
            {(sectionType === 0 || sectionType === 1) &&
              products &&
              products.length && (
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      defaultValue={products}
                      onChange={(_, newValue) => {
                        const val = newValue.map((item) => item.id.toString());
                        setProductIds(val);
                        setProducts(newValue);
                      }}
                      getOptionLabel={(option) => option.product_title}
                      multiple={true}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Products" />
                      )}
                      options={productData}
                    />
                  </FormControl>
                </Grid>
              )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateWebSection;

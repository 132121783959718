import { CircularProgress } from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import React, { useEffect } from "react";
import { useState } from "react";
import { Toaster } from "react-hot-toast";
import { useMobileSliderQuery } from "../../../hooks/slider.hooks";
import {
  useDeleteWebSection,
  useWebSectionQueries,
} from "../../../hooks/websection.hooks";
import { filterData } from "../../../utils/constants";
import popMsg from "../../../utils/popup";
import DelBox from "../../ui/DelBox";
import Layout from "../../ui/Layout";
import MyTable from "../../ui/MyTable";
import RowActions from "../../ui/RowActions";
import TopBlock from "../../ui/TopBlock";
import AddWebSection from "./AddWebSection";
import SectionDetail from "./SectionDetail";
import UpdateWebSection from "./UpdateWebSection";

const ManageWebHome = () => {
  const [search, setSearch] = useState("");
  const [openAddForm, setOpenAddForm] = useState(false);
  const [renderedData, setRenderedData] = useState([]);
  const [openUpdateForm, setOpenUpdateForm] = useState(false);
  const [sectionToUpdate, setSectionToUpdate] = useState();
  const [delOpen, setDelOpen] = useState(false);
  const [delId, setDelId] = useState("");

  const [showDetails, setShowDetails] = useState(false);
  const [sectionToShow, setSectionToShow] = useState(null);

  const columnHelper = createColumnHelper();
  const queryClient = useQueryClient();

  // Queries and Mutations
  const { data, isLoading } = useWebSectionQueries();
  const { mutateAsync: deleteSection } = useDeleteWebSection();

  const handleClickEdit = (row) => {
    setSectionToUpdate(row);
    setOpenUpdateForm(true);
  };

  const handleDelete = () => {
    deleteSection(delId, {
      onSuccess: (res) => {
        setDelOpen(false);
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("web_sections");
      },
    });
  };

  const handleShowDetails = (row) => {
    setSectionToShow(row);
    setShowDetails(true);
  };

  const handleClickDelete = (row) => {
    setDelId(row.id);
    setDelOpen(true);
  };

  useEffect(() => {
    filterData("section_name", search, data, setRenderedData);
  }, [search, data]);

  const columns = [
    columnHelper.accessor("serialNumber", {
      header: "S.No",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("section_name", {
      header: "Section Name",
      cell: (info) => {
        const sectionName = info.getValue();
        const handleClick = () => {
          // Handle the click event
          handleShowDetails(info.row.original);
        };
        return (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={handleClick}
          >
            {sectionName}
          </span>
        );
      },
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("section_type", {
      header: "Section Type",
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      cell: (info) => {
        const sectionType = info.getValue();

        return sectionType === 0
          ? "Product Slider"
          : sectionType === 1
          ? "Product Grid"
          : sectionType === 2
          ? "Banner Image"
          : "";
      },
    }),
    columnHelper.accessor("section_order", {
      header: "Display Index",
      footer: (props) => props.column.id,
      cell: (info) => info.getValue(),
      enableColumnFilter: false,
    }),
    columnHelper.display({
      id: "actions",
      header: "Actions",
      cell: (info) => (
        <RowActions
          handleClickDelete={handleClickDelete}
          handleClickEdit={handleClickEdit}
          row={info.row}
        />
      ),
    }),
  ];

  return (
    <Layout title="Web-Sections">
      <div style={{ overflow: "unset" }}>
        <TopBlock
          search={search}
          setSearch={setSearch}
          setOpenAddForm={setOpenAddForm}
        />
        {isLoading && (
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        )}
        {!isLoading && renderedData && renderedData.length && (
          <MyTable columns={columns} data={renderedData} />
        )}
      </div>
      {delOpen && (
        <DelBox
          open={delOpen}
          setOpen={setDelOpen}
          deleteEntry={handleDelete}
        />
      )}
      {openAddForm && (
        <AddWebSection open={openAddForm} setOpen={setOpenAddForm} />
      )}
      {openUpdateForm && (
        <UpdateWebSection
          open={openUpdateForm}
          setOpen={setOpenUpdateForm}
          section={sectionToUpdate}
        />
      )}
      {
        <SectionDetail
          open={showDetails}
          setOpen={setShowDetails}
          section={sectionToShow}
        />
      }
      <Toaster />
    </Layout>
  );
};

export default ManageWebHome;

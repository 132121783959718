import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  TableCell,
  TableRow,
  Box,
  Table,
  DialogActions,
  Button,
} from "@material-ui/core";

const AgencyProfile = ({ agency, open, setOpen }) => {
  if (!agency) return;
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Box sx={{ width: 1, maxWidth: "lg" }}>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>agency Profile</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell>{agency?.company_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Display Name</TableCell>
                  <TableCell>{agency?.display_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company Phone</TableCell>
                  <TableCell>{agency?.company_phone_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Comapny E-mail</TableCell>
                  <TableCell>{agency?.company_email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Name</TableCell>
                  <TableCell>{agency?.contact_person_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Phone</TableCell>
                  <TableCell>{agency?.contact_person_phone_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person E-mail</TableCell>
                  <TableCell>{agency?.contact_person_email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company Address</TableCell>
                  <TableCell>{agency?.company_adress}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Warehouse Address</TableCell>
                  <TableCell>{agency?.company_warehouse_adress}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company GST</TableCell>
                  <TableCell>{agency?.company_gst}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company PAN</TableCell>
                  <TableCell>{agency?.company_pan_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>{agency?.country_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>State</TableCell>
                  <TableCell>{agency?.state_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>City</TableCell>
                  <TableCell>{agency?.city_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Zipcodes</TableCell>
                  <TableCell>
                    {agency &&
                      agency.zipcode &&
                      agency.zipcode.length &&
                      agency.zipcode.map((zp) => (
                        <TableRow> {zp.zipcode}</TableRow>
                      ))}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Reference ID</TableCell>
                  <TableCell>{agency.reference_id}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Service Name</TableCell>
                  <TableCell>{agency.service_name}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default AgencyProfile;

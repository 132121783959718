import {
    Box,
    Button,
    Typography,
  } from "@material-ui/core";
  import "./OrderDetails.css";
  import ArrowBackIcon from "@mui/icons-material/ArrowBack";
  import Paper from "@mui/material/Paper";
  import { useEffect, useState } from "react";
  import toast, { Toaster } from "react-hot-toast";
  import Grid from "@mui/material/Grid";
  import { useNavigate, useParams } from "react-router-dom";
  import Table from "@mui/material/Table";
  import MenuItem from "@mui/material/MenuItem";
  import FormControl from "@mui/material/FormControl";
  import Select from "@mui/material/Select";
  import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
  import EmailIcon from "@mui/icons-material/Email";
  import TableBody from "@mui/material/TableBody";
  import TableCell from "@mui/material/TableCell";
  import TableContainer from "@mui/material/TableContainer";
  import TableHead from "@mui/material/TableHead";
  import TableRow from "@mui/material/TableRow";
  import PersonIcon from "@mui/icons-material/Person";
  import Cookies from "js-cookie";
  // import Sidebar from "../../Container/Sidebar/Sidebar";
  // import Header from "../../Container/Header/Header";
  // import Footer from "../../Container/Footer/Footer";
  import { imagePathProduct as imagePath } from "../../../App";
  import { url } from "../../../App";
  import DateRangeIcon from "@mui/icons-material/DateRange";
  import Vendor_Header from "../mainPage/Vendor_Header/Vendor_Header";
  import Vendor_Footer from "../mainPage/Vendor_Footer/Vendor_Footer";
  import Vendor_Sidebar from "../mainPage/vendor_sidebar/Vendor_Sidebar";
  
  const OrderDetailsVendor = () => {
    const [status, setStatus] = useState("");
    const [userdata, setUserData] = useState([]);
    const [orderdata, setOrderData] = useState([]);
    const [productdata, setProductData] = useState([]);
    const [orderdate, setorderdate] = useState("");
    const params = useParams();
    const token = Cookies.get("content");
    const navigate = useNavigate()
    function getOrderDetails(id) {
      let config = {
        method: "GET",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      fetch(`${url}/api/vendor/order/detail/${id}`, config)
        .then((res) => res.json())
        .then((result) => {
          // console.log("product customer list user", result);
          if (result.order === "No Available") return null;
          setUserData(result.user);
          setOrderData(result.order);
          setProductData(result.product);
        });
    }
  
    useEffect(() => {
      getOrderDetails(params.id);
    }, []);
  
    useEffect(() => {
      if (orderdata.created_at) {
        var isoDateTime = new Date(orderdata.created_at);
        var localDateTime =
          isoDateTime.toLocaleDateString() +
          " " +
          isoDateTime.toLocaleTimeString();
        // console.log("orderdata.created_at", localDateTime);
        setorderdate(localDateTime);
      }
    }, [orderdata.created_at]);
  
    // /////////////////////////////////status update///////////////////////////////////////////////
    const updateStatus = (status, id) => {
      const contryConfig = {
        method: "POST",
        headers: {
          authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status }),
      };
      fetch(`${url}/api/vendor/order/status/${id}`, contryConfig).then(
        (response) => {
          response.json().then((res) => {
            if (!res.success) {
              return toast.error(<span className="text-lg">{res.message}</span>);
            }
            if (res.success) {
              getOrderDetails()
            }
          });
        }
      );
    };
  
    const renderData = (data) => {
      return (
        <Table>
          <TableHead>
            {data.map((i, index) => {
              // console.log("iiiiiiiiiiiiiiiiii", i);
              return (
                <TableRow
                  key={index}
                  className="list_row"
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {/* {i.coupon_code} */}
                    <img
                      src={`${imagePath}${i.images}`}
                      alt=""
                      style={{ width: "150px", height: "100px" }}
                    />
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {i.product_title}{" "}
                    {i.quantify ? `(${i.quantify} ${i.unit})` : ""}
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {parseFloat(i.final_price).toFixed(2) + "₹"}
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {i.quantity}
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    <b>
                      {parseFloat(i.final_price * i.quantity).toFixed(2) + "₹"}
                    </b>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableHead>
        </Table>
      );
    };
  
    return (
      <>
        <div className="container">
          <Vendor_Sidebar />
          <Vendor_Header name="Product Order Details" />
          <div className="content_category2">
            {/* Details */}
            <div className="orderDetailsDiv1">
              <div className="orderDetailsDiv2">
                {/* order Details */}
                <Box
                  sx={{
                    "& > :not(style)": {
                      m: 1,
                      // minWidth: "50rem",
                      padding: "2rem",
                      borderRadius: "5px",
                    },
                  }}
                >
                  <Paper elevation={3}>
                    <Typography>
                      <h3>Product Order Details</h3>
                    </Typography>
                    <hr />
                    <div className="productdetail">
                      {/* <div className="productdetail1"> */}
                      {renderData(productdata)}
                      {/* </div> */}
                    </div>
                    <hr />
                    <div className="orderdetaildiv1">
                      <div className="orderdetaildiv2">
                        <Typography>
                          <h4>
                            Payment Method :{" "}
                            {orderdata.payment_method === 0 ? (
                              <span style={{ color: "skyblue" }}>
                                Cash on Delivery
                              </span>
                            ) : orderdata.payment_method === 1 ? (
                              <span style={{ color: "skyblue" }}>Online</span>
                            ) : (
                              ""
                            )}
                          </h4>
                        </Typography>
                        <br />
                        <Typography>
                          <h4>
                            Order Status :{" "}
                            {orderdata.delivery_status === 0 ? (
                              <span style={{ color: "orange" }}>Pending</span>
                            ) : orderdata.delivery_status === 1 ? (
                              <span style={{ color: "blue" }}>Accepted</span>
                            ) : orderdata.delivery_status === 2 ? (
                              <span style={{ color: "orange" }}>Shipped</span>
                            ) : orderdata.delivery_status === 3 ? (
                              <span style={{ color: "orange" }}>
                                Out for Delivery
                              </span>
                            ) : orderdata.delivery_status === 4 ? (
                              <span style={{ color: "green" }}>Delivered</span>
                            ) : orderdata.delivery_status === 5 ? (
                              <span style={{ color: "red" }}>Canceled</span>
                            ) : (
                              ""
                            )}
                          </h4>
                        </Typography>
                        <br />
                        <Typography>
                          <h4>Order Note : {orderdata.order_note}</h4>
                        </Typography>
                      </div>
                      <div className="orderdetaildiv3">
                        {/* <span>
                        <Typography>Items Price:</Typography>
                        <Typography>Tax / Vat:</Typography>
                        <Typography>Addon Cost:</Typography>
                        <Typography>Subtotal:</Typography>
                        <Typography>Coupon Discount:</Typography>
                        <Typography>Delivery Fee:</Typography>
                        <Typography>
                          <h3>Total:</h3>
                        </Typography>
                        </span> */}
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ borderBottom: "none" }}>
                                  Items Price:
                                </TableCell>
                                <TableCell style={{ borderBottom: "none" }}>
                                  {parseFloat(orderdata.total_price).toFixed(2) +
                                    "₹"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ borderBottom: "none" }}>
                                  Tax / Vat:
                                </TableCell>
                                <TableCell>
                                  {parseFloat(orderdata.tax_amount).toFixed(2) +
                                    "₹"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ borderBottom: "none" }}>
                                  <b> Subtotal:</b>
                                </TableCell>
                                <TableCell style={{ borderBottom: "none" }}>
                                  <b>
                                    {parseFloat(
                                      orderdata.total_price + orderdata.tax_amount
                                    ).toFixed(2) + "₹"}
                                  </b>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ borderBottom: "none" }}>
                                  Coupon Discount:
                                </TableCell>
                                <TableCell style={{ borderBottom: "none" }}>
                                  {"-"}
                                  {orderdata.discounted_amount
                                    ? parseFloat(
                                        orderdata.discounted_amount
                                      ).toFixed(2) + "₹"
                                    : "00.00₹"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ borderBottom: "none" }}>
                                  Delivery Fee:
                                </TableCell>
                                <TableCell>
                                  {orderdata.delivery_fee
                                    ? parseFloat(orderdata.delivery_fee).toFixed(
                                        2
                                      ) + "₹"
                                    : "00.00₹"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell style={{ borderBottom: "none" }}>
                                  <h3>Total:</h3>
                                </TableCell>
                                <TableCell style={{ borderBottom: "none" }}>
                                  <h3>
                                    {parseFloat(orderdata.paid_amount).toFixed(
                                      2
                                    ) + "₹"}
                                    {/* {parseFloat(
                                      orderdata.total_price +
                                        orderdata.tax_amount +
                                        orderdata.delivery_fee -
                                        orderdata.discounted_amount
                                    ).toFixed(2) + "₹"} */}
                                  </h3>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableContainer>
                      </div>
                    </div>
                  </Paper>
                </Box>
              </div>
              <div className="orderDetailsDiv3">
                {/* Customer details */}
                <Box
                  sx={{
                    "& > :not(style)": {
                      m: 1,
                      // minWidth: "25rem",
                      padding: "2rem",
                      borderRadius: "5px",
                    },
                  }}
                >
                  <Paper elevation={3}>
                    <Grid>
                      <Typography>
                        <h3>Customer</h3>
                      </Typography>
                    </Grid>
                    <hr />
                    <Grid>
                      <Typography
                        style={{
                          display: "flex",
                          alignItem: "center",
                          margin: "1rem 0 1rem 0",
                        }}
                      >
                        <PersonIcon /> <h4>{userdata.customer_name}</h4>
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography style={{ margin: "1rem 0 1rem 0" }}>
                        <h4>Contact Info</h4>
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          display: "flex",
                          alignItem: "center",
                          margin: "0rem 0 1rem 0",
                        }}
                      >
                        <EmailIcon />
                        {userdata.customer_email}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          display: "flex",
                          alignItem: "center",
                          margin: "0rem 0 1rem 0",
                        }}
                      >
                        <LocalPhoneIcon />
                        {userdata.customer_phone}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          display: "flex",
                          alignItem: "center",
                          margin: "1rem 0 1rem 0",
                        }}
                      >
                        <h4>Delivery Address</h4>
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          display: "flex",
                          alignItem: "center",
                          margin: "0rem 0 1rem 0",
                        }}
                      >
                        {userdata.customer_name}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          display: "flex",
                          alignItem: "center",
                          margin: "0rem 0 1rem 0",
                        }}
                      >
                        {userdata.customer_phone}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          display: "flex",
                          alignItem: "center",
                          margin: "0rem 0 1rem 0",
                        }}
                      >
                        {userdata.type}
                      </Typography>
                    </Grid>
                    <Grid>
                      <Typography
                        style={{
                          display: "flex",
                          alignItem: "center",
                          margin: "0rem 0 1rem 0",
                        }}
                      >
                        {userdata.address +
                          ", " +
                          userdata.city_name +
                          ",  " +
                          userdata.state_name +
                          ", " +
                          userdata.country_name +
                          ", " +
                          userdata.zipcode}
                      </Typography>
                    </Grid>
                    {/* <div>
                      <div>
                        <TableContainer component={Paper}>
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell style={{ borderBottom: "none" }}>
                                  <span style={{width: "20px"}}>
                                    <PersonIcon /> {userdata.customer_name}
                                  </span>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </TableContainer>
                      </div>
                    </div> */}
                  </Paper>
                </Box>
              </div>
            </div>
  
            {/* list heading and add btn */}
            <div className="headADD_states">
              <div className="listListPo">
                <span
                  className="list_heading"
                  onClick={() => {
                    window.location.reload(false);
                  }}
                  style={{
                    marginLeft: "6px",
                    marginTop: "14px",
                    cursor: "pointer",
                  }}
                >
                  Product Order Details
                </span>
                <div className="listListPo2">
                  <span
                    className="list_headingPO"
                    style={{
                      // marginLeft: "2px",
                      marginTop: "14px",
                      cursor: "pointer",
                      color: "grey",
                    }}
                  >
                    Order Date: <DateRangeIcon />
                    {orderdate}
                  </span>
                  <span
                    className="list_headingPO"
                    style={{
                      // marginLeft: "2px",
                      marginTop: "14px",
                      cursor: "pointer",
                      // backgroundColor: "rgba(0, 201, 219, .1)",
                    }}
                  >
                    {orderdata.delivery_status === 0 ? (
                      <span style={{ color: "orange" }}>Pending</span>
                    ) : orderdata.delivery_status === 1 ? (
                      <span style={{ color: "blue" }}>Accepted</span>
                    ) : orderdata.delivery_status === 2 ? (
                      <span style={{ color: "orange" }}>Shipped</span>
                    ) : orderdata.delivery_status === 3 ? (
                      <span style={{ color: "orange" }}>Out for Delivery</span>
                    ) : orderdata.delivery_status === 4 ? (
                      <span style={{ color: "green" }}>Delivered</span>
                    ) : orderdata.delivery_status === 5 ? (
                      <span style={{ color: "red" }}>Canceled</span>
                    ) : (
                      ""
                    )}
                  </span>
                  <span
                    className="list_headingPO"
                    style={{
                      // marginLeft: "2px",
                      marginTop: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {orderdata.payment_status === 1 ? (
                      <span style={{ color: "green" }}>Paid</span>
                    ) : orderdata.payment_status === 0 ? (
                      <span style={{ color: "red" }}>Unpaid</span>
                    ) : (
                      ""
                    )}
                  </span>
                  <span
                    className="list_headingPO"
                    style={{
                      // marginLeft: "2px",
                      marginTop: "14px",
                      cursor: "pointer",
                    }}
                  >
                    {orderdata.payment_method === 0 ? (
                      <span style={{ color: "skyblue" }}>Cash on Delivery</span>
                    ) : orderdata.payment_method === 1 ? (
                      <span style={{ color: "skyblue" }}>Online</span>
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </div>
              <div>
                {/* <TextField
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    id="outlined-basic"
                    placeholder="Search by Order Number"
                    variant="outlined"
                    style={{ marginRight: "15px" }}
                  /> */}
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  style={{ marginTop: "0rem" }}
                >
                  <Select
                    value={status}
                    onChange={(e) => {
                      setStatus(e.target.value);
                      updateStatus(e.target.value, orderdata.id);
                    }}
                    displayEmpty
                    //   label= "Update Status"
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem disabled value="">
                      Update product Order Status
                    </MenuItem>
                    <MenuItem value={0}>Pending</MenuItem>
                    <MenuItem value={1}>Accepted</MenuItem>
                    <MenuItem value={2}>Shipped</MenuItem>
                    <MenuItem value={3}>Out for Delivery</MenuItem>
                    <MenuItem value={4}>Delivered</MenuItem>
                    <MenuItem value={5}>Canceled</MenuItem>
                  </Select>
                </FormControl>
                <Button
                  size="large"
                  className="addbtn"
                  variant="contained"
                  style={{
                    height: "55px",
                    backgroundColor: "#023F9E",
                    color: "white",
                  }}
                  startIcon={<ArrowBackIcon />}
                  onClick={() => navigate("/allorder")}
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
          <Vendor_Footer />
        </div>
  
        <Toaster />
      </>
    );
  };
  
  export default OrderDetailsVendor;
  
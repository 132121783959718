import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@material-ui/core";

const DelBox = ({ open, setOpen, deleteEntry }) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    deleteEntry();
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="delete-dialog-title"
      maxWidth="xs"
      style={{
        backgroundColor: "rgba(0, 0, 0, 0.5)",
      }}
    >
      <DialogTitle id="delete-dialog-title">Delete Confirmation</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this item?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleDelete} color="secondary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DelBox;

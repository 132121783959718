import { IconButton, Switch } from "@material-ui/core";
import { ModeEdit } from "@mui/icons-material";
import { Delete } from "@mui/icons-material";
import { createColumnHelper } from "@tanstack/react-table";
import { imagepath_categories } from "../../../utils/constants";
import { useUpdateCatStatus } from "../../../hooks/category.hooks";
import { useQueryClient } from "@tanstack/react-query";
import popMsg from "../../../utils/popup";

export const CategoryColumns = (
  setDelOpen,
  setDelId,
  setOpenUpdate,
  setUpdateCategory
) => {
  const columnHelper = createColumnHelper();

  const queryClient = useQueryClient();

  const { mutateAsync: updateStatus } = useUpdateCatStatus();

  const handleClickEdit = (row) => {
    setOpenUpdate(true);
    setUpdateCategory(row);
  };
  const handleClickDelete = (row) => {
    setDelOpen(true);
    setDelId(row.id);
  };

  const handleChangeStatus = (_, val, row) => {
    const category = {
      id: row.id,
      status: val,
    };

    // Optimistically update the UI immediately
    row.is_status = val;

    updateStatus(category, {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["categories"]);
        popMsg(res.data.message, res.data.success);
      },
      onError: (err) => {
        console.error("Error updating category status:", err);
        // Revert the UI state if needed
        row.is_status = !val;
      },
    });
  };

  return [
    columnHelper.accessor("serialNumber", {
      header: "Serial Number",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),
    columnHelper.accessor("category_name", {
      header: "Category Name",
      cell: (info) => info.getValue(),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
    }),

    columnHelper.accessor("image", {
      header: "Image",
      cell: (info) => (
        <img
          style={{ maxWidth: "6rem", maxHeight: "6rem", borderRadius: "10px" }}
          src={`${imagepath_categories}${info.getValue()}`}
          alt={info.getValue()}
        />
      ),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor("is_status", {
      header: "Status",
      cell: (info) => (
        <Switch
          onChange={(e, val) => handleChangeStatus(e, val, info.row.original)}
          defaultChecked={info.getValue()}
        />
      ),
      footer: (props) => props.column.id,
      enableColumnFilter: false,
      enableSorting: false,
    }),
    {
      header: "Actions",
      filterable: false,
      cell: (info) => (
        <div>
          <IconButton
            key={`edit-${info.row.original.id}`}
            onClick={() => handleClickEdit(info.row.original)}
          >
            <ModeEdit />
          </IconButton>

          <IconButton
            key={`delete-${info.row.original.id}`}
            onClick={() => handleClickDelete(info.row.original)}
          >
            <Delete />
          </IconButton>
        </div>
      ),
    },
  ];
};

import { Table, TableCell, TableRow, TableContainer } from "@material-ui/core";
import React from "react";
import { formatDate } from "../../../utils/constants";
import Window from "../../ui/Window";

const CustomerDetails = ({ open, setOpen, details }) => {
  const handelClose = () => {
    setOpen(false);
  };
  return (
    <Window open={open} setOpen={handelClose} title="Customer Details">
      <div>
        {details && (
          <TableContainer>
            <Table>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>{details.name}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Email</TableCell>
                <TableCell>{details.email}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Address</TableCell>
                <TableCell>{details.address}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Joined on</TableCell>
                <TableCell>
                  {formatDate(details.created, true, false)}
                </TableCell>
              </TableRow>
            </Table>
          </TableContainer>
        )}
      </div>
    </Window>
  );
};

export default CustomerDetails;

import React from "react";
import { Table, TableCell, TableContainer, TableRow } from "@material-ui/core";

const DeliveryAddreddTable = ({ address }) => {
  return (
    <div
      style={{
        padding: "1rem 0.5rem",
        border: "0.5px solid #333",
        borderRadius: "0.6rem",
        margin: "1rem 0rem",
      }}
    >
      <h4>Delivery Address :</h4>
      <TableContainer>
        <Table>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>{address?.customer_name}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Phone</TableCell>
            <TableCell>{address?.customer_phone}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Address</TableCell>
            <TableCell>{`${address?.address},${address?.city_name},${address?.state_name},${address?.country_name}-${address?.zipcode}`}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Type</TableCell>
            <TableCell>{address?.type}</TableCell>
          </TableRow>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DeliveryAddreddTable;

import axios from "../axios";
import { apiRoutes } from "../utils/apiRoutes";

async function getAllCustomers() {
  try {
    const response = await axios.get(`${apiRoutes.getCustomers}`);
    const data = response.data;
    return data.user_list;
  } catch (err) {
    console.error(err);
  }
}

export { getAllCustomers };

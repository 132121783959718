import React from "react";
import { useNavigate } from "react-router-dom";
import {
  baseUrl,
  imagepath_product,
  imagepath_sections,
} from "../../../utils/constants";
import Window from "../../ui/Window";

const SectionDetail = ({ open, setOpen, section }) => {
  const navigate = useNavigate();
  return (
    <Window open={open} setOpen={setOpen} title={section?.section_name}>
      <div>
        {section?.section_type === 2 ? (
          <div>
            <img
              style={{ maxWidth: "35rem" }}
              alt={section.image}
              src={`${imagepath_sections}${section?.image}`}
            />
          </div>
        ) : (
          ""
        )}
        {section?.section_type === 0 || section?.section_type === 1 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "1rem",
            }}
          >
            {section.product.map((item) => {
              return (
                <div
                  key={item.id}
                  style={{
                    border: "0.5px solid #eee",
                    padding: "0.5rem",
                    borderRadius: "1rem",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    gap: "0.5rem",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    navigate(`/manage_product/details/${item.id}`);
                  }}
                >
                  <img
                    alt={item.images[0]}
                    src={`${baseUrl}${imagepath_product}${item?.images[0]}`}
                    style={{ width: "10rem" }}
                  />
                  <h3>{item?.product_title}</h3>
                </div>
              );
            })}
          </div>
        ) : (
          ""
        )}
      </div>
    </Window>
  );
};

export default SectionDetail;

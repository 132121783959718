import React, { useEffect, useState } from "react";
import {
  DialogContent,
  DialogTitle,
  DialogActions,
  FormControl,
  Grid,
  InputLabel,
  Select,
  Button,
  MenuItem,
  TextField,
  Input,
  makeStyles,
  Dialog,
} from "@material-ui/core";
import { baseUrl, imagepath_subSubCat } from "../../../utils/constants";
import popMsg from "../../../utils/popup";
import { useCategoriesQuery } from "../../../hooks/category.hooks";
import { useSubCategoryQuery } from "../../../hooks/subcategory.hooks";
import { useUpdateSubSubCatData } from "../../../hooks/ssc.hooks";
import { useQueryClient } from "@tanstack/react-query";

const UpdateSubSubCategory = ({ open, setOpen, subSubCat }) => {
  const [sscId, setSscId] = useState();
  const [category, setCategory] = useState();
  const [subCategory, setSubCategory] = useState();
  const [subSubCategoryName, setSubSubCategoryName] = useState();
  const [oldImage, setOldImage] = useState();
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [filteredSubCategories, setFilteredSubCategories] = useState([]);

  const queryClient = useQueryClient();

  // Queries
  const {
    data: categoryData,
    isLoading: isCategoryLoading,
    isError: isCategoryError,
  } = useCategoriesQuery();
  const { data: allSubCategories, isLoading: isAllSubCategoriesLoading } =
    useSubCategoryQuery();
  const { mutateAsync: updateSubSubCategory } = useUpdateSubSubCatData();

  // Filtering sub-categories wrt category id
  useEffect(() => {
    if (!category) return;
    const filteredSubCategories = allSubCategories.filter(
      (subcat) => subcat.category_id === category
    );
    setFilteredSubCategories([...filteredSubCategories]);
  }, [category]);

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));

  const classes = useStyles();

  useEffect(() => {
    if (!subSubCat) return;
    setSscId(subSubCat.id);
    setCategory(subSubCat.category_id);
    setSubCategory(subSubCat.sub_category_id);
    setSubSubCategoryName(subSubCat.sub_sub_category_name);
    setOldImage(subSubCat.image);
  }, [subSubCat]);

  // Handlers
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };

  const handleClose = () => {
    setOpen(false);
    // reset();
    setImage(null);
    setImagePreview("");
  };

  const handleSave = () => {
    const ssc = {
      id: sscId,
      category_id: category,
      sub_category_id: subCategory,
      sub_sub_category_name: subSubCategoryName,
      image,
    };
    updateSubSubCategory(ssc, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("sub-sub-categories");
      },
    });
  };
  //   Functions
  // function reset() {
  //   setCategory("");
  //   setSubCategory("");
  //   setSubSubCategoryName("");
  //   setImage(null);
  //   setImagePreview("");
  //   setOldImage("");
  // }
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Update Sub-Sub-Category</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Select Category
              </InputLabel>
              <Select
                value={category}
                onChange={(e) => {
                  setCategory(e.target.value);
                  setSubCategory("");
                }}
              >
                <MenuItem value=""></MenuItem>
                {isCategoryLoading ? (
                  <MenuItem disabled>Loading categories...</MenuItem>
                ) : isCategoryError ? (
                  <MenuItem disabled>Error loading categories.</MenuItem>
                ) : (
                  categoryData &&
                  categoryData.map((cat) => (
                    <MenuItem value={cat.id} key={cat.id}>
                      {cat.category_name}
                    </MenuItem>
                  ))
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Select Sub-Category
              </InputLabel>
              <Select
                value={subCategory}
                onChange={(e) => {
                  setSubCategory(e.target.value);
                }}
                disabled={!category || isAllSubCategoriesLoading}
              >
                <MenuItem value=""></MenuItem>
                {filteredSubCategories && filteredSubCategories.length > 0 ? (
                  filteredSubCategories.map((subCat) => (
                    <MenuItem value={subCat.id} key={subCat.id}>
                      {subCat.sub_category_name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem disabled>No sub-categories available</MenuItem>
                )}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="sub sub-category name"
              value={subSubCategoryName}
              onChange={(e) => setSubSubCategoryName(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          {oldImage && (
            <Grid item xs={12}>
              <img
                style={{ width: "15rem", height: "10rem" }}
                alt="old_sub_cat_img"
                src={`${baseUrl}${imagepath_subSubCat}${oldImage}`}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Select Image :
              </InputLabel>
              <Input
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
                type="file"
                onChange={handleImageChange}
              />
              {imagePreview && (
                <img
                  style={{
                    height: "10rem",
                    width: "15rem",
                    marginTop: "1rem",
                    marginLeft: "10%",
                  }}
                  src={imagePreview}
                  alt={"service-image"}
                />
              )}
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button variant="outlined" onClick={handleSave} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateSubSubCategory;

import { IconButton } from "@material-ui/core";
import Delete from "@mui/icons-material/Delete";
import ModeEdit from "@mui/icons-material/ModeEdit";
import React from "react";

const RowActions = ({ row, handleClickEdit, handleClickDelete }) => {
  return (
    <div>
      <IconButton
        key={`edit=${row.original.id}`}
        onClick={() => handleClickEdit(row.original)}
      >
        <ModeEdit />
      </IconButton>
      <IconButton
        key={`delete-${row.original.id}`}
        onClick={() => handleClickDelete(row.original)}
      >
        <Delete />
      </IconButton>
    </div>
  );
};

export default RowActions;

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addNewSubCategory,
  deleteSubCategoryData,
  getSubCategories,
  updateSubCategoryData,
  updateSubCatStatus,
} from "../services/product.service";

const useSubCategoryQuery = () => {
  return useQuery({
    queryKey: ["sub-categories"],
    queryFn: getSubCategories,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useAddSubCategoryData = () => {
  const queryClient = useQueryClient();
  return useMutation(addNewSubCategory, {
    onSettled: () => {
      queryClient.invalidateQueries("sub-categories");
    },
    onError: (error) => {
      console.error("error when adding new sub-category", error);
    },
  });
};

const useUpdateSubCategoryData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSubCategoryData, {
    onSettled: () => {
      queryClient.invalidateQueries("sub-categories");
    },
    onError: (error) => {
      console.error("error when updating sub-category", error);
    },
  });
};

const useDeleteSubCategory = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteSubCategoryData, {
    onSettled: () => {
      queryClient.invalidateQueries("sub-categories");
    },
    onError: (error) => {
      console.error("error when Deleting sub-category", error);
    },
  });
};

const useUpdateSubCategoryStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateSubCatStatus, {
    onSettled: () => {
      queryClient.invalidateQueries("sub-categories");
    },
    onError: (error) => {
      console.error("error when changing status - sub-category", error);
    },
  });
};

export {
  useSubCategoryQuery,
  useAddSubCategoryData,
  useUpdateSubCategoryData,
  useDeleteSubCategory,
  useUpdateSubCategoryStatus,
};

import { Button, makeStyles, TextField, Typography } from "@material-ui/core";
import { useCookies } from "react-cookie";
import "../../Login.css";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import userProfileName from "../Redux/action/userProfileName";
import { baseUrl } from "../../utils/constants";
import { useLogin } from "../../hooks/login.hooks";
const Login = () => {
  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
    textfieldClass: {
      "& .MuiInput-input": {
        "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
        },
      },
    },
  });
  const classes = useStyle();
  const [cookies, setCookie, removeCookie] = useCookies([]);
  let [email, setEmail] = useState("");
  let [password, setPassword] = useState("");
  removeCookie("content");
  removeCookie("userID");
  removeCookie("Role");
  let navigate = useNavigate();
  let dispatch = useDispatch();

  const { mutateAsync: login } = useLogin();

  async function loginHandler() {
    let payload = {
      email,
      password,
    };
    login(payload, {
      onSuccess: (res) => {
        if (res.data.success) {
          console.log("entered this block");
          let token = res.data.token;
          let role = res.data.user_info[0].user_type;
          setCookie("content", token);
          setCookie("userID", res.data.user_info[0].id);
          setCookie("Role", res.data.user_info[0].user_type);
          setCookie("userName", res.data.user_info[0].name);
          dispatch(userProfileName(res.data.user_info[0].name));
          if (role === 1) {
            navigate(`/home`);
            window.location.reload(false);
          } else if (role === 3) {
            navigate("/vendor/main");
            window.location.reload(false);
          } else if (role === 2) {
            navigate("/agency/main");
            window.location.reload(false);
          }
        }
      },
    });
  }
  const handleInputEnter = (e) => {
    console.log("called");
    if (e.keyCode === 13) loginHandler();
  };
  return (
    <>
      <div className="Login">
        <div className="abslogo"></div>
        <div className="Login_inner">
          <div className="Login_logo">
            <div className="logo">
              <img
                style={{ width: "200px", height: "250px" }}
                alt="Shapier-logo"
                src={require("./spr.png")}
              />
            </div>
          </div>
          <div className="loginConfig">
            <Typography
              variant="h3"
              style={{ marginBottom: "15px", color: "#023F9E", fontFamily: "" }}
            >
              Login
            </Typography>
            <div>
              <div>
                <TextField
                  value={email}
                  className={classes.textfieldClass}
                  type={email}
                  color="primary"
                  onKeyUp={handleInputEnter}
                  onChange={(e) => setEmail(e.target.value)}
                  style={{ width: "440px" }}
                  placeholder="Entre your e-mail address"
                  // id="outlined-basic"
                  variant="outlined"
                />
              </div>
              <div style={{ marginTop: "25px" }}>
                <TextField
                  style={{ width: "440px" }}
                  placeholder="Password"
                  value={password}
                  onKeyUp={handleInputEnter}
                  onChange={(e) => setPassword(e.target.value)}
                  type={"password"}
                  id="outlined-basic"
                  variant="outlined"
                />
              </div>
            </div>
            <div style={{ marginTop: "25px" }}>
              <Button
                onClick={loginHandler}
                className="loginbtn"
                style={{ marginTop: "10px", width: "440px", color: "white" }}
                size="large"
                variant="contained"
              >
                Login
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Toaster />
    </>
  );
};

export default Login;

import axios from "axios";
import Cookies from "js-cookie";
import { baseUrl } from "../utils/constants";
import popMsg from "../utils/popup";

const api = axios.create({
  baseURL: baseUrl,
});

api.interceptors.request.use((config) => {
  const token = Cookies.get("content");
  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      const { data } = error.response;

      if (!data.success) {
        if (data.message) {
          setTimeout(() => {
            popMsg(data.message, false);
          }, 100);
        } else if (data.errors && data.errors.length > 0) {
          // Handle the errors array from the backend globally
          data.errors.forEach((error) => {
            setTimeout(() => {
              popMsg(`${error.code}: ${error.message}`, false);
            }, 100);
          });
        }
      }
    }

    return Promise.reject(error);
  }
);

export default api;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";
import popMsg from "../../../utils/popup";
import { updateAgency } from "../../../services/agency.service";
import {
  getCities,
  getCountries,
  getStates,
  getZipcodes,
} from "../../../services/address.service";
import { Autocomplete } from "@mui/material";
import { getServices } from "../../../services/serviceProduct.service";
import { useUpdateAgencyData } from "../../../hooks/agency.hooks";

const UpdateAgency = ({ open, setOpen, updatedAgency, selectedAgency }) => {
  const [companyName, setCompanyName] = useState("");
  const [agencyId, setAgencyId] = useState("");
  const [displayName, setDisplayName] = useState("");
  const [mobile, setMobile] = useState("");
  const [companyEmail, setCompanyEmail] = useState("");
  const [contactPersonName, setContactPersonName] = useState("");
  const [contactPersonPhone, setContactPersonPhone] = useState("");
  const [contactPersonEmail, setContactPersonEmail] = useState("");
  const [companyAddress, setCompanyAddress] = useState("");
  const [warehouseAddress, setWarehouseAddress] = useState("");
  const [companyGST, setCompanyGST] = useState("");
  const [companyPAN, setCompanyPAN] = useState("");

  const [zipcodeIds, setZipcodeIds] = useState([]);
  const [zipcodes, setZipcodes] = useState([]);

  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  // const [zipcode, setZipcode] = useState([]);
  const [serviceId, setServiceId] = useState();
  const [referenceId, setReferenceId] = useState();

  const [countryData, setCountryData] = useState([]);
  const [stateData, setStateData] = useState([]);
  const [cityData, setCityData] = useState([]);
  const [zipData, setZipData] = useState([]);
  const [serviceData, setServiceData] = useState([]);

  const { mutateAsync: updateAgency } = useUpdateAgencyData();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  //   UseEffects

  useEffect(() => {
    if (!selectedAgency) return;
    const zp = selectedAgency.zipcode.map((zp) => {
      return {
        id: zp.id,
        name: zp.zipcode,
      };
    });
    setAgencyId(selectedAgency.id);
    setReferenceId(selectedAgency.reference_id);
    setCompanyName(selectedAgency.company_name);
    setDisplayName(selectedAgency.display_name);
    setMobile(selectedAgency.company_phone_number);
    setCompanyEmail(selectedAgency.company_email);
    setContactPersonName(selectedAgency.contact_person_name);
    setContactPersonPhone(selectedAgency.contact_person_phone_number);
    setContactPersonEmail(selectedAgency.contact_person_email);
    setCompanyAddress(selectedAgency.company_adress);
    setWarehouseAddress(selectedAgency.company_warehouse_adress);
    setCompanyGST(selectedAgency.company_gst);
    setCompanyPAN(selectedAgency.company_pan_number);
    setCountry(selectedAgency.country_id);
    setState(selectedAgency.state_id);
    setCity(selectedAgency.city_id);
    setZipcodeIds(selectedAgency.zipcode_id);
    // setSelectedZipcodes(selectedAgency.zipcode.map((zp) => zp.id).join(","));
    setServiceId(selectedAgency.service_id);
  }, [selectedAgency]);

  useEffect(() => {
    getCountryData();
    getServiceData();
  }, []);
  useEffect(() => {
    setStateData(null);
    setCityData(null);
    setZipData(null);
    if (country) getStatesDate(country);
  }, [country]);
  useEffect(() => {
    setCityData(null);
    setZipData(null);
    if (state) getCityData(state);
  }, [state]);
  useEffect(() => {
    setZipData(null);
    if (city) getZipData(city);
  }, [city]);

  useEffect(() => {
    if (!zipData) return;
    const filtered =
      zipData &&
      zipData?.filter((zip) => {
        if (zipcodeIds?.includes(zip?.id)) return zip;
      });
    setZipcodes(filtered);
  }, [zipData, zipcodeIds]);

  // Handlers
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    update();
  };

  async function update() {
    let payload = {
      agencyId,
      company_name: companyName,
      display_name: displayName,
      company_phone_number: mobile,
      company_email: companyEmail,
      contact_person_name: contactPersonName,
      contact_person_phone_number: contactPersonPhone,
      contact_person_email: contactPersonEmail,
      company_adress: companyAddress,
      company_warehouse_adress: warehouseAddress,
      company_gst: companyGST,
      company_pan_number: companyPAN,
      country_id: country,
      state_id: state,
      city_id: city,
      zipcode_id: JSON.stringify(zipcodeIds),
      service_id: serviceId,
      reference_id: referenceId,
    };
    updateAgency(payload, {
      onSuccess: (res) => {
        popMsg(res.data.message, res.data.success);
        if (res.data.success) updatedAgency(true);
        handleClose();
      },
    });
  }

  async function getCountryData() {
    const data = await getCountries();
    setCountryData(data);
  }
  async function getStatesDate(countryId) {
    const data = await getStates(countryId);
    setStateData(data);
  }
  async function getCityData(stateId) {
    const data = await getCities(stateId);
    setCityData(data);
  }
  async function getZipData(cityId) {
    const data = await getZipcodes(cityId);
    setZipData(data);
  }

  async function getServiceData() {
    const data = await getServices();
    let filtered = data.map((service) => {
      return {
        id: service.id,
        name: service.service_name,
      };
    });
    setServiceData(filtered);
  }

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Update Agency</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Company Name"
              value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Display Name"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Mobile Number"
              value={mobile}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const numbersOnly = enteredValue.replace(/[^0-9]/g, "");
                const truncatedValues = numbersOnly.slice(0, 11);
                setMobile(truncatedValues);
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Company E-mail"
              value={companyEmail}
              onChange={(e) => setCompanyEmail(e.target.value)}
              type="email"
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Contact Person Name"
              value={contactPersonName}
              onChange={(e) => setContactPersonName(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Contact Person Phone"
              value={contactPersonPhone}
              onChange={(e) => {
                const enteredValue = e.target.value;
                const numbersOnly = enteredValue.replace(/[^0-9]/g, "");
                const truncatedValues = numbersOnly.slice(0, 11);
                setContactPersonPhone(truncatedValues);
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Contact Person Email"
              value={contactPersonEmail}
              onChange={(e) => setContactPersonEmail(e.target.value)}
              type="email"
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Company Address"
              value={companyAddress}
              onChange={(e) => setCompanyAddress(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Warehouse Address"
              value={warehouseAddress}
              onChange={(e) => setWarehouseAddress(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Company GST"
              value={companyGST}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphanumericValue = inputValue
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toUpperCase();
                const trimmedValue = alphanumericValue.slice(0, 15);
                setCompanyGST(trimmedValue);
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Company PAN"
              value={companyPAN}
              onChange={(e) => {
                const inputValue = e.target.value;
                const alphanumericValue = inputValue
                  .replace(/[^a-zA-Z0-9]/g, "")
                  .toUpperCase();
                const trimmedValue = alphanumericValue.slice(0, 10);
                setCompanyPAN(trimmedValue);
              }}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>Country</InputLabel>
              <Select
                value={country}
                onChange={(e) => setCountry(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {countryData &&
                  countryData.map((country) => {
                    return (
                      <MenuItem value={country.id} key={country.id}>
                        {country.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>State</InputLabel>
              <Select value={state} onChange={(e) => setState(e.target.value)}>
                <MenuItem value=""></MenuItem>
                {stateData &&
                  stateData.map((state) => {
                    return (
                      <MenuItem value={state.id} key={state.id}>
                        {state.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>City</InputLabel>
              <Select value={city} onChange={(e) => setCity(e.target.value)}>
                <MenuItem value=""></MenuItem>
                {cityData &&
                  cityData.map((city) => {
                    return (
                      <MenuItem value={city.id} key={city.id}>
                        {city.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          {zipData && zipData?.length && (
            <Grid item xs={12}>
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  options={zipData}
                  fullWidth
                  value={zipcodes}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Zipcodes"
                      InputLabelProps={{
                        className: classes.textFieldLabel,
                      }}
                    />
                  )}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, newValue) => {
                    const ids = newValue.map((zip) => zip.id);
                    setZipcodeIds(ids);
                    setZipcodes(newValue);
                  }}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>Services</InputLabel>
              <Select
                value={serviceId}
                onChange={(e) => setServiceId(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                {serviceData &&
                  serviceData.map((service) => {
                    return (
                      <MenuItem value={service.id} key={service.id}>
                        {service.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Reference Id"
              value={referenceId}
              onChange={(e) => setReferenceId(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button variant="outlined" onClick={handleSave} color="primary">
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateAgency;

import { Button, TextField } from "@material-ui/core";
import React, { useState } from "react";
import Header from "../../Container/Header/Header";
import Sidebar from "../../Container/Sidebar/Sidebar";
import AddIcon from "@mui/icons-material/Add";
import { getAllProducts } from "../../../services/main-product.service";
import { useEffect } from "react";
import ProductTable from "./ProductTable";
import Footer from "../../Container/Footer/Footer";
import {
  // useLocation,
  useNavigate,
} from "react-router-dom";
import { apiRoutes } from "../../../utils/apiRoutes";
import DeleteConfirmation from "../../ui/DeleteConfirmation";
import { Toaster } from "react-hot-toast";
import UpdateStock from "./UpdateStock";
import Vendor_Sidebar from "../../VENDOR/mainPage/vendor_sidebar/Vendor_Sidebar";
import jwtDecode from "jwt-decode";
import Cookies from "js-cookie";

const ManageProduct = () => {
  const [search, setSearch] = useState("");
  const [productData, setProductData] = useState([]);
  const [renderedData, setRenderedData] = useState([]);

  const [delOpen, setDelOpen] = useState(false);
  const [productDeleted, setProductDeleted] = useState(false);
  const [delId, setDelID] = useState();

  const [productStatusUpdated, setProductStatusUpdated] = useState(false);

  const [openUpdateStock, setOpenUpdateStock] = useState(false);
  const [productToUpdateStock, setProductToUpdateStock] = useState();
  const [stockUpdated, setStockUpdated] = useState(false);

  const [userRole, setUserRole] = useState(null);

  const navigate = useNavigate();
  // const location = useLocation();

  // Get Products Before Loading Component
  useEffect(() => {
    get();
    const token = Cookies.get("content");
    if (token) {
      let tokenData = jwtDecode(token);
      setUserRole(tokenData.role);
    }
  }, []);

  useEffect(() => {
    filterData();
  }, [search]);

  useEffect(() => {
    if (productDeleted) get();
    setProductDeleted(false);
  }, [productDeleted]);

  useEffect(() => {
    if (productStatusUpdated) get();
    setProductStatusUpdated(false);
  }, [productStatusUpdated]);

  useEffect(() => {
    if (stockUpdated) get();
    setStockUpdated(false);
  }, [stockUpdated]);

  // Handles
  const handleDelete = (id) => {
    setDelOpen(true);
    setDelID(id);
  };

  const handleUpdateStock = (product) => {
    setOpenUpdateStock(true);
    setProductToUpdateStock(product);
  };

  // Function To get All the Products
  async function get() {
    const data = await getAllProducts();
    setProductData(data);
    setRenderedData(data);
  }
  // Filter out the Products by Product Title & Set in renderedData
  function filterData() {
    let filteredData =
      productData &&
      productData.filter((product) => {
        return product.product_title
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    setRenderedData(filteredData);
  }

  return (
    <>
      <div className="container">
        {userRole === 3 ? (
          <Vendor_Sidebar />
        ) : userRole === 1 ? (
          <Sidebar />
        ) : (
          ""
        )}

        <Header name="Manage Products" />
        <div className="content_category">
          <div className="headADD_states">
            <span
              className="list_heading"
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              All Products
            </span>
            <div>
              <TextField
                placeholder="Search Product"
                id="search_products_input"
                variant="outlined"
                style={{ marginRight: "1rem" }}
                onChange={(e) => setSearch(e.target.value)}
              />
              <Button
                size="large"
                className="addbtn"
                variant="contained"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                startIcon={<AddIcon />}
                onClick={() => navigate("/manage_product/add")}
              >
                ADD
              </Button>
            </div>
          </div>
          {/* Table for Listing Products */}
          <div className="sss">
            <div className="ss">
              <ProductTable
                handleDelete={handleDelete}
                productData={renderedData}
                setStatusUpdated={setProductStatusUpdated}
                handleUpdateStock={handleUpdateStock}
              />
            </div>
          </div>
        </div>
        <DeleteConfirmation
          open={delOpen}
          setOpen={setDelOpen}
          url={`${apiRoutes.deleteProduct}${delId}`}
          setDeleted={setProductDeleted}
        />
        <UpdateStock
          open={openUpdateStock}
          setOpen={setOpenUpdateStock}
          product={productToUpdateStock}
          updated={setStockUpdated}
        />
        <Footer />
      </div>
      <Toaster />
    </>
  );
};

export default ManageProduct;

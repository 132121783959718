let initialState={
    productDetails:null,
};

const vendorReducer=(state=initialState,action)=>{
    switch(action.type){
        case "productDetails":{
            return {
                ...state,productDetails:action.playload
            };
        }
        default:return state;
    }
}

export default vendorReducer;
import axios from "../axios";
import { AxiosError } from "axios";
import { apiRoutes } from "../utils/apiRoutes";
import popMsg from "../utils/popup";

async function getCategoriesByBrand(brandId) {
  try {
    const response = await axios.get(
      `${apiRoutes.getCategoryByBrand}${brandId}`
    );
    const data = response.data.category;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function getCategories() {
  try {
    const response = await axios.get(`${apiRoutes.getCategories}`);
    const data = response.data;
    return data.category;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data && errorResponse.data.message) {
        return err.response?.data;
      }
    }
  }
}
// For Mutations
// Brand
async function getAllBrands() {
  try {
    const response = await axios.get(`${apiRoutes.getBrand}`);
    const data = response.data.brand;
    const mapped = data.map((brand, index) => ({
      ...brand,
      serialNumber: index + 1,
    }));
    return mapped;
  } catch (err) {
    console.error(err);
  }
}
async function addNewBrand(brand) {
  if (!brand.brand_name) return popMsg("Brand name cannot be empty");
  if (!brand.image) return popMsg("Image Required");
  const payload = new FormData();
  payload.append("brand_name", brand.brand_name);
  payload.append("image", brand.image);
  return axios.post(`${apiRoutes.addBrand}`, payload);
}

async function updateBrandData(brand) {
  if (!brand.brand_name) return popMsg("Brand name cannot be empty");
  const payload = new FormData();
  payload.append("brand_name", brand.brand_name);
  if (brand.image) payload.append("image", brand.image);
  return axios.post(`${apiRoutes.updateBrand}${brand.id}`, payload);
}
async function deleteBrandData(brandId) {
  return axios.get(`${apiRoutes.deleteBrand}${brandId}`);
}

async function updateBrandStatusData(brand) {
  const payload = { is_status: brand.status };
  return axios.post(`${apiRoutes.updateBrandStatus}${brand.id}`, payload);
}
// Sub-Category
async function getSubCategories() {
  try {
    const response = await axios.get(`${apiRoutes.getSubCategories}`);
    const data = response.data.sub_category;
    const mapped = data.map((subcat, index) => ({
      ...subcat,
      serialNumber: index + 1,
    }));
    return mapped;
  } catch (err) {
    console.error(err);
  }
}

async function addNewSubCategory(subcat) {
  if (!subcat.category_id) return popMsg("Select Category");
  if (!subcat.sub_category_name) return popMsg("Sub Category Name Required");
  if (!subcat.image) popMsg("Image Required");
  const payload = new FormData();
  payload.append("category_id", subcat.category_id);
  payload.append("sub_category_name", subcat.sub_category_name);
  payload.append("image", subcat.image);
  return axios.post(`${apiRoutes.addSubCategory}`, payload);
}

async function updateSubCategoryData(subcat) {
  if (!subcat.category_id) return popMsg("Select Category");
  if (!subcat.sub_category_name) return popMsg("Sub Category Name Required");
  const payload = new FormData();
  payload.append("category_id", subcat.category_id);
  payload.append("sub_category_name", subcat.sub_category_name);
  if (subcat.image) payload.append("image", subcat.image);
  return axios.post(`${apiRoutes.updateSubCategory}${subcat.id}`, payload);
}

async function deleteSubCategoryData(subcatId) {
  return axios.get(`${apiRoutes.deleteSubCategory}${subcatId}`);
}

async function updateSubCatStatus(subcat) {
  const payload = { is_status: subcat.status };
  return axios.post(
    `${apiRoutes.updateSubCategoryStatus}${subcat.id}`,
    payload
  );
}

// Sub-Sub-Category
async function getSubSubCategories() {
  try {
    const response = await axios.get(`${apiRoutes.getSubSubCategories}`);
    const data = response.data.sub_sub_category;
    const mapped = data.map((ssc, index) => ({
      ...ssc,
      serialNumber: index + 1,
    }));
    return mapped;
  } catch (err) {
    console.error(err);
  }
}
async function addNewSubSubCategory(ssc) {
  if (!ssc.category_id) return popMsg("Select Category");
  if (!ssc.sub_category_id) return popMsg("Select Sub Category");
  if (!ssc.sub_sub_category_name) return popMsg("Sub Category Name Required");
  if (!ssc.image) popMsg("Image Required");
  const payload = new FormData();
  payload.append("category_id", ssc.category_id);
  payload.append("sub_category_id", ssc.sub_category_id);
  payload.append("sub_sub_category_name", ssc.sub_sub_category_name);
  payload.append("image", ssc.image);

  return axios.post(`${apiRoutes.addSubSubCategories}`, payload);
}

async function updateSubSubCategoryData(ssc) {
  if (!ssc.category_id) return popMsg("Select Category");
  if (!ssc.sub_category_id) return popMsg("Select Sub Category");
  if (!ssc.sub_sub_category_name) return popMsg("Sub Category Name Required");
  const payload = new FormData();
  payload.append("category_id", ssc.category_id);
  payload.append("sub_category_id", ssc.sub_category_id);
  payload.append("sub_sub_category_name", ssc.sub_sub_category_name);
  if (ssc.image) payload.append("image", ssc.image);
  return axios.post(`${apiRoutes.updateSubSubCategory}${ssc.id}`, payload);
}

async function deleteSubSubCategoryData(sscId) {
  return axios.get(`${apiRoutes.deleteSubSubCategory}${sscId}`);
}

async function updateSubSubCategoryStatus(ssc) {
  const payload = { is_status: ssc.status };
  return axios.post(
    `${apiRoutes.updateSubSubCategoryStatus}${ssc.id}`,
    payload
  );
}

export {
  getCategories,
  getCategoriesByBrand,
  getSubCategories,
  getSubSubCategories,
  getAllBrands,
  addNewBrand,
  updateBrandData,
  deleteBrandData,
  updateBrandStatusData,
  addNewSubCategory,
  updateSubCategoryData,
  deleteSubCategoryData,
  updateSubCatStatus,
  updateSubSubCategoryStatus,
  deleteSubSubCategoryData,
  updateSubSubCategoryData,
  addNewSubSubCategory,
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  makeStyles,
  TextField,
} from "@material-ui/core";
import { useQueryClient } from "@tanstack/react-query";
import React from "react";
import { useState } from "react";
import { useAddUnitsData } from "../../../hooks/unit.hooks";
import popMsg from "../../../utils/popup";

const AddUnit = ({ open, setOpen }) => {
  const [unitName, setUnitName] = useState("");

  const { mutateAsync: addUnitData } = useAddUnitsData();
  const queryClient = useQueryClient();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };
  function resetForm() {
    setUnitName("");
  }
  const handleSave = () => {
    const unit = unitName;
    addUnitData(unit, {
      onSuccess: (res) => {
        handleClose();
        popMsg(res.data.message, res.data.success);
        queryClient.invalidateQueries("units");
      },
    });
  };
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Add Unit</DialogTitle>
      <DialogContent>
        <Box style={{ width: "100%", overflow: "unset" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  size="small"
                  placeholder="Unit Name"
                  value={unitName}
                  onChange={(e) => {
                    let value = e.target.value;
                    setUnitName(value);
                  }}
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUnit;

import { Button, TextField } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import React from "react";

const TopBlock = ({ search, setSearch, setOpenAddForm, dontShowAdd }) => {
  return (
    <div className="top_block">
      <div className="inner_top_right">
        <TextField
          aria-label="search_input_category"
          size="small"
          variant="outlined"
          placeholder="Search"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {!dontShowAdd && (
          <Button
            startIcon={<AddCircleOutline />}
            size="small"
            className="add_button"
            variant="contained"
            onClick={() => setOpenAddForm(true)}
          >
            Add
          </Button>
        )}
      </div>
    </div>
  );
};

export default TopBlock;

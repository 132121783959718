import axios from "../axios";
import { apiRoutes } from "../utils/apiRoutes";
import { mapSerialNumber } from "../utils/constants";

async function getAllOrders() {
  try {
    const response = await axios.get(`${apiRoutes.getAllOrders}`);
    const data = response.data.order;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function getAllServiceOrders() {
  try {
    const response = await axios.get(`${apiRoutes.getAllServiceOrders}`);
    const data = response.data.service_order;
    const mapped = mapSerialNumber(data);
    return mapped;
  } catch (err) {
    console.error(err);
  }
}

async function getAllReturnOrders() {
  try {
    const response = await axios.get(`${apiRoutes.getAllReturnOrders}`);
    const data = response.data.return_order_list;
    const mapped = mapSerialNumber(data);
    return mapped;
  } catch (err) {
    console.error(err);
  }
}

async function getOrderDetailsProduct() {
  try {
    const response = await axios.get(`${apiRoutes.orderDetails}`);
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function updateOrderStatus(payload, orderId) {
  try {
    const response = await axios.post(
      `${apiRoutes.orderStatusUpdate}${orderId}`,
      payload
    );
    const data = response.data;
    return data;
  } catch (err) {
    console.error(err);
  }
}

async function getServiceOrderDetails(orderId) {
  try {
    const response = await axios.get(
      `${apiRoutes.getServiceOrderDetails}${orderId}`
    );
    const data = response.data.order;
    return data;
  } catch (err) {
    console.log(err);
  }
}

async function updateServiceOrderStatus(order) {
  const payload = { status: order.status };
  return axios.post(
    `${apiRoutes.updateServiceOrderStatus}${order.id}`,
    payload
  );
}

async function updateReturnOrderDecision(decision) {
  const payload = {
    status: decision.status,
  };
  console.log("decision", decision);
  return axios.post(
    `${apiRoutes.returnOrderAdminAction}${decision.orderId}`,
    payload
  );
}

export {
  getAllOrders,
  getAllServiceOrders,
  getOrderDetailsProduct,
  updateOrderStatus,
  updateServiceOrderStatus,
  getServiceOrderDetails,
  getAllReturnOrders,
  updateReturnOrderDecision,
};

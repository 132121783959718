import React from "react";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  baseUrl,
  formatDate,
  imagepath_product,
  isAdmin,
} from "../../../utils/constants";
import Layout from "../../ui/Layout";
import { getProductDetails } from "../../../services/main-product.service";
import { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
  TablePagination,
  Button,
} from "@material-ui/core";
import { useUpdateAdminProductDecision } from "../../../hooks/product.hooks";
import popMsg from "../../../utils/popup";
import { Toaster } from "react-hot-toast";
import BackButton from "../../ui/BackButton";

const ProductDetails = () => {
  const [selectedImage, setSelectedImage] = useState();
  const [product, setProduct] = useState();
  const [quanData, setQuanData] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const { id } = useParams();
  const { mutateAsync: updateDecision } = useUpdateAdminProductDecision();

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
    tableCellHead: {
      fontSize: "1rem",
      fontWeight: "600",
      color: "#36454F",
    },
    tableCellBody: {
      fontSize: "14px",
    },
    icons: {
      width: "25px",
      height: "25px",
      color: "#023F9E",
      padding: "5px",
      cursor: "pointer",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (id) get(id);
  }, [id]);

  useEffect(() => {
    console.log(isAdmin());
  }, []);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
  };

  const handleApprove = () => {
    const prod = {
      id,
      status: 1,
    };
    updateDecision(prod, {
      onSuccess: (res) => {
        popMsg(res.data.message, res.data.success);
        navigate("/manage_product");
      },
    });
  };
  const handleReject = () => {
    const prod = {
      id,
      status: 2,
    };
    updateDecision(prod, {
      onSuccess: (res) => {
        popMsg(res.data.message, res.data.success);
        navigate("/manage_product");
      },
    });
  };

  async function get(productId) {
    const data = await getProductDetails(productId);
    setProduct(data[0]);
    setSelectedImage(data[0].images[0]);
    if (data[0]?.quantification?.length > 0) {
      setQuanData(data[0]?.quantification);
    }
    // console.log(data);
  }

  return (
    <Layout title="Product Details">
      {product && (
        <div style={{ minHeight: "80vh", width: "100%", overflow: "unset" }}>
          <div
            style={{
              minHeight: "2rem",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <p style={{ color: "#333" }}>
                <strong>product title : </strong>
                <span>{product.product_title}</span>
              </p>
            </div>
            <BackButton />
          </div>
          <div
            className=""
            style={{ minHeight: "80vh", width: "100%", display: "flex" }}
          >
            {/* Product Images and Description */}
            <div style={{ width: "47%" }}>
              {/* Image */}
              <div
                style={{
                  height: "80%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {/* single image */}
                <img
                  style={{ maxWidth: "50%" }}
                  src={`${baseUrl}${imagepath_product}${selectedImage}`}
                />
              </div>
              <div>
                {/* All images */}
                {product &&
                  product.images &&
                  product.images.length > 0 &&
                  product.images.map((image) => {
                    return (
                      <img
                        src={`${baseUrl}${imagepath_product}${image}`}
                        style={{ maxWidth: "5rem", maxHeight: "5rem" }}
                        onClick={() => setSelectedImage(image)}
                      />
                    );
                  })}
              </div>
            </div>
            <div style={{ color: "#333", width: "47%" }}>
              <div>
                {/* Description */}
                <h3>Description: </h3>
                <p>{product && product.description && product.description}</p>
              </div>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>MinQuantity</TableCell>
                    <TableCell>Return Time</TableCell>
                    <TableCell>Tax Charges</TableCell>
                    <TableCell>Perishable</TableCell>
                    <TableCell>Subscribed</TableCell>
                    <TableCell>Visible</TableCell>
                    <TableCell>Created </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableCell>{product?.min_quantity}</TableCell>
                  <TableCell>{product.return_time}</TableCell>
                  <TableCell>{product?.tex_charges}</TableCell>
                  <TableCell>
                    {product.is_perishable === 0 ? "No" : "Yes"}
                  </TableCell>
                  <TableCell>
                    {product.subscribe === 0 ? "No" : "Yes"}
                  </TableCell>
                  <TableCell>
                    {product.product_status === 1 ? "Yes" : "NO"}
                  </TableCell>
                  <TableCell>
                    {formatDate(product.created_at, false, false)}
                  </TableCell>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div
            style={{
              color: "#333",
              display: "flex",
              // justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <div
              style={{
                border: "0.5px solid #333",
                margin: "1rem 0rem",
                borderRadius: "1rem",
              }}
            >
              <TableContainer>
                <Table>
                  <TableRow>
                    <TableCell>Brand</TableCell>
                    <TableCell>{product.brand_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Category</TableCell>
                    <TableCell>{product.category_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sub-Category</TableCell>
                    <TableCell>{product.sub_category_name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Sub-Sub-Category</TableCell>
                    <TableCell>{product.sub_sub_category_name}</TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </div>
            <div
              style={{
                border: "0.5px solid #333",
                margin: "1rem 0rem",
                borderRadius: "1rem",
              }}
            >
              <TableContainer>
                <Table>
                  <TableRow>
                    <TableCell>Admin Commission</TableCell>
                    <TableCell>
                      {" "}
                      {`${product.admin_commission} ${product.admin_commission_type}`}{" "}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Agency Commission</TableCell>
                    <TableCell>{`${product.agency_commission} ${product.agency_commission_type}`}</TableCell>
                  </TableRow>
                </Table>
              </TableContainer>
            </div>
            <div>
              {!quanData && (
                <div
                  style={{
                    border: "0.5px solid #333",
                    margin: "1rem 0rem",
                    borderRadius: "1rem",
                    padding: "0.5rem 2rem",
                  }}
                >
                  <TableContainer>
                    <Table>
                      <TableRow>
                        <TableCell>Sale Price</TableCell>
                        <TableCell>{product.sale_price}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Purchase Price</TableCell>
                        <TableCell>{product.purchase_price}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Discount</TableCell>
                        <TableCell>{`${product.discount_price} ${product.discount_type}`}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Final Price</TableCell>
                        <TableCell>{product.final_price}</TableCell>
                      </TableRow>
                    </Table>
                  </TableContainer>
                </div>
              )}
              {quanData && quanData.length > 0 && (
                <div>
                  <h2>Quantifications</h2>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className={classes.tableCellHead}>
                            Quantity
                          </TableCell>
                          <TableCell className={classes.tableCellHead}>
                            Unit
                          </TableCell>
                          <TableCell className={classes.tableCellHead}>
                            Sale Price
                          </TableCell>
                          <TableCell className={classes.tableCellHead}>
                            Purchase Price
                          </TableCell>
                          <TableCell className={classes.tableCellHead}>
                            Discount Type
                          </TableCell>
                          <TableCell className={classes.tableCellHead}>
                            Discount Amount
                          </TableCell>
                          <TableCell className={classes.tableCellHead}>
                            Final Price
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {quanData.map((quan) => {
                          return (
                            <TableRow>
                              <TableCell className={classes.tableCellBody}>
                                {quan.quantify}
                              </TableCell>
                              <TableCell className={classes.tableCellBody}>
                                {quan.unit}
                              </TableCell>
                              <TableCell className={classes.tableCellBody}>
                                {quan.sale_price}
                              </TableCell>
                              <TableCell className={classes.tableCellBody}>
                                {quan.purchase_price}
                              </TableCell>
                              <TableCell className={classes.tableCellBody}>
                                {quan.discount_type}
                              </TableCell>
                              <TableCell className={classes.tableCellBody}>
                                {quan.discount_amount}
                              </TableCell>
                              <TableCell className={classes.tableCellBody}>
                                {quan.final_price_amount}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                    <TablePagination
                      sx={{ px: 2 }}
                      page={page}
                      component="div"
                      rowsPerPage={rowsPerPage}
                      count={quanData && quanData.length ? quanData.length : 0}
                      onPageChange={handleChangePage}
                      rowsPerPageOptions={[5, 10, 25]}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      nextIconButtonProps={{ "aria-label": "Next Page" }}
                      backIconButtonProps={{ "aria-label": "Previous Page" }}
                      showfirstbutton="true"
                      showlastbutton="true"
                    />
                  </TableContainer>
                </div>
              )}
            </div>
          </div>
          {isAdmin() && product.product_status === 0 && (
            <div
              style={{
                width: "100%",
                backgroundColor: "azure",
                minHeight: "5rem",
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                margin: "3rem 0rem",
              }}
            >
              <Button
                variant="contained"
                style={{
                  color: "white",
                  background: "green",
                  padding: "0.5rem 8rem",
                }}
                onClick={handleApprove}
              >
                Approve
              </Button>
              <Button
                variant="contained"
                style={{
                  color: "white",
                  background: "red",
                  padding: "0.5rem 8rem",
                }}
                onClick={handleReject}
              >
                Reject
              </Button>
            </div>
          )}
        </div>
      )}
      <Toaster />
    </Layout>
  );
};

export default ProductDetails;

import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

import popMsg from "../../../utils/popup";
import { updateCoupon } from "../../../services/coupons.service";

const UpdateCoupon = ({ open, setOpen, coupon, setCouponUpdated }) => {
  const [couponId, setCouponId] = useState();
  const [couponTitle, setCouponTitle] = useState("");
  const [couponDescription, setCouponDescription] = useState("");
  const [couponType, setCouponType] = useState("");
  const [accessLimit, setAccessLimit] = useState();
  const [startDate, setStartDate] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [minPurchase, setMinPurchase] = useState();
  const [maxDiscount, setMaxDiscount] = useState();
  const [discount, setDiscount] = useState();
  const [discountType, setDiscountType] = useState();
  const [couponCode, setCouponCode] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  useEffect(() => {
    if (!coupon) return;
    setCouponId(coupon.id);
    setCouponTitle(coupon.title);
    setCouponDescription(coupon.description);
    setCouponType(coupon.coupon_type);
    setAccessLimit(coupon.access_limit);
    setStartDate(coupon.start_date);
    setExpiryDate(coupon.expire_date);
    setMinPurchase(coupon.min_purchase);
    setMaxDiscount(coupon.max_discount);
    setDiscount(coupon.discount);
    setDiscountType(coupon.discount_type);
    setCouponCode(coupon.coupon_code);
  }, [coupon]);

  const handleClose = () => {
    setOpen(false);
    // resetForm();
  };
  const handleSave = () => {
    update();
  };
  async function update() {
    let payload = {
      title: couponTitle,
      description: couponDescription,
      coupon_type: couponType,
      access_limit: accessLimit,
      start_date: startDate,
      expire_date: expiryDate,
      min_purchase: minPurchase,
      max_discount: maxDiscount,
      discount: discount,
      discount_type: discountType,
      coupon_code: couponCode,
    };
    const data = await updateCoupon(payload, couponId, setIsLoading);
    if (data.success) {
      setCouponUpdated(true);
      handleClose();
      popMsg(data.message, true);
    }
  }

  const addDays = (date, days) => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Update Coupon</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Coupon title"
              value={couponTitle}
              onChange={(e) => setCouponTitle(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              size="small"
              label="Coupon description"
              value={couponDescription}
              onChange={(e) => setCouponDescription(e.target.value)}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Coupon Type
              </InputLabel>
              <Select
                value={couponType}
                onChange={(e) => setCouponType(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value="0">Default</MenuItem>
                <MenuItem value="1">First Order</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Minimum Purchase"
              value={minPurchase}
              onChange={(e) => setMinPurchase(Number(e.target.value))}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Access limit"
              value={accessLimit}
              onChange={(e) => setAccessLimit(Number(e.target.value))}
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
              fullWidth
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Start-Date"
              type="date"
              InputLabelProps={{
                shrink: true,
                className: classes.textFieldLabel,
              }}
              InputProps={{
                inputProps: {
                  min: new Date().toISOString().split("T")[0],
                },
              }}
              fullWidth
              value={startDate ? startDate : ""}
              onChange={(e) =>
                setStartDate(
                  new Date(e.target.value).toISOString().split("T")[0]
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Expiry-Date"
              type="date"
              InputLabelProps={{
                shrink: true,
                className: classes.textFieldLabel,
              }}
              disabled={!startDate}
              InputProps={{
                inputProps: {
                  min:
                    startDate &&
                    addDays(startDate, 1).toISOString().split("T")[0],
                },
              }}
              fullWidth
              value={expiryDate ? expiryDate : ""}
              onChange={(e) =>
                setExpiryDate(
                  new Date(e.target.value).toISOString().split("T")[0]
                )
              }
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Coupon Code"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              fullWidth
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <FormControl fullWidth>
              <InputLabel style={{ fontSize: "0.8rem" }}>
                Discount Type
              </InputLabel>
              <Select
                value={discountType}
                onChange={(e) => setDiscountType(e.target.value)}
              >
                <MenuItem value=""></MenuItem>
                <MenuItem value={1}>Percentage</MenuItem>
                <MenuItem value={0}>Amount</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label="Discount"
              value={discount}
              onChange={(e) => setDiscount(Number(e.target.value))}
              fullWidth
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              size="small"
              label="Max Discount"
              value={maxDiscount}
              onChange={(e) => setMaxDiscount(Number(e.target.value))}
              fullWidth
              InputLabelProps={{
                className: classes.textFieldLabel,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose} color="secondary">
          Close
        </Button>
        <Button
          disabled={isLoading}
          variant="outlined"
          onClick={handleSave}
          color="primary"
        >
          Update
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default UpdateCoupon;

import axios from "../axios";
import { AxiosError } from "axios";
import { apiRoutes } from "../utils/apiRoutes";

async function getNotifications() {
  try {
    const response = await axios.get(`${apiRoutes.getNotifications}`);
    const data = response.data;
    return data.notification;
  } catch (err) {
    console.error(err);
  }
}

async function addNotifications(payload, setIsLoading) {
  setIsLoading(true);
  try {
    const response = await axios.post(`${apiRoutes.addNotifications}`, payload);
    const data = response.data;
    return data;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data) {
        return err.response.data;
      }
    }
  } finally {
    setIsLoading(false);
  }
}

export { addNotifications, getNotifications };

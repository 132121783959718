import toast from "react-hot-toast";

function popMsg(message, success) {
  if (!message) return;
  const options = {
    duration: 2000,
    position: "top-center",
    style: {
      color: "#fff",
      background: success ? "#00b894" : "#d63031",
      fontSize: "1.2rem",
    },
    icon: success ? "🎉" : "😞",
  };
  return toast(message, options);
}

export default popMsg;

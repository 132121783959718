import {
  Button,
  DialogTitle,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import React from "react";
import { imagePathProduct } from "../../../App";

const Reason = ({ open, setOpen, reason, description, returnImage }) => {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogTitle>Reason For Return</DialogTitle>
      <DialogContent>
        {/* Change Image Path */}
        {returnImage && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              style={{ maxHeight: "23rem", maxWidth: "23rem" }}
              src={`${imagePathProduct}${returnImage}`}
              alt={returnImage}
            />
          </div>
        )}
        {reason && (
          <h2>
            <span style={{ fontWeight: "500" }}>Reason: </span> {reason}
          </h2>
        )}
        {description && (
          <p style={{ marginTop: "1rem" }}>
            <strong>Description: </strong> {description}
          </p>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary" variant="outlined">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Reason;

import axios from "../axios";
import { AxiosError } from "axios";
import { apiRoutes } from "../utils/apiRoutes";
import popMsg from "../utils/popup";
import { isValidEmail } from "../utils/constants";

async function getAllVendors() {
  try {
    const response = await axios.get(`${apiRoutes.getAllVendors}`);
    const data = response.data;
    return data.vendor;
  } catch (err) {
    if (err instanceof AxiosError) {
      const errorResponse = err.response;
      if (errorResponse && errorResponse.data && errorResponse.data.message) {
        return err.response?.data;
      }
    }
  }
}

async function addVendor(payload) {
  // setIsLoading(true);
  let {
    company_name,
    display_name,
    company_phone_number,
    company_email,
    contact_person_name,
    contact_person_phone_number,
    contact_person_email,
    company_adress,
    company_warehouse_adress,
    company_gst,
    company_pan_number,
    category_id,
    sub_category_id,
    sub_sub_category_id,
    country_id,
    state_id,
    city_id,
    zipcode_id,
  } = payload;
  if (!company_name) {
    popMsg("Company name is required");
    return;
  }
  if (!display_name) {
    popMsg("Display name is required");
    return;
  }
  if (!company_phone_number) {
    popMsg("Company phone number is required");
    return;
  }
  if (company_phone_number.length < 10) {
    popMsg("Entre a valid Company phone number");
    return;
  }
  if (!company_email) {
    popMsg("Company email is required");
    return;
  }
  if (!isValidEmail(company_email)) {
    popMsg("Entre a valid Company email");
    return;
  }
  if (!contact_person_name) {
    popMsg("Contact person name is required");
    return;
  }
  if (!contact_person_phone_number) {
    popMsg("Contact person phone number is required");
    return;
  }
  if (contact_person_phone_number.length < 10) {
    popMsg("Contact Person Phone is Invalid");
  }
  if (!contact_person_email) {
    popMsg("Contact person email is required");
    return;
  }
  if (!isValidEmail(contact_person_email)) {
    popMsg("Entre a Valid Email for Contact Person");
  }
  if (!company_adress) {
    popMsg("Company address is required");
    return;
  }
  if (!company_warehouse_adress) {
    popMsg("Company warehouse address is required");
    return;
  }
  if (!company_gst) {
    popMsg("Company GST is required");
    return;
  }
  if (company_gst.length !== 15) {
    popMsg("GSTN must be of 15 Characters");
  }
  if (!company_pan_number) {
    popMsg("Company PAN number is required");
    return;
  }
  if (company_pan_number.length !== 10) {
    popMsg("GSTN must be of 10 Characters");
  }
  if (!category_id) {
    popMsg("Product category ID is required");
    return;
  }
  if (!sub_category_id) {
    popMsg("Product sub-category ID is required");
    return;
  }
  if (!sub_sub_category_id) {
    popMsg("Product sub-sub-category is required");
    return;
  }
  if (!country_id) {
    popMsg("Country ID is required");
    return;
  }
  if (!state_id) {
    popMsg("State ID is required");
    return;
  }
  if (!city_id) {
    popMsg("City ID is required");
    return;
  }
  if (!zipcode_id) {
    popMsg("Zip code ID is required");
    return;
  }
  return axios.post(`${apiRoutes.addVendor}`, payload);
  // try {
  //   const response = await axios.post(`${apiRoutes.addVendor}`, payload);
  //   const data = response.data;
  //   return data;
  // } catch (err) {
  //   if (err instanceof AxiosError) {
  //     const errorResponse = err.response;
  //     if (errorResponse && errorResponse.data && errorResponse.data.message) {
  //       return err.response?.data;
  //     }
  //   }
  //   // return popMsg(err.message, false);
  // } finally {
  //   setIsLoading(false);
  // }
}

async function updateVendor(payload) {
  let {
    company_name,
    display_name,
    company_phone_number,
    company_email,
    contact_person_name,
    contact_person_phone_number,
    contact_person_email,
    company_adress,
    company_warehouse_adress,
    company_gst,
    company_pan_number,
    category_id,
    sub_category_id,
    sub_sub_category,
    country_id,
    state_id,
    city_id,
    zipcode_id,
  } = payload;

  if (!company_name) {
    popMsg("Company name is required");
    return;
  }
  if (!display_name) {
    popMsg("Display name is required");
    return;
  }
  if (!company_phone_number) {
    popMsg("Company phone number is required");
    return;
  }
  if (company_phone_number.length < 10) {
    popMsg("Entre a valid Company phone number");
    return;
  }
  if (!company_email) {
    popMsg("Company email is required");
    return;
  }
  if (!isValidEmail(company_email)) {
    popMsg("Entre a valid Company email");
    return;
  }
  if (!contact_person_name) {
    popMsg("Contact person name is required");
    return;
  }
  if (!contact_person_phone_number) {
    popMsg("Contact person phone number is required");
    return;
  }
  if (contact_person_phone_number.length < 10) {
    popMsg("Contact Person Phone is Invalid");
  }
  if (!contact_person_email) {
    popMsg("Contact person email is required");
    return;
  }
  if (!isValidEmail(contact_person_email)) {
    popMsg("Entre a Valid Email for Contact Person");
  }
  if (!company_adress) {
    popMsg("Company address is required");
    return;
  }
  if (!company_warehouse_adress) {
    popMsg("Company warehouse address is required");
    return;
  }
  if (!company_gst) {
    popMsg("Company GST is required");
    return;
  }
  if (company_gst.length !== 15) {
    popMsg("GSTN must be of 15 Characters");
  }
  if (!company_pan_number) {
    popMsg("Company PAN number is required");
    return;
  }
  if (company_pan_number.length !== 10) {
    popMsg("GSTN must be of 10 Characters");
  }
  if (!category_id) {
    popMsg("Product category ID is required");
    return;
  }
  if (!sub_category_id) {
    popMsg("Product sub-category ID is required");
    return;
  }
  if (!sub_sub_category) {
    popMsg("Product sub-sub-category is required");
    return;
  }
  if (!country_id) {
    popMsg("Country ID is required");
    return;
  }
  if (!state_id) {
    popMsg("State ID is required");
    return;
  }
  if (!city_id) {
    popMsg("City ID is required");
    return;
  }
  if (!zipcode_id) {
    popMsg("Zip code ID is required");
    return;
  }

  return axios.post(`${apiRoutes.updateVendor}${payload.vendorId}`, payload);

  // try {
  //   const response = await axios.post(
  //     `${apiRoutes.updateVendor}${vendorId}`,
  //     payload
  //   );
  //   const data = response.data;
  //   return data;
  // } catch (err) {
  //   if (err instanceof AxiosError) {
  //     const errorResponse = err.response;
  //     if (errorResponse && errorResponse.data && errorResponse.data.message) {
  //       return err.response?.data;
  //     }
  //   }
  // } finally {
  //   setIsLoading(false);
  // }
}

export { addVendor, getAllVendors, updateVendor };

import axios from "../axios";
import { apiRoutes } from "../utils/apiRoutes";
import { mapSerialNumber } from "../utils/constants";
async function getAllWebSections() {
  try {
    const response = await axios.get(`${apiRoutes.getWebSection}`);
    const data = response.data.section_list;
    const mapped = mapSerialNumber(data);
    return mapped;
  } catch (err) {
    console.error(err);
  }
}
async function addWebSection(section) {
  const payload = new FormData();
  payload.append("section_name", section.sectionName);
  payload.append("section_type", section.sectionType);
  payload.append(
    "section_order",
    section.sectionOrder ? section.sectionOrder : 0
  );
  if (section.image) payload.append("image", section.image);
  if (section?.productIds?.length)
    payload.append("product_id", section.productIds);
  return axios.post(`${apiRoutes.addWebSection}`, payload);
}

async function updateWebSection(section) {
  const payload = new FormData();
  payload.append("section_name", section.sectionName);
  payload.append("section_type", section.sectionType);
  payload.append(
    "section_order",
    section.sectionOrder ? section.sectionOrder : 0
  );
  if (section.image) payload.append("image", section.image);
  if (section?.productIds?.length)
    payload.append("product_id", section.productIds);
  return axios.post(
    `${apiRoutes.updateWebSection}${section.sectionId}`,
    payload
  );
}

async function deleteWebSection(sectionId) {
  return axios.get(`${apiRoutes.deleteWebSection}${sectionId}`);
}

export { addWebSection, getAllWebSections, updateWebSection, deleteWebSection };

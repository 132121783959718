import popMsg from "../utils/popup";
import axios from "../axios";
import { apiRoutes } from "../utils/apiRoutes";

async function loginUser(payload) {
  if (!payload.email) popMsg("email required");
  if (!payload.password) popMsg("password required");
  if (payload.password.trim().length < 7 && payload.trim().length > 16)
    popMsg("password must me of 8-16 characters");
  return axios.post(`${apiRoutes.login}`, payload);
}

export { loginUser };

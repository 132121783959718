const auth=(id)=>{
    return{
        type:"auth",
        playload:id
    }
}
const logout=(id)=>{
    return{
        type:"logout",
    }
}
const UpdatedProductData=(data)=>{
    return {
        type:"UpdatedProductData",
        playload:data
    }
}
export default auth;
export {logout,UpdatedProductData};
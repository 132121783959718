import {
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  TableBody,
  Switch,
  TablePagination,
} from "@material-ui/core";
import { VisibilityOutlined } from "@material-ui/icons";
import { BuildCircle, ModeEdit } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { changeProductVisibility } from "../../../services/main-product.service";
import {
  baseUrl,
  imagepath_product,
  initialRows,
} from "../../../utils/constants";
import popMsg from "../../../utils/popup";

const ProductTable = ({
  productData,
  handleDelete,
  setStatusUpdated,
  handleUpdateStock,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  const navigate = useNavigate();

  const useStyles = makeStyles((theme) => ({
    tableCellHead: {
      fontSize: "1rem",
      fontWeight: "600",
      color: "#36454F",
    },
    tableCellBody: {
      fontSize: "14px",
    },
    icons: {
      width: "25px",
      height: "25px",
      color: "#023F9E",
      padding: "2px",
      cursor: "pointer",
    },
  }));
  const classes = useStyles();

  // Handlers
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
  };

  async function statusUpdate(status, id) {
    let payload = { status };
    const data = await changeProductVisibility(payload, id);
    if (data.success) {
      setStatusUpdated(true);
      popMsg(data.message, true);
    }
  }

  return (
    <>
      <TableContainer className="cat_table" component={Paper}>
        <Table style={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableCellHead}>S.No</TableCell>
              <TableCell className={classes.tableCellHead}>
                Product-Name
              </TableCell>
              <TableCell className={classes.tableCellHead}>Image</TableCell>
              <TableCell className={classes.tableCellHead}>User-Type</TableCell>
              <TableCell className={classes.tableCellHead}>Status</TableCell>
              <TableCell className={classes.tableCellHead}>Visible</TableCell>
              <TableCell className={classes.tableCellHead}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {productData &&
              productData.length > 0 &&
              productData
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((product, index) => {
                  return (
                    <TableRow className="list_row" key={product.id}>
                      <TableCell className={classes.tableCellBody}>
                        {(page + 1) * rowsPerPage - rowsPerPage + index + 1}
                      </TableCell>
                      <TableCell
                        style={{ cursor: "pointer", color: "blue" }}
                        className={classes.tableCellBody}
                        onClick={() => {
                          navigate(`/manage_product/details/${product.id}`);
                        }}
                      >
                        {product.product_title}
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        <img
                          alt={product.images[0]}
                          src={`${baseUrl}${imagepath_product}${product.images[0]}`}
                          style={{ width: "3rem", height: "3rem" }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        {product.uesr_type}
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        {product.product_status === 2 ? (
                          <span style={{ color: "red" }}>Rejected</span>
                        ) : product.product_status === 1 ? (
                          <span style={{ color: "green" }}>Approved</span>
                        ) : product.product_status === 0 ? (
                          <span style={{ color: "orange" }}>Pending</span>
                        ) : (
                          ""
                        )}
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        <Switch
                          disabled={
                            product.product_status === 2
                              ? true
                              : product.product_status === 0
                              ? true
                              : false
                          }
                          style={{ cursor: "pointer" }}
                          color="primary"
                          checked={product.is_status}
                          onChange={(e, val) => {
                            statusUpdate(val, product.id);
                          }}
                        />
                      </TableCell>
                      <TableCell className={classes.tableCellBody}>
                        <ModeEdit
                          onClick={() =>
                            navigate(`/manage_product/update/${product.id}`)
                          }
                          className={classes.icons}
                        />
                        <DeleteIcon
                          onClick={() => handleDelete(product.id)}
                          className={classes.icons}
                        />
                        <VisibilityOutlined
                          onClick={() =>
                            navigate(`/manage_product/details/${product.id}`)
                          }
                          className={classes.icons}
                        />
                        <BuildCircle
                          onClick={() => handleUpdateStock(product)}
                          className={classes.icons}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })}
          </TableBody>
        </Table>
        <TablePagination
          sx={{ px: 2 }}
          page={page}
          component="div"
          rowsPerPage={rowsPerPage}
          count={productData && productData.length ? productData.length : 0}
          onPageChange={handleChangePage}
          rowsPerPageOptions={[5, 10, 25]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          nextIconButtonProps={{ "aria-label": "Next Page" }}
          backIconButtonProps={{ "aria-label": "Previous Page" }}
          showfirstbutton="true"
          showlastbutton="true"
        />
      </TableContainer>
    </>
  );
};

export default ProductTable;

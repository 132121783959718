import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "../axios";
import { useQueryClient } from "@tanstack/react-query";
import { apiRoutes } from "../utils/apiRoutes";
import popMsg from "../utils/popup";

const fetchCategories = async () => {
  try {
    const response = await axios.get(apiRoutes.getCategories);
    const data = response.data;
    const filtered = data.category.map((cat, index) => ({
      ...cat,
      serialNumber: index + 1,
    }));
    return filtered;
  } catch (error) {
    throw new Error("Failed to fetch categories");
  }
};

const addCategory = (category) => {
  const payload = new FormData();
  payload.append("category_name", category.category_name);
  payload.append("image", category.image);
  payload.append("brands", category.brands);
  return axios.post(`${apiRoutes.addCategory}`, payload);
};

const updateCategory = (category) => {
  if (!category.category_name) return popMsg("Cateogry name required");
  // if (!category.brands.length) return popMsg("select brands");
  const payload = new FormData();
  payload.append("category_name", category.category_name);
  payload.append("brands", category.brands);
  if (category.image) payload.append("image", category.image);
  return axios.post(`${apiRoutes.updateCategories}${category.id}`, payload);
};

const deleteCategory = (categoryId) => {
  return axios.get(`${apiRoutes.deleteCategory}${categoryId}`);
};

const updateCategoryStatus = (category) => {
  const payload = { is_status: category.status };
  return axios.post(`${apiRoutes.updateCategoryStatus}${category.id}`, payload);
};

const useCategoriesQuery = () => {
  return useQuery({
    queryKey: ["categories"],
    queryFn: fetchCategories,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useAddCategoryData = () => {
  const queryClient = useQueryClient();
  return useMutation(addCategory, {
    onMutate: async (newCategory) => {
      await queryClient.cancelQueries("categories");
      const previousCategoryData = queryClient.getQueryData(["categories"]);

      queryClient.setQueryData(["categories"], (oldQueryData) => {
        if (!oldQueryData || !Array.isArray(oldQueryData)) {
          return [newCategory]; // Set the initial data with the newCategory
        }
        return [
          ...oldQueryData,
          {
            id: oldQueryData.length + 1,
            serialNumber: oldQueryData.length + 1,
            ...newCategory,
            is_status: 1,
          },
        ];
      });

      return { previousCategoryData };
    },
    onError: (_err, _newCategory, context) => {
      queryClient.setQueryData(["categories"], context.previousCategoryData);
    },
    onSettled: () => {
      queryClient.invalidateQueries("categories");
    },
  });
};

const useDeleteCategory = () => {
  return useMutation((categoryId) => deleteCategory(categoryId));
};

const useUpdateCategory = () => {
  return useMutation((category) => updateCategory(category), {
    onError: (err) => {
      popMsg(err.message);
    },
  });
};

const useUpdateCatStatus = () => {
  return useMutation((category) => updateCategoryStatus(category));
};

export {
  useAddCategoryData,
  useCategoriesQuery,
  useDeleteCategory,
  useUpdateCategory,
  useUpdateCatStatus,
};

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  makeStyles,
  Switch,
  TablePagination,
  TextField,
  Typography,
} from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import Footer from "../../Container/Footer/Footer";
import { getAllCustomers } from "../../../services/users.service";
import { initialRows, updateStatus } from "../../../utils/constants";
import { apiRoutes } from "../../../utils/apiRoutes";
import UserWishlist from "./UserWishlist";
import CustomerDetails from "./CustomerDetails";

const CustomerList = () => {
  const [customerData, setCustomerData] = useState();
  const [renderedData, setRenderedData] = useState();
  const [search, setSearch] = useState("");
  const [statusUpdated, setStatusUpdated] = useState(false);
  const [showWishlist, setShowWishlist] = useState(false);
  const [wishlistData, setWishlistData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [details, setDetails] = useState();

  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);

  const useStyle = makeStyles({
    root: {
      backgroundColor: "white",
      "&:hover": {
        backgroundColor: "#023F9E",
        color: "white",
      },
    },
  });

  const classes = useStyle();

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (statusUpdated) get();
    setStatusUpdated(false);
  }, [statusUpdated]);

  useEffect(() => {
    filterData();
  }, [search]);

  // Handlers
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleStatusUpdate = (id, val) => {
    const url = `${apiRoutes.updateCustomerStatus}${id}`;
    const payload = { status: val };
    updateStatus(url, payload, setStatusUpdated);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
  };

  async function get() {
    const data = await getAllCustomers();
    setCustomerData(data);
    setRenderedData(data);
  }

  function filterData() {
    const filteredData =
      customerData &&
      customerData.filter((customer) => {
        return customer.name
          .toString()
          .toLowerCase()
          .includes(search.toLowerCase());
      });
    setRenderedData(filteredData);
  }
  // Table body
  const renderData = (data) => {
    return (
      <TableBody>
        {data.map((i, index) => {
          return (
            <TableRow
              key={index}
              className="list_row"
              sx={{
                "&:last-child td, &:last-child th": {
                  border: 0,
                },
              }}
            >
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {(page + 1) * rowsPerPage - rowsPerPage + index + 1}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <Button
                  style={{ color: "blue", fontWeight: "500", fontSize: "16px" }}
                  onClick={() => {
                    setShowDetails(true);
                    let d = {
                      name: i.name,
                      email: i.email,
                      address: "Need To Be Added",
                      created: i.created_at,
                    };
                    setDetails(d);
                  }}
                >
                  {i.name}
                </Button>
                {/* </link> */}
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {i.email}
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {i.phone}
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => {
                    setWishlistData(i.wishlist);
                    setShowWishlist(true);
                  }}
                >
                  Wishlist
                </Button>
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {i.is_status === 1 ? (
                  <span style={{ color: "green" }}>Active</span>
                ) : i.is_status === 0 ? (
                  <span style={{ color: "red" }}>InActive</span>
                ) : (
                  ""
                )}
              </TableCell>
              <TableCell
                style={{ fontSize: "17px" }}
                component="th"
                scope="row"
              >
                {" "}
                <Switch
                  style={{ cursor: "pointer" }}
                  color="primary"
                  checked={i.is_status}
                  onChange={(_, val) => {
                    handleStatusUpdate(i.id, val);
                  }}
                />
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    );
  };

  return (
    <>
      <div className="container">
        <Sidebar />
        <Header name="Manage Customer" />
        <div className="content_category">
          {/* list heading and add btn */}
          <div className="headADD_states">
            <span
              className="list_heading"
              onClick={() => {
                window.location.reload(false);
              }}
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              All Customer
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search Customer name"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
            </div>
          </div>

          {/* list of countries */}
          <div className="sss">
            <div className="ss">
              <TableContainer className="cat_table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Name
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Email
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Phone Number
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Wishlist
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Status
                      </TableCell>

                      <TableCell
                        style={{
                          fontSize: "18px",
                          marginLeft: "30px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderedData &&
                    renderedData.length &&
                    renderData(
                      renderedData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    )}
                </Table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={
                    renderedData && renderedData.length
                      ? renderedData.length
                      : 0
                  }
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  showfirstbutton="true"
                  showlastbutton="true"
                />
              </TableContainer>
            </div>
          </div>
        </div>
        <UserWishlist
          open={showWishlist}
          setOpen={setShowWishlist}
          data={wishlistData}
        />
        <CustomerDetails
          open={showDetails}
          setOpen={setShowDetails}
          details={details}
        />
        <Footer />
      </div>

      <Toaster />
    </>
  );
};

export default CustomerList;

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TableContainer,
  TableCell,
  TableRow,
  Box,
  Table,
  DialogActions,
  Button,
} from "@material-ui/core";
import React from "react";

const VendorProfile = ({ open, setOpen, vendor }) => {
  // Handlers
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Box sx={{ width: 1, maxWidth: "lg" }}>
        <Dialog open={open} onClose={handleClose} fullWidth>
          <DialogTitle>Vendor Profile</DialogTitle>
          <DialogContent>
            <TableContainer>
              <Table>
                <TableRow>
                  <TableCell>Company Name</TableCell>
                  <TableCell>{vendor?.company_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Display Name</TableCell>
                  <TableCell>{vendor?.display_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company Phone</TableCell>
                  <TableCell>{vendor?.company_phone_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Comapny E-mail</TableCell>
                  <TableCell>{vendor?.company_email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Name</TableCell>
                  <TableCell>{vendor?.contact_person_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person Phone</TableCell>
                  <TableCell>{vendor?.contact_person_phone_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Contact Person E-mail</TableCell>
                  <TableCell>{vendor?.contact_person_email}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company Address</TableCell>
                  <TableCell>{vendor?.company_adress}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Warehouse Address</TableCell>
                  <TableCell>{vendor?.company_warehouse_adress}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company GST</TableCell>
                  <TableCell>{vendor?.company_gst}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Company PAN</TableCell>
                  <TableCell>{vendor?.company_pan_number}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Category</TableCell>
                  <TableCell>{vendor?.category_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sub-Category</TableCell>
                  <TableCell>{vendor?.sub_category_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Sub Sub-Category</TableCell>
                  <TableCell>{vendor?.sub_sub_category_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Country</TableCell>
                  <TableCell>{vendor?.country_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>State</TableCell>
                  <TableCell>{vendor?.state_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>City</TableCell>
                  <TableCell>{vendor?.city_name}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Zipcode</TableCell>
                  <TableCell>{vendor?.zipcode}</TableCell>
                </TableRow>
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleClose} color="secondary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default VendorProfile;

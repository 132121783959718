import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addNewBrand,
  deleteBrandData,
  getAllBrands,
  updateBrandData,
  updateBrandStatusData,
} from "../services/product.service";

const useBrandsQuery = () => {
  return useQuery({
    queryKey: ["brands"],
    queryFn: getAllBrands,
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useAddBrandData = () => {
  const queryClient = useQueryClient();
  return useMutation(addNewBrand, {
    onSettled: () => {
      queryClient.invalidateQueries("brands");
    },
    onError: (error) => {
      console.error("error when adding new Brand", error);
    },
  });
};

const useUpdateBrandData = () => {
  const queryClient = useQueryClient();
  return useMutation(updateBrandData, {
    onSettled: () => {
      queryClient.invalidateQueries("brands");
    },
    onError: (error) => {
      console.error("error when adding new Brand", error);
    },
  });
};

const useDeleteBrandData = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteBrandData, {
    onSettled: () => {
      queryClient.invalidateQueries("brands");
    },
    onError: (error) => {
      console.error("error when adding new Brand", error);
    },
  });
};

const useUpdateBrandStatus = () => {
  const queryClient = useQueryClient();
  return useMutation(updateBrandStatusData, {
    onSettled: () => {
      queryClient.invalidateQueries("brands");
    },
    onError: (error) => {
      console.error("error when adding new Brand", error);
    },
  });
};

export {
  useBrandsQuery,
  useAddBrandData,
  useUpdateBrandData,
  useDeleteBrandData,
  useUpdateBrandStatus,
};

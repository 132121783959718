import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  addWebSection,
  deleteWebSection,
  getAllWebSections,
  updateWebSection,
} from "../services/web_home.service";

const useWebSectionQueries = () => {
  return useQuery({
    queryFn: getAllWebSections,
    queryKey: ["web_sections"],
    staleTime: 300000,
    cacheTime: 3600000,
  });
};

const useAddWebSection = () => {
  const queryClient = useQueryClient();
  return useMutation(addWebSection, {
    onSettled: () => {
      queryClient.invalidateQueries("web_sections");
    },
    onError: (error) => {
      console.error("error when adding new Web-Section", error);
    },
  });
};

const useUpdateWebSection = () => {
  const queryClient = useQueryClient();
  return useMutation(updateWebSection, {
    onSettled: () => {
      queryClient.invalidateQueries("web_sections");
    },
    onError: (error) => {
      console.error("error when updating Web-Section", error);
    },
  });
};

const useDeleteWebSection = () => {
  const queryClient = useQueryClient();
  return useMutation(deleteWebSection, {
    onSettled: () => {
      queryClient.invalidateQueries("web_sections");
    },
    onError: (error) => {
      console.error("error when deleting Web-Section", error);
    },
  });
};

export {
  useWebSectionQueries,
  useAddWebSection,
  useUpdateWebSection,
  useDeleteWebSection,
};

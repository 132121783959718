import { Button, CircularProgress, TextField } from "@material-ui/core";
import { AddCircleOutline } from "@material-ui/icons";
import React, { useState } from "react";
import { useEffect } from "react";
import {
  useCategoriesQuery,
  useDeleteCategory,
} from "../../../hooks/category.hooks";
import Layout from "../../ui/Layout";
import MyTable from "../../ui/MyTable";
import { CategoryColumns } from "./columns";
import { filterData } from "../../../utils/constants";
import AddCategory from "./AddCategory";
import { Toaster } from "react-hot-toast";
import DelBox from "../../ui/DelBox";
import popMsg from "../../../utils/popup";
import { useQueryClient } from "@tanstack/react-query";
import EditCategory from "./EditCategory";

const Categories = () => {
  const [search, setSearch] = useState("");
  const [renderedData, setRenderedData] = useState([]);
  const [openAddForm, setOpenAddForm] = useState(false);

  const [delOpen, setDelOpen] = useState(false);
  const [delId, setDelId] = useState(null);

  const [updateCategory, setUpdateCategory] = useState();
  const [openUpdate, setOpenUpdate] = useState(false);

  const columns = CategoryColumns(
    setDelOpen,
    setDelId,
    setOpenUpdate,
    setUpdateCategory
  );
  const { mutate: deleteCategory } = useDeleteCategory();

  const { data, isLoading, isError, error, status } = useCategoriesQuery();

  const queryClient = useQueryClient();
  useEffect(() => {
    if (status === "success") setRenderedData(data);
  }, [status]);
  useEffect(() => {
    filterData("category_name", search, data, setRenderedData);
  }, [search, data]);

  if (isError) console.error(error);

  const handleDelete = () => {
    deleteCategory(delId, {
      onSuccess: () => {
        setDelOpen(false);
        popMsg("Deleted Successfully", true);
        queryClient.invalidateQueries("categories");
      },
    });
  };

  return (
    <Layout title="Categories">
      <div style={{ overflow: "unset" }}>
        <div className="top_block">
          <div className="inner_top_right">
            <TextField
              aria-label="search_input_category"
              size="small"
              variant="outlined"
              placeholder="Search Category Name"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <Button
              startIcon={<AddCircleOutline />}
              size="small"
              className="add_button"
              variant="contained"
              onClick={() => setOpenAddForm(true)}
            >
              Add
            </Button>
          </div>
        </div>
        {isLoading && (
          <div className="loading_spinner">
            <CircularProgress />
          </div>
        )}
        {!isLoading && renderedData && renderedData.length && (
          <MyTable columns={columns} data={renderedData} />
        )}
      </div>
      {openAddForm && (
        <AddCategory open={openAddForm} setOpen={setOpenAddForm} />
      )}
      {delOpen && (
        <DelBox
          open={delOpen}
          setOpen={setDelOpen}
          deleteEntry={handleDelete}
        />
      )}
      {openUpdate && (
        <EditCategory
          open={openUpdate}
          setOpen={setOpenUpdate}
          category={updateCategory}
          key={`edit_category_${updateCategory.id}`}
        />
      )}
      <Toaster />
    </Layout>
  );
};

export default Categories;

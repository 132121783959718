import { useMutation } from "@tanstack/react-query";
import { loginUser } from "../services/login.service";

const useLogin = () => {
  return useMutation(loginUser, {
    onError: (err) => {
      console.error("error while login: ", err);
    },
  });
};
export { useLogin };

import {
  Button,
  // makeStyles,
  TextField,
  TablePagination,
} from "@material-ui/core";
import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useEffect, useState } from "react";
import { Toaster } from "react-hot-toast";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VendorProfile from "./VendorProfile";
import Sidebar from "../../Container/Sidebar/Sidebar";
import Header from "../../Container/Header/Header";
import Footer from "../../Container/Footer/Footer";
import "./Vendor.css";
import AddVendor from "./AddVendor";
import { getAllVendors } from "../../../services/vendor.service";
import UpdateVendor from "./UpdateVendor";
import { apiRoutes } from "../../../utils/apiRoutes";
import DeleteConfirmation from "../../ui/DeleteConfirmation";
import { initialRows } from "../../../utils/constants";
const Vendor = () => {
  const [openAddVendor, setOpenAddVendor] = useState(false);
  const [addedVendor, setAddedVendor] = useState(false);
  const [renderedData, setRenderedData] = useState();
  const [openUpdateVendor, setOpenUpdateVendor] = useState(false);
  const [updatedVendor, setUpdatedVendor] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState("");
  const [deletedVendor, setDeletedVendor] = useState(false);
  const [delopen, setdelOpen] = useState(false);
  const [delID, setdelID] = useState(false);
  const [data, setData] = useState([]);
  const [openProfile, setOpenProfile] = useState(false);
  const [vendorProfile, setVendorProfile] = useState();
  let [search, setSearch] = useState("");
  // -----------
  const [rowsPerPage, setRowsPerPage] = useState(initialRows);
  const [page, setPage] = useState(0);
  // -----------

  // UseEffects
  useEffect(() => {
    getVendors();
  }, []);

  useEffect(() => {
    if (addedVendor) getVendors();
    setAddedVendor(false);
  }, [addedVendor]);

  useEffect(() => {
    filterData();
  }, [search]);

  useEffect(() => {
    if (updatedVendor) getVendors();
    setUpdatedVendor(false);
  }, [updatedVendor]);

  useEffect(() => {
    if (deletedVendor) getVendors();
    setDeletedVendor(false);
  }, [deletedVendor]);

  // Handlers
  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(+e.target.value);
    // setPage(0);
  };

  // Handlers - END

  // Functions
  async function getVendors() {
    const data = await getAllVendors();
    setData(data);
    setRenderedData(data);
  }

  async function filterData() {
    let filteredData = data.filter((vendor) => {
      return vendor.company_name
        .toString()
        .toLowerCase()
        .includes(search.toLowerCase());
    });
    setRenderedData(filteredData);
  }
  // Table Body
  const renderData = (renderedData) => {
    if (!renderedData.length) return null;
    return (
      <TableBody>
        {renderedData
          ? renderedData.map((i, index) => {
              return (
                <TableRow
                  className="list_row"
                  key={index}
                  sx={{
                    "&:last-child td, &:last-child th": {
                      border: 0,
                    },
                  }}
                >
                  <TableCell
                    style={{ fontSize: "17px" }}
                    component="th"
                    scope="row"
                  >
                    {(page + 1) * rowsPerPage - rowsPerPage + index + 1}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {i.company_name}
                  </TableCell>
                  <TableCell
                    style={{ fontSize: "17px", cursor: "pointer" }}
                    align="right"
                  >
                    <ModeEditIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        setOpenUpdateVendor(true);
                        setSelectedVendor(i);
                      }}
                    />
                    <DeleteIcon
                      style={{
                        width: "30px",
                        height: "30px",
                        color: "#023F9E",
                        cursor: "pointer",
                        padding: "5px",
                      }}
                      onClick={() => {
                        setdelOpen(true);
                        setdelID(i.id);
                      }}
                    />
                    <VisibilityIcon
                      onClick={() => {
                        setOpenProfile(true);
                        setVendorProfile(i);
                      }}
                      style={{
                        width: "30px",
                        height: "30px",
                        cursor: "pointer",
                        color: "#023F9E",
                        padding: "5px",
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })
          : ""}
      </TableBody>
    );
  };

  return (
    <>
      <div className="container">
        <Sidebar />
        <Header name="Manage Vendor" />
        <div className="content_category">
          {/* list heading and add btn */}
          <div className="headADD_states">
            <span
              onClick={() => {
                window.location.reload(false);
              }}
              className="list_heading"
              style={{
                marginLeft: "6px",
                marginTop: "14px",
                cursor: "pointer",
              }}
            >
              All Vendors
            </span>
            <div>
              <TextField
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                id="outlined-basic"
                placeholder="Search Vendor"
                variant="outlined"
                style={{ marginRight: "15px" }}
              />
              <Button
                size="large"
                className="addbtn"
                variant="contained"
                style={{
                  height: "55px",
                  backgroundColor: "#023F9E",
                  color: "white",
                }}
                startIcon={<AddIcon />}
                onClick={() => setOpenAddVendor(true)}
              >
                Add
              </Button>
            </div>
          </div>
          {/* list of countries */}
          <div className="sss">
            <div className="ss">
              <TableContainer className="cat_table" component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                      >
                        No.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Vendor Name
                      </TableCell>
                      <TableCell
                        style={{
                          fontSize: "18px",
                          fontWeight: "550",
                          color: "#36454F",
                        }}
                        align="right"
                      >
                        Action
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  {renderedData &&
                    renderedData.length &&
                    renderData(
                      renderedData.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    )}
                </Table>
                <TablePagination
                  sx={{ px: 2 }}
                  page={page}
                  component="div"
                  rowsPerPage={rowsPerPage}
                  count={
                    renderedData && renderedData.length
                      ? renderedData.length
                      : 0
                  }
                  onPageChange={handleChangePage}
                  rowsPerPageOptions={[5, 10, 25]}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  nextIconButtonProps={{ "aria-label": "Next Page" }}
                  backIconButtonProps={{ "aria-label": "Previous Page" }}
                  showFirstButton
                  showLastButton
                />
              </TableContainer>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      <AddVendor
        open={openAddVendor}
        setOpen={setOpenAddVendor}
        setAddedVendor={setAddedVendor}
      />
      <UpdateVendor
        open={openUpdateVendor}
        setOpen={setOpenUpdateVendor}
        setUpdatedVendor={setUpdatedVendor}
        vendor={selectedVendor}
      />
      <DeleteConfirmation
        open={delopen}
        setOpen={setdelOpen}
        url={`${apiRoutes.deleteVendor}${delID}`}
        setDeleted={setDeletedVendor}
      />
      <VendorProfile
        open={openProfile}
        setOpen={setOpenProfile}
        vendor={vendorProfile}
      />
      <Toaster />
    </>
  );
};

export default Vendor;

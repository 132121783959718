import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import "../../../App.css";
import { useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import astheticMenu from "../../Redux/action/astheticMenu";
import physicalMenu from "../../Redux/action/physicalMenu";
import frontEndMenu from "../../Redux/action/frontEndMenu";
import sectionMenu from "../../Redux/action/sectionMenu";
import { BiWorld } from "react-icons/bi";
import { FaCity } from "react-icons/fa";
import { GiModernCity } from "react-icons/gi";
import { BsFillPinMapFill } from "react-icons/bs";
import MenuIcon from "@mui/icons-material/Menu";
import HiOutlineShoppingCart from "@mui/icons-material/ShoppingCart";
import { TbBrandFlickr } from "react-icons/tb";
import { BiCategoryAlt } from "react-icons/bi";
import { TbTriangleSquareCircle } from "react-icons/tb";
import { IoMdCube } from "react-icons/io";
import { FaHandshake } from "react-icons/fa";
import { FaWeightHanging } from "react-icons/fa";
import { HiOfficeBuilding } from "react-icons/hi";
import { FaBoxOpen } from "react-icons/fa";
import { BsGear } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { MdNotifications } from "react-icons/md";

const Sidebar = () => {
  let navigate = useNavigate();
  let [show, setShow] = useState(false);
  let [showorder, setShoworder] = useState(false);
  let [physical, setPhysical] = useState(false);
  let [frontend, setFrontend] = useState(false);
  let [slider, setSlider] = useState(false);
  let [section, setSection] = useState(false);
  let dispatch = useDispatch();
  let asthMenu = useSelector((state) => state.reducer.astheticMenu);
  let physMenu = useSelector((state) => state.reducer.physicalMenu);
  let frontMenu = useSelector((state) => state.reducer.frontEndMenu);
  let secMenu = useSelector((state) => state.reducer.sectionMenu);

  const location = useLocation();

  function isAvailableInUrl(str) {
    const result = location.pathname.includes(str);
    return result;
  }

  // console.log("secMenu :", secMenu);
  useEffect(() => {
    setShow(asthMenu);
  }, [asthMenu]);
  useEffect(() => {
    setPhysical(physMenu);
  }, [physMenu]);
  useEffect(() => {
    if (secMenu) {
      setFrontend(true);
      setSection(secMenu);
    }
  }, [secMenu]);
  useEffect(() => {
    // setFrontend(frontMenu);
    if (frontMenu) {
      setSlider(true);
      setFrontend(true);
    }
  }, [frontMenu]);
  return (
    <>
      <div className="sidebar">
        <img
          onClick={() => navigate("/home")}
          alt={"APP-LOGO"}
          src={require("./sp.png")}
          style={{ width: "100%", justifySelf: "center" }}
        />
        {/* Address asthetic */}
        <div>
          <List>
            <hr />
            <div
              className={`${
                isAvailableInUrl("/home")
                  ? "sideName_color_active sideName_color"
                  : "sideName_color"
              }`}
              disablePadding
            >
              <ListItemButton
                onClick={() => {
                  navigate("/home");
                  dispatch(astheticMenu(false));
                  dispatch(physicalMenu(false));
                  dispatch(sectionMenu(false));
                  dispatch(frontEndMenu(false));
                  setPhysical(false);
                  setShoworder(false);
                  setFrontend(false);
                  setSection(false);
                  setShow(false);
                }}
              >
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary={"Dashboard"} />
              </ListItemButton>
            </div>
            {/* /////////////////////////asthetic /////////////////////////// */}

            {show ? (
              <div
                onClick={() => setShow(false)}
                style={{ backgroundColor: show ? "#f5f5f5" : "white" }}
                className="sideName_color_dropdown"
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MenuIcon />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "black" }}
                    primary={"Asthetic Address"}
                  />
                  <ArrowDropDownIcon style={{ color: "black" }} />
                </ListItemButton>
              </div>
            ) : (
              <div
                onClick={() => {
                  setPhysical(false);
                  setFrontend(false);
                  setSlider(false);
                  setShoworder(false);
                  setShow(true);
                }}
                style={{ backgroundColor: show ? "#f5f5f5" : "white" }}
                className="sideName_color_dropdown"
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MenuIcon />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "black" }}
                    primary={"Asthetic Address"}
                  />
                  <ArrowRightIcon style={{ color: "black" }} />
                </ListItemButton>
              </div>
            )}

            {show ? (
              <>
                <div className="submenu">
                  {/* country */}
                  <div
                    className={`${
                      isAvailableInUrl("/country")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(astheticMenu(true));
                      dispatch(physicalMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/country");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <BiWorld style={{ width: "25px", height: "25px" }} />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Country"} />
                    </ListItemButton>
                  </div>
                  {/* state */}
                  <div
                    className={`${
                      isAvailableInUrl("/state")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(astheticMenu(true));
                      dispatch(physicalMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/state");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <FaCity style={{ width: "25px", height: "25px" }} />
                      </ListItemIcon>
                      <ListItemText primary={"Manage State"} />
                    </ListItemButton>
                  </div>
                  {/* City */}
                  <div
                    className={`${
                      isAvailableInUrl("/city")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(astheticMenu(true));
                      dispatch(physicalMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/city");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <GiModernCity
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage City"} />
                    </ListItemButton>
                  </div>
                  {/* Zipcode */}
                  <div
                    className={`${
                      isAvailableInUrl("/zipcode")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(astheticMenu(true));
                      dispatch(physicalMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/zipcode");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <BsFillPinMapFill
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Zipcode"} />
                    </ListItemButton>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}

            {/* ///////////////////end of asthetic dropdown */}

            {/* /////////////////////////Physical Product /////////////////////////// */}

            {physical ? (
              <div
                onClick={() => {
                  setPhysical(false);
                  setShoworder(false);
                  setShow(false);
                }}
                style={{ backgroundColor: physical ? "#f5f5f5" : "white" }}
                className="sideName_color_dropdown"
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <HiOutlineShoppingCart />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "black" }}
                    primary={"Physical Product"}
                  />
                  <ArrowDropDownIcon style={{ color: "black" }} />
                </ListItemButton>
              </div>
            ) : (
              <div
                onClick={() => {
                  setFrontend(false);
                  setPhysical(true);
                  setShoworder(false);
                  setShow(false);
                  setSlider(false);
                }}
                style={{ backgroundColor: physical ? "#f5f5f5" : "white" }}
                className="sideName_color_dropdown"
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <HiOutlineShoppingCart />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "black" }}
                    primary={"Physical Product"}
                  />
                  <ArrowRightIcon style={{ color: "black" }} />
                </ListItemButton>
              </div>
            )}

            {physical ? (
              <>
                <div className="submenu">
                  {/* Category */}
                  <div
                    className={`${
                      isAvailableInUrl("/category")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(physicalMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/category");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <BiCategoryAlt
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Category"} />
                    </ListItemButton>
                  </div>
                  <hr />
                  {/* Brand */}
                  <div
                    className={`${
                      isAvailableInUrl("/brand")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(physicalMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/brand");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <IoMdCube style={{ width: "25px", height: "25px" }} />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Brand"} />
                    </ListItemButton>
                  </div>
                  <hr />
                  {/* SubCategory */}
                  <div
                    className={`${
                      isAvailableInUrl("/subcategory")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(physicalMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/subcategory");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <TbTriangleSquareCircle
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage SubCategory"} />
                    </ListItemButton>
                  </div>
                  <hr />
                  {/* subsubcategory */}
                  <div
                    className={`${
                      isAvailableInUrl("/subsubcategory")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(physicalMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/subsubcategory");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <TbBrandFlickr
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage SubSubCategory"} />
                    </ListItemButton>
                  </div>
                  <hr />
                  {/* Vendor */}
                  <div
                    className={`${
                      isAvailableInUrl("/vendor")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(physicalMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/vendor");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <FaHandshake
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Vendor"} />
                    </ListItemButton>
                  </div>
                  <hr />
                  {/* Service */}
                  <div
                    className={`${
                      isAvailableInUrl("/service")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(physicalMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/service");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <DashboardIcon />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Service"} />
                    </ListItemButton>
                  </div>
                  <hr />
                  {/* Agency */}
                  <div
                    className={`${
                      isAvailableInUrl("/agency")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(physicalMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/agency");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <HiOfficeBuilding
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Agency"} />
                    </ListItemButton>
                  </div>
                  <hr />
                  {/* unit */}
                  <div
                    className={`${
                      isAvailableInUrl("/unit")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(physicalMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/unit");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <FaWeightHanging
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Unit"} />
                    </ListItemButton>
                  </div>
                  <hr />
                  {/* Product */}
                  <div
                    className={`${
                      isAvailableInUrl("/manage_product")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(physicalMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/manage_product");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <FaBoxOpen style={{ width: "25px", height: "25px" }} />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Product"} />
                    </ListItemButton>
                  </div>
                  <hr />
                </div>
              </>
            ) : (
              ""
            )}
            {/* /////////////////////////Fronend Setting /////////////////////////// */}

            {frontend ? (
              <div
                onClick={() => setFrontend(false)}
                style={{ backgroundColor: frontend ? "#f5f5f5" : "white" }}
                className="sideName_color_dropdown"
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AccessTimeFilledIcon />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "black" }}
                    primary={"Frontend-Setting"}
                  />
                  <ArrowDropDownIcon style={{ color: "black" }} />
                </ListItemButton>
              </div>
            ) : (
              <div
                onClick={() => {
                  setFrontend(true);
                  setShow(false);
                  setPhysical(false);
                  setShoworder(false);
                }}
                style={{ backgroundColor: frontend ? "#f5f5f5" : "white" }}
                className="sideName_color_dropdown"
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <AccessTimeFilledIcon />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "black" }}
                    primary={"Frontend-Setting"}
                  />
                  <ArrowRightIcon style={{ color: "black" }} />
                </ListItemButton>
              </div>
            )}

            {frontend ? (
              <>
                {/* Slider Setting */}

                {slider ? (
                  <div
                    onClick={() => {
                      dispatch(frontEndMenu(true));
                      dispatch(astheticMenu(false));
                      dispatch(physicalMenu(false));
                      setSlider(false);
                      setShoworder(false);
                    }}
                    className="sideName_color_dropdown"
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AccessTimeFilledIcon />
                      </ListItemIcon>
                      <ListItemText
                        style={{ color: "black" }}
                        primary={"Slider Setting"}
                      />
                      <ArrowDropDownIcon style={{ color: "black" }} />
                    </ListItemButton>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setSlider(true);
                      setShow(false);
                      setPhysical(false);
                      setShoworder(false);
                    }}
                    className="sideName_color_dropdown"
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AccessTimeFilledIcon />
                      </ListItemIcon>
                      <ListItemText
                        style={{ color: "black" }}
                        primary={"Slider Setting"}
                      />
                      <ArrowRightIcon style={{ color: "black" }} />
                    </ListItemButton>
                  </div>
                )}

                {slider ? (
                  <>
                    <div className="submenu">
                      {/* web slider */}
                      <div
                        className={`${
                          isAvailableInUrl("/webslider")
                            ? "sideName_color_active sideName_color"
                            : "sideName_color"
                        }`}
                        disablePadding
                        onClick={() => {
                          dispatch(astheticMenu(false));
                          dispatch(physicalMenu(false));
                          dispatch(sectionMenu(false));
                          dispatch(frontEndMenu(true));
                          navigate("/webslider");
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <DashboardIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Web Slider"} />
                        </ListItemButton>
                      </div>
                      {/* mobile slider */}
                      <div
                        className={`${
                          isAvailableInUrl("/mobileslider")
                            ? "sideName_color_active sideName_color"
                            : "sideName_color"
                        }`}
                        disablePadding
                        onClick={() => {
                          // dispatch(astheticMenu(false));
                          // dispatch(physicalMenu(false));
                          // dispatch(sectionMenu(false));
                          dispatch(frontEndMenu(true));
                          navigate("/mobileslider");
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <DashboardIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Mobile Slider"} />
                        </ListItemButton>
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {/* Home Section */}

                {section ? (
                  <div
                    onClick={() => {
                      setSection(false);
                    }}
                    className="sideName_color_dropdown"
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AccessTimeFilledIcon />
                      </ListItemIcon>
                      <ListItemText
                        style={{ color: "black" }}
                        primary={"Home Section"}
                      />
                      <ArrowDropDownIcon style={{ color: "black" }} />
                    </ListItemButton>
                  </div>
                ) : (
                  <div
                    onClick={() => {
                      setSection(true);
                    }}
                    className="sideName_color_dropdown"
                    disablePadding
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <AccessTimeFilledIcon />
                      </ListItemIcon>
                      <ListItemText
                        style={{ color: "black" }}
                        primary={"Home Section"}
                      />
                      <ArrowRightIcon style={{ color: "black" }} />
                    </ListItemButton>
                  </div>
                )}

                {section ? (
                  <>
                    <div className="submenu">
                      {/* web section */}
                      <div
                        className={`${
                          isAvailableInUrl("/websection")
                            ? "sideName_color_active sideName_color"
                            : "sideName_color"
                        }`}
                        disablePadding
                        onClick={() => {
                          dispatch(sectionMenu(true));
                          setSlider(false);
                          dispatch(physicalMenu(false));
                          dispatch(astheticMenu(false));
                          dispatch(frontEndMenu(false));
                          navigate("/websection");
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <DashboardIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Web Section"} />
                        </ListItemButton>
                      </div>
                      {/* mobile section */}
                      {/* <div
                        className="sideName_color"
                        disablePadding
                        onClick={() => {
                          // dispatch(frontEndMenu(true))
                          dispatch(sectionMenu(true));
                          setSlider(false);
                          dispatch(physicalMenu(false));
                          dispatch(astheticMenu(false));
                          dispatch(frontEndMenu(false));
                          navigate("/mobilesection");
                        }}
                      >
                        <ListItemButton>
                          <ListItemIcon>
                            <DashboardIcon />
                          </ListItemIcon>
                          <ListItemText primary={"Mobile Section"} />
                        </ListItemButton>
                      </div> */}
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {/* //////////////////////////////sub-admin///////////////////////////// */}
            <div
              className={`${
                isAvailableInUrl("/subadmin")
                  ? "sideName_color_active sideName_color"
                  : "sideName_color"
              }`}
              onClick={() => {
                setPhysical(false);
                setFrontend(false);
                setSection(false);
                setShow(false);
                setShoworder(false);
                setSlider(false);
                navigate("/subadmin");
              }}
              key={"2"}
              disablePadding
            >
              <ListItemButton>
                <ListItemIcon>
                  <AccessTimeFilledIcon />
                </ListItemIcon>
                <ListItemText primary={"Sub Admin"} />
              </ListItemButton>
            </div>

            {/* //////////////////////////////All Customer///////////////////////////// */}
            <div
              className={`${
                isAvailableInUrl("/customer")
                  ? "sideName_color_active sideName_color"
                  : "sideName_color"
              }`}
              disablePadding
              onClick={() => {
                dispatch(astheticMenu(false));
                dispatch(physicalMenu(false));
                dispatch(frontEndMenu(false));
                setPhysical(false);
                setFrontend(false);
                setSection(false);
                setShow(false);
                setShoworder(false);
                setSlider(false);
                navigate("/customer");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <BiWorld style={{ width: "25px", height: "25px" }} />
                </ListItemIcon>
                <ListItemText primary={"Customer List"} />
              </ListItemButton>
            </div>

            {/* /////////////////////////Order /////////////////////////// */}
            {showorder ? (
              <div
                onClick={() => setShoworder(false)}
                style={{ backgroundColor: show ? "#f5f5f5" : "white" }}
                className="sideName_color_dropdown"
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MenuIcon />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "black" }}
                    primary={"Order Details"}
                  />
                  <ArrowDropDownIcon style={{ color: "black" }} />
                </ListItemButton>
              </div>
            ) : (
              <div
                onClick={() => {
                  setPhysical(false);
                  setFrontend(false);
                  setSlider(false);
                  setShoworder(true);
                  setShow(false);
                }}
                style={{ backgroundColor: show ? "#f5f5f5" : "white" }}
                className="sideName_color_dropdown"
                disablePadding
              >
                <ListItemButton>
                  <ListItemIcon>
                    <MenuIcon />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: "black" }}
                    primary={"Order Details"}
                  />
                  <ArrowRightIcon style={{ color: "black" }} />
                </ListItemButton>
              </div>
            )}

            {showorder ? (
              <>
                <div className="submenu">
                  {/* All product order */}
                  <div
                    className={`${
                      isAvailableInUrl("/allorder")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(astheticMenu(false));
                      dispatch(physicalMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/allorder");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <BiWorld style={{ width: "25px", height: "25px" }} />
                      </ListItemIcon>
                      <ListItemText primary={"All Product Order"} />
                    </ListItemButton>
                  </div>
                  {/* All service order */}
                  <div
                    className={`${
                      isAvailableInUrl("/allservice")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(astheticMenu(false));
                      dispatch(physicalMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/allservice");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <FaCity style={{ width: "25px", height: "25px" }} />
                      </ListItemIcon>
                      <ListItemText primary={"All Service Order"} />
                    </ListItemButton>
                  </div>
                  <div
                    className={`${
                      isAvailableInUrl("/returnOrder")
                        ? "sideName_color_active sideName_color"
                        : "sideName_color"
                    }`}
                    disablePadding
                    onClick={() => {
                      dispatch(astheticMenu(false));
                      dispatch(physicalMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/returnOrder");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <GiReturnArrow
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Return Order"} />
                    </ListItemButton>
                  </div>
                  {/* City */}
                  {/* <div
                    className="sideName_color"
                    disablePadding
                    onClick={() => {
                      dispatch(astheticMenu(true));
                      dispatch(physicalMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/city");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <GiModernCity
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage City"} />
                    </ListItemButton>
                  </div> */}
                  {/* Zipcode */}
                  {/* <div
                    className="sideName_color"
                    disablePadding
                    onClick={() => {
                      dispatch(astheticMenu(true));
                      dispatch(physicalMenu(false));
                      dispatch(frontEndMenu(false));
                      navigate("/zipcode");
                    }}
                  >
                    <ListItemButton>
                      <ListItemIcon>
                        <BsFillPinMapFill
                          style={{ width: "25px", height: "25px" }}
                        />
                      </ListItemIcon>
                      <ListItemText primary={"Manage Zipcode"} />
                    </ListItemButton>
                  </div> */}
                </div>
              </>
            ) : (
              ""
            )}

            {/* //////////////////////////////Discount Coupon///////////////////////////// */}
            <div
              className={`${
                isAvailableInUrl("/discount/coupon")
                  ? "sideName_color_active sideName_color"
                  : "sideName_color"
              }`}
              disablePadding
              onClick={() => {
                dispatch(astheticMenu(false));
                dispatch(physicalMenu(false));
                dispatch(frontEndMenu(false));
                setPhysical(false);
                setFrontend(false);
                setSection(false);
                setShow(false);
                setShoworder(false);
                setSlider(false);
                navigate("/discount/coupon");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <BiWorld style={{ width: "25px", height: "25px" }} />
                </ListItemIcon>
                <ListItemText primary={"Discount Coupon"} />
              </ListItemButton>
            </div>

            {/* Notification Panel */}
            <div
              className={`${
                isAvailableInUrl("/notifications")
                  ? "sideName_color_active sideName_color"
                  : "sideName_color"
              }`}
              disablePadding
              onClick={() => {
                dispatch(astheticMenu(false));
                dispatch(physicalMenu(false));
                dispatch(frontEndMenu(false));
                setPhysical(false);
                setFrontend(false);
                setSection(false);
                setShow(false);
                setShoworder(false);
                setSlider(false);
                navigate("/notifications");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <MdNotifications style={{ width: "25px", height: "25px" }} />
                </ListItemIcon>
                <ListItemText primary={"Notifications Panel"} />
              </ListItemButton>
            </div>
            {/* //////////////////////          Settings            ////////////// */}
            <div
              className={`${
                isAvailableInUrl("/settings")
                  ? "sideName_color_active sideName_color"
                  : "sideName_color"
              }`}
              disablePadding
              onClick={() => {
                dispatch(astheticMenu(false));
                dispatch(physicalMenu(false));
                dispatch(frontEndMenu(false));
                setPhysical(false);
                setFrontend(false);
                setSection(false);
                setShow(false);
                setShoworder(false);
                setSlider(false);
                navigate("/settings");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <BsGear style={{ width: "25px", height: "25px" }} />
                </ListItemIcon>
                <ListItemText primary={"Settings"} />
              </ListItemButton>
            </div>

            {/* ////////////////////////////// All Service ///////////////////////////// */}
            {/* <div
              className="sideName_color"
              disablePadding
              onClick={() => {
                dispatch(astheticMenu(false));
                dispatch(physicalMenu(false));
                dispatch(frontEndMenu(false));
                setPhysical(false);
                setFrontend(false);
                setSection(false);
                setShow(false);
                setShoworder(false);
                setSlider(false);
                navigate("/allservice");
              }}
            >
              <ListItemButton>
                <ListItemIcon>
                  <BiWorld style={{ width: "25px", height: "25px" }} />
                </ListItemIcon>
                <ListItemText primary={"All Service Order"} />
              </ListItemButton>
            </div> */}
          </List>
        </div>
      </div>
    </>
  );
};

export default Sidebar;

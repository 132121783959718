import React from "react";
import { useState } from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  Grid,
  makeStyles,
  Input,
  InputLabel,
  FormControl,
} from "@material-ui/core";
import popMsg from "../../utils/popup";
import { addNotifications } from "../../services/notifications.service";

const AddNotificationForm = ({ open, setOpen, setAddedNotification }) => {
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const useStyles = makeStyles((theme) => ({
    textFieldLabel: {
      fontSize: "0.8rem",
    },
  }));
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
    resetForm();
  };
  const handleImageChange = (e) => {
    setImage(e.target.files[0]);
    setImagePreview(URL.createObjectURL(e.target.files[0]));
  };
  const handleSave = () => {
    add();
  };

  async function add() {
    if (!title) {
      popMsg("Title Required", false);
      return;
    }
    if (!description) {
      popMsg("Description Required", false);
      return;
    }
    if (!image) {
      popMsg("Image Required", false);
      return;
    }
    let payload = new FormData();
    payload.append("notification_title", title);
    payload.append("notification_description", description);
    payload.append("image", image);

    const data = await addNotifications(payload, setIsLoading);
    if (data.success) {
      popMsg(data.message, true);
      setAddedNotification(true);
      handleClose();
    }
  }

  function resetForm() {
    setTitle("");
    setDescription("");
    setImagePreview(null);
    setImage("");
  }

  return (
    <Box sx={{ width: 1 }}>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Add Service</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Notification Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                fullWidth
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                multiline
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                fullWidth
                InputLabelProps={{
                  className: classes.textFieldLabel,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel style={{ fontSize: "0.8rem" }}>
                  Select Image :
                </InputLabel>
                <Input
                  InputLabelProps={{
                    className: classes.textFieldLabel,
                  }}
                  type="file"
                  onChange={handleImageChange}
                />
                {imagePreview && (
                  <img
                    style={{
                      height: "10rem",
                      width: "15rem",
                      marginTop: "1rem",
                      marginLeft: "10%",
                    }}
                    src={imagePreview}
                    alt={"service-image"}
                  />
                )}
              </FormControl>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleClose} color="secondary">
            Close
          </Button>
          <Button
            variant="outlined"
            disabled={isLoading}
            onClick={handleSave}
            color="primary"
          >
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddNotificationForm;
